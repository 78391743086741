const Skeleton = () => {
  return (
    <div className="flex flex-col w-full h-full mt-10">
      <div className="h-14 w-full flex items-center gap-4">
        <div className="flex-1 bg-gray-100 h-full rounded-md" />
        <span className="flex items-center gap-4 text-xs border-b pb-2 font-medium italic text-light-black">
          <div className="h-2 w-5 rounded-full bg-gray-100"></div>% matched
        </span>
        <div className="flex-1 bg-gray-100 h-full rounded-md" />
      </div>

      <div className="flex flex-col mt-9">
        <div className="flex items-center w-full gap-32">
          <label className="font-semibold text-sm text-light-black flex-1">
            Candidate Skills
          </label>
          <label className="font-semibold text-sm text-light-black flex-1">
            Matched Skills
          </label>
        </div>

        <div className="flex mt-3 gap-32">
          <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] w-full gap-2">
            <span className="font-semibold text-xs text-primary">Skill</span>
            <span className="font-semibold text-xs text-primary">Rating</span>
            <span className="font-semibold text-xs text-primary">Years</span>
            <span className="font-semibold text-xs text-primary">When</span>
            <div />
          </div>
          <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] w-full gap-2">
            <span className="font-semibold text-xs text-primary">Skill</span>
            <span className="font-semibold text-xs text-primary">Rating</span>
            <span className="font-semibold text-xs text-primary">Years</span>
            <span className="font-semibold text-xs text-primary">When</span>
            <div />
          </div>
        </div>
        {/* Skills List */}
        <div className="flex flex-col gap-2.5 mt-2">
          {[1, 2, 3, 4, 5, 6, 7].map((skill) => {
            return (
              <div className="flex items-center">
                <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] items-center w-full gap-2">
                  <div className="bg-gray-100 rounded-md h-9"></div>
                  <div className="bg-gray-100 rounded-md h-9"></div>
                  <div className="bg-gray-100 rounded-md h-9"></div>
                  <div className="bg-gray-100 rounded-md h-9"></div>
                </div>
                <div className="w-80 flex items-center justify-center">
                  <span className="flex justify-center items-center text-xs border-b pb-2 font-medium italic text-light-black gap-4">
                    <div className="h-2 w-8 rounded-full bg-gray-100"></div> %
                    matched
                  </span>
                </div>
                <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] items-center w-full gap-2">
                  <div className="bg-gray-100 rounded-md h-9"></div>
                  <div className="bg-gray-100 rounded-md h-9"></div>
                  <div className="bg-gray-100 rounded-md h-9"></div>
                  <div className="bg-gray-100 rounded-md h-9"></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
