import SecureIcon from "../../assets/svg/SecureIcon";

const UnauthorizedPage = () => {
  return (
    <div className="flex flex-col justify-center items-center rounded-lg bg-white w-full flex-grow p-4">
      <SecureIcon className={"w-52 h-52"} />
      <div className="flex flex-col gap-2 items-center">
        <span className="font-bold text-4xl text-secondary">401</span>
        <span className="font-bold text-2xl text-primary">Unauthorized access!</span>
        <span className="font-medium text-sm text-gray-600">
          You are not authorized to access this page.
        </span>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
