import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import regexList from "../../../utils/regexList";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { changePassword } from "../../../endpoints/user.service";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";
import { removeToken } from "../../../store/actions/auth.action";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdatePassword = async (data) => {
    setIsLoading(true);
    const payload = data;
    payload.id = user?._id;
    delete payload.confirmPassword;
    try {
      const { success, data } = await changePassword(payload);
      if (success) {
        enqueueSnackbar("Password changed successfully.", {
          variant: "success",
        });
        dispatch(removeToken());
        navigate("/");
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
      console.log("Error changing password", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col bg-light-white-900 p-5 w-full h-full rounded-md relative">
      <form
        onSubmit={handleSubmit(handleUpdatePassword)}
        className="flex flex-col gap-5 mt-5"
      >
        <p className="text-primary text-sm font-bold underline text-center">
          Account password
        </p>

        <div className="flex flex-col gap-3 w-80 mx-auto mt-12">
          <CustomInput
            label={"Current Password"}
            name="oldPassword"
            placeholder={"Enter your Current Password"}
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.password,
                message: "Please enter a valid password.",
              },
            }}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"New Password"}
            name="newPassword"
            placeholder={"Enter New Password"}
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.password,
                message: "Please enter a valid password.",
              },
              validate: (value) =>
                value !== watch("oldPassword") ||
                "New password should not match with old password.",
            }}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"Confirm New Password"}
            name="confirmPassword"
            placeholder={"Enter New Password"}
            rules={{
              required: "This field is required.",
              validate: (value) =>
                value === watch("newPassword") ||
                "Confirm Password do not match.",
            }}
            control={control}
            errors={errors}
          />

          <div className="flex justify-center mt-14">
            <button
              type="submit"
              disabled={!isDirty}
              className={`flex gap-3 bg-primary text-white rounded-md px-3 py-2 text-sm font-medium ${
                isDirty ? "opacity-100" : "opacity-50"
              }`}
            >
              Change Password
              {isLoading && <LoadingAnimation />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
