import * as React from "react";
const Calender = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#6F6F6F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M15.833 3.334H4.167c-.92 0-1.667.746-1.667 1.667v11.666c0 .92.746 1.667 1.667 1.667h11.666c.92 0 1.667-.746 1.667-1.667V5.001c0-.92-.746-1.667-1.667-1.667ZM13.336 1.666v3.333M6.664 1.666v3.333M2.5 8.334h15"
    />
  </svg>
);
export default Calender;
