import * as React from "react";
const StackIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      fill="#6F6F6F"
      d="M2.25 5.625 9 9.563l6.75-3.938L9 1.687 2.25 5.626Z"
      opacity={0.2}
    />
    <path
      stroke="#6F6F6F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.25 12.375 9 16.313l6.75-3.938"
    />
    <path
      stroke="#6F6F6F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.25 9 9 12.938 15.75 9"
    />
    <path
      stroke="#6F6F6F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.25 5.625 9 9.563l6.75-3.938L9 1.687 2.25 5.626Z"
    />
  </svg>
);
export default StackIcon;
