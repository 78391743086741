import { useEffect, useState } from "react";

const ChatIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 24"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <path
        d="M14.9147 20.2959C15.4494 20.3999 15.9934 20.4546 16.5387 20.4599C17.9139 20.4614 19.2738 20.1705 20.528 19.6066L24.6547 20.2132C24.8477 20.2146 25.0388 20.1753 25.2156 20.0979C25.3923 20.0204 25.5508 19.9066 25.6806 19.7638C25.8105 19.621 25.9087 19.4525 25.9691 19.2692C26.0294 19.0858 26.0504 18.8919 26.0307 18.6999L25.5347 14.5319C26.0651 13.312 26.3371 11.9954 26.3334 10.6652C26.3299 9.38497 26.074 8.11797 25.5803 6.93671C25.0867 5.75545 24.365 4.68312 23.4565 3.78107C22.548 2.87901 21.4705 2.16494 20.2858 1.67971C19.1011 1.19448 17.8323 0.947605 16.552 0.953219C14.3525 0.952927 12.2177 1.69817 10.4962 3.06732C8.77475 4.43648 7.56815 6.34869 7.07336 8.49189"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5387 15.6602C16.5291 17.3433 15.9575 18.975 14.9147 20.2962C14.2089 21.1605 13.3187 21.8561 12.3094 22.332C11.3001 22.8079 10.1972 23.0522 9.08135 23.0469C8.0287 23.042 6.98868 22.8172 6.02801 22.3869L2.94668 22.9375C2.79186 22.9546 2.63518 22.9355 2.489 22.8818C2.34282 22.828 2.21114 22.7409 2.10435 22.6276C1.99755 22.5142 1.91857 22.3775 1.87362 22.2284C1.82867 22.0792 1.81899 21.9217 1.84535 21.7682L2.27201 18.6175C1.86715 17.684 1.661 16.6764 1.66668 15.6589C1.66525 14.0388 2.19245 12.4626 3.16824 11.1694C4.14402 9.87619 5.51511 8.93669 7.07335 8.49352C7.72971 8.2983 8.41124 8.20081 9.09601 8.20419C11.0714 8.20772 12.9646 8.995 14.3601 10.3932C15.7555 11.7914 16.539 13.6861 16.5387 15.6615"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
