import api from "../api";
import axios from "../utils/axios.instance";
import handleError from "./handle-error";

export const profileImage = async () => {
  return await axios.get(api.profileImage);
};

export const uploadUrl = async (url, payload) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      body: payload,
    });
    const responseData = await response.json();

    return responseData.result;
  } catch (err) {
    console.error(err);
    return handleError(err);
  }
};
