import * as React from "react";
const CallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <circle
      cx={12}
      cy={12}
      r={11.4}
      fill="#fff"
      stroke="#4D4D4D"
      strokeWidth={1.2}
    />
    <g fill="#4D4D4D" stroke="#4D4D4D" strokeWidth={0.6} clipPath="url(#a)">
      <path d="M14.552 19.6c-.067 0-.134-.006-.2-.018-2.288-.412-4.569-1.659-6.422-3.512-1.853-1.853-3.1-4.134-3.512-6.421-.077-.432.103-.881.461-1.145.458-.337.952-.65 1.467-.93a1.162 1.162 0 0 1 1.077-.028c1.14.574 1.897 1.532 2.247 2.845.095.356.01.741-.226 1.031l-.756.925a.435.435 0 0 0-.036.509 8.042 8.042 0 0 0 2.493 2.493c.156.099.36.084.508-.037l.925-.755c.29-.237.675-.322 1.031-.227 1.313.35 2.271 1.107 2.846 2.247a1.16 1.16 0 0 1-.028 1.078c-.28.515-.594 1.008-.931 1.466a1.18 1.18 0 0 1-.944.479ZM6.918 8.15a.473.473 0 0 0-.226.06c-.487.264-.952.56-1.384.877a.446.446 0 0 0-.177.433c.385 2.144 1.561 4.289 3.31 6.038 1.75 1.75 3.895 2.926 6.039 3.312a.446.446 0 0 0 .433-.178c.318-.432.613-.897.878-1.384a.438.438 0 0 0 .018-.405c-.485-.962-1.266-1.574-2.386-1.874a.438.438 0 0 0-.387.088l-.924.756c-.391.319-.936.354-1.356.086a8.762 8.762 0 0 1-2.715-2.715 1.157 1.157 0 0 1 .087-1.355l.755-.925a.436.436 0 0 0 .088-.386c-.3-1.12-.912-1.901-1.874-2.386a.399.399 0 0 0-.18-.042ZM19.233 12.38h-.016a.362.362 0 0 1-.346-.377 6.623 6.623 0 0 0-1.925-4.948 6.603 6.603 0 0 0-4.949-1.925.369.369 0 0 1-.377-.347.362.362 0 0 1 .347-.376 7.344 7.344 0 0 1 5.49 2.136 7.348 7.348 0 0 1 2.137 5.49.362.362 0 0 1-.361.347Z" />
      <path d="M17.307 12.38h-.021a.362.362 0 0 1-.34-.383 4.681 4.681 0 0 0-1.36-3.583 4.686 4.686 0 0 0-3.583-1.36.362.362 0 1 1-.042-.723 5.404 5.404 0 0 1 4.137 1.572 5.404 5.404 0 0 1 1.57 4.137.362.362 0 0 1-.361.34Z" />
      <path d="m15.384 12.38-.034-.001a.362.362 0 0 1-.327-.394 2.755 2.755 0 0 0-.797-2.21 2.754 2.754 0 0 0-2.21-.798.362.362 0 0 1-.068-.72 3.476 3.476 0 0 1 2.79 1.005 3.476 3.476 0 0 1 1.005 2.79.362.362 0 0 1-.36.328Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M4 4h16v16H4z" />
      </clipPath>
    </defs>
  </svg>
);
export default CallIcon;
