import { useEffect, useState } from "react";
import ChevronLeftIcon from "../../../../assets/svg/ChevronLeftIcon";
import AboutSection from "./components/AboutSection";
import AddContactSection from "./components/AddContactSection";
import CompanyIcon from "../../../../assets/svg/CompanyIcon";
import { useNavigate, useParams } from "react-router-dom";
import { getCompanyList } from "../../../../endpoints/company.service";

const tabs = [
  "About",
  "Schedule Interview",
  "Send Assessment Link",
  "Activity History",
  "Messages",
];

const AddCompanyPage = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [companyDetails, setCompanyDetails] = useState();

  useEffect(() => {
    if (companyId) {
      getCompanyList(`?companyId=${companyId}`).then((response) => {
        const { success, data } = response;
        if (success) {
          setCompanyDetails(data[0]);
        }
      });
    }
  }, [companyId]);

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center self-start"
      >
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>

      {/* Header section */}
      <div className="flex items-center justify-between rounded-lg border bg-[#FAFAFA] px-3 py-3.5 mt-5">
        <div className="flex items-center gap-4">
          <div className="flex items-center justify-center h-12 w-12 rounded-full bg-[#00000033] text-white font-bold text-lg">
            <CompanyIcon className={"w-6 h-6"} fill={"white"} />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-xs font-semibold text-[#6f6f6f]">
              {companyDetails?.name ?? "Company Name"}
            </label>
            <label className="text-xs font-semibold text-[#6f6f6f]">
              {companyDetails?.address1 ?? "Company Address"}
            </label>
          </div>
        </div>

        <div className="flex flex-col h-full">
          <label className="text-xs font-semibold text-primary">
            CA-FQ1478
          </label>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="flex items-center justify-between border-b px-3 mt-4">
        {tabs?.map((tab, tabIndex) => {
          return (
            <button
              key={tab}
              onClick={() => setActiveTab(tabIndex)}
              className={`${
                tabIndex === activeTab
                  ? "border-secondary"
                  : "border-transparent"
              } text-primary text-sm font-medium border-b-2 py-1 px-3`}
            >
              {tab}
            </button>
          );
        })}
      </div>

      <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar">
        {/* About Section */}
        <AboutSection
          companyDetails={companyDetails}
          setCompanyDetails={setCompanyDetails}
        />

        {/* Contact Section */}
        <AddContactSection />
      </div>
    </div>
  );
};

export default AddCompanyPage;
