import * as React from "react";
const AssessmentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      fill="#4D4D4D"
      d="M11.833 14H.166V1.75C.166.785.951 0 1.916 0h8.167c.964 0 1.75.785 1.75 1.75V14Zm-10.5-1.167h9.333V1.75a.584.584 0 0 0-.583-.583H1.916a.584.584 0 0 0-.583.583v11.083Zm8.166-9.916H6v1.166h3.5V2.917Zm0 3.5H6v1.166h3.5V6.417Zm0 3.5H6v1.166h3.5V9.917ZM4.833 2.333H2.499v2.334h2.334V2.333Zm0 3.5H2.499v2.334h2.334V5.833Zm0 3.5H2.499v2.334h2.334V9.333Z"
    />
  </svg>
);
export default AssessmentIcon;
