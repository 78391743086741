import { useState, useRef, useEffect } from "react";
import ChevronDown from "../../../../assets/svg/ChevronDown";

const SectionContainer = ({
  section,
  sectionIndex,
  activeQuestion,
  setActiveQuestion,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null); // Ref for the content container
  const [height, setHeight] = useState("0px"); // State to manage height for transition

  useEffect(() => {
    // Adjust height based on whether the section is open or closed
    if (isOpen) {
      setHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setHeight("0px");
    }
  }, [isOpen]); // Update height when isOpen changes

  useEffect(() => {
    if (activeQuestion?.section === sectionIndex) {
      setIsOpen(true);
    }
  }, [activeQuestion]);

  return (
    <div className="flex flex-col">
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex items-center justify-between p-4 bg-white rounded-lg shadow hover:bg-gray-100 transition-colors duration-200"
      >
        <div className="flex flex-col gap-1">
          <span className="font-semibold text-sm text-left text-primary">
            Section {sectionIndex + 1}:
          </span>
          <span className="font-medium text-sm text-left text-primary">
            {section?.title}
          </span>
        </div>
        <div
          className={`transition-transform duration-300 ease-in-out ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          <ChevronDown className={"w-4 h-4"} fill={"#6f6f6f"} />
        </div>
      </button>

      <div
        ref={contentRef}
        style={{ height }} // Dynamically set the height
        className="overflow-hidden transition-all duration-300 ease-in-out" // Add transition class
      >
        <div className="flex flex-col gap-1">
          {section?.questions?.map((question, questionIndex) => {
            const isActive =
              activeQuestion?.section === sectionIndex &&
              activeQuestion?.question === questionIndex;
            return (
              <div
                key={questionIndex}
                onClick={() =>
                  setActiveQuestion({
                    section: sectionIndex,
                    question: questionIndex,
                  })
                }
                className={`px-8 py-1.5 ${
                  isActive ? "bg-light-white-200" : "hover:bg-light-white-700"
                } relative cursor-pointer`}
              >
                {isActive ? (
                  <div className="h-full w-2 absolute bg-secondary top-0 right-0 rounded-l-md" />
                ) : null}
                <span className="font-medium text-sm text-light-black">
                  Question {questionIndex + 1}/{section?.questions?.length}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SectionContainer;
