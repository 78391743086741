import { useState } from "react";

const CustomCheckbox = ({ defaultValue, onChange }) => {
  const [isChecked, setIsChecked] = useState(defaultValue ?? false);

  return (
    <button
      type="button"
      onClick={() => {
        setIsChecked((prev) => {
          onChange && onChange(!prev);
          return !prev;
        });
      }}
      style={{
        border: "1px solid #DDDDDD",
      }}
      className={`w-12 h-7 rounded-full flex items-center ${
        isChecked ? "justify-end bg-secondary" : "bg-ngray"
      } px-0.5`}
    >
      {/* {isChecked.toString()} */}
      <div className="h-5 w-5 bg-white rounded-full"></div>
    </button>
  );
};

export default CustomCheckbox;
