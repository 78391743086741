import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface ModalProps {
  isOpen: boolean,
  children: ReactNode
  containerStyle?: string
}

const Modal = ({ isOpen, children, containerStyle }: ModalProps) => {
  if (!isOpen) return null; // Do not render if the modal is closed

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        className={twMerge(
          "bg-white p-6 rounded-lg shadow-sm max-w-5xl w-full relative",
          containerStyle // Correctly pass the `containerStyle` string here
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
