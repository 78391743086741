export const getOrdinalSuffix = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getDayWithOrdinal = (day) => {
  const suffix = getOrdinalSuffix(day);
  return (
    <>
      {day}
      <sup className="ordinal-suffix">{suffix}</sup>
    </>
  );
};
