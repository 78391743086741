import PlusIcon from "../../assets/svg/PlusIcon";
import ChevronDown from "../../assets/svg/ChevronDown";
import SearchIcon from "../../assets/svg/SearchIcon";
import GroupIcon from "../../assets/svg/GroupIcon";
import ProfileImg from "../../assets/images/profilepic.png";
import { useNavigate } from "react-router-dom";
import Phone from "../../assets/svg/Phone";
import Email from "../../assets/svg/Email";
import { useEffect, useState } from "react";
import {
  accountMembers,
  inviteUserViaEmail,
} from "../../endpoints/recruiter.service";
import { recruiterApprovalStatus } from "../../utils/constants";
import Spinner from "../../components/Spinner/Spinner";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import CrossBig from "../../assets/svg/CrossBig";
import AddIcon from "../../assets/svg/AddIcon";
import { useForm } from "react-hook-form";
import CustomInput from "../../components/CustomInput/CustomInput";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import { colors } from "../../utils/theme";

const AccountMembers = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user, companyDetails } = useSelector((state) => state.auth);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      inviteModal: false,
      isSubmitting: false,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
    },
  });
  const fields = watch();

  useEffect(() => {
    setLoading(true);
    accountMembers({
      page: 1,
      limit: 10,
    })
      .then((response) => {
        const { data, success } = response;
        if (success) {
          setMembers(data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const handleItemClick = (item) => {
    navigate(`/account/account-members/${item?._id}`, { state: { item } });
  };

  const handleFormSubmit = async () => {
    setValue("isSubmitting", true);
    try {
      await inviteUserViaEmail({
        firstName: fields?.firstName,
        lastName: fields?.lastName,
        email: fields?.email,
      });
      enqueueSnackbar("Member invited successfully", { variant: "success" });
      reset({
        inviteModal: false,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
      });
    } catch (error) {
      enqueueSnackbar("Error inviting member", { variant: "error" });
    } finally {
      setValue("isSubmitting", false);
    }
  };

  return (
    <div className="flex flex-col overflow-y-hidden h-full rounded-lg bg-white w-full flex-grow p-4">
      {/* Search and filter section */}
      <p className="text-center text-sm font-bold underline">
        {user?.companyName ?? companyDetails?.name} Staff Members
      </p>
      <div className="flex items-center justify-between w-full my-8">
        <div className="flex items-center gap-8">
          {/* Input container */}
          <div className="flex rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5">
            <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-2">
              <label className="text-primary text-sm font-medium">
                Search by
              </label>
              <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
            </button>
            <input
              className="bg-white text-xs font-medium text-light-black outline-none px-3 py-2 rounded-lg w-80"
              placeholder="Search by Name, Title or Ref number..."
            />
            <SearchIcon className={"w-6 h-6 cursor-pointer"} fill={"#2e2e2e"} />
          </div>
        </div>

        <button
          onClick={() => setValue("inviteModal", true)}
          className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-semibold text-sm"
        >
          <PlusIcon className={"w-4 h-4"} fill={"white"} />
          Invite Members
        </button>
      </div>

      {fields?.inviteModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-1/3">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-xl font-semibold">Invite Member</h2>
              <button onClick={() => setValue("inviteModal", false)}>
                <CrossBig width={24} height={24} />
              </button>
            </div>
            <div className="bg-[#F9FAFB] px-8 py-3 rounded-2xl">
              <div className="mb-4">
                <CustomInput
                  label="First Name"
                  name="firstName"
                  control={control}
                  errors={errors}
                  rules={{
                    required: "This field is required.",
                  }}
                />
              </div>
              <div className="mb-4">
                <CustomInput
                  label="Last Name"
                  name="lastName"
                  control={control}
                  errors={errors}
                  rules={{
                    required: "This field is required.",
                  }}
                />
              </div>
              <div className="mb-4">
                <CustomInput
                  label="Company email address"
                  name="email"
                  control={control}
                  errors={errors}
                  rules={{
                    required: "This field is required.",
                  }}
                />
              </div>
              <button className="py-2 px-4 rounded-md text-[#2E2E2E] text-xs bg-[#F7F7F7] flex gap-1 border border-[#DDDDDD] mt-12">
                <AddIcon width={16} height={16} />
                <span>Add Member</span>
              </button>
              <button
                onClick={handleSubmit(handleFormSubmit)}
                className="text-xl bg-primary text-white py-1.5 px-5 rounded-md flex items-center justify-self-end mt-14 mb-6"
              >
                {fields.isSubmitting && (
                  <LoadingAnimation color={colors.White} />
                )}{" "}
                Invite
              </button>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : members?.length === 0 ? (
        <div className="flex flex-1 h-screen flex-col items-center justify-center">
          <p className="text-primary font-semibold">No Account Member</p>
          <p className="mt-2 mb-5">Invite Members now!</p>
        </div>
      ) : (
        <div className="flex flex-1 h-full flex-col overflow-y-scroll no-scrollbar">
          {members.map((data) => (
            <div
              className="flex cursor-pointer flex-col"
              onClick={() => handleItemClick(data)}
            >
              <div className="bg-[#FAFAFA] flex justify-between text-xs px-5 py-2 cursor-pointer text-[#2E2E2E]">
                <div className="flex items-center w-full justify-between">
                  <div className="w-1/5">
                    <img
                      src={ProfileImg}
                      alt="profile img"
                      className="h-10 w-10 bg-gray-500 rounded-full"
                    />
                  </div>
                  <div className="flex flex-col gap-[6px] w-1/4 text-[#6F6F6F]">
                    <p className="text-[#2E2E2E]">
                      {data.firstName} {data.lastName}
                    </p>
                    <p>{data.agentPosition || "Agent"}</p>
                  </div>
                  <div className="flex flex-col gap-[6px] w-1/4">
                    <p className="flex gap-[5px]">
                      <span>
                        <Phone />
                      </span>
                      {data.phone || "   ----"}
                    </p>
                    <p className="flex gap-[5px]">
                      <span>
                        <Email />
                      </span>
                      {data.email}
                    </p>
                  </div>
                  <p className="w-1/5 text-center">{data.refNo}</p>
                </div>
              </div>
              <div className="flex items-end gap-7 space-y-3 ml-8">
                <div>
                  <GroupIcon className={"w-8 h-8"} />
                </div>
                <div className="flex flex-col flex-1">
                  <div className="flex font-semibold text-xs italic text-[#BABABA] gap-4 mb-[6px]">
                    <p>Received 1:00pm</p>
                    <span>16th November 2024</span>
                  </div>
                  {data.recruiterApprovalStatus ===
                    recruiterApprovalStatus.ALL ||
                  data.recruiterApprovalStatus ===
                    recruiterApprovalStatus.PENDING ? (
                    <div className="bg-[#FAFAFA] text-[#6F6F6F] flex justify-between text-xs font-medium px-5 py-2 cursor-pointer">
                      <p className="text-[#A43370] text-xs px-[10px] py-1 bg-[#F7DEEC] rounded">
                        Pending ABC Recruitment Admin Approval
                      </p>
                      <p className="w-1/5 text-center">{data.id}</p>
                    </div>
                  ) : data.recruiterApprovalStatus ===
                    recruiterApprovalStatus.APPROVED ? (
                    <div className="bg-[#FAFAFA] text-[#6F6F6F] flex justify-between text-xs font-medium px-5 py-2 cursor-pointer">
                      <p className="text-[#17D72A] text-xs    ">
                        ABC Recruitment Admin Approved{" "}
                        <span className="italic text-[#BABABA] text-xs ml-4">
                          by John clark
                        </span>
                      </p>
                      <p className="w-1/5 text-center">{data.id}</p>
                    </div>
                  ) : (
                    <div className="bg-[#FAFAFA] text-[#6F6F6F] flex justify-between text-xs font-medium px-5 py-2">
                      <p className="w-1/4">{data.title}</p>
                      <div className="flex w-[40%] gap-2">
                        {data.key === "applied" ? (
                          <span>{data.mode}</span>
                        ) : (
                          <>
                            <span>{data.time}</span>
                            <span>{data.date}</span>
                          </>
                        )}
                      </div>
                      <p className="w-1/4">
                        {data.key === "call" ? data.number : data.position}
                      </p>
                      <p className="w-1/4 text-center">{data.id}</p>
                    </div>
                  )}
                </div>
              </div>
              <hr className="border-t-1 border-gray-300 my-4" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AccountMembers;
