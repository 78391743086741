import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import QuillEditor from "../../../components/CreateQuestion/Question/QuillEditor";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { SkillInput } from "./Skills";
import CustomInput from "../../../components/CustomInput/CustomInput";
import moment from "moment/moment";
import { useSnackbar } from "notistack";
import {
  addExperience,
  getExperienceList,
  updateExperience,
} from "../../../endpoints/experience.service";
import { skillsUsed } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../store/actions/auth.action";
import { addSkill } from "../../../endpoints/candidate.service";

const SkillItem = ({
  skillData,
  workExperienceId,
  customSkillId,
  setExperiences,
  deleteSkill,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { title, rating, isEditing, lastUsed, years } = skillData;
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    setEditable(isEditing ?? false);
  }, [isEditing]);

  const addUpdateSkill = async (data, index) => {
    try {
      if (!workExperienceId) {
        enqueueSnackbar(
          "Please to save your experience before saving skills.",
          {
            variant: "error",
          }
        );
        return;
      }
      const payload = {
        workExperienceId,
        title: data?.skill,
        lastUsed: skillsUsed[data?.lastUsed] ?? 1,
        rating: parseInt(data?.level),
        years: parseInt(data?.experience),
        type: 1,
        userType: 1,
      };

      const { success, data: response } = await addSkill(payload);
      if (success) {
        setEditable(false);
        setExperiences((prev) => {
          return prev?.map((exp) => {
            if (exp?._id === workExperienceId) {
              return {
                ...exp,
                skills: exp?.skills ? [response, ...exp?.skills] : [response],
              };
            } else {
              return exp;
            }
          });
        });
        enqueueSnackbar("Skills saved successfully.", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  return (
    <>
      {!editable ? (
        <div>
          <div className="flex justify-between items-center grid-cols-4 mb-2">
            <span className="flex-1 font-medium text-primary text-xs">
              {title}
            </span>
            <ProgressBar value={rating} total={10} />
            <span className="flex-1 font-medium text-primary text-center text-xs">
              {years}
            </span>
            <span className="flex-1 font-medium text-primary text-xs text-center">
              {Object.keys(skillsUsed).find(
                (key) => skillsUsed[key] === lastUsed
              )}
            </span>
          </div>
        </div>
      ) : (
        <SkillInput
          workExperienceId={workExperienceId}
          customSkillId={customSkillId}
          editSkill={customSkillId}
          deleteSkill={deleteSkill}
          setEditable={setEditable}
          addUpdateSkill={addUpdateSkill}
          setExperiences={setExperiences}
        />
      )}
    </>
  );
};

const ExperienceSection = forwardRef(
  ({ experienceData, editable, handleSave }, ref) => {
    const {
      jobTitle,
      startDate,
      endDate,
      isCurrent,
      companyName,
      location,
      jobDescription,
    } = experienceData;
    const {
      handleSubmit,
      control,
      setValue,
      reset,
      formState: { errors, dirtyFields },
    } = useForm({
      defaultValues: {
        startDate: startDate
          ? moment(startDate).format("YYYY-MM-DD")
          : undefined,
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
        jobTitle: jobTitle ?? undefined,
        companyName: companyName ?? undefined,
        location: location ?? undefined,
        jobDescription: jobDescription ?? undefined,
      },
    });

    const [currentlyWorking, setCurrentlyWorking] = useState(
      isCurrent != undefined ? isCurrent : false
    );

    useImperativeHandle(ref, () => ({
      submit: handleSubmit((data) =>
        handleSave({ ...data, isChanged: Object.keys(dirtyFields).length > 0 })
      ),
      reset: () => reset(),
    }));

    useEffect(() => {
      if (currentlyWorking) {
        setValue("endDate", null);
      } else {
        setValue("endDate", moment().format("YYYY-MM-DD"));
      }
    }, [currentlyWorking]);

    useEffect(() => {
      Object.entries(experienceData)?.forEach(([key, value]) => {
        setValue(key, value);
      });
    }, [experienceData]);

    return (
      <div className="w-full">
        {!editable ? (
          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <span className="font-montserrat text-primary font-bold text-sm">
                {moment(startDate).format("MMM YYYY")} -{" "}
                {isCurrent ? "Present" : moment(endDate).format("MMM YYYY")}
              </span>
              <span className="font-montserrat text-primary font-semibold text-sm">
                {jobTitle}, {companyName}
              </span>
              <span className="font-montserrat text-primary text-xs">
                {location}
              </span>
              <span
                className="font-montserrat mt-1 text-primary font-medium text-xs text-justify leading-5"
                dangerouslySetInnerHTML={{ __html: jobDescription }}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4 mb-3">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={currentlyWorking}
                className="accent-[#EFBF04] size-4 rounded text-emerald-500"
                onChange={(e) => setCurrentlyWorking(e?.target?.checked)}
              />
              <label className="text-primary text-xs font-medium">
                Currently working for this role
              </label>
            </div>
            <div className="grid grid-cols-2 gap-5">
              <div className="flex flex-col gap-3">
                <CustomInput
                  type="date"
                  label="Start Date"
                  name="startDate"
                  rules={{ required: "This field is required." }}
                  control={control}
                  errors={errors}
                />
                <CustomInput
                  type="text"
                  label={"Job Title"}
                  name={"jobTitle"}
                  placeholder={"Enter your Job Title"}
                  rules={{ required: "This field is required." }}
                  control={control}
                  errors={errors}
                />
                <CustomInput
                  label="Location"
                  name="location"
                  rules={{
                    required: "This field is required.",
                  }}
                  placeholder={"Ex: London"}
                  control={control}
                  errors={errors}
                />
              </div>
              <div className="flex flex-col gap-3">
                <div
                  className={`${
                    currentlyWorking ? "opacity-20" : "opacity-100"
                  }`}
                >
                  <CustomInput
                    type="date"
                    label={"End Date"}
                    name={"endDate"}
                    rules={{
                      required: currentlyWorking
                        ? false
                        : "This field is required.",
                    }}
                    control={control}
                    errors={errors}
                  />
                </div>

                <CustomInput
                  type="text"
                  label={"Company Name"}
                  name={"companyName"}
                  placeholder={"Enter your Company Name"}
                  rules={{ required: "This field is required." }}
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
            <div className="mr-4">
              <Controller
                name={"jobDescription"}
                control={control}
                rules={{
                  required: "This field is required.",
                }}
                render={({ field }) => {
                  return (
                    <div className="flex flex-col gap-1">
                      <span className="font-montserrat text-primary font-medium text-xs mb-2">
                        Job Description{" "}
                        <span className="font-montserrat text-primary font-medium text-xs text-red-500">
                          *
                        </span>
                      </span>
                      <QuillEditor {...field} />
                      {errors["jobDescription"]?.message && (
                        <span className="whitespace-pre text-xs mt-2 text-orange-700">
                          {errors["jobDescription"]?.message}
                        </span>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

const ExperienceBlock = ({ customExpId, setExperiences, experienceData }) => {
  const { skills, isEditing, _id } = experienceData;
  const experienceFormRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [editable, setEditable] = useState(false); // State for edit mode
  const [tempSkills, setTempSkills] = useState([]);

  useEffect(() => {
    setEditable(isEditing ?? false);
  }, [isEditing]);

  useEffect(() => {
    setTempSkills(skills?.length ? skills : []);
  }, [skills?.length]);

  const handleEditToggle = (data) => {
    if (editable) {
      let payload = data;
      if (!data?.endDate) {
        delete payload.endDate;
        payload.isCurrent = true;
      }
      if (_id) {
        payload.id = _id;
        updateExperience(payload).then((response) => {
          const { success, data } = response;
          if (success) {
            setExperiences((prev) => {
              return prev?.map((exp) => {
                if (exp?._id === _id) {
                  return { ...exp, ...data, isEditing: false };
                } else {
                  return exp;
                }
              });
            });
            enqueueSnackbar("Experience updated successfully.", {
              variant: "success",
            });
          }
        });
      } else {
        addExperience(payload).then((response) => {
          const { success, data } = response;
          if (success) {
            setExperiences((prev) => {
              return prev?.map((exp, index) => {
                if (index === customExpId) {
                  return { ...exp, ...data, isEditing: true };
                } else {
                  return exp;
                }
              });
            });

            enqueueSnackbar("Experience added successfully.", {
              variant: "success",
            });
          }
        });
      }
    }
  };

  const handleFormData = (data) => {
    if (data?.isChanged) {
      handleEditToggle(data);
      return;
    }
    setExperiences((prev) => {
      return prev?.map((exp) => {
        if (exp?._id === _id) {
          return { ...exp, isEditing: false };
        } else {
          return exp;
        }
      });
    });
  };

  const handleSave = () => {
    if (editable) {
      experienceFormRef?.current?.submit();
    } else {
      setEditable(true);
    }
  };

  const handleAddSkill = () => {
    setTempSkills((prev) => {
      return [
        {
          customId: prev?.length + 1,
          isEditing: true,
          title: "asdfsd",
          years: "",
          skillsUsed: "",
        },
        ...prev,
      ];
    });
  };

  return (
    <div className={`flex flex-col ${editable && "border rounded-lg p-4"}`}>
      <p
        className="text-[#6f6f6f] text-xs font-medium cursor-pointer flex justify-end"
        onClick={handleSave} // Toggle editing state
      >
        {editable ? (_id ? "Update" : "Save") : "Edit"}
      </p>

      <ExperienceSection
        ref={experienceFormRef}
        experienceData={experienceData}
        editable={editable}
        handleSave={handleFormData}
      />

      {(!!tempSkills?.length || editable) && (
        <div className="flex flex-col w-full">
          <div className="flex flex-col">
            {!!tempSkills?.length && (
              <div className="flex mb-5 mt-6 grid-cols-4">
                <span className="font-montserrat text-primary font-semibold text-sm flex-1">
                  Skills Used
                </span>
                <span className="font-montserrat text-primary font-semibold flex-1 text-sm">
                  Experience Level
                </span>
                <span className="font-montserrat text-primary font-semibold flex-1 text-sm text-center">
                  Years
                </span>
                <span className="font-montserrat text-primary font-semibold flex-1 text-sm text-center">
                  Skills Used
                </span>
              </div>
            )}
            {tempSkills?.length > 0 && (
              <div className="flex flex-col gap-4">
                {tempSkills.map((skill, index) => (
                  <SkillItem
                    key={skill?._id ?? skill?.customId}
                    skillData={skill}
                    workExperienceId={_id}
                    customSkillId={skill?._id ?? skill?.customId}
                    deleteSkill={() => {}}
                    setExperiences={setExperiences}
                  />
                ))}
              </div>
            )}
            {editable ? (
              <button
                onClick={handleAddSkill}
                className="text-sm py-1 px-2 text-[#6f6f6f] rounded font-semibold w-max mt-2"
              >
                + Add Skill
              </button>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

const Experience = forwardRef(({ isEditing, setData, handleNext }, ref) => {
  const { user } = useSelector((state) => state.auth);
  const [experiences, setExperiences] = useState(user?.experience ?? []);
  const [isFetching, setIsFetching] = useState(true);
  const dispatch = useDispatch();

  const handleAddExperience = () => {
    setExperiences((prev) => {
      return [
        {
          customId: prev?.length + 1,
          isEditing: true,
          isCurrent: false,
          startDate: "",
          endDate: "",
          jobTitle: "",
          location: "",
          companyName: "",
          jobDescription: "",
        },
        ...prev,
      ];
    });
  };

  useEffect(() => {
    dispatch(setUserData({ experience: experiences?.filter((x) => x?._id) }));
  }, [experiences]);

  useEffect(() => {
    if (experiences?.length) {
      setData((prev) => {
        return {
          ...prev,
          experience: {
            ...prev?.experience,
            isCompleted: true,
            isFilled: true,
          },
        };
      });
    }
  }, [experiences]);

  const fetchExperienceList = useCallback(async () => {
    try {
      const { success, data } = await getExperienceList();
      if (success) {
        setExperiences(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchExperienceList();
  }, []);

  return (
    <div className="flex flex-col">
      <div
        className={`flex gap-3 mb-8 ${
          !experiences?.length ? "flex-col items-start" : "items-center"
        }`}
      >
        <span className="font-montserrat text-[#383838] font-bold text-sm">
          Work Experience
        </span>
        {isEditing && (
          <button
            onClick={handleAddExperience}
            className="text-sm border py-1 px-2 text-[#6f6f6f] rounded font-semibold"
          >
            + Add Experience
          </button>
        )}
      </div>
      <div className="flex flex-col gap-10 w-[90%]">
        {experiences?.map((exp, index) => {
          return (
            <React.Fragment key={exp?._id ?? exp?.customId}>
              <ExperienceBlock
                key={exp?._id ?? exp?.customId}
                experienceData={exp}
                customExpId={index}
                setExperiences={setExperiences}
                setData={setData}
              />
              {isFetching && experiences?.length === 0 && (
                <div className="bg-light-white-500 w-full h-2" />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
});

export default Experience;
