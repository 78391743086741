import { useEffect, useState } from "react";

const TrackIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 14"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <path
        d="M12.9072 7V2.625C12.9072 2.14175 12.5155 1.75 12.0322 1.75H3.28223C2.79898 1.75 2.40723 2.14175 2.40723 2.625V11.375C2.40723 11.8583 2.79898 12.25 3.28223 12.25H7.65723"
        stroke="#2E2E2E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99023 11.0833C10.9568 11.0833 11.7402 10.2999 11.7402 9.33334C11.7402 8.36682 10.9568 7.58334 9.99023 7.58334C9.02371 7.58334 8.24023 8.36682 8.24023 9.33334C8.24023 10.2999 9.02371 11.0833 9.99023 11.0833Z"
        stroke="#2E2E2E"
        stroke-width="1.25"
      />
      <path
        d="M11.4492 10.5L12.9076 11.6667"
        stroke="#2E2E2E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.74121 4.66666H10.5745"
        stroke="#2E2E2E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.74121 7H7.07454"
        stroke="#2E2E2E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default TrackIcon;
