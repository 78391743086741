import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import QuillEditor from "../../../components/CreateQuestion/Question/QuillEditor";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getValueOrPlaceholder } from "../../../utils/helperFunction";
import LocationIconOutline from "../../../assets/svg/LocationIconOutline";

const About = forwardRef(({ isEditing, setEditingTab, handleSave }, ref) => {
  const { user } = useSelector((state) => state.auth);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      about: user?.about,
    },
  });

  useImperativeHandle(ref, () => handleSubmit(handleSave));

  useEffect(() => {
    if (isEditing) {
      setValue("about", user?.about ?? "");
    }
  }, [user?.about, isEditing]);

  return (
    <div>
      {!isEditing ? (
        <div className="h-full flex flex-1 flex-col overflow-y-scroll no-scrollbar w-full pr-20 pt-5">
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <LocationIconOutline className={"w-4 h-4"} fill="#6f6f6f" />
              <span className="font-medium text-xs text-light-black">
                London, United Kingdom
              </span>
            </div>
            <span className="text-primary font-medium">
              A Highly Skilled Senior Web Developer with Over 15 Years of
              Experience
            </span>
            <span className="text-primary text-xs font-medium text-justify leading-5">
              A highly skilled Node.js and React developer with 15 years od
              experience in full stack development, specializing in building
              scalable, high-performance applications. Proficient in Javascript,
              Typescript and modern frameworks, with deep expertise in back-end
              development using Node.js and front-end technologies like React.
              Extensive experience with databases, particularly MongoDB, and
              integrating third-party APIs. Adept at optimizing code, improving
              system efficiency, and ensuring seamless user experiences. Strong
              problem-solving skills and a passion for delivering high-quality
              software solutions on time and within budget.
            </span>
            {/* <div
              className="font-montserrat text-primary font-medium text-xs leading-5 mb-3"
              dangerouslySetInnerHTML={{
                __html: user?.about,
              }}
            /> */}
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <span className="font-montserrat text-[#383838] font-bold text-sm mb-8">
            About
          </span>
          <div className="mr-4">
            <Controller
              name={"about"}
              control={control}
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => {
                return (
                  <div className="flex flex-col gap-1">
                    <QuillEditor {...field} />
                    {errors["about"]?.message && (
                      <span className="whitespace-pre text-xs mt-2 text-orange-700">
                        {errors["about"]?.message}
                      </span>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default About;
