import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import { userTypes } from "../../utils/constants";

const Layout = () => {
  const { pathname } = useLocation();
  const { logged_in, user } = useSelector((state) => state.auth);
  // Todo
  const isLoggedIn =
    (logged_in && user && user._id) ||
    user?.userType === userTypes.REFERRAL_AMBASSADOR;

  return (
    <div
      className={`flex flex-col items-center w-full no-scrollbar h-screen max-h-screen overflow-hidden ${
        isLoggedIn || pathname === "/" ? "bg-white" : "bg-[#F7F8FB]"
      }`}
    >
      {pathname === "/" && (
        <img
          src={require("../../assets/images/landingBackground.png")}
          className="absolute h-screen w-screen z-0 object-cover"
          alt="bgImage"
        />
      )}
      <Navbar />
      {/* Responsive container */}
      <div className="flex flex-col flex-grow w-full max-w-[1728px] no-scrollbar  mx-auto h-full overflow-hidden">
        {/* Main content area */}
        <div
          className={`flex flex-grow w-full h-full no-scrollbar max-h-[93vh] ${
            isLoggedIn ? "xl:pl-5 xl:pr-44 px-4" : "px-4 xl:px-24"
          }`}
        >
          {/* Sidebar conditionally rendered */}
          {isLoggedIn && <Sidebar />}
          {/* Outlet for page content */}
          <div
            className={`flex flex-1 flex-grow no-scrollbar ${
              !isLoggedIn && "flex-col"
            } ${
              pathname !== "/"
                ? "bg-[#F7F8FB] py-5 xl:py-10 px-4 md:px-8 xl:px-10 2xl:px-24"
                : "bg-white"
            }`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
