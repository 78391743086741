import environment from '../env';

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';

import rootReducer from './reducers/index';

const { debug } = environment;
const initialState = {};

const middlewares = [thunk];

if (debug) middlewares.push(logger);

const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));

export default store;
