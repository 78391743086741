export const assessmentCandidateList = [
  {
    refId: "CA-7181MN",
    name: "Michael Smith",
    status: "Completed Assessment",
    date: new Date(),
    read: false,
  },
  {
    refId: "CA-9202OP",
    name: "Nishan",
    status: "Completed Assessment",
    date: new Date(),
    read: false,
  },
  {
    refId: "CA-1222QR",
    name: "Pravat",
    status: "Completed Assessment",
    date: new Date(),
    read: false,
  },
  {
    refId: "CA-5262UV",
    name: "Pooja Pal",
    status: "Completed Assessment",
    date: new Date(),
    read: false,
  },
  {
    refId: "CA-7282WX",
    name: "Annette Black",
    status: "Assessment Sent",
    date: new Date(),
    read: true,
  },
  {
    refId: "CA-9303YZ",
    name: "Esther Howard",
    status: "Assessment Sent",
    date: new Date(),
    read: true,
  },
  {
    refId: "CA-1424AA",
    name: "Jacob Jones",
    status: "Assessment Sent",
    date: new Date(),
    read: true,
  },
  {
    refId: "CA-3444BB",
    name: "Jenny Wilson",
    status: "Assessment Sent",
    date: new Date(),
    read: true,
  },
  {
    refId: "CA-1234AB",
    name: "Brooklyn Simmons",
    status: "Assessment Sent",
    date: new Date(),
    read: true,
  },
];
