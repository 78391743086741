import "twin.macro";
import tw, { styled } from "twin.macro";
import { colors } from "../../../utils/theme";

export const Title = styled.span(() => [
  tw`font-montserrat font-medium text-xs leading-6 flex-1 text-primary`,
]);

export const RowContainer = tw.div`flex gap-3`;

export const SavedSectionRowView = tw.div`flex items-center gap-3`;

export const SavedSectionView = tw.div`flex flex-1 min-h-9 items-center gap-3 py-1.5 px-2 bg-[#F5F5F5] rounded`;

export const SavedSection = styled.span(() => [
  tw`font-montserrat font-normal text-sm`,
  `color:${colors.PrimaryBlack}`,
]);

export const SavedSectionIndex = styled.span(() => [
  tw`font-montserrat font-normal text-sm`,
  `color:${colors.PrimaryBlack}`,
]);
