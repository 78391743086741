import ChevronDown from "../../../../assets/svg/ChevronDown";
import CloseIcon from "../../../../assets/svg/CloseIcon";
import SearchIcon from "../../../../assets/svg/SearchIcon";

const SelectCandidate = ({
  setShowModal,
  handleNext,
  candidateList,
  selectedCandidates,
  handleSelectCandidate,
  setSelectedCandidates,
}) => {
  const allSelected =
    candidateList?.length > 0 &&
    selectedCandidates?.length === candidateList?.length;

  const handleSelectAll = (event) => {
    if (event?.target?.checked) {
      setSelectedCandidates(candidateList);
    } else {
      setSelectedCandidates([]);
    }
  };
  return (
    <div className="flex flex-col">
      {/* Header */}
      <div className="flex items-center justify-between">
        <span className="font-semibold text-primary">
          Select candidates to share assessment
        </span>
        <button
          onClick={() => {
            setSelectedCandidates([]);
            setShowModal(false);
          }}
        >
          <CloseIcon className={"w-6 h-6"} />
        </button>
      </div>
      {/* Search section */}
      <div className="flex rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5 mt-6">
        <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-2">
          <label className="text-primary text-sm font-medium">Search by</label>
          <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
        </button>
        <input
          className="bg-white text-xs font-medium text-light-black outline-none px-3 py-2 rounded-lg w-80 flex-1"
          placeholder="Search..."
        />
        <SearchIcon className={"w-5 h-5"} fill={"#2e2e2e"} />
      </div>
      {/* Table cotainer */}
      <div className="mt-5 border rounded-md">
        <div className="grid grid-cols-[8%_1fr_1fr_1.5fr_1fr] border-b py-3">
          <div className="flex items-center justify-center">
            <input
              type="checkbox"
              id="select-all"
              checked={allSelected}
              onChange={handleSelectAll}
              className="h-4 w-4 border rounded-sm border-primary"
            />
          </div>
          <span className="font-semibold text-primary text-xs">Reference</span>
          <span className="font-semibold text-primary text-xs">Name</span>
          <span className="font-semibold text-primary text-xs">
            Email address
          </span>
          <span className="font-semibold text-primary text-xs">Status</span>
        </div>
        {candidateList?.map((item, index) => {
          const isSelected = selectedCandidates.some(
            (candidate) => candidate?._id === item?._id
          );
          return (
            <div
              key={index}
              className={`grid grid-cols-[8%_1fr_1fr_1.5fr_1fr] ${
                index != 0 && "border-t"
              } py-3`}
            >
              <div className="flex items-center justify-center">
                <input
                  type="checkbox"
                  id={`assessment-${item._id}`}
                  checked={isSelected}
                  onChange={() => handleSelectCandidate(item)}
                  className={`h-4 w-4 border rounded-sm ${
                    isSelected ? "bg-primary" : "border-primary"
                  }`}
                />
              </div>
              <span className="font-medium text-primary text-xs">
                {item.refNo}
              </span>
              <span className="font-medium text-primary text-xs">
                {item.firstName} {item.lastName}
              </span>
              <span className="font-medium text-primary text-xs">
                {item.email}
              </span>
              <span className="font-medium text-primary text-xs">
                {item.status}
              </span>
            </div>
          );
        })}
      </div>
      <button
        onClick={handleNext}
        className="bg-primary rounded-md self-end mt-4 text-white font-medium text-sm px-4 py-1.5 hover:bg-black"
      >
        Next
      </button>
    </div>
  );
};

export default SelectCandidate;
