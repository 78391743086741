const regexList = {
  email: /^[^@\s]+@[^@\s]+\.[a-zA-Z]{2,}$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()^<>+_#"=':;,./-])[A-Za-z\d@$!%*?&()^<>+_#"=':;,./-]{4,20}$/,
  // FOR PASSWORD
  minMaxLength: /^.{4,20}$/,
  noWhitespace: /^\S+$/,
  containLowercase: /^[^a-z]*[a-z].*$/,
  containUppercase: /^[^A-Z]*[A-Z].*$/,
  containNumeric: /^[^\d]*\d.*$/,
  // eslint-disable-next-line
  containSpecialCharacter:
    /^[^!@#$%^&*()_+{}\[\]:;<>,.?~\\-]*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-].*$/,
  website:
    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/,
  contactRegex:
    /^\+?(\d{1,4})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
};

export default regexList;
