import {
  SET_CANDIDATES_ASSESSMENT_DATA,
  SET_CANDIDATES_DATA,
} from "./action.types";

export const setCandidates = (payload) => ({
  type: SET_CANDIDATES_DATA,
  payload,
});

export const setCandidateAssessment = (payload) => ({
  type: SET_CANDIDATES_ASSESSMENT_DATA,
  payload,
});
