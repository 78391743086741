import React, { useState } from "react";
import ChevronLeftIcon from "../../../assets/svg/ChevronLeftIcon";
import Divider from "../../../components/Divider/Divider";
import { assessmentDetails as initialAssessmentDetails } from "./dummyData";
import ChevronDown from "../../../assets/svg/ChevronDown";
import ChevronUp from "../../../assets/svg/ChevronUp";
import LeftArrowIcon from "../../../assets/svg/LeftArrowIcon";
import RightArrowIcon from "../../../assets/svg/RightArrowIcon";
import CustomEditor from "../../../components/Yoopta";
import DragIcon from "../../../assets/svg/DragIcon";
import DeleteIcon from "../../../assets/svg/DeleteIcon";

const AnswerAssessment = () => {
  const [activeSectionIndex, setActiveSectionIndex] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState({
    sectionIndex: null,
    questionIndex: null,
  });
  const [editors, setEditors] = useState([]);
  const [assessmentDetails, setAssessmentDetails] = useState(
    initialAssessmentDetails
  );

  const changeEditorContent = (index, value) => {
    const updatedEditors = [...editors];
    updatedEditors[index] = value;
    setEditors(updatedEditors);
  };

  const handleSectionClick = (index) => {
    setActiveSectionIndex(index === activeSectionIndex ? null : index);
    setSelectedQuestion({ sectionIndex: null, questionIndex: null });
  };

  const handleQuestionClick = (sectionIndex, questionIndex) => {
    setActiveSectionIndex(sectionIndex);
    setSelectedQuestion({ sectionIndex, questionIndex });
  };

  const handleBack = () => {
    const { sectionIndex, questionIndex } = selectedQuestion;
    if (questionIndex > 0) {
      setSelectedQuestion({
        sectionIndex,
        questionIndex: questionIndex - 1,
      });
    } else if (sectionIndex > 0) {
      const prevSection = assessmentDetails.sections[sectionIndex - 1];
      setActiveSectionIndex(sectionIndex - 1);
      setSelectedQuestion({
        sectionIndex: sectionIndex - 1,
        questionIndex: prevSection.questions.length - 1,
      });
    }
  };

  const handleNext = () => {
    const { sectionIndex, questionIndex } = selectedQuestion;
    const currentSection = assessmentDetails?.sections[sectionIndex];

    if (questionIndex < currentSection.questions.length - 1) {
      setSelectedQuestion({
        sectionIndex,
        questionIndex: questionIndex + 1,
      });
    } else if (sectionIndex < assessmentDetails.sections.length - 1) {
      setActiveSectionIndex(sectionIndex + 1);
      setSelectedQuestion({
        sectionIndex: sectionIndex + 1,
        questionIndex: 0,
      });
    }
  };

  const handleAddTextAnswer = () => {
    setEditors([...editors, { type: "text", content: "" }]);
  };

  const handleAddCodeAnswer = () => {
    setEditors([...editors, { type: "code", content: "" }]);
  };

  const handleSubmitAnswer = () => {
    const { sectionIndex, questionIndex } = selectedQuestion;
    if (sectionIndex !== null && questionIndex !== null) {
      const updatedSections = [...assessmentDetails.sections];
      const currentQuestion =
        updatedSections[sectionIndex].questions[questionIndex];
      currentQuestion.isSaved = true;
      setAssessmentDetails({
        ...assessmentDetails,
        sections: updatedSections,
      });
      setEditors([]);
      handleNext();
    }
  };

  const AboutAssessment = () => (
    <div className="flex flex-col px-9 mt-6">
      <span className="font-montserrat font-semibold text-sm">
        About Assessment
      </span>
      <span className="font-montserrat font-medium text-xs text-justify text-wrap leading-6 mt-3 mb-[52px]">
        {assessmentDetails.description}
      </span>
      <div className="flex justify-center">
        <button
          onClick={() => {
            setActiveSectionIndex(0);
            setSelectedQuestion({ sectionIndex: 0, questionIndex: 0 });
          }}
          className="flex items-center justify-center border bg-green-600 rounded px-[86px] py-2 gap-1 text-white font-semibold text-sm"
        >
          Start Assessment
        </button>
      </div>
    </div>
  );

  const section =
    selectedQuestion?.sectionIndex !== null
      ? assessmentDetails?.sections[selectedQuestion?.sectionIndex]
      : null;
  const question =
    section && selectedQuestion?.questionIndex !== null
      ? section?.questions[selectedQuestion.questionIndex]
      : null;

  const questionNumber = question ? selectedQuestion?.questionIndex + 1 : null;
  const totalQuestions = section ? section?.questions?.length : 0;

  return (
    <div className="flex flex-row w-full relative laptop-small-screen gap-7">
      <div className="flex w-full gap-4">
        {/* Left container */}
        <div className="w-[25%]">
          <div className="w-full h-full flex flex-col bg-white pt-[18px] shadow-nShadow rounded-lg relative pb-16 overflow-y-scroll no-scrollbar">
            <div className="px-3 flex flex-col">
              <button className="flex items-center">
                <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
                <label className="text-xs font-medium text-primary">
                  Back to listing
                </label>
              </button>
              <span className="font-montserrat font-semibold text-base mt-7">
                Question Progress
              </span>
              <Divider className="bg-[#DDDDDD] my-4" />
            </div>

            {/* Sections */}
            <div className="flex flex-col">
              <button
                onClick={() => handleSectionClick(null)}
                className={`flex items-center justify-end pr-4 py-3 pl-3 xl:pr-0 xl:justify-start relative gap-2 ${
                  activeSectionIndex === null && "bg-[#F2F2F2]"
                }`}
              >
                <label className="font-bold text-xs cursor-pointer text-primary">
                  About
                </label>
                <div
                  className={`w-2 h-full right-0 absolute ${
                    activeSectionIndex === null ? "bg-secondary" : "bg-white"
                  } rounded-l`}
                />
              </button>

              {assessmentDetails.sections.map((section, i) => (
                <div key={i} className="flex flex-col">
                  <button
                    onClick={() => handleSectionClick(i)}
                    className={`flex items-center justify-end pr-4 py-2.5 pl-3 xl:pr-0 xl:justify-start relative gap-2`}
                  >
                    <div className="flex justify-between flex-1 pr-3 items-center">
                      <div className="flex flex-col items-start gap-2">
                        <label className="font-bold text-xs cursor-pointer text-primary">
                          {`Section ${i + 1}:`}
                        </label>
                        <label className="font-medium text-xs cursor-pointer text-primary">
                          {`${section?.sectionName}`}
                        </label>
                      </div>

                      {activeSectionIndex === i ? (
                        <ChevronUp className={"w-5 h-5"} fill={"#2e2e2e"} />
                      ) : (
                        <ChevronDown className={"w-5 h-5"} fill={"#2e2e2e"} />
                      )}
                    </div>
                  </button>

                  {activeSectionIndex === i && (
                    <>
                      {section.questions.map((question, qIndex) => (
                        <button
                          key={qIndex}
                          onClick={() => handleQuestionClick(i, qIndex)}
                          className={`flex items-center justify-end pr-4 py-2.5 pl-6 xl:pr-0 xl:justify-start relative gap-2 ${
                            selectedQuestion.sectionIndex === i &&
                            selectedQuestion.questionIndex === qIndex
                              ? "bg-[#F2F2F2]"
                              : ""
                          }`}
                        >
                          <div className="flex justify-between pr-4 flex-1">
                            <label className="font-medium text-xs cursor-pointer text-light-black">
                              {`Question ${qIndex + 1}`}
                            </label>
                            {question.isSaved && (
                              <label className="font-medium text-[10px] cursor-pointer text-[#23BD33] italic">
                                Answered
                              </label>
                            )}
                          </div>
                          <div
                            className={`w-2 h-full right-0 absolute ${
                              selectedQuestion.sectionIndex === i &&
                              selectedQuestion.questionIndex === qIndex
                                ? "bg-secondary"
                                : "bg-white"
                            } rounded-l`}
                          />
                        </button>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right container */}
        <div className="w-[70%]">
          <div className="shadow-nShadow bg-white flex flex-col flex-1 w-full h-full p-6 max-h-[88vh] rounded-lg overflow-y-scroll no-scrollbar">
            <div className="flex justify-between items-center mb-4">
              <span className="font-montserrat font-semibold text-sm">
                {assessmentDetails.title}
              </span>
              <span className="font-montserrat font-semibold text-xs">
                Ref: {assessmentDetails.refId}
              </span>
            </div>
            <Divider className="bg-light-grey" />
            {question ? (
              <div>
                <div className="flex justify-center items-center py-3 border rounded-md bg-[#FAFAFA] pl-3 mt-4 mb-6">
                  <span className="font-bold text-xs text-primary">
                    {`Section ${selectedQuestion.sectionIndex + 1} :`}{" "}
                    <span className="font-medium text-primary">
                      {section?.sectionName}
                    </span>
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <label className="font-medium text-xs cursor-pointer text-light-black">
                    {`Question ${questionNumber} of ${totalQuestions}`}
                  </label>
                  <div className="flex gap-2">
                    <button
                      className="flex items-center justify-center cursor-pointer rounded px-3 py-2 gap-1 text-light-black font-medium text-xs"
                      onClick={handleBack}
                    >
                      <LeftArrowIcon />
                      Back
                    </button>
                    <button
                      className="flex items-center justify-center cursor-pointer rounded px-3 py-2 gap-1 text-light-black font-medium text-xs"
                      onClick={handleNext}
                    >
                      Next
                      <RightArrowIcon />
                    </button>
                  </div>
                </div>
                <span className="font-montserrat font-medium text-xs flex py-6 pl-3 pr-4">
                  {question.question}
                </span>
                <div className="w-full border-t border-dashed border-light-grey mb-4" />
                <div style={{ position: "relative" }}>
                  {editors.length > 0 && (
                    <label className="font-medium text-xs cursor-pointer text-light-black">
                      Your Answer
                    </label>
                  )}
                  {editors.map((editor, index) => (
                    <div className="flex items-center justify-center">
                      <DragIcon />
                      <div
                        key={index}
                        style={{ position: "relative" }}
                        className="w-100 flex-1"
                      >
                        <CustomEditor
                          onChangeContent={(value) =>
                            changeEditorContent(index, value)
                          }
                          value={undefined}
                        />
                      </div>
                      <DeleteIcon />
                    </div>
                  ))}
                </div>
                <div className="flex justify-between">
                  <div className="flex gap-2">
                    <button
                      className="flex items-center justify-center border bg-[#ECECEC] rounded-md px-3 py-1.5 gap-1 text-light-black font-medium text-xs"
                      onClick={handleAddTextAnswer}
                    >
                      Add Text Answer
                    </button>
                    <button
                      className="flex items-center justify-center border bg-[#ECECEC] rounded-md px-3 py-1.5 gap-1 text-light-black font-medium text-xs"
                      onClick={handleAddCodeAnswer}
                    >
                      Add Code Answer
                    </button>
                  </div>
                  {editors.length > 0 && (
                    <button
                      onClick={handleSubmitAnswer}
                      className="flex items-center justify-center border bg-green-600 rounded px-3 py-2 gap-1 text-white font-semibold text-sm"
                    >
                      Submit Answer
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <AboutAssessment />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerAssessment;
