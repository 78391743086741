// src/components/DotLoader.js
import React from "react";

const DotLoader = () => {
  return (
    <div className="flex justify-center items-center space-x-1">
      <div
        className="w-1 h-1 bg-primary rounded-full animate-sequential-blink"
        style={{ animationDelay: "0s" }}
      ></div>
      <div
        className="w-1 h-1 bg-primary rounded-full animate-sequential-blink"
        style={{ animationDelay: "0s" }}
      ></div>
      <div
        className="w-1 h-1 bg-primary rounded-full animate-sequential-blink"
        style={{ animationDelay: "1s" }}
      ></div>
      <div
        className="w-1 h-1 bg-primary rounded-full animate-sequential-blink"
        style={{ animationDelay: "1s" }}
      ></div>
      <div
        className="w-1 h-1 bg-primary rounded-full animate-sequential-blink"
        style={{ animationDelay: "1s" }}
      ></div>
      <div
        className="w-1 h-1 bg-primary rounded-full animate-sequential-blink"
        style={{ animationDelay: "1s" }}
      ></div>
    </div>
  );
};

export default DotLoader;
