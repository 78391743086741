import { useState } from "react";
import { staffs } from "../dummyData";
import Phone from "../../../../assets/svg/Phone";
import Email from "../../../../assets/svg/Email";
import Modal from "../../../../components/Modal/Modal";
import CloseIcon from "../../../../assets/svg/CloseIcon";
import LocationIconOutline from "../../../../assets/svg/LocationIconOutline";
import CopyIcon from "../../../../assets/svg/CopyIcon";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";

const DetailModal = ({ data, setShowDetails }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className="flex flex-col w-[28rem]">
      <div className="flex items-center justify-between">
        <span className="font-semibold">Contact Details</span>
        <button onClick={() => setShowDetails(false)}>
          <CloseIcon className={"w-7 h-7"} />
        </button>
      </div>

      <div className="flex items-center gap-8 p-6">
        <div className="flex flex-col gap-2 items-center">
          <img src={data?.image} className="rounded-full h-40 w-40" />
          <div className="flex items-center gap-1">
            <div className="w-[6px] h-[6px] bg-[#23BD33] rounded-full animate-pulse" />
            <span className="font-montserrat text-primary italic text-xss max-[900px]:w-min">
              {data?.status}
            </span>
          </div>
          <span className="font-montserrat text-primary text-xs max-[900px]:w-min">
            RU-324DMG
          </span>
        </div>

        <div className="flex flex-1 flex-col gap-6">
          <div className="flex flex-col gap-2">
            <span className="font-montserrat text-primary font-semibold w-max border-b-2 pb-1 border-primary">
              {data?.name}
            </span>
            <span className="font-montserrat text-primary font-medium text-xs">
              {data?.position}
            </span>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex gap-3">
              <LocationIconOutline className={"w-4 h-4"} fill={"#2e2e2e"} />
              <span className="font-montserrat text-primary font-normal text-xs">
                London
              </span>
            </div>
            <div className="flex gap-3">
              <Email />
              <span className="font-montserrat text-primary font-normal text-xs">
                {data?.email}
              </span>

              <CopyToClipboard
                text={data?.email}
                onCopy={() =>
                  enqueueSnackbar("Copied successfully.", {
                    variant: "success",
                  })
                }
              >
                <button>
                  <CopyIcon className="w-3 h-3" />
                </button>
              </CopyToClipboard>
            </div>
            <div className="flex gap-3">
              <Phone />
              <span className="font-montserrat text-primary font-normal text-xs">
                {data?.phone}
              </span>
            </div>
            <div className="flex gap-3">
              <Phone />
              <span className="font-montserrat text-primary font-normal text-xs">
                {data?.phone}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StaffItem = (staff) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <>
      <button
        onClick={() => setShowDetails(true)}
        className="bg-[#fafafa] p-2 grid grid-cols-[1.5fr_1fr_0.7fr_0.5fr] rounded-md relative"
      >
        <div className="flex items-center gap-3 absolute top-0 left-3">
          <img src={staff?.image} className="rounded-full h-10 w-10" />
          <div className="flex flex-col pt-1 gap-1">
            <span className="font-montserrat text-primary font-semibold max-[900px]:w-min">
              {staff?.name}
            </span>
            <div className="flex items-center gap-1">
              <div className="w-[6px] h-[6px] bg-[#23BD33] rounded-full animate-pulse" />
              <span className="font-montserrat text-primary italic text-xss max-[900px]:w-min">
                {staff?.status}
              </span>
            </div>
          </div>
        </div>
        <div />
        <span className="font-montserrat text-primary font-semibold text-xs max-[900px]:w-min">
          {staff?.position}
        </span>
        <span className="font-montserrat text-secondary font-medium text-xs max-[900px]:w-min">
          Contact details
        </span>
        <span className="font-montserrat text-primary text-[10px] max-[900px]:w-min">
          RU-324DMG
        </span>
      </button>
      <Modal isOpen={showDetails} containerStyle="w-max">
        <DetailModal data={staff} setShowDetails={setShowDetails} />
      </Modal>
    </>
  );
};

const Staff = () => {
  return (
    <div className="flex flex-col gap-12 pl-28 pr-20">
      <span className="font-semibold text-center text-sm underline w-ful">
        Barclay Simpson Staff Members
      </span>
      <div className="flex flex-col gap-10">
        {staffs.map((staff) => (
          <StaffItem key={staff._id} {...staff} />
        ))}
      </div>
    </div>
  );
};

export default Staff;
