import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SearchContext } from "./SearchContext";
import { useState } from "react";

const tabs = [
  { name: "Search", path: "" },
  { name: "Template Searches", path: "template-searches" },
];

const SearchLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchResults, setSearchResults] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [addedSkills, setAddedSkills] = useState([]);

  const isTabActive = (path) => pathname === `/search${path ? `/${path}` : ""}`;

  return (
    <SearchContext.Provider
      value={{
        searchResults,
        setSearchResults,
        addedSkills,
        setAddedSkills,
        templateList,
        setTemplateList,
      }}
    >
      <div className="flex flex-col overflow-y-hidden h-full rounded-lg bg-white w-full flex-grow p-4 gap-10">
        {/* Tabs container */}
        <div className="flex items-center justify-around border-b mt-2 overflow-hidden h-10">
          {tabs.map((item, index) => (
            <button
              onClick={() => navigate(item.path)}
              key={item.name}
              className={`justify-center items-center h-full cursor-pointer ${
                isTabActive(item.path)
                  ? item.path === "possible-match"
                    ? "text-[#34C759] border-b-4 border-primary"
                    : "text-primary border-b-4 border-primary"
                  : "text-gray-300"
              } font-semibold text-sm`}
              aria-current={isTabActive(item.path) ? "page" : undefined}
            >
              {item.name}
              {/* {index === 1 ? `: ${searchResults?.length}` : null} */}
            </button>
          ))}
        </div>
        {/* Body Container */}
        <div className="flex flex-1 flex-col overflow-y-scroll no-scrollbar gap-10 px-5 rounded-md">
          <Outlet />
        </div>
      </div>
    </SearchContext.Provider>
  );
};

export default SearchLayout;
