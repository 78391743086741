import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, allowedRoles, user }) => {
  if (!user) {
    // If no user is logged in, redirect to login
    return <Navigate to="/login" replace />;
  }

  if (!allowedRoles.includes(user?.userType)) {
    // If user role is not authorized, redirect to "not authorized" page
    return <Navigate to="/unauthorized" replace />;
  }

  // If user role is authorized, render the children
  return children;
};

export default ProtectedRoute;
