import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getCandidateAssessment } from "../../../endpoints/candidate.service";
import { useDispatch, useSelector } from "react-redux";
import { setCandidateAssessment } from "../../../store/actions/candidate.action";
import { CandidateAssessmentStatus } from "../../../utils/constants";

const Assessment = ({ item, onClickAssessment }) => {
  const status = CandidateAssessmentStatus.find(
    (x) => x.value === item?.status
  );
  return (
    <div
      className="flex items-center py-3.5 border rounded-md bg-[#FAFAFA] pl-3  hover:bg-white cursor-pointer mb-3"
      onClick={() => onClickAssessment && onClickAssessment(item)}
    >
      <label className="w-1/4 font-medium text-xs text-primary">
        {item?.refNo}
      </label>
      <label
        style={{ width: "30%" }}
        className="font-medium text-xs text-primary"
      >
        {item?.title}
      </label>
      <label
        style={{ width: "30%" }}
        className="font-medium text-xs text-primary"
      >
        Recruiter {item?.recruiterFirstName} {item?.recruiterLastName}
      </label>
      <label
        style={{ width: "30%" }}
        className="font-medium text-xs text-primary"
      >
        {status.label}
      </label>
      <label
        style={{ width: "20%" }}
        className="italic font-medium text-xs text-primary"
      >
        {dayjs(item.updatedAt).format("DD-MMM-YY")}
        <label className="font-medium text-xs text-primary pl-2">
          {dayjs(item.updatedAt).format("HH:mm")}
        </label>
      </label>
    </div>
  );
};

const CandidateAssessments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { candidateAssessment } = useSelector((state) => state.candidate);
  const [loading, setLoading] = useState(candidateAssessment?.length === 0);

  useEffect(() => {
    getCandidateAssessment()
      .then((res) => {
        const { success, data } = res;
        if (success) {
          dispatch(setCandidateAssessment(data));
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleClickAssessment = (item) => {
    navigate(`/my-assessments/candidate-Assessments/${item.refNo}`);
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <div className="flex flex-1 flex-col pt-2 max-h-fit overflow-y-hidden">
        <div className="flex items-center pb-3 pl-3">
          <label className="w-1/4 flex font-semibold text-xs text-primary">
            Reference
          </label>
          <label
            style={{ width: "30%" }}
            className="flex font-semibold text-xs text-primary"
          >
            Title
          </label>
          <label
            style={{ width: "30%" }}
            className="flex font-semibold text-xs text-primary"
          >
            Assessor
          </label>
          <label
            style={{ width: "30%" }}
            className="flex font-semibold text-xs text-primary"
          >
            Status
          </label>
          <div style={{ width: "20%" }} />
        </div>

        {loading ? (
          <div className="gap-2.5 flex flex-col">
            {Array.from({ length: 10 }, (x, i) => i).map((key) => {
              return (
                <div className="flex justify-between">
                  <div
                    style={{
                      borderWidth: 1,
                    }}
                    className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
                  >
                    <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                    <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                    <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar">
              {candidateAssessment?.map((item) => {
                return (
                  <Assessment
                    key={item.refId}
                    item={item}
                    onClickAssessment={handleClickAssessment}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CandidateAssessments;
