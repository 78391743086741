import * as React from "react";
const AnswersIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_4621_13685)">
      <path
        d="M9.33333 8.66667H12V10H9.33333V8.66667ZM6.71867 3.806L8.33333 10.6667H6.96467L6.494 8.66667H4.17333L3.70267 10.6667H2.334L3.96 3.75867C4.15133 2.934 5.04333 2.42 5.918 2.78667C6.336 2.962 6.61733 3.364 6.71933 3.80533L6.71867 3.806ZM6.17933 7.33333L5.40867 4.06C5.40067 4.02467 5.36933 4 5.33333 4C5.29733 4 5.266 4.02467 5.258 4.06L4.48733 7.33333H6.18H6.17933ZM16 2V13.3333H11.4313L8.86733 15.4533C8.626 15.668 8.31733 15.7767 8.006 15.7767C7.68867 15.7767 7.36867 15.664 7.114 15.4373L4.61533 13.3333H0V2C0 0.897333 0.897333 0 2 0H14C15.1027 0 16 0.897333 16 2ZM14.6667 2C14.6667 1.632 14.368 1.33333 14 1.33333H2C1.63267 1.33333 1.33333 1.632 1.33333 2V12H5.10267L7.98667 14.43L10.9527 12H14.6667V2ZM9.33333 7.33333H13.3333V6H9.33333V7.33333ZM9.33333 4.66667H13.3333V3.33333H9.33333V4.66667Z"
        fill="#4D4D4D"
      />
    </g>
    <defs>
      <clipPath id="clip0_4621_13685">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AnswersIcon;
