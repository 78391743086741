import { useEffect, useRef } from "react";
import Quill from "quill";

const QuillEditorViewOnly = ({ value }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const quill = new Quill(editorRef.current, {
      theme: "snow", // Use the snow theme
      readOnly: true, // Set editor to read-only
      modules: {
        toolbar: false, // Disable the toolbar
      },
    });

    // Set initial content
    if (typeof value === "string") {
      quill.clipboard.dangerouslyPasteHTML(value?.trim());
    }

    // Handle content change
    quill.on("text-change", () => {
      const content = quill.root.innerHTML;
      onChange(content);
    });

    // editorRef.current = quill;

    return () => {
      quill.off("text-change");
    };
  }, [value]);

  return (
    <>
      <style>
        {`
      .quill-container {
    border: none !important;
    box-shadow: none !important;
    height: 300px;
}

.ql-editor {
    padding: 0;
    margin: 0;
}

.ql-container {
    border: none !important;
    box-shadow: none !important;
}
      `}
      </style>
      <div ref={editorRef} className="font-medium text-primary" />
    </>
  );
};

export default QuillEditorViewOnly;
