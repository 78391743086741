import * as React from "react";
const MatchesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#4D4D4D"
      d="M13.333 0a2.67 2.67 0 0 0-2.666 2.667c0 .044.011.086.013.13L4.924 4.596a2.66 2.66 0 0 0-2.257-1.263A2.67 2.67 0 0 0 0 6a2.67 2.67 0 0 0 2.667 2.667 2.65 2.65 0 0 0 1.449-.434l4.207 3.856A2.63 2.63 0 0 0 8 13.333 2.67 2.67 0 0 0 10.667 16a2.67 2.67 0 0 0 2.666-2.667c0-1.008-.569-1.877-1.397-2.33l1.418-5.672A2.668 2.668 0 0 0 16 2.667 2.674 2.674 0 0 0 13.333 0ZM2.667 7.333a1.333 1.333 0 1 1-.001-2.666 1.333 1.333 0 0 1 0 2.666Zm9.333 6a1.333 1.333 0 1 1-2.666.001 1.333 1.333 0 0 1 2.666 0Zm-1.354-2.664a2.651 2.651 0 0 0-1.429.431L5.011 7.244c.199-.373.322-.793.322-1.245 0-.044-.011-.086-.013-.13l5.756-1.799c.243.39.584.705.988.927l-1.417 5.672h-.001ZM13.333 4a1.333 1.333 0 1 1 0-2.666 1.333 1.333 0 0 1 0 2.666Z"
    />
  </svg>
);
export default MatchesIcon;
