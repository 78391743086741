import PersonalDetails from "./components/PersonalDetails";
import SkillSection from "./components/SkillSection";

const AboutSection = ({ candidateDetails, setCandidateDetails }) => {
  return (
    <>
      {/* Personal Detail Section */}
      <PersonalDetails
        candidateDetails={candidateDetails}
        setCandidateDetails={setCandidateDetails}
      />

      {/* Skill Section */}
      <SkillSection />
    </>
  );
};

export default AboutSection