import profile1 from "../../../assets/profilePic1.png";
import profile2 from "../../../assets/profilePic2.png";
import profile3 from "../../../assets/profilePic3.png";

import amazon from "../../../assets/tmp/amazon.png";
import dell from "../../../assets/tmp/dell.png";
import ebay from "../../../assets/tmp/ebay.png";
import facebook from "../../../assets/tmp/facebook.png";
import google from "../../../assets/tmp/google.png";
import netflix from "../../../assets/tmp/netflix.png";
import uber from "../../../assets/tmp/uber.png";

export const jobs = [
  {
    _id: "CA-FQ1478",
    title: "Internal Audit Manager",
    location: "South East London",
    price: "$59,000",
    jobType: "Permanent",
    about: "Government/Public Sector, Not-for Profit. Commerce and Industry",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
  },
  {
    _id: "CA-FQ1578",
    title: "Internal Audit Manager",
    location: "South East London",
    price: "$59,000",
    jobType: "Permanent",
    about: "Government/Public Sector, Not-for Profit. Commerce and Industry",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
  },
];

export const staffs = [
  {
    _id: "CA-FQ1578",
    name: "Sarah Cole",
    position: "Lead Recruitment Agent",
    email: "sarahcole_123@gmail.com",
    phone: "+1  234-777-2399",
    status: "Online",
    image: profile1,
  },
  {
    _id: "CA-FQ1378",
    name: "Cameron Williamson",
    position: "Lead Recruitment Agent",
    email: "sarahcole_123@gmail.com",
    phone: "+1  234-777-2399",
    status: "Online",
    image: profile2,
  },
  {
    _id: "CA-FQ2478",
    name: "Guy Hawkins",
    position: "Lead Recruitment Agent",
    email: "sarahcole_123@gmail.com",
    phone: "+1  234-777-2399",
    status: "Online",
    image: profile3,
  },
  {
    _id: "CA-F45578",
    name: "Jacob Jones",
    position: "Lead Recruitment Agent",
    email: "sarahcole_123@gmail.com",
    phone: "+1  234-777-2399",
    status: "Online",
    image: profile1,
  },
];

export const partners = [
  {
    image: amazon,
    name: "Amazon",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    image: ebay,
    name: "EBAY",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    image: facebook,
    name: "Facebook",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    image: google,
    name: "Google",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    image: uber,
    name: "Uber",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    image: netflix,
    name: "Netflix",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    image: dell,
    name: "Dell",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
];
