import { useEffect, useState } from "react";

const ChevronRightIcon = ({ size = "24", fill = "none", className }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke={fill}
      class={_class}
    >
      <path
        d="M8.46918 4.21995C8.32873 4.36058 8.24984 4.5512 8.24984 4.74995C8.24984 4.9487 8.32873 5.13933 8.46918 5.27995L15.1892 12L8.46918 18.7199C8.39549 18.7886 8.33639 18.8714 8.2954 18.9634C8.2544 19.0554 8.23236 19.1547 8.23059 19.2554C8.22881 19.3561 8.24733 19.4562 8.28505 19.5495C8.32278 19.6429 8.37892 19.7278 8.45014 19.799C8.52136 19.8702 8.60619 19.9264 8.69958 19.9641C8.79297 20.0018 8.893 20.0203 8.9937 20.0185C9.0944 20.0168 9.19372 19.9947 9.28572 19.9537C9.37771 19.9127 9.46051 19.8536 9.52918 19.78L16.7792 12.53C16.9196 12.3893 16.9985 12.1987 16.9985 12C16.9985 11.8012 16.9196 11.6106 16.7792 11.47L9.52918 4.21995C9.38855 4.0795 9.19793 4.00061 8.99918 4.00061C8.80043 4.00061 8.6098 4.0795 8.46918 4.21995Z"
        fill={fill}
      />
    </svg>
  );
};
export default ChevronRightIcon;
