export const assessmentDetails = {
  refId: "AS-1290MN",
  title: "Full Stack Developer Assessment",
  description: `facere fuga odit totam culpa facere fuga odit totam culpa.`,
  status: "Assessment Received",
  sender: "John Doe",
  createdAt: new Date(),
  read: false,
  updatedAt: new Date(),
  sections: [
    {
      sectionId: "AB-ABABAB",
      sectionName: "Python Basics",
      createdAt: new Date(),
      updatedAt: new Date(),
      read: false,
      isEdit: true,
      isSaved: false,
      questions: [
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question:
            "Write a Python function that takes a string as input and returns a dictionary where the keys are the words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question: "Write a Python function that tae words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question: "Write a Python function that ds?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question: "Write a Python function tha words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
      ],
    },
    {
      sectionId: "AB-ABABAB",
      sectionName: "Javascript Basics",
      createdAt: new Date(),
      updatedAt: new Date(),
      read: false,
      isEdit: true,
      isSaved: false,
      questions: [
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question: "Write a Python function that takes a string as inpus?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question: "Write a Python function that takese keys are the words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question:
            "Write a Python function thatinput and returns a dictionary where the keys are the words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question: "Write a Python function that takethe words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question:
            "Write a Python function that takes a string as input and returnds?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question: "Write a Python function thy where the keys are the words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question:
            "Writeinput and returns a dictionary where the keys are the words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question:
            "Write a Python function that takes a stringwhere the keys are the words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
      ],
    },
    {
      sectionId: "AB-ABABAB",
      sectionName: "Backend Basics",
      createdAt: new Date(),
      updatedAt: new Date(),
      read: false,
      isEdit: true,
      isSaved: false,
      questions: [
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question:
            "Write and returns a dictionary where the keys are the words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question:
            "Write a Python function that takes a string as input and returns a dictionary where the keys are the words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
      ],
    },
    {
      sectionId: "AB-ABABAB",
      sectionName: "Frontend Basics",
      createdAt: new Date(),
      updatedAt: new Date(),
      read: false,
      isEdit: true,
      isSaved: false,
      questions: [
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question:
            "Write ainput and returns a dictionary where the keys are the words?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
        {
          questionId: "ABCDEF",
          questionType: "single-answer",
          question: "Write a Python function that takes a string as ?",
          answers: [
            {
              answerType: "text",
              answer: "ABCD",
            },
            {
              answerType: "code",
              answer: "ABCD",
            },
          ],
          isEdit: true,
          isSaved: false,
        },
      ],
    },
  ],
};
