import { useEffect, useState } from "react";

const CareerIcon = ({
    size = "24",
    fill = 'none',
    className
}) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" stroke-width="0" stroke={fill} class={_class}>
            <g clip-path="url(#clip0_2321_363)">
                <path d="M14 2.66667H12V1.33333C12 0.979711 11.8595 0.640573 11.6095 0.390524C11.3594 0.140476 11.0203 0 10.6667 0L5.33333 0C4.97971 0 4.64057 0.140476 4.39052 0.390524C4.14048 0.640573 4 0.979711 4 1.33333V2.66667H2C1.46957 2.66667 0.960859 2.87738 0.585786 3.25245C0.210714 3.62753 0 4.13623 0 4.66667L0 16H16V4.66667C16 4.13623 15.7893 3.62753 15.4142 3.25245C15.0391 2.87738 14.5304 2.66667 14 2.66667ZM5.33333 1.33333H10.6667V2.66667H5.33333V1.33333ZM2 4H14C14.1768 4 14.3464 4.07024 14.4714 4.19526C14.5964 4.32029 14.6667 4.48986 14.6667 4.66667V8H1.33333V4.66667C1.33333 4.48986 1.40357 4.32029 1.5286 4.19526C1.65362 4.07024 1.82319 4 2 4ZM1.33333 14.6667V9.33333H7.33333V10.6667H8.66667V9.33333H14.6667V14.6667H1.33333Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_2321_363">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )

}
export default CareerIcon