import { Link, useNavigate } from "react-router-dom";
import ChevronDown from "../../../assets/svg/ChevronDown";
import PlusIcon from "../../../assets/svg/PlusIcon";
import SearchIcon from "../../../assets/svg/SearchIcon";
import { getCandidates } from "../../../endpoints/candidate.service";
import { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { setCandidates } from "../../../store/actions/candidate.action";
import { useDispatch, useSelector } from "react-redux";

const ItemCard = ({ item }) => {
  return (
    <Link
      to={`/contacts/edit-candidate/${item?._id}`}
      className="flex items-center justify-between py-2.5 border rounded-md bg-[#FAFAFA] pl-5 pr-20 hover:bg-white cursor-pointer"
    >
      <div className="flex items-center gap-3">
        <div className="h-4 w-4 border rounded-sm" />
        <label className="flex font-semibold text-xs text-primary">
          {item?.firstName} {item?.lastName}
        </label>
      </div>
      <label className="flex font-semibold text-xs text-primary">
        {item?.jobTitle}
      </label>
      <label className="flex italic text-xs text-purple">{item?.status}</label>
      <label className="flex font-semibold text-xs text-primary">
        {item?.refNo}
      </label>
    </Link>
  );
};

const CandidateSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { candidates } = useSelector((state) => state?.candidate);
  const [loading, setLoading] = useState(candidates?.length === 0);

  useEffect(() => {
    getCandidates()
      .then((response) => {
        const { success, data } = response;
        if (success) {
          dispatch(setCandidates(data));
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {/* Search and filter section */}
      <div className="flex items-center justify-between w-full mt-6">
        <div className="flex items-center gap-8">
          {/* Input container */}
          <div className="flex rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5">
            <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-2">
              <label className="text-primary text-sm font-medium">
                Search by
              </label>
              <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
            </button>
            <input
              className="bg-white text-xs font-medium text-light-black outline-none px-3 py-2 rounded-lg w-80"
              placeholder="Search by Name, Title or Ref number..."
            />
            <SearchIcon className={"w-6 h-6"} fill={"#2e2e2e"} />
          </div>
          <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-4">
            <label className="text-primary text-sm font-medium">
              Filter status
            </label>
            <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
          </button>
        </div>

        <button
          onClick={() => navigate("/contacts/add-candidate")}
          className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-semibold text-sm"
        >
          <PlusIcon className={"w-4 h-4"} fill={"white"} />
          Candidate
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <>
          {/* ScrollArea  */}
          <div className="flex flex-col mt-4 max-h-fit overflow-y-scroll no-scrollbar gap-2">
            {candidates?.map((item) => {
              return <ItemCard key={item?._id} item={item} />;
            })}
          </div>
        </>
      )}
    </>
  );
};

export default CandidateSection;
