import { useEffect, useState } from "react";
import ChevronDown from "../../../../assets/svg/ChevronDown";
import ChevronLeftIcon from "../../../../assets/svg/ChevronLeftIcon";
import { useNavigate, useParams } from "react-router-dom";
import { getCandidates } from "../../../../endpoints/candidate.service";
import AboutSection from "./components/AboutSection/AboutSection";
import SendAssessmentLinkSection from "./components/SendAssessmentLinkSection/SendAssessmentLinkSection";
import { useDispatch } from "react-redux";
import { setAssessmentList } from "../../../../store/actions/assessment.action";
import { listAssessment } from "../../../../endpoints/assessment.service";
import Spinner from "../../../../components/Spinner/Spinner";

const tabs = [
  "About",
  "Schedule Interview",
  "Send Assessment Link",
  "Activity History",
  "Messages",
];

const RenderBody = ({ activeTab, candidateDetails, setCandidateDetails }) => {
  switch (activeTab) {
    case 0:
      return (
        <AboutSection
          candidateDetails={candidateDetails}
          setCandidateDetails={setCandidateDetails}
        />
      );
    case 2:
      return <SendAssessmentLinkSection />;
    default:
      return (
        <AboutSection
          candidateDetails={candidateDetails}
          setCandidateDetails={setCandidateDetails}
        />
      );
  }
};

const AddCandidatePage = () => {
  const navigate = useNavigate();
  const { candidateId } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [candidateDetails, setCandidateDetails] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (candidateId) {
      setLoading(true)
      fetchAssessment();
      getCandidates({ candidateId })
        .then((response) => {
          const { success, data } = response;
          if (success) {
            setCandidateDetails(data[0]);
          }
        })
        .finally(() => setLoading(false));
    }else{
      setLoading(false)
    }
  }, [candidateId]);

  const fetchAssessment = () => {
    listAssessment().then((res) => {
      const { data, success } = res;
      if (success) {
        dispatch(setAssessmentList(data));
      }
    });
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center self-start"
      >
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>

      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <>
          {/* Header section */}
          <div className="flex items-center justify-between rounded-lg border bg-[#FAFAFA] px-3 py-3.5 mt-5">
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center h-12 w-12 rounded-full bg-[#00000033] text-white font-bold text-lg">
                {candidateDetails?.firstName?.charAt(0)?.toUpperCase()}
                {candidateDetails?.lastName?.charAt(0)?.toUpperCase()}
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-xs font-semibold text-[#6f6f6f]">
                  {candidateDetails?.firstName} {candidateDetails?.lastName}
                </label>
                <label className="text-xs font-semibold text-[#6f6f6f]">
                  {candidateDetails?.jobTitle}
                </label>
              </div>
            </div>

            <div className="flex flex-col items-end gap-1">
              <div className="flex items-center justify-center text-primary font-medium text-sm gap-2">
                Status:
                <button className="flex items-center justify-center bg-green-500 rounded-md font-semibold py-1 gap-4 px-2 text-sm text-white">
                  Open to offers
                  <ChevronDown className={"w-4 h-4"} fill={"white"} />
                </button>
              </div>
              <label className="text-xs font-semibold text-primary italic">
                {candidateDetails?.refNo}
              </label>
            </div>
          </div>

          {/* Tabs Section */}
          <div className="flex items-center justify-between border-b px-3 mt-4">
            {tabs?.map((tab, tabIndex) => {
              return (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tabIndex)}
                  className={`${
                    tabIndex === activeTab
                      ? "border-secondary"
                      : "border-transparent"
                  } text-primary text-sm font-medium border-b-2 py-1 px-3`}
                >
                  {tab}
                </button>
              );
            })}
          </div>
          <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar">
            <RenderBody
              activeTab={activeTab}
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AddCandidatePage;
