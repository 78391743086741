import * as React from "react";
const AccountSettingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#4D4D4D"
        d="M10 4c0-2.206-1.794-4-4-4S2 1.794 2 4s1.794 4 4 4 4-1.794 4-4ZM6 6.667A2.67 2.67 0 0 1 3.333 4 2.67 2.67 0 0 1 6 1.333 2.67 2.67 0 0 1 8.667 4 2.67 2.67 0 0 1 6 6.667Zm8.667 4.666c0-.368-.06-.721-.171-1.052l1.213-.7-.665-1.155-1.215.7A3.34 3.34 0 0 0 12 8.066v-1.4h-1.333v1.4a3.337 3.337 0 0 0-1.829 1.06l-1.215-.7-.666 1.155 1.214.7a3.314 3.314 0 0 0 0 2.105l-1.214.7.666 1.155 1.215-.7c.47.531 1.108.912 1.829 1.059V16H12v-1.4a3.337 3.337 0 0 0 1.829-1.06l1.215.7.665-1.155-1.213-.699c.11-.331.17-.685.17-1.053Zm-3.334 2c-1.102 0-2-.897-2-2 0-1.102.898-2 2-2 1.103 0 2 .898 2 2 0 1.103-.897 2-2 2Zm-8-4h2v1.334h-2c-1.102 0-2 .897-2 2V16H0v-3.333a3.337 3.337 0 0 1 3.333-3.334Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default AccountSettingIcon;
