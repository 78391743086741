import { useState } from "react";
import AssessmentsSection from "./AssessmentsSection/AssessmentsSection";

const tabs = ["Live Assessments", "Assessment Setup"];

const MyAssessmentsPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      {/* Tabs container */}
      <div className="flex items-center justify-around border rounded-xl mt-4 overflow-hidden h-10">
        {tabs?.map((item, index) => {
          return (
            <button
              onClick={() => setActiveTab(index)}
              key={item}
              style={{
                borderBottom:
                  activeTab === index
                    ? "3px solid #FF9800"
                    : "3px solid transparent",
              }}
              className={`w-1/2 flex justify-center items-center h-full cursor-pointer ${
                activeTab === index ? "text-primary" : "text-light-grey"
              } font-semibold text-sm`}
            >
              {item}
            </button>
          );
        })}
      </div>

      <AssessmentsSection activeTab={tabs[activeTab]} />
    </div>
  );
};

export default MyAssessmentsPage;
