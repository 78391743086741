import { useState } from "react";
import ChevronLeftIcon from "../../assets/svg/ChevronLeftIcon";
import ScheduleSection from "./ScheduleSection/ScheduleSection";

const tabs = ["Schedules", "Scheduling"];

const CalendarsPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="flex flex-col flex-1 rounded-lg bg-white w-full flex-grow p-4">
      <button className="flex items-center">
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary">
          Back to listing
        </label>
      </button>

      {/* Tabs container */}
      <div className="flex items-center justify-around border rounded-xl mt-4 overflow-hidden h-10">
        {tabs?.map((item, index) => {
          return (
            <button
              onClick={() => setActiveTab(index)}
              key={item}
              style={{
                borderBottom:
                  activeTab === index
                    ? "3px solid #FF9800"
                    : "3px solid transparent",
              }}
              className={`w-1/2 flex justify-center items-center h-full cursor-pointer ${
                activeTab === index ? "text-primary" : "text-light-grey"
              } font-semibold text-sm`}
            >
              {item}
            </button>
          );
        })}
      </div>

      <div className="flex flex-1 mt-5 flex-grow max-h-fit overflow-y-scroll no-scrollbar">
        <ScheduleSection />
      </div>
    </div>
  );
};

export default CalendarsPage;
