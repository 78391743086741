import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useRef, useState } from "react";
import { Cropper } from "react-cropper";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import ChangePassword from "./components/ChangePassword";
import Modal from "../../components/Modal/Modal";
import { profileImage, uploadUrl } from "../../endpoints/images.service";
import { updateRecruiterProfile } from "../../endpoints/recruiter.service";
import { useSnackbar } from "notistack";
import { setUserData } from "../../store/actions/auth.action";
import { userTypes } from "../../utils/constants";

const MyAccountDetailsPage = () => {
  const navigate = useNavigate();
  const cropperRef = useRef(null);
  const filePickerRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const isReferralAmbassador = user?.userType === userTypes.REFERRAL_AMBASSADOR;
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: isReferralAmbassador
      ? {
          firstName: user?.firstName,
          lastName: user?.lastName,
          location: user?.location,
          image: undefined,
          profilePic: undefined,
        }
      : {
          firstName: user?.firstName,
          lastName: user?.lastName,
          jobTitle: user?.jobTitle,
          location: user?.location,
          image: undefined,
          profilePic: undefined,
        },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const fields = watch();

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      setValue("file", file, { shouldDirty: true });
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("image", reader.result, { shouldDirty: true });
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("Error during file upload or API calls:", error);
    }
  };

  const handleItemClick = (item) => {
    navigate("/account-members-detail", { state: { item } });
  };

  const getCroppedImage = (e) => {
    e.preventDefault();

    if (cropperRef.current) {
      const cropperInstance = cropperRef.current.cropper;
      if (cropperInstance) {
        const croppedDataUrl = cropperInstance.getCroppedCanvas().toDataURL();
        setValue("profilePic", croppedDataUrl);
        setValue("image", undefined);
      } else {
        console.error("Cropper instance is not found");
      }
    } else {
      console.error("cropperRef.current is not initialized");
    }
  };

  const updateProfile = async (payload) => {
    payload.id = user?._id;
    try {
      setIsLoading(true);
      if (payload?.profilePic) {
        const { data } = await profileImage();
        if (!data?.data || !data?.data.uploadURL) {
          throw new Error("Failed to get upload URL");
        }
        const formData = new FormData();
        const base64Data = payload?.profilePic?.split(";base64,").pop();
        const byteArray = Uint8Array.from(atob(base64Data), (c) =>
          c.charCodeAt(0)
        );
        const blob = new Blob([byteArray], { type: payload?.file?.type });
        formData.append("file", blob, payload?.file?.name);
        const fileResponse = await uploadUrl(data?.data.uploadURL, formData);
        delete payload["file"];
        delete payload["profilePic"];
        delete payload["image"];
        const picURl = fileResponse?.variants?.find(
          (x) => x.split("/").slice(-1)[0] === "public"
        );
        payload["profilePic"] = picURl;
      }
      const { success, data: userApiData } = await updateRecruiterProfile(
        payload
      );
      if (success) {
        dispatch(setUserData(userApiData));
        setValue("profilePic", undefined);
        setValue("image", undefined);
        setValue("openProfileModel", false);
        enqueueSnackbar("Details updated successfully.", {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log("error", error);
    } finally {
      setIsLoading(false);
      setIsEditing(false);
    }
  };

  return (
    <div className="flex flex-col overflow-y-scroll no-scrollbar h-full rounded-lg bg-white w-full flex-grow p-10 gap-10">
      <div className="flex flex-col bg-gray-50 rounded-md w-full">
        {isReferralAmbassador && (
          <p className="text-center text-primary text-sm font-bold underline mt-7">
            Ambassador account details
          </p>
        )}
        {/* Profile Update Section */}
        <div className={`flex flex-col gap-5 p-5 w-full`}>
          <div
            className={`grid ${
              isReferralAmbassador ? "grid-cols-2" : "grid-cols-3"
            }`}
          >
            <div className="flex flex-col">
              <label className="font-semibold text-primary text-sm">
                Update your personal details
              </label>
              <span className="font-medium text-xs text-light-grey">
                Keep your profile accurate and up to date.
              </span>
            </div>
            {!isReferralAmbassador ? (
              <div className="flex justify-center items-center">
                <label className="font-semibold text-primary text-sm underline">
                  Account Admin
                </label>
              </div>
            ) : null}
            <div className="flex justify-end">
              <button
                onClick={() => {
                  if (isEditing) {
                    handleSubmit(updateProfile)();
                  } else {
                    setIsEditing((prev) => !prev);
                  }
                }}
                className="flex bg-primary rounded-md py-1.5 px-4 w-max"
              >
                <span className="flex items-center gap-4 font-semibold text-sm text-white m-auto">
                  {isEditing ? "Save" : "Edit"}
                  {isLoading ? <LoadingAnimation /> : null}
                </span>
              </button>
            </div>
          </div>
          {/* Form container */}
          <div className={`${isEditing ? "opacity-100" : "opacity-60"} w-full`}>
            <div className="flex gap-14 items-center w-full">
              {/* Personal details form  */}
              <div className="flex flex-col gap-5 w-1/2">
                <CustomInput
                  disabled={!isEditing}
                  label="First Name"
                  name="firstName"
                  placeholder={"Ex: Jonh"}
                  control={control}
                  errors={errors}
                />
                <CustomInput
                  disabled={!isEditing}
                  label="Last Name"
                  name="lastName"
                  placeholder={"Ex: doe"}
                  control={control}
                  errors={errors}
                />
                {!isReferralAmbassador ? (
                  <CustomInput
                    disabled={!isEditing}
                    label="Job Title"
                    name="jobTitle"
                    placeholder={"Ex: Project Manager"}
                    control={control}
                    errors={errors}
                  />
                ) : null}
                <CustomInput
                  disabled={!isEditing}
                  label={"Location"}
                  name="location"
                  placeholder={"Ex: United Kingdom"}
                  control={control}
                  errors={errors}
                />
              </div>
              {/* Image container */}
              <div className="flex flex-col px-5 gap-6 max-w-[40%]">
                <div className="flex self-center rounded-full h-40 w-40 bg-[#E1E0E0] items-center justify-center">
                  {fields?.profilePic || user?.profilePic ? (
                    <img
                      src={fields?.profilePic || user?.profilePic}
                      className="rounded-full h-40 w-40 object-fill"
                    />
                  ) : (
                    <span className="font-montserrat text-white font-semibold text-6xl">
                      {user?.firstName?.charAt(0)}
                      {user?.lastName?.charAt(0)}
                    </span>
                  )}
                </div>
                <button
                  type="button"
                  disabled={!isEditing}
                  onClick={() => filePickerRef?.current?.click()}
                  className="flex  items-center border rounded-lg overflow-hidden pr-2"
                >
                  <span className="text-sm text-[#6f6f6f] font-medium py-2.5 px-3 bg-[#E9EAF0]">
                    PNG/JPG
                  </span>
                  <span className="text-xs flex font-medium px-3 text-ngray flex-1 text-left whitespace-wrap">
                    {fields?.file
                      ? fields?.file?.name
                      : user?.profilePic
                      ? user?.profilePic
                      : "Upload logo here"}
                  </span>
                </button>
                <input
                  ref={filePickerRef}
                  type="file"
                  className="hidden"
                  accept=".png, .jpg"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>

          {/* Image Cropper Modal */}
          <Modal isOpen={fields?.image}>
            <div className="flex flex-col mt-7">
              {fields?.image && (
                <Cropper
                  src={fields?.image}
                  ref={cropperRef}
                  style={{ height: 400, width: "100%" }}
                  aspectRatio={1}
                  viewMode={2}
                  guides={false}
                  minCropBoxWidth={50} // Minimum width for the crop area
                  minCropBoxHeight={50} // Minimum height for the crop area
                />
              )}
              <div className="mt-10 flex justify-end">
                <button
                  onClick={(e) => getCroppedImage(e)}
                  className="bg-primary text-white px-6 py-2.5 rounded-md"
                >
                  Crop Image
                </button>
              </div>
            </div>
            <style>
              {`
                /* Ensure the cropper's crop box is circular */
                .cropper-face,
                .cropper-view-box,
                .cropper-crop-box {
                  border-radius: 50% !important; /* Make crop area circular */
                  border: 2px solid white !important; /* Optional: Set the color of the circular crop lines */
                }

                .cropper-face {
                  background-color: rgba(0, 0, 0, 0.3) !important; /* Optional: Set the background color of the circular crop area */
                }

                .cropper-view-box {
                  border-radius: 50% !important; /* Make sure the selection area is circular */
                }

                .cropper-crop-box {
                  border-radius: 50% !important; /* Ensure the cropping boundary is circular */
                }
              `}
            </style>
          </Modal>
        </div>
        {/* Change password Section */}
        <ChangePassword />
      </div>
    </div>
  );
};

export default MyAccountDetailsPage;
