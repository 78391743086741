//@ts-ignore
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useLayoutEffect } from "react";

const tabs = [
  { name: "Account Members", path: "account-members" },
  { name: "Company Details", path: "my-company-details" },
  { name: "My Account Details", path: "my-account-details" },
];

const AccountPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (pathname === "/account") {
      navigate("account-members");
    }
  }, [pathname]);

  const handleItemClick = (item) => {
    navigate("/account-members-detail", { state: { item } });
  };

  const isTabActive = (path: string) => pathname === `/account/${path}`;

  return (
    <div className="flex flex-col overflow-y-hidden h-full rounded-lg bg-white w-full flex-grow p-4">
      {/* Tabs container */}
      <div className="flex items-center justify-around border rounded-xl mt-4 overflow-hidden h-10">
        {tabs.map((item) => (
          <button
            onClick={() => navigate(item.path)}
            key={item.name}
            style={{
              borderBottom: isTabActive(item.path)
                ? "3px solid #FF9800"
                : "3px solid transparent",
            }}
            className={`w-1/3 flex justify-center items-center h-full cursor-pointer ${isTabActive(item.path) ? "text-primary" : "text-light-pink"
              } font-semibold text-sm`}
            aria-current={isTabActive(item.path) ? "page" : undefined}
          >
            {item.name}
          </button>
        ))}
      </div>

      <div className="flex flex-1 flex-col overflow-y-hidden no-scrollbar">
        <Outlet />
      </div>
    </div>
  );

};

export default AccountPage;
