import React from "react";
import { useNavigate } from "react-router-dom";
import { JobStatus, JobType } from "../../../../utils/constants";
import { useSelector } from "react-redux";

const StatusDetail = ({ label, value, extraClass }) => {
  return (
    <div className="flex items-center">
      <span
        className={`flex-1 font-inter font-medium text-xs tracking-[0.2px] text-light-black leading-5`}
      >
        {label}
      </span>
      <span
        className={`flex-1 font-inter font-medium text-xs text-primary leading-4 ${extraClass}`}
      >
        {value ?? "-"}
      </span>
    </div>
  );
};

export const JobPostSection = () => {
  const navigate = useNavigate();
  const { jobDetail } = useSelector((state) => state?.job);
  const job =
    JobType.find(({ value }) => value === jobDetail?.jobType)?.label || "-";

  return (
    <section className="flex pt-12 relative overflow-y-scroll no-scrollbar">
      <div className="flex flex-col pl-10 pr-7 gap-6 flex-[1.8]">
        <div className="flex flex-col gap-3">
          <p className="font-inter font-bold text-xs tracking-[0.2px] text-primary">
            Description
          </p>
          <div
            className="font-montserrat font-normal text-xs text-light-black leading-5"
            dangerouslySetInnerHTML={{ __html: jobDetail?.description }}
          ></div>
        </div>
      </div>

      <div class="border-l-[1px] border-[#ddddd] h-[450px] mx-4"></div>

      <div className="flex flex-col gap-9 pr-6 flex-1">
        <div className="flex flex-col gap-3">
          <StatusDetail
            label="Status"
            value={
              jobDetail?.status === JobStatus.LIVE
                ? "Live"
                : jobDetail?.status === JobStatus.CLOSED
                ? "Closed"
                : "Draft"
            }
            extraClass={
              jobDetail?.status === JobStatus.LIVE
                ? "text-green-500"
                : jobDetail?.status === JobStatus.CLOSED
                ? "text-red-500"
                : "text-yellow-500"
            }
          />
          <StatusDetail label="Job Reference" value="AB1234" />
          <StatusDetail label="Experience" value="2-4 Years" />
          <StatusDetail label="Employment" value={job} />
          <StatusDetail
            label="Salary"
            value={`${
              jobDetail?.salary?.min ? "$" + jobDetail?.salary?.min : "-"
            }${jobDetail?.salary?.max ? "- " + jobDetail?.salary?.max : "-"}`}
          />
        </div>

        <div className="flex flex-col gap-5">
          <p className="font-inter font-bold text-xs tracking-[0.2px] text-primary">
            Company Details
          </p>

          <div className="flex flex-col gap-3">
            <StatusDetail
              label="Company Name"
              value={jobDetail?.companyDetails?.name}
            />
            <StatusDetail
              label="Website URL"
              value="http://www.iselectrics.com"
            />
            <StatusDetail
              label="Address"
              value="2972 Westheimer Rd. Santa Ana, Illinois 85486"
            />
            <StatusDetail label="Phone Number" value="308.555.0121" />
            <StatusDetail
              label="Email Address"
              value="tranthuy.nute@gmail.com"
            />
          </div>
        </div>
      </div>

      <div className="absolute top-0 right-0 pr-4 pt-4">
        <button
          onClick={() => navigate("/job/create", { state: jobDetail })}
          type="submit"
          className="group flex items-center justify-center font-semibold text-sm relative"
        >
          <span className="z-50">Edit</span>
        </button>
      </div>
    </section>
  );
};
