import React, { useState } from "react";
import { Slider } from "@nextui-org/slider";
import LocationIconFilled from "../../assets/svg/LocationIconFilled";

const CustomSlider = ({
  label,
  value = 0,
  onChange,
  max = 10,
  min = 0,
  step = 1,
  className,
  fillerClass,
  baseClass,
  labelStyle,
}) => {
  return (
    <div className={`flex flex-1 flex-col ${className}`}>
      <span
        className={`font-montserrat text-primary font-medium text-sm mb-2 ${labelStyle}`}
      >
        {label}
      </span>

      <Slider
        classNames={{
          base: `w-full flex flex-1 justify-center ${baseClass}`,
          filler: `bg-[#EFBF04] rounded-full ${fillerClass} `,
          track: "border items-center h-[6px]",
          labelWrapper: "bg-black",
          // label:'mt-4 bg-black'
          // labelWrapper
        }}
        renderThumb={(props) => (
          <div
            {...props}
            className="rounded-full cursor-grab data-[dragging=true]:cursor-grabbing"
          >
            <span className="font-montserrat text-light-black font-medium text-xs	bg-[#ECECEC] flex items-center justify-center w-[15px] h-[15px] rounded-full">
              {value}
            </span>
            <LocationIconFilled
              className={"w-[14px] h-[14px]"}
              fill={"#EFBF04"}
            />
          </div>
        )}
        // marks={sliderValues}
        value={value}
        step={step}
        showSteps={true}
        maxValue={max}
        minValue={min}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomSlider;
