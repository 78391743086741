import { useEffect, useState } from "react";

const SaveIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <path d="M2.40039 3.9999C2.40039 3.57556 2.56896 3.16859 2.86902 2.86853C3.16908 2.56847 3.57604 2.3999 4.00039 2.3999H10.7036C11.1279 2.39999 11.5348 2.56862 11.8348 2.8687L13.1316 4.1655C13.4317 4.46549 13.6003 4.87239 13.6004 5.2967V11.9999C13.6004 12.4242 13.4318 12.8312 13.1318 13.1313C12.8317 13.4313 12.4247 13.5999 12.0004 13.5999H4.00039C3.57604 13.5999 3.16908 13.4313 2.86902 13.1313C2.56896 12.8312 2.40039 12.4242 2.40039 11.9999V3.9999ZM4.00039 3.1999C3.78822 3.1999 3.58473 3.28419 3.43471 3.43422C3.28468 3.58425 3.20039 3.78773 3.20039 3.9999V11.9999C3.20039 12.2121 3.28468 12.4156 3.43471 12.5656C3.58473 12.7156 3.78822 12.7999 4.00039 12.7999V9.1999C4.00039 8.88164 4.12682 8.57642 4.35186 8.35137C4.57691 8.12633 4.88213 7.9999 5.20039 7.9999H10.8004C11.1187 7.9999 11.4239 8.12633 11.6489 8.35137C11.874 8.57642 12.0004 8.88164 12.0004 9.1999V12.7999C12.2126 12.7999 12.416 12.7156 12.5661 12.5656C12.7161 12.4156 12.8004 12.2121 12.8004 11.9999V5.2967C12.8003 5.08455 12.716 4.8811 12.566 4.7311L11.2692 3.4343C11.1192 3.28426 10.9157 3.19995 10.7036 3.1999H10.4004V5.1999C10.4004 5.51816 10.274 5.82339 10.0489 6.04843C9.82388 6.27347 9.51865 6.3999 9.20039 6.3999H6.00039C5.68213 6.3999 5.37691 6.27347 5.15186 6.04843C4.92682 5.82339 4.80039 5.51816 4.80039 5.1999V3.1999H4.00039ZM5.60039 3.1999V5.1999C5.60039 5.30599 5.64253 5.40773 5.71755 5.48274C5.79256 5.55776 5.8943 5.5999 6.00039 5.5999H9.20039C9.30648 5.5999 9.40822 5.55776 9.48323 5.48274C9.55825 5.40773 9.60039 5.30599 9.60039 5.1999V3.1999H5.60039ZM11.2004 12.7999V9.1999C11.2004 9.09382 11.1582 8.99207 11.0832 8.91706C11.0082 8.84204 10.9065 8.7999 10.8004 8.7999H5.20039C5.0943 8.7999 4.99256 8.84204 4.91755 8.91706C4.84253 8.99207 4.80039 9.09382 4.80039 9.1999V12.7999H11.2004Z" fill="#6F6F6F" />
    </svg>
  );
};

export default SaveIcon;
