import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProfileImg from "../../assets/profilePic1.png";
import { getReferralMatchedCandidates } from "../../endpoints/skill.service";
import { useState, useEffect } from "react";
import { candidateStatus } from "../../utils/constants";
import moment from "moment";

const Matches2Page = () => {
  const [matchedList, setMatchedList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getReferralMatchedCandidates()
      .then((response) => {
        const { success, data } = response;
        if (success) {
          setMatchedList(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <div className="flex flex-1 flex-col max-h-fit overflow-y-hidden py-7 px-6 justify-center items-center">
        {loading ? (
          <div className="flex flex-col overflow-auto w-full gap-2.5">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((key) => {
              return (
                <div key={key} className="h-64 w-full flex items-center gap-8">
                  <div className="flex-1 bg-gray-100 h-full" />
                  <span className="flex items-center gap-4 text-xs border-b pb-2 font-medium italic text-light-black">
                    <div className="h-2 w-20 rounded-full bg-gray-100"></div>%
                    matched
                  </span>
                  <div className="flex-1 bg-gray-100 h-full" />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <span className="font-medium text-sm">
              {matchedList?.length
                ? "Possible matched results"
                : "No possible matched results found."}
            </span>

            {/* List */}
            <div className="flex flex-col gap-2.5 overflow-auto mt-8 no-scrollbar w-full">
              {matchedList?.map((item) => {
                return (
                  <Link
                    to={"match-detail"}
                    state={{ id: item?._id }}
                    className="flex items-center w-full gap-5 cursor-pointer group hover:border-gray-200 border-white border"
                  >
                    <div className="flex py-2 px-4 bg-[#F9FAFB] group-hover:bg-white gap-4 flex-1 justify-between h-14">
                      <div className="flex items-center gap-4">
                        <img
                          src={item?.profilePic}
                          className="w-10 h-10 rounded-full"
                        />
                        <div className="flex flex-col justify-between">
                          <span className="font-medium text-primary text-xs capitalize">
                            {item?.firstName} {item?.lastName}
                          </span>
                          <span className="font-medium text-primary text-xs">
                            {item?.jobTitle}
                          </span>
                        </div>
                      </div>
                      <span className="font-medium text-green-700 text-xs pr-12">
                        {candidateStatus[item?.status]}
                      </span>
                    </div>
                    <span className="text-xs border-b pb-2 font-medium italic text-light-black">
                      {item?.matchingPercentage?.toFixed(1)}% matched
                    </span>
                    <div className="flex py-2 px-4 bg-[#F9FAFB] group-hover:bg-white gap-4 flex-1 justify-between h-[3.4rem]">
                      <div className="flex flex-col justify-between">
                        <span className="font-medium text-light-black text-[10.5px]">
                          Jo-CBMD123
                        </span>
                        <span className="font-medium text-primary text-xs">
                          {item?.jobDetails?.title}
                        </span>
                      </div>
                      <div className="flex items-center gap-6 pr-8">
                        <div className="flex flex-col justify-between">
                          <span className="font-medium text-primary text-xs">
                            Post
                          </span>
                          <span className="font-medium text-primary text-xs">
                            Matched
                          </span>
                        </div>
                        <div className="flex flex-col justify-between">
                          <span className="font-medium text-xs italic text-light-black">
                            {moment(item?.jobDetails?.createdAt).format(
                              "DD/MM/YY"
                            )}
                          </span>
                          <span className="font-medium text-xs italic text-light-black">
                            {moment(item?.item?.createdAt).format("DD/MM/YY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Matches2Page;
