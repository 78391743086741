import * as React from "react";
const LeftArrowIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.01956 10.3134C7.06868 10.3592 7.10809 10.4144 7.13541 10.4757C7.16274 10.537 7.17744 10.6032 7.17862 10.6704C7.17981 10.7375 7.16746 10.8042 7.14231 10.8665C7.11716 10.9287 7.07973 10.9853 7.03225 11.0328C6.98477 11.0802 6.92822 11.1177 6.86596 11.1428C6.8037 11.168 6.73701 11.1803 6.66988 11.1791C6.60274 11.1779 6.53653 11.1632 6.4752 11.1359C6.41387 11.1086 6.35867 11.0692 6.31289 11.0201L3.64623 8.35339C3.55259 8.25964 3.5 8.13256 3.5 8.00006C3.5 7.86756 3.55259 7.74048 3.64623 7.64673L6.31289 4.98006C6.35867 4.93094 6.41387 4.89153 6.4752 4.86421C6.53653 4.83688 6.60274 4.82218 6.66988 4.821C6.73701 4.81982 6.8037 4.83216 6.86596 4.85731C6.92822 4.88246 6.98477 4.91989 7.03225 4.96737C7.07973 5.01485 7.11716 5.0714 7.14231 5.13366C7.16746 5.19592 7.17981 5.26261 7.17862 5.32974C7.17744 5.39688 7.16274 5.46309 7.13541 5.52442C7.10809 5.58575 7.06868 5.64095 7.01956 5.68673L5.20623 7.50006H11.6662C11.7988 7.50006 11.926 7.55274 12.0198 7.64651C12.1135 7.74028 12.1662 7.86745 12.1662 8.00006C12.1662 8.13267 12.1135 8.25985 12.0198 8.35361C11.926 8.44738 11.7988 8.50006 11.6662 8.50006H5.20623L7.01956 10.3134Z"
      fill="#6F6F6F"
    />
  </svg>
);
export default LeftArrowIcon;
