import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProfileImg from "../../assets/profilePic1.png";

const MatchesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickAssessment = (item) => {
    navigate(`/my-assessments/candidate-Assessments/${item.refNo}`);
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <div className="flex flex-1 flex-col max-h-fit overflow-y-hidden py-7 px-6 justify-center items-center rounded-md bg-[#F9FAFB]">
        <span className="font-medium text-sm">Possible matched results</span>

        {/* List */}
        <div className="flex flex-col gap-4 overflow-auto mt-8 no-scrollbar w-full">
          {[1, 2, 3, 4, 5, 6, 7, 8]?.map((item) => {
            return (
              <div className="flex items-center w-full gap-5">
                <div className="flex  border py-2 px-4 bg-white gap-4 flex-1 justify-between h-14">
                  <div className="flex items-center gap-4">
                    <img src={ProfileImg} className="w-10 h-10 rounded-full" />
                    <div className="flex flex-col justify-between">
                      <span className="font-medium text-primary text-xs">
                        Beth Smith
                      </span>
                      <span className="font-medium text-primary text-xs">
                        Senior Java Developer
                      </span>
                    </div>
                  </div>
                  <span className="font-medium text-green-700 text-xs pr-10">
                    Available
                  </span>
                </div>
                <span className="text-xs border-b pb-2 font-medium italic text-light-black">
                  100% matched
                </span>
                <div className="flex  border py-2 px-4 bg-white gap-4 flex-1 justify-between h-[3.4rem]">
                  <div className="flex flex-col justify-between">
                    <span className="font-medium text-light-black text-[10px]">
                      Jo-CBMD123
                    </span>
                    <span className="font-medium text-primary text-xs">
                      Senior Java Developer
                    </span>
                  </div>
                  <div className="flex flex-col justify-between">
                    <span className="font-medium text-primary text-xs">
                      Post
                    </span>
                    <span className="font-medium text-primary text-xs">
                      Matched
                    </span>
                  </div>
                  <div className="flex flex-col justify-between">
                    <span className="font-medium text-xs italic text-light-black">
                      18/12/24
                    </span>
                    <span className="font-medium text-xs italic text-light-black">
                      18/12/24
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MatchesPage;
