import * as React from "react";
const JobTrackIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g fill="#4D4D4D" clipPath="url(#a)">
      <path d="M3.384 3.25c-.73 0-1.324.7-1.324 1.562 0 .862.594 1.563 1.324 1.563h5.07c1.315 0 2.385 1.262 2.385 2.813 0 1.55-1.07 2.812-2.385 2.812H6v-1.25h2.454c.73 0 1.325-.7 1.325-1.563 0-.861-.594-1.562-1.325-1.562h-5.07C2.07 7.625 1 6.363 1 4.812 1 3.262 2.07 2 3.384 2h6.657v1.25H3.384Z" />
      <path d="M.745 9.956a2.544 2.544 0 1 1 3.598 3.598l-1.8 1.798-1.798-1.799a2.544 2.544 0 0 1 0-3.597Zm1.814 2.568a.79.79 0 1 0 0-1.58.79.79 0 0 0 0 1.58ZM11.657 1.393a2.544 2.544 0 1 1 3.598 3.597l-1.799 1.8-1.799-1.8a2.544 2.544 0 0 1 0-3.597Zm1.814 2.568a.79.79 0 1 0 0-1.58.79.79 0 0 0 0 1.58Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 16h16V0H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default JobTrackIcon;
