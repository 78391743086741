import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SearchContext } from "./SearchContext";
import { isNullOrEmpty } from "../../utils/helperFunction";
import ChevronLeftIcon from "../../assets/svg/ChevronLeftIcon";

const dummyData = [
  {
    _id: "6730883a1029dd4bd3a0e61e",
    name: "Rose2",
    status: "",
    profilePic:
      "https://imagedelivery.net/GASHEXe_7q8VSc7ZloC3oQ/59e93c9e-f11b-4a0a-84da-c3a312734d00/public",
    isInvitee: false,
    matchingPercentage: 89.33,
  },
  {
    _id: "67851f04b4c9bf192b10bd7c",
    name: "pravat",
    jobTitle: "Software Developer",
    status: "AVAILABLE",
    profilePic:
      "https://imagedelivery.net/GASHEXe_7q8VSc7ZloC3oQ/5d1327fd-c715-4408-b75c-615b33177900/public",
    isInvitee: true,
    contactOwner: "675ff4785cebe267d893c406",
    matchingPercentage: 82,
  },
  {
    _id: "6730352e1029dd4bd3a0e192",
    name: "Rose1",
    jobTitle: "Developer",
    status: "OPEN_TO_OPPORTUNITIES",
    isInvitee: false,
    matchingPercentage: 81,
  },
  {
    _id: "673c75dc9ff9ad35376fcec7",
    name: "Ankit",
    status: "Available",
    isInvitee: false,
    matchingPercentage: 79,
  },
  {
    _id: "6768134100e592a379ec8046",
    name: "Pravat",
    jobTitle: "Front-end developer",
    status: "AVAILABLE",
    profilePic:
      "https://imagedelivery.net/GASHEXe_7q8VSc7ZloC3oQ/dbfe38fc-1aef-47db-8e69-a865c947ea00/public",
    isInvitee: true,
    contactOwner: "675ff4785cebe267d893c406",
    matchingPercentage: 79,
  },
  {
    _id: "6714f8c8a8313c2218f3cff4",
    name: "Pravat",
    jobTitle: "React Native Developer",
    status: "Available",
    profilePic: "img url",
    isInvitee: false,
    matchingPercentage: 76.5,
  },
  {
    _id: "672e37421029dd4bd3a0d37b",
    name: "Rose",
    jobTitle: "Developer",
    status: "CONTRACTED",
    isInvitee: false,
    matchingPercentage: 74,
  },
  {
    _id: "677e80353f8785d58f789a3a",
    name: "pravat",
    jobTitle: "Software Developer",
    status: "AVAILABLE",
    profilePic:
      "https://imagedelivery.net/GASHEXe_7q8VSc7ZloC3oQ/f539ea41-5255-47ed-5f46-25a0bbc21400/public",
    isInvitee: true,
    contactOwner: "675ff4785cebe267d893c406",
    matchingPercentage: 72.6,
  },
  {
    _id: "66d70fa656623e15396cd099",
    name: "Pravat",
    jobTitle: "Software Developer",
    status: "AVAILABLE",
    profilePic:
      "https://imagedelivery.net/GASHEXe_7q8VSc7ZloC3oQ/4d977d84-8df2-400f-b6aa-bd37337ed700/public",
    isInvitee: false,
    matchingPercentage: 70,
  },
  {
    _id: "67851ad6b4c9bf192b10bc76",
    name: "pravat",
    jobTitle: "Software Developer",
    status: "AVAILABLE",
    profilePic:
      "https://imagedelivery.net/GASHEXe_7q8VSc7ZloC3oQ/fbee5e69-e0bc-4136-b7de-44833c826400/public",
    isInvitee: true,
    contactOwner: "675ff4785cebe267d893c406",
    matchingPercentage: 64.95,
  },
  {
    _id: "673ae4109ff9ad35376fc203",
    name: "Ankit",
    jobTitle: "Software Developer",
    status: "CONTRACTED",
    profilePic:
      "https://imagedelivery.net/GASHEXe_7q8VSc7ZloC3oQ/ca858946-aeb1-49de-b03a-2034ab723500/public",
    isInvitee: false,
    matchingPercentage: 0,
  },
];

const TemplateResultPage = () => {
  const navigate = useNavigate();
  const { searchResults } = useContext(SearchContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return (
    <div className="flex flex-col items-center w-full max-h-fit">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center self-start pb-5"
      >
        <ChevronLeftIcon className={"w-4 h-4"} />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>
      <div className="bg-[#F9FAFB] w-full px-4 rounded-xl items-center flex flex-col">
        <span className="font-semibold text-sm text-light-grey mt-8 mb-6">
          Possible matched results.
        </span>
        {/* Add a scrollable container here */}
        <div className="flex flex-col gap-4 overflow-auto no-scrollbar w-full">
          {dummyData?.map((item) => {
            return (
              <div
                className="flex items-center gap-5 cursor-pointer group hover:border-white"
                onClick={() => navigate("/search/match-detail")}
              >
                <div className="flex border border-[#f5f5f5] py-2 px-4 bg-white gap-4 justify-between h-14 items-center w-1/4 group-hover:bg-gray-100">
                  <p className="text-xs font-medium text-light-black">
                    {queryParams.get("template_name")}
                  </p>
                </div>
                <span className="text-xs border-b pb-2 font-medium italic text-light-black w-28">
                  {item?.matchingPercentage}% matched
                </span>
                <div className="flex border border-[#f5f5f5] py-2 px-4 bg-white gap-4 flex-1 justify-between h-14 items-center group-hover:bg-gray-100">
                  <div className="flex items-center gap-4">
                    {!isNullOrEmpty(item?.profilePic) ? (
                      <img
                        aria-label="name"
                        src={item?.profilePic}
                        className="w-10 h-10 rounded-full"
                      />
                    ) : (
                      <div className="w-10 h-10 flex bg-gray-200 font-medium text-primary text-xl rounded-full items-center justify-center">
                        {item?.name?.charAt(0)}
                      </div>
                    )}
                    <span className="font-medium text-primary text-xs">
                      {item?.name}
                    </span>
                  </div>
                  <p className="font-medium text-primary text-xs">
                    {item?.jobTitle ?? "N/A"}
                  </p>
                  <p className="font-medium text-green-700 text-xs pr-10">
                    Available
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TemplateResultPage;
