import { useEffect, useState } from "react";

const TrackingIcon = ({ className, fill = "white" }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 35 35"
      stroke-width="0.5"
      class={_class}
    >
      <rect
        x="25.6768"
        y="9.01562"
        width="17.0323"
        height="17.0323"
        rx="8.51614"
        transform="rotate(90 25.6768 9.01562)"
        stroke="#2E2E2E"
        stroke-width="1.19226"
      />
      <path
        d="M16.7633 19.6789C16.7624 19.8092 16.7243 19.9405 16.6547 20.0514C16.7286 20.0974 16.9112 20.0808 17.023 20.0897C19.6661 20.0676 20.629 16.6307 18.4257 15.2344L18.2193 15.6048C18.8259 15.9985 19.2282 16.6818 19.2282 17.4566C19.2125 18.8702 18.0488 19.7283 16.7633 19.6789Z"
        fill="#2E2E2E"
      />
      <path
        d="M19.2251 21.0496C20.0308 21.7421 21.2998 21.0162 21.1061 19.97C21.0289 19.4253 20.5135 18.9964 19.9652 19.0057C19.1525 18.9883 18.5639 19.8815 18.903 20.6192C18.9179 20.6535 18.9333 20.6856 18.9522 20.7178C18.3692 21.0622 17.7057 21.245 17.0228 21.245C14.9347 21.245 13.236 19.5464 13.236 17.4582C13.236 16.822 13.3957 16.1983 13.6977 15.6451C13.7844 15.6753 13.871 15.6879 13.9645 15.6894C14.4115 15.6894 14.7733 15.3254 14.7733 14.8784C14.7831 14.4435 14.4004 14.0601 13.9645 14.0695C13.2888 14.0561 12.8972 14.8952 13.3461 15.4009C11.2716 19.1954 15.5173 23.2749 19.2251 21.0496Z"
        fill="#2E2E2E"
      />
      <path
        d="M20.7322 16.6599C20.8764 17.4486 20.8089 18.3541 20.4321 19.1036C20.563 19.1604 20.6856 19.2419 20.7855 19.3441C21.2176 18.4842 21.341 17.4667 21.1324 16.5322C21.016 16.614 20.8781 16.6597 20.7322 16.6599Z"
        fill="#2E2E2E"
      />
      <path
        d="M14.3855 14.1866C14.5057 14.2577 14.6092 14.366 14.675 14.4867C15.3433 13.9588 16.1692 13.6715 17.0228 13.6715C17.5814 13.6717 18.1244 13.7943 18.6114 14.0206L18.0475 15.0334C15.2154 13.8992 13.0359 17.5736 15.3846 19.5156C15.2043 20.3564 16.3685 20.7284 16.7082 19.9402C16.8159 19.6925 16.7538 19.3808 16.5613 19.191C16.3182 18.9296 15.8729 18.9104 15.6086 19.1499C15.1109 18.7327 14.8174 18.1137 14.8174 17.4561C14.7846 15.9306 16.4421 14.8152 17.8385 15.4084L17.2723 16.428C16.6182 16.2633 15.9549 16.7788 15.9632 17.4583C16.013 18.8585 18.0294 18.858 18.079 17.4583C18.0799 17.1315 17.9204 16.8078 17.6576 16.6132C17.7998 16.3567 18.2876 15.4772 18.4239 15.2339C18.6999 14.7377 19.1765 13.8765 19.4498 13.3862C19.5829 13.1417 19.2174 12.9337 19.0795 13.1798L18.82 13.6474C17.4219 12.9652 15.5479 13.17 14.3855 14.1866Z"
        fill="#2E2E2E"
      />
      <path
        d="M20.2422 15.4655C19.7954 15.8793 20.1183 16.6741 20.7309 16.6605C21.6511 16.6299 21.6558 15.2946 20.7309 15.2642C20.6952 15.2642 20.6596 15.2665 20.6239 15.2746C20.2641 14.6786 19.7703 14.1956 19.1937 13.8486L18.9872 14.2233C19.491 14.5274 19.9229 14.9508 20.2422 15.4655Z"
        fill="#2E2E2E"
      />
      <path
        d="M22.6963 17.4581C22.6963 14.329 20.1505 11.7832 17.0214 11.7832C9.49297 12.095 9.4951 22.8222 17.0214 23.1329C20.1505 23.1329 22.6963 20.5871 22.6963 17.4581ZM11.7723 17.4581C11.7723 14.5632 14.1265 12.2089 17.0214 12.2089C23.9852 12.4965 23.983 22.4192 17.0214 22.7072C14.1265 22.7072 11.7723 20.3508 11.7723 17.4581Z"
        fill="#2E2E2E"
      />
    </svg>
  );
};

export default TrackingIcon;
