import { Link, useNavigate } from "react-router-dom";
import { isNullOrEmpty } from "../../utils/helperFunction";
import ChevronLeftIcon from "../../assets/svg/ChevronLeftIcon";
import { useSelector } from "react-redux";
import { candidateStatus } from "../../utils/constants";

const PossibleMatchPage = () => {
  const navigate = useNavigate();
  const { searchResults } = useSelector((state) => state.search);

  return (
    <div className="flex flex-col items-center w-full max-h-fit">
      <button
        onClick={() => navigate("/search")}
        className="flex items-center self-start pb-5"
      >
        <ChevronLeftIcon className={"w-4 h-4"} />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>
      <div className="bg-[#F9FAFB] w-full px-4 rounded-xl items-center flex flex-col">
        <span className="font-semibold text-sm text-light-grey mt-8 mb-6">
          Possible matched results.
        </span>
        {/* Add a scrollable container here */}
        <div className="flex flex-col gap-4 overflow-auto no-scrollbar w-full pb-4">
          {searchResults?.map((item) => {
            return (
              <Link
                to={"/search/match-detail"}
                state={{ candidateDetails: item }}
                className="flex items-center gap-5 cursor-pointer group hover:border-white"
              >
                <div className="flex border border-[#f5f5f5] py-2 px-4 bg-white gap-4 justify-between h-14 items-center w-1/4 group-hover:bg-gray-100">
                  <p className="text-xs font-medium text-light-black">
                    Ad-hoc search
                  </p>
                </div>
                <span className="text-xs border-b pb-2 font-medium italic text-light-black w-28">
                  {item?.matchingPercentage}% matched
                </span>
                <div className="flex border border-[#f5f5f5] py-2 px-4 bg-white gap-4 flex-1 justify-between h-14 items-center group-hover:bg-gray-100">
                  <div className="flex items-center gap-4">
                    {!isNullOrEmpty(item?.profilePic) ? (
                      <img
                        aria-label="name"
                        src={item?.profilePic}
                        className="w-10 h-10 rounded-full"
                      />
                    ) : (
                      <div className="w-10 h-10 flex bg-gray-200 font-medium text-primary text-xl rounded-full items-center justify-center">
                        {item?.name?.charAt(0)}
                      </div>
                    )}
                    <span className="font-medium text-primary text-xs">
                      {item?.name}
                    </span>
                  </div>
                  <p className="font-medium text-primary text-xs">
                    {item?.jobTitle ?? "N/A"}
                  </p>
                  <p className="font-medium text-green-700 text-xs pr-10">
                    {candidateStatus[item?.status] ?? "N/A"}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PossibleMatchPage;
