import { useEffect, useState } from "react";

const ChevronDown = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <path
        d="M9.3335 13.333L16.0002 19.9997L22.6668 13.333"
        stroke={fill}
        stroke-opacity="0.964706"
        stroke-width="2.1193"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ChevronDown;
