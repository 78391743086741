import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { SkillInputProps } from "./skillInput.types";
import TrashIcon from "../../../../assets/svg/TrashIcon";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import SaveIcon from "../../../../assets/svg/SaveIcon";
import CustomSlider from "../../../../components/CustomSlider/CustomSlider";
import { searchSkill } from "../../../../endpoints/skill.service";
import { useSnackbar } from "notistack";
import { hoursToYears } from "../../../../utils/helperFunction";
import moment from "moment";
import { skillsLastUsed, skillsUsed } from "../../../../utils/constants";

export const SkillInput: React.FC<SkillInputProps> = ({
  data,
  control,
  errors,
  addUpdateSkill,
  index,
  deleteSkill,
  setValue
}) => {
  // const {
  //   watch,
  //   control,
  //   setValue,
  //   formState: { errors },
  //   handleSubmit,
  // } = useForm({
  //   defaultValues: {
  //     ...data,
  //     lastUsed: data?.lastUsed ?? "current",
  //   },
  // });
  const { enqueueSnackbar } = useSnackbar();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'skills',
  });

  const skillsWatch = useWatch({
    control,
    name: "skills", // Watch the entire skills array
  });

  const wholeFields = useWatch({ control });

  const totalExperience = hoursToYears(moment(wholeFields?.isCurrent ? moment() : moment(wholeFields?.endDate)).diff(wholeFields?.startDate, 'hours'))

  // const skillsWatch = watch(`skills`)


  const onSave = () => {
    // addUpdateSkill(field, index);
  };

  const getSuggestions = async (text: string) => {
    try {
      const { success, data } = await searchSkill(text);
      if (success && data?.length) {
        if (data?.some((item) => item?.title === text)) {
          setShowSuggestions(false);
        } else {
          setShowSuggestions(true);
        }
        setSuggestions(data);
      } else {
        setShowSuggestions(false);
        setSuggestions([]);
      }
    } catch (error) {
      setShowSuggestions(false)
      setSuggestions([])
    }
  }

  const validateTitle = (title: string) => {
    if (skillsWatch?.find((item, itemIndex) => item?.title?.length > 2 && index !== itemIndex && (item?.title === title))) {
      setValue(`skills.${index}.title`, '');
      enqueueSnackbar(
        "Skill already exist",
        {
          variant: "error",
        }
      );
    }
  }

  useEffect(() => {
    if (skillsWatch[index]?.title) {
      getSuggestions(skillsWatch[index]?.title)
      validateTitle(skillsWatch[index]?.title)
    }
  }, [skillsWatch[index]?.title])

  console.log('====================================');
  console.log(skillsWatch);
  console.log('====================================');

  return (
    <div className="flex items-center px-4 py-3 bg-light-white-700 rounded-lg">
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary relative"
      >
        <Controller
          control={control}
          name={`skills.${index}.title`}
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-[85%]"
              placeholder="Javascript"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {showSuggestions ? (
          <div className="absolute top-full left-0 w-full bg-gray-50 rounded-md border border-gray-300 z-50 shadow-lg max-h-52 overflow-y-auto">
            {suggestions?.map((item) => {
              return (
                <div
                  key={item?._id}
                  onClick={() => {
                    setValue(`skills.${index}.title`, item?.title);
                    setShowSuggestions(false);
                  }}
                  className="hover:bg-white px-4 py-2 cursor-pointer"
                >
                  <span className="font-medium text-xs text-primary">
                    {item?.title}
                  </span>
                </div>
              );
            })}
          </div>
        ) : null}
        {errors?.skills?.length && errors?.skills[index]?.title?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.skills?.length && errors?.skills[index]?.title?.message}
          </span>
        )}
      </div>
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary"
      >
        <Controller
          control={control}
          name={`skills.${index}.rating`}
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <CustomSlider
              step={1}
              min={0}
              max={10}
              fillerClass={`h-[5px]`}
              baseClass
              className
              label
              value={value}
              labelStyle={`text-xs`}
              onChange={onChange}
            />
          )}
        />
        {errors?.skills?.length && errors?.skills[index]?.rating?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.skills?.length && errors?.skills[index]?.rating?.message}
          </span>
        )}
      </div>
      <label
        style={{ width: "25%" }}
        className="flex flex-col font-semibold text-sm text-primary items-center justify-center"
      >
        <Controller
          control={control}
          name={`skills.${index}.years`}
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-1/2"
              placeholder="9"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {errors?.skills?.length && errors?.skills[index]?.years?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.skills?.length && errors?.skills[index]?.years?.message}
          </span>
        )}
      </label>
      <label
        style={{ width: "25%" }}
        className="flex flex-col font-semibold text-sm text-primary items-center justify-center"
      >
        <Controller
          control={control}
          name={`skills.${index}.lastUsed`}
          rules={{
            required: "This field is required.",
          }}
          render={({ field }) => (
            <select
              {...field}
              onChange={(e) => field.onChange(e?.target?.value)}
              className="w-[2/3] outline-none bg-white py-1.5 rounded-md px-2 font-medium text-primary text-xs"
            >
              {Object.entries(skillsUsed)?.map(([key, value]) => {
                return (
                  <option key={value} value={value}>
                    {key}
                  </option>
                );
              })}
            </select>
          )}
        />
        {errors?.skills?.length && errors?.skills[index]?.lastUsed?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.skills?.length && errors?.skills[index]?.lastUsed?.message}
          </span>
        )}
      </label>
      {/* <div style={{ width: "20%" }} className="flex text-sm text-primary">
        <div className="w-full">
          <CustomInput
            name={`skills.${index}.lastUsed`}
            control={control}
            errors={errors}
            type="dropdown"
            nestedFields
            placeholder="Select"
            dropdownList={["current", "this year", "last year", "2+ years"]}
            rules={{
              required: "This field is required.",
            }}
          />
        </div>
      </div> */}
      <div className="flex flex-col w-[5%] items-end gap-3">
        <div className="cursor-pointer"
          onClick={onSave}
        >
          <SaveIcon fill="" className={"w-4 h-4"} />
        </div>
        <button type="button" className="cursor-pointer" onClick={() => deleteSkill(index)}>
          <TrashIcon fill="" className={"w-4 h-4"} />
        </button>
      </div>
    </div>
  );
};
