import { RESET_SEARCH_DATA, SET_SEARCH_DATA } from "./action.types";

export const setSearchData = (payload) => ({
  type: SET_SEARCH_DATA,
  payload,
});

export const resetSearchData = () => ({
  type: RESET_SEARCH_DATA,
});
