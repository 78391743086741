import * as React from "react";
const MembersIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#4D4D4D"
        d="M4.233 7.333H2A.667.667 0 0 0 1.333 8v2H0V8a2 2 0 0 1 2-2h3.025a3.985 3.985 0 0 0-.792 1.333ZM14 6h-3.025c.35.388.618.841.792 1.333H14a.667.667 0 0 1 .667.667v2H16V8a2 2 0 0 0-2-2Zm-3.333 2.667a2.667 2.667 0 1 0-5.334 0 2.667 2.667 0 0 0 5.334 0Zm-1.334 0a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0ZM12 14a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v2h1.333v-2A.667.667 0 0 1 6 13.333h4a.667.667 0 0 1 .667.667v2H12v-2Zm2.667-11.333a2.667 2.667 0 1 0-5.334 0 2.667 2.667 0 0 0 5.334 0Zm-1.334 0a1.333 1.333 0 1 1-2.667 0 1.333 1.333 0 0 1 2.667 0Zm-6.666 0a2.667 2.667 0 1 0-5.334 0 2.667 2.667 0 0 0 5.334 0Zm-1.334 0a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default MembersIcon;
