import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import CustomInput from "../../components/CustomInput/CustomInput";
import regexList from "../../utils/regexList";
import VerificationCodeInput from "../../components/OtpInputBox/VerificationCodeInput";
import { useDispatch, useSelector } from "react-redux";
import { removeToken } from "../../store/actions/auth.action";
import { verifyJob, verifyJobStatus } from "../../endpoints/job.service";
import { useSnackbar } from "notistack";
import VerificationCardSkeleton from "./components/VerificationCardSkeleton";

const VerifyUserJobPage = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const { jobId, email } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logged_in,user } = useSelector((state) => state.auth);
  const [code, setCode] = useState();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const disabled = code?.length !== 6 || !isDirty;

  const handleSubmitButton = async (formData) => {
    try {
      setLoading(true);
      const { success, data } = await verifyJob({
        email: formData?.email,
        otp: code,
      });
      navigate(`/job-detail/${jobId}`);
      if (success) {
        enqueueSnackbar("Verified successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      if (error !== "Email already verified") {
        enqueueSnackbar(error, {
          variant: "error",
        });
      } else {
        navigate(`/job-detail/${jobId}`);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(removeToken());
    verifyJobStatus({ email: email })
      .then((response) => {
        const { success } = response;
        if (success) {
          navigate(`/job-detail/${jobId}`);
        }
      })
      .catch((error) => {
        dispatch(removeToken());
      })
      .finally(() => setIsPageLoading(false));
  }, [logged_in, user?._id]);

  return isPageLoading ? (
    <VerificationCardSkeleton />
  ) : (
    <div className="flex flex-col items-center my-20 border rounded-xl px-10 w-max py-10 m-auto bg-white">
      <span className="font-didact font-medium text-3xl">Verify</span>
      <span className="font-montserrat my-6 text-light-black font-medium text-sm w-96 text-center">
        Enter your email address and the code you received on your email to
        proceed to view the job details
      </span>

      <form className="flex flex-col gap-3 w-full">
        <CustomInput
          label={"Email"}
          name="email"
          placeholder={"Enter your Email"}
          rules={{
            required: "This field is required.",
            pattern: {
              value: regexList.email,
              message: "Please enter a valid email.",
            },
          }}
          control={control}
          errors={errors}
        />

        <div className="flex flex-col gap-2 mt-3">
          <span className="font-medium text-xs text-primary">Code</span>
          <VerificationCodeInput
            loading={false}
            inputContainer={"w-12 h-12 rounded-md"}
            onChange={(codeNum) => {
              setCode(codeNum);
            }}
            length={6}
          />
        </div>
        <button
          disabled={disabled}
          onClick={handleSubmit(handleSubmitButton)}
          className={`font-montserrat font-medium text-sm text-white bg-secondary hover:bg-secondary rounded-full py-3 w-[28rem] mt-10 ${
            disabled ? "opacity-50" : "opacity-100"
          }`}
        >
          Proceed
        </button>
      </form>
    </div>
  );
};

export default VerifyUserJobPage;
