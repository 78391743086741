import * as React from "react";
const Email = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0.666687H2C1.46957 0.666687 0.960859 0.877401 0.585786 1.25247C0.210714 1.62755 0 2.13625 0 2.66669L0 15.3334H16V2.66669C16 2.13625 15.7893 1.62755 15.4142 1.25247C15.0391 0.877401 14.5304 0.666687 14 0.666687ZM2 2.00002H14C14.1768 2.00002 14.3464 2.07026 14.4714 2.19528C14.5964 2.32031 14.6667 2.48988 14.6667 2.66669V3.11135L9.41467 8.36402C9.03895 8.73823 8.53028 8.94833 8 8.94833C7.46972 8.94833 6.96105 8.73823 6.58533 8.36402L1.33333 3.11135V2.66669C1.33333 2.48988 1.40357 2.32031 1.5286 2.19528C1.65362 2.07026 1.82319 2.00002 2 2.00002ZM1.33333 14V5.00002L5.64267 9.30669C6.26842 9.93086 7.11617 10.2814 8 10.2814C8.88383 10.2814 9.73158 9.93086 10.3573 9.30669L14.6667 5.00002V14H1.33333Z"
      fill="#2E2E2E"
    />
  </svg>
);
export default Email;
