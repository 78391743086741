import { useEffect } from "react";
import { ContentContainer } from "./MyAssessmentsDetailsPage.style";

const MyAssessmentsDetailsPage = () => {
  const id = useParams();
  useEffect(() => {
    getAssessmentDetails(id).then((res) => {});
    return () => {};
  }, []);

  return (
    <ContentContainer>
      <div>
        <h1>Assessment Details</h1>
      </div>
    </ContentContainer>
  );
};

export default MyAssessmentsDetailsPage;
