import { useSelector } from "react-redux";
import { JobSkills } from "../../../../utils/constants";
import { useEffect, useState } from "react";
import { getSkills } from "../../../../endpoints/skill.service";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import LoadingAnimation from "../../../../assets/svg/LoadingAnimation";
import { colors } from "../../../../utils/theme";

const SkillListSection = ({ data, index, editSkill }) => {
  const { jobDetail } = useSelector((state) => state?.job);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [skillsList, setSkillsList] = useState(
    state?.skills?.map((x) => ({
      skill: x?.title,
      rating: x?.rating,
      experience: x?.years,
      lastUsed: JobSkills.find((j) => j.value === x?.lastUsed)?.label,
      _id: x?._id,
      isSaved: true,
      isEdit: false,
    })) || []
  );

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        setIsLoading(true);
        const result = await getSkills({
          jobId: jobDetail?._id,
          type: 2,
        });

        if (result?.success && result?.data?.length) {
          const fetchedSkills = result.data.map((x) => ({
            skill: x?.title,
            rating: x?.rating,
            experience: x?.years,
            lastUsed: JobSkills.find((j) => j.value === x?.lastUsed)?.label,
            _id: x?._id,
            isSaved: true,
            isEdit: false,
          }));

          setSkillsList(fetchedSkills);
        }
      } catch (error) {
        enqueueSnackbar("Error fetching skills", { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    if (jobDetail?._id) {
      fetchSkills();
    }
  }, [jobDetail?._id]);

  return (
    <div className="flex flex-col mt-8 w-3/4 self-center">
      {isLoading ? (
        <div className="flex justify-center mt-40">
          <LoadingAnimation color={colors.PrimaryBlack} />
        </div>
      ) : skillsList.length === 0 ? (
        <p className="flex justify-center mt-24">No skills were added.</p>
      ) : (
        <div>
          <div className="grid flex-1 mt-7 mb-2 justify-center grid-cols-[1.9fr_0.75fr_0.6fr_1fr] px-20">
            {["Skill", "Rating", "Years", "When", ""].map((label) => (
              <span
                key={label}
                className="text-xs font-semibold text-gray-700 flex-1"
              >
                {label}
              </span>
            ))}
          </div>
          <div className="overflow-auto h-full px-20">
            {skillsList.map((skill) => (
              <div
                key={skill?._id}
                className="grid space-x-3 py-2.5 items-center w-full justify-center relative grid-cols-[1.7fr_0.68fr_0.58fr_1fr]"
              >
                {[
                  skill.skill,
                  `${skill.rating}/10`,
                  skill.experience,
                  skill.lastUsed,
                ].map((value, index) => (
                  <div
                    key={index}
                    className="border border-gray-100 bg-[#FAFAFA] px-4 py-2.5 text-xs font-medium text-primary rounded flex flex-1"
                  >
                    {value}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SkillListSection;
