import { useNavigate } from 'react-router-dom'


const LandingPage = () => {
    const navigate = useNavigate()
    return <div className="flex justify-center relative h-[40rem] pt-20">
        <img
            src={require('../../assets/images/bgImage.png')}
            className='absolute h-96 w-full bottom-16'
            alt='bgImage'
        />
        <div className="flex flex-col w-full flex items-center justify-center gap-5 z-50 h-max">
            <div className="flex flex-col w-full justify-center items-center">
                <span className="z-50 text-center w-[70%] text-4xl font-didact font-bold">Streamline your <span className='text-secondary'>hiring process.</span></span>
                <span className="z-50 text-center w-[70%] text-4xl font-didact font-bold">Evaluate candidate skills efficiently.</span>
            </div>
            <span className="z-50 text-center w-[40%] font-medium text-light-text font-montserrat">Create custom tests or collaborate with companies to ensure the best fit for their team.</span>
            <div className="flex gap-5 mt-5 z-50">
                <button
                    onClick={() => navigate('/register')}
                    className="flex group items-center gap-3 rounded-full px-4 py-3 bg-primary hover:bg-black">
                    <span className="font-montserrat text-white font-medium">Register your interest</span>
                    <div className="group-hover:rotate-[360deg] rotate-0 transition duration-200 ease-in">
                        {/* <RightArrow className={'w-5 h-5'} fill='white' /> */}
                    </div>
                </button>
                {/* <button className="rounded-full px-4 py-3 border hover:bg-[#EFEFEF]">
                    <span className="font-montserrat">Contact Us</span>
                </button> */}
            </div>
        </div>
    </div>
}

export default LandingPage