import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useRef, useState } from "react";
import { Cropper } from "react-cropper";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import Modal from "../../components/Modal/Modal";
import { profileImage, uploadUrl } from "../../endpoints/images.service";
import { updateReferralProfile } from "../../endpoints/recruiter.service";
import { useSnackbar } from "notistack";
import { setUserData } from "../../store/actions/auth.action";
import { userTypes } from "../../utils/constants";
import ChangePassword from "../MyAccountDetailsPage/components/ChangePassword";
import Switch from "../../components/Switch/Switch";
import DownloadIcon from "../../assets/svg/DownloadIcon";

const MyAccountSettingsPage = () => {
  const navigate = useNavigate();
  const cropperRef = useRef(null);
  const filePickerRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const isReferralAmbassador = user?.userType === userTypes.REFERRAL_AMBASSADOR;
  const [selectedTab, setSelectedTab] = useState("updateDetails");
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: isReferralAmbassador
      ? {
          firstName: user?.firstName,
          lastName: user?.lastName,
          location: user?.location,
          email: user?.email,
          phoneNo: user?.phoneNo?.toString(),
          image: undefined,
          profilePic: undefined,
        }
      : {
          firstName: user?.firstName,
          lastName: user?.lastName,
          jobTitle: user?.jobTitle,
          location: user?.location,
          image: undefined,
          profilePic: undefined,
        },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const fields = watch();

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      setValue("file", file, { shouldDirty: true });
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("image", reader.result, { shouldDirty: true });
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("Error during file upload or API calls:", error);
    }
  };

  const getCroppedImage = (e) => {
    e.preventDefault();

    if (cropperRef.current) {
      const cropperInstance = cropperRef.current.cropper;
      if (cropperInstance) {
        const croppedDataUrl = cropperInstance.getCroppedCanvas().toDataURL();
        setValue("profilePic", croppedDataUrl);
        setValue("image", undefined);
      } else {
        console.error("Cropper instance is not found");
      }
    } else {
      console.error("cropperRef.current is not initialized");
    }
  };

  const updateProfile = async (payload) => {
    payload.id = user?._id;
    try {
      setIsLoading(true);
      if (payload?.profilePic) {
        const { data } = await profileImage();
        if (!data?.data || !data?.data.uploadURL) {
          throw new Error("Failed to get upload URL");
        }
        const formData = new FormData();
        const base64Data = payload?.profilePic?.split(";base64,").pop();
        const byteArray = Uint8Array.from(atob(base64Data), (c) =>
          c.charCodeAt(0)
        );
        const blob = new Blob([byteArray], { type: payload?.file?.type });
        formData.append("file", blob, payload?.file?.name);
        const fileResponse = await uploadUrl(data?.data.uploadURL, formData);
        delete payload["file"];
        delete payload["profilePic"];
        delete payload["image"];
        const picURl = fileResponse?.variants?.find(
          (x) => x.split("/").slice(-1)[0] === "public"
        );
        payload["profilePic"] = picURl;
      }
      const { success, data: userApiData } = await updateReferralProfile(
        payload
      );
      if (success) {
        dispatch(setUserData(userApiData));
        setValue("profilePic", undefined);
        setValue("image", undefined);
        setValue("openProfileModel", false);
        enqueueSnackbar("Details updated successfully.", {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log("error", error);
    } finally {
      setIsLoading(false);
      setIsEditing(false);
    }
  };

  return (
    <div className="flex flex-col w-full p-10 bg-white">
      {/* Top Navigation Bar */}
      <div className="w-full bg-grey-100 rounded-md px-5 flex items-center justify-between">
        <div className="flex justify-center gap-10 w-full px-16">
          <div
            className={`text-xs cursor-pointer py-2 px-3 w-1/4 text-center relative ${
              selectedTab === "updateDetails"
                ? "text-primary font-medium"
                : "text-light-black"
            }`}
            onClick={() => setSelectedTab("updateDetails")}
          >
            Details
            {selectedTab === "updateDetails" && (
              <div className="absolute bottom-0 left-0 w-full h-1 bg-secondary rounded-t-full" />
            )}
          </div>
          <div
            className={`text-xs cursor-pointer py-2 px-3 w-1/4 text-center relative ${
              selectedTab === "changePassword"
                ? "text-primary font-medium"
                : "text-light-black"
            }`}
            onClick={() => setSelectedTab("changePassword")}
          >
            Change Password
            {selectedTab === "changePassword" && (
              <div className="absolute bottom-0 left-0 w-full h-1 bg-secondary rounded-t-full" />
            )}
          </div>
          <div
            className={`text-xs cursor-pointer py-2 px-3 w-1/4 text-center relative ${
              selectedTab === "settings"
                ? "text-primary font-medium"
                : "text-light-black"
            }`}
            onClick={() => setSelectedTab("settings")}
          >
            Settings
            {selectedTab === "settings" && (
              <div className="absolute bottom-0 left-0 w-full h-1 bg-secondary rounded-t-full" />
            )}
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex flex-col w-full h-full mt-5 overflow-y-scroll no-scrollbar">
        {selectedTab === "updateDetails" && (
          <div className="flex flex-1 flex-col gap-5 bg-grey-100 rounded-md p-5 ">
            <div className="mb-11">
              <p className="text-center text-primary text-sm font-bold underline mt-5">
                Ambassador account details
              </p>
              <div className="flex flex-row-reverse w-full mt-11">
                <div className="flex  flex-col flex-1 gap-16 justify-center">
                  <div className="flex self-center rounded-full h-40 w-40 bg-[#E1E0E0] items-center justify-center">
                    {fields?.profilePic || user?.profilePic ? (
                      <img
                        src={fields?.profilePic || user?.profilePic}
                        className="rounded-full h-40 w-40 object-fill"
                      />
                    ) : (
                      <span className="font-montserrat text-white font-semibold text-6xl">
                        {user?.firstName?.charAt(0)}
                        {user?.lastName?.charAt(0)}
                      </span>
                    )}
                  </div>
                  <button
                    type="button"
                    disabled={!isEditing}
                    onClick={() => filePickerRef?.current?.click()}
                    className="flex max-w-[300px] items-center self-center border rounded overflow-hidden"
                  >
                    <span className="text-sm text-[#6f6f6f] font-medium py-2.5 px-3 bg-[#E9EAF0]">
                      PNG/JPG
                    </span>
                    <span className="text-xs font-medium px-3 text-ngray text-left truncate">
                      {fields?.file
                        ? fields?.file?.name
                        : user?.profilePic
                        ? user?.profilePic
                        : "Upload Image here"}
                    </span>
                    <div className="px-2">
                      <DownloadIcon />
                    </div>
                  </button>
                  <input
                    ref={filePickerRef}
                    type="file"
                    className="hidden"
                    accept=".png, .jpg"
                    onChange={handleFileChange}
                  />
                  <button
                    onClick={() => {
                      if (isEditing) {
                        handleSubmit(updateProfile)();
                      } else {
                        setIsEditing((prev) => !prev);
                      }
                    }}
                    className="flex self-end bg-primary rounded-md py-1.5 mt-8 px-4 w-max"
                  >
                    <span className="flex items-center gap-4 font-semibold text-sm text-white m-auto">
                      {isEditing ? "Save" : "Edit"}
                      {isLoading ? <LoadingAnimation /> : null}
                    </span>
                  </button>
                </div>
                <div className="flex flex-1">
                  <div className="flex flex-col gap-6 w-[80%]">
                    <CustomInput
                      disabled={!isEditing}
                      label="First Name"
                      name="firstName"
                      placeholder={"Ex: John"}
                      control={control}
                      errors={errors}
                    />
                    <CustomInput
                      disabled={!isEditing}
                      label="Last Name"
                      name="lastName"
                      placeholder={"Ex: Doe"}
                      control={control}
                      errors={errors}
                    />
                    <CustomInput
                      type="phone"
                      disabled={!isEditing}
                      label="Mobile Number"
                      name="phoneNo"
                      placeholder={"Ex: +1234567890"}
                      control={control}
                      errors={errors}
                      filled={false}
                    />
                    <CustomInput
                      disabled={!isEditing}
                      label="Location"
                      name="location"
                      placeholder={"Ex: United Kingdom"}
                      control={control}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mt-10">
                <div class="border-t-2 border-dotted border-light-grey mb-8" />
                <CustomInput
                  disabled
                  label="Email Address"
                  name="email"
                  className="w-[40%]"
                  placeholder={"Ex: john.doe@example.com"}
                  control={control}
                  errors={errors}
                />
              </div>
            </div>

            {/* Image Cropper Modal */}
            <Modal isOpen={fields?.image}>
              <div className="flex flex-col mt-7">
                {fields?.image && (
                  <Cropper
                    src={fields?.image}
                    ref={cropperRef}
                    style={{ height: 400, width: "100%" }}
                    aspectRatio={1}
                    viewMode={2}
                    guides={false}
                    minCropBoxWidth={50} // Minimum width for the crop area
                    minCropBoxHeight={50} // Minimum height for the crop area
                  />
                )}
                <div className="mt-10 flex justify-end">
                  <button
                    onClick={(e) => getCroppedImage(e)}
                    className="bg-primary text-white px-6 py-2.5 rounded-md"
                  >
                    Crop Image
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        )}

        {selectedTab === "changePassword" && <ChangePassword />}

        {selectedTab === "settings" && (
          <div className="flex flex-col gap-5 bg-grey-100 h-full rounded-md p-5">
            <p className="text-center text-primary text-sm font-bold underline mt-5 mb-12">
              Account settings
            </p>
            <div className="flex items-center gap-20 justify-between w-80 mx-auto">
              <label className="text-xs font-medium text-primary">
                Show location on profile
              </label>
              <Controller
                control={control}
                name="locationProfile"
                render={({ field: { onChange, value } }) => (
                  <Switch isChecked={value} onToggle={onChange} />
                )}
              />
            </div>
            <div className="flex items-center gap-20 justify-between w-80 mx-auto">
              <label className="text-xs font-medium text-primary">
                Receive message notifications
              </label>
              <Controller
                control={control}
                name="notification"
                render={({ field: { onChange, value } }) => (
                  <Switch isChecked={value} onToggle={onChange} />
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccountSettingsPage;
