export const AssessmentSetupData = [
  {
    refId: "AS-1234EF",
    title: "Python Assessment",
    status: "Assessment Live",
    time: "10:12",
    date: "22/07/2024",
  },
  {
    refId: "AS-5678GH",
    title: "JavaScript Assessment",
    status: "Question received by company",
    time: "14:30",
    date: "21/07/2024",
  },
  {
    refId: "AS-9101IJ",
    title: "React Native Assessment",
    status: "Create questions sent to company",
    time: "11:45",
    date: "20/07/2024",
  },
  {
    refId: "AS-1121KL",
    title: "Java Assessment",
    status: "Question received by company",
    time: "09:50",
    date: "19/07/2024",
  },
  {
    refId: "AS-3141MN",
    title: "Node.js Assessment",
    status: "Create questions sent to company",
    time: "16:20",
    date: "18/07/2024",
  },
  {
    refId: "AS-5161OP",
    title: "TypeScript Assessment",
    status: "Question received by company",
    time: "13:15",
    date: "17/07/2024",
  },
  {
    refId: "AS-7181QR",
    title: "C++ Assessment",
    status: "Create questions sent to company",
    time: "08:40",
    date: "16/07/2024",
  },
  {
    refId: "AS-9202ST",
    title: "Swift Assessment",
    status: "Question received by company",
    time: "12:10",
    date: "15/07/2024",
  },
  {
    refId: "AS-1222UV",
    title: "Kotlin Assessment",
    status: "Create questions sent to company",
    time: "10:25",
    date: "14/07/2024",
  },
  {
    refId: "AS-3242WX",
    title: "Golang Assessment",
    status: "Question received by company",
    time: "15:35",
    date: "13/07/2024",
  },
  {
    refId: "AS-5262YZ",
    title: "PHP Assessment",
    status: "Create questions sent to company",
    time: "09:55",
    date: "12/07/2024",
  },
  {
    refId: "AS-7282AB",
    title: "Ruby Assessment",
    status: "Question received by company",
    time: "11:50",
    date: "11/07/2024",
  },
  {
    refId: "AS-9303CD",
    title: "SQL Assessment",
    status: "Create questions sent to company",
    time: "17:15",
    date: "10/07/2024",
  },
  {
    refId: "AS-1424EF",
    title: "HTML/CSS Assessment",
    status: "Question received by company",
    time: "12:40",
    date: "09/07/2024",
  },
  {
    refId: "AS-3444GH",
    title: "AWS Assessment",
    status: "Create questions sent to company",
    time: "14:50",
    date: "08/07/2024",
  },
];
