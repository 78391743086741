const JobCardPlaceholder = () => {
  return (
    <div className="rounded-xl border p-4 h-34 flex flex-col">
      <div className="flex items-center justify-between">
        {/* Simulating title */}
        <div className="h-4 bg-gray-200  animate-sequential-blink rounded-full w-2/3"></div>
        {/* Simulating timestamp */}
        <div className="h-3 bg-gray-200  animate-sequential-blink rounded-full w-1/4"></div>
      </div>
      {/* Simulating industry type */}
      <div className="h-4 bg-gray-200  animate-sequential-blink rounded-full w-1/2 mt-2"></div>

      {/* Simulating location and details */}
      <div className="flex flex-col my-2.5 space-y-2">
        <div className="h-4 bg-gray-200  animate-sequential-blink rounded-full w-1/3"></div>
        <div className="h-4 bg-gray-200  animate-sequential-blink rounded-full w-2/3"></div>
      </div>

      {/* Simulating description */}
      <div className="h-3 bg-gray-200  animate-sequential-blink rounded-full w-full mt-2"></div>
      <div className="h-3 bg-gray-200  animate-sequential-blink rounded-full w-4/5 mt-1"></div>
    </div>
  );
};

export default JobCardPlaceholder;
