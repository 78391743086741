import { parsePhoneNumberFromString, CountryCode } from "libphonenumber-js";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
/**
 * Extracts the dial code (country calling code) from a phone number.
 *
 * @param phoneNumber - The phone number to extract the dial code from.
 * @param countryCode - The country code (optional) to help parse the number.
 * @returns The extracted dial code, or null if the phone number is invalid.
 */
const extractDialCode = (phoneNumber, countryCode = "") => {
  const parsedPhoneNumber = parsePhoneNumberFromString(
    phoneNumber,
    countryCode
  );

  const dialCode = `+${parsedPhoneNumber?.countryCallingCode}`; // Get the dial code with '+'
  const localNumber = parsedPhoneNumber?.nationalNumber; // Get the local part of the number

  return {
    dialCode,
    localNumber,
  };
  if (parsedPhoneNumber?.isValid()) {
  }

  return null; // Return null if the phone number is invalid
};

function formatPhoneNumber(phoneNumber) {
  // Parse the phone number with the provided country code (e.g., 'GB' for UK)
  const phone = parsePhoneNumberFromString(phoneNumber.toString());

  // Check if the phone number is valid
  if (!phone) {
    return "Invalid phone number.";
  }

  // Format the phone number in international format
  return phone.formatInternational();
}

const isNullOrEmpty = (value) => {
  return (
    !value ||
    value === undefined ||
    value === "" ||
    value.length === 0 ||
    value === "undefined" ||
    value === "null" ||
    value === "Invalid Date"
  );
};

const getValueOrPlaceholder = (value) => {
  if (
    value === null ||
    value === undefined ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return "-";
  }

  return value;
};

export const timeSincePost = (postedDateTime) => {
  const now = dayjs();
  const postedTime = dayjs(postedDateTime);
  const timeDiff = now.diff(postedTime);

  if (timeDiff < 60 * 1000) {
    // Less than a minute
    const seconds = Math.round(timeDiff / 1000);
    return seconds > 5 ? `${seconds} seconds ago` : "Just now";
  } else if (timeDiff < 60 * 60 * 1000) {
    // Less than an hour
    const minutes = Math.round(timeDiff / (60 * 1000));
    return `${minutes} ${minutes > 1 ? "minutes ago" : "minute ago"}`;
  } else if (timeDiff < 24 * 60 * 60 * 1000) {
    // Less than a day
    const hours = Math.round(timeDiff / (60 * 60 * 1000));
    return `${hours} ${hours > 1 ? "hours ago" : "hour ago"}`;
  } else if (timeDiff < 7 * 24 * 60 * 60 * 1000) {
    // Less than a week
    const days = Math.round(timeDiff / (24 * 60 * 60 * 1000));
    return `${days} ${days > 1 ? "days ago" : "day ago"}`;
  } else if (timeDiff < 4 * 7 * 24 * 60 * 60 * 1000) {
    // Less than a month
    const weeks = Math.round(timeDiff / (7 * 24 * 60 * 60 * 1000));
    return `${weeks} ${weeks > 1 ? "weeks ago" : "week ago"}`;
  } else if (timeDiff < 12 * 4 * 7 * 24 * 60 * 60 * 1000) {
    // Less than a year
    const months = Math.round(timeDiff / (4 * 7 * 24 * 60 * 60 * 1000));
    return `${months} ${months > 1 ? "months ago" : "month ago"}`;
  } else {
    // A year or more
    const years = Math.round(timeDiff / (12 * 4 * 7 * 24 * 60 * 60 * 1000));
    return `${years} ${years > 1 ? "years ago" : "year ago"}`;
  }
};

function hoursToYears(hours, useLeapYear = false) {
  const hoursPerYear = useLeapYear ? 8766 : 8760; // 8760 for regular year, 8766 for leap year adjustment
  return hours / hoursPerYear;
}


export {
  extractDialCode,
  formatPhoneNumber,
  isNullOrEmpty,
  getValueOrPlaceholder,
  hoursToYears
};
