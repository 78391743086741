import * as React from "react";
const MultiQuestion = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 6.75C8.05109 6.75 7.86032 6.82902 7.71967 6.96967C7.57902 7.11032 7.5 7.30109 7.5 7.5V9H3V4.5H5.25C5.44891 4.5 5.63968 4.42098 5.78033 4.28033C5.92098 4.13968 6 3.94891 6 3.75C6 3.55109 5.92098 3.36032 5.78033 3.21967C5.63968 3.07902 5.44891 3 5.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V9C1.5 9.39782 1.65804 9.77936 1.93934 10.0607C2.22064 10.342 2.60218 10.5 3 10.5H7.5C7.89782 10.5 8.27936 10.342 8.56066 10.0607C8.84196 9.77936 9 9.39782 9 9V7.5C9 7.30109 8.92098 7.11032 8.78033 6.96967C8.63968 6.82902 8.44891 6.75 8.25 6.75Z"
      fill="#2E2E2E"
    />
    <path
      d="M7.5 13.5H3C2.60218 13.5 2.22064 13.658 1.93934 13.9393C1.65804 14.2206 1.5 14.6022 1.5 15V19.5C1.5 19.8978 1.65804 20.2794 1.93934 20.5607C2.22064 20.842 2.60218 21 3 21H7.5C7.89782 21 8.27936 20.842 8.56066 20.5607C8.84196 20.2794 9 19.8978 9 19.5V15C9 14.6022 8.84196 14.2206 8.56066 13.9393C8.27936 13.658 7.89782 13.5 7.5 13.5ZM3 19.5V15H7.5V19.5H3Z"
      fill="#2E2E2E"
    />
    <path
      d="M11.25 6H21.75C21.9489 6 22.1397 5.92098 22.2803 5.78033C22.421 5.63968 22.5 5.44891 22.5 5.25C22.5 5.05109 22.421 4.86032 22.2803 4.71967C22.1397 4.57902 21.9489 4.5 21.75 4.5H11.25C11.0511 4.5 10.8603 4.57902 10.7197 4.71967C10.579 4.86032 10.5 5.05109 10.5 5.25C10.5 5.44891 10.579 5.63968 10.7197 5.78033C10.8603 5.92098 11.0511 6 11.25 6Z"
      fill="#2E2E2E"
    />
    <path
      d="M11.25 9H18C18.1989 9 18.3897 8.92098 18.5303 8.78033C18.671 8.63968 18.75 8.44891 18.75 8.25C18.75 8.05109 18.671 7.86032 18.5303 7.71967C18.3897 7.57902 18.1989 7.5 18 7.5H11.25C11.0511 7.5 10.8603 7.57902 10.7197 7.71967C10.579 7.86032 10.5 8.05109 10.5 8.25C10.5 8.44891 10.579 8.63968 10.7197 8.78033C10.8603 8.92098 11.0511 9 11.25 9Z"
      fill="#2E2E2E"
    />
    <path
      d="M21.75 15H11.25C11.0511 15 10.8603 15.079 10.7197 15.2197C10.579 15.3603 10.5 15.5511 10.5 15.75C10.5 15.9489 10.579 16.1397 10.7197 16.2803C10.8603 16.421 11.0511 16.5 11.25 16.5H21.75C21.9489 16.5 22.1397 16.421 22.2803 16.2803C22.421 16.1397 22.5 15.9489 22.5 15.75C22.5 15.5511 22.421 15.3603 22.2803 15.2197C22.1397 15.079 21.9489 15 21.75 15Z"
      fill="#2E2E2E"
    />
    <path
      d="M18 18H11.25C11.0511 18 10.8603 18.079 10.7197 18.2197C10.579 18.3603 10.5 18.5511 10.5 18.75C10.5 18.9489 10.579 19.1397 10.7197 19.2803C10.8603 19.421 11.0511 19.5 11.25 19.5H18C18.1989 19.5 18.3897 19.421 18.5303 19.2803C18.671 19.1397 18.75 18.9489 18.75 18.75C18.75 18.5511 18.671 18.3603 18.5303 18.2197C18.3897 18.079 18.1989 18 18 18Z"
      fill="#2E2E2E"
    />
    <path
      d="M7.71932 3.96981L5.24957 6.43956L5.02982 6.21981C4.88837 6.08319 4.69892 6.0076 4.50227 6.00931C4.30562 6.01101 4.11752 6.08989 3.97846 6.22895C3.8394 6.368 3.76053 6.55611 3.75882 6.75276C3.75711 6.94941 3.8327 7.13886 3.96932 7.28031L4.71932 8.03031C4.85997 8.17091 5.0507 8.2499 5.24957 8.2499C5.44845 8.2499 5.63918 8.17091 5.77982 8.03031L8.77982 5.03031C8.85146 4.96113 8.90859 4.87837 8.9479 4.78686C8.98721 4.69536 9.0079 4.59695 9.00876 4.49736C9.00963 4.39778 8.99065 4.29902 8.95294 4.20685C8.91523 4.11467 8.85954 4.03093 8.78912 3.96051C8.7187 3.89009 8.63496 3.83441 8.54279 3.79669C8.45062 3.75898 8.35186 3.74001 8.25227 3.74087C8.15269 3.74174 8.05427 3.76243 7.96277 3.80173C7.87127 3.84104 7.78851 3.89818 7.71932 3.96981Z"
      fill="#2E2E2E"
    />
  </svg>
);
export default MultiQuestion;
