import { useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput";
import regexList from "../../../utils/regexList";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signup } from "../../../endpoints/auth.service";
import { useSnackbar } from "notistack";
import TickOutline from "../../../assets/svg/TickOutline";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";

const RegisterAmbassadorPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      isLoading: false,
    },
  });

  const states = watch();

  const handleRegister = async (fields) => {
    try {
      setValue("isLoading", true);
      const { data, success } = await signup({
        ...fields,
        userType: 4,
      });
      console.log("register", data);
      if (success) {
        if (!data?.verifiedEmail) {
          navigate("/email-verification", {
            state: {
              email: fields?.email,
              isSignup: true,
              userType: true,
            },
          });
        }
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    } finally {
      setValue("isLoading", false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen pb-56">
      <div className="flex flex-col justify-center items-center border-[#DDDDDD] border rounded-lg py-10 px-20 relative bg-white">
        <label className="font-semibold text-3xl">
          Register as a Referral Ambassador
        </label>

        {/* First Step Form (Initial Form) */}
        {!isFormSubmitted ? (
          <form
            className="flex flex-col w-full pt-10"
            onSubmit={handleSubmit(() => {
              setIsFormSubmitted(true);
            })}
          >
            <div className="flex flex-col gap-5">
              <CustomInput
                label={"Email"}
                placeholder={"Enter your Email"}
                name="email"
                rules={{
                  required: "This field is required.",
                  pattern: {
                    value: regexList.email,
                    message: "Please enter a valid email.",
                  },
                }}
                control={control}
                errors={errors}
              />
              <CustomInput
                label={"Password"}
                placeholder={"Enter your Password"}
                name="password"
                rules={{
                  required: "This field is required.",
                  pattern: {
                    value: regexList.password,
                    message: "Please enter a valid password.",
                  },
                }}
                control={control}
                errors={errors}
              />
              <CustomInput
                label={"Confirm Password"}
                placeholder={"Enter your Password"}
                name="confirmPassword"
                rules={{
                  required: "This field is required.",
                  pattern: {
                    value: regexList.password,
                    message: "Please enter a valid password.",
                  },
                }}
                control={control}
                errors={errors}
              />
            </div>

            <button
              type="submit"
              disabled={states?.isLoading}
              className="bg-primary flex items-center justify-center rounded-md mt-10 py-3"
            >
              {states?.isLoading && <LoadingAnimation />}
              <span className="text-white font-semibold">
                Register referral ambassador account
              </span>
            </button>
            <span className="mx-auto text-sm mt-1 font-medium text-light-black">
              Already have an account?{" "}
              <span
                onClick={() => navigate("/login-ambassador")}
                className="text-secondary cursor-pointer"
              >
                Login
              </span>
            </span>
          </form>
        ) : (
          <form
            className="flex flex-col w-3/4 pt-10"
            onSubmit={handleSubmit(handleRegister)}
          >
            <label className="text-light-black text-sm font-medium flex self-center mb-8">
              You’re almost done! We need some details about you to verify your
              account. We won’t share your details with anyone
            </label>
            <div className="flex items-center justify-center gap-3">
              <CustomInput
                label={"Given Name"}
                placeholder={"Enter your name"}
                name="firstName"
                rules={{
                  required: "This field is required.",
                }}
                control={control}
                errors={errors}
              />
              <CustomInput
                label={"Family Name"}
                placeholder={"Enter your Family Name"}
                name="lastName"
                rules={{
                  required: "This field is required.",
                }}
                control={control}
                errors={errors}
              />
            </div>
            <div className="h-3" />
            <CustomInput
              label={"Country"}
              placeholder={"Enter your country"}
              name="country"
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
            />
            <div className="h-3" />
            <div className="flex justify-center gap-3 items-end">
              <CustomInput
                label={"Phone number"}
                placeholder={"United Kingdom (+44)"}
                name="phone"
                rules={{
                  required: "This field is required.",
                }}
                control={control}
                errors={errors}
              />
              <CustomInput
                placeholder={"01234567890"}
                name="confirm phone"
                rules={{
                  required: "This field is required.",
                }}
                control={control}
                errors={errors}
              />
            </div>
            <p className="text-light-black text-sm font-medium mt-1 mb-2">
              We might need to contact you. This is never shared with
              candidates.
            </p>

            {/* Checkbox container  */}
            <div className="flex items-center gap-2 mt-3">
              <div
                onClick={() => setKeepMeSignedIn((prev) => !prev)}
                className="border-2 border-[#6F6F6F] h-4 w-4 flex items-center justify-center cursor-pointer"
              >
                {keepMeSignedIn ? (
                  <TickOutline className="w-1.5 h-1.5" fill="#6F6F6F" />
                ) : null}
              </div>
              <span className="font-montserrat font-medium text-primary text-sm">
                I agree with <span className="underline">Terms of Use</span> and{" "}
                <span className="underline">Privacy Policy</span>
              </span>
            </div>

            <button
              type="submit"
              disabled={states?.isLoading}
              className="bg-primary flex items-center justify-center rounded-md mt-10 py-3"
            >
              {states?.isLoading && <LoadingAnimation />}
              <span className="text-white font-medium">Create new account</span>
            </button>
            <span className="mx-auto text-sm mt-1 font-medium text-light-black">
              Already have an account?{" "}
              <span
                onClick={() => navigate("/login")}
                className="text-secondary cursor-pointer"
              >
                Login
              </span>
            </span>
          </form>
        )}
      </div>
    </div>
  );
};

export default RegisterAmbassadorPage;
