import * as React from "react";
const OfferAcceptIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <circle
      cx={12}
      cy={12}
      r={11.4}
      fill="#fff"
      stroke="#FF9800"
      strokeWidth={1.2}
    />
    <g fill="#FF9800" clipPath="url(#a)">
      <path d="M10.347 7.697H7.9a.41.41 0 1 0 0 .82h2.448a.41.41 0 1 0 0-.82ZM10.347 9.338H7.9a.41.41 0 1 0 0 .82h2.448a.41.41 0 0 0 0-.82ZM10.347 10.979H7.9a.41.41 0 1 0 0 .82h2.448a.41.41 0 1 0 0-.82ZM10.347 12.62H7.9a.41.41 0 1 0 0 .82h2.448a.41.41 0 1 0 0-.82ZM10.347 14.287H7.9a.41.41 0 0 0 0 .82h2.448a.41.41 0 1 0 0-.82ZM11.725 8.517h2.447a.41.41 0 1 0 0-.82h-2.447a.41.41 0 0 0 0 .82ZM11.725 10.158h2.447a.41.41 0 1 0 0-.82h-2.447a.41.41 0 0 0 0 .82ZM13.314 11.389a.41.41 0 0 0-.41-.41h-1.18a.41.41 0 0 0 0 .82h1.18a.41.41 0 0 0 .41-.41ZM17.2 14.46a.41.41 0 0 0-.577.064l-1.056 1.317-.654-.474a.41.41 0 1 0-.482.664l.97.703a.41.41 0 0 0 .561-.075l1.3-1.622a.41.41 0 0 0-.063-.576Z" />
      <path d="M18.236 13.201a3.383 3.383 0 0 0-1.964-.987V6.652c0-.91-.741-1.652-1.652-1.652H7.453C6.542 5 5.8 5.741 5.8 6.652v9.501c0 .91.74 1.652 1.652 1.652h5.764A3.377 3.377 0 0 0 15.807 19a3.411 3.411 0 0 0 2.43-5.799ZM6.621 16.154V6.652c0-.459.373-.832.832-.832h7.167c.458 0 .831.373.831.832v5.55a3.413 3.413 0 0 0-2.753 4.783H7.453a.833.833 0 0 1-.832-.832Zm9.186 2.027c-.819 0-1.572-.376-2.066-1.032a2.59 2.59 0 0 1 2.107-4.145h.006a2.57 2.57 0 0 1 1.797.774 2.59 2.59 0 0 1-1.844 4.403Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M5.5 5h14v14h-14z" />
      </clipPath>
    </defs>
  </svg>
);
export default OfferAcceptIcon;
