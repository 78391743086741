import { useNavigate, useParams } from "react-router-dom";
import ApplyIcon from "../../assets/svg/ApplyIcon";
import ShareArrowIcon from "../../assets/svg/ShareArrowIcon";
import ReferIcon from "../../assets/svg/ReferIcon";
import TrackIcon from "../../assets/svg/TrackIcon";
import ShareJobModal from "./components/ShareJobModal";
import { useEffect, useState } from "react";
import { getJobById } from "../../endpoints/job.service";
import { JobTypeEnum } from "../../utils/constants";

const PublicJobDetailPage = () => {
  const { jobId } = useParams();
  const [showShareJobModal, setShowShareJobModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState();

  useEffect(() => {
    if (jobId) {
      setLoading(true);
      getJobById(jobId)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            setJobDetails(data);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [jobId]);

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4 cursor-pointer">
      <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar px-10 py-5 gap-3.5 w-full">
        <div className="flex w-full gap-4">
          {/* Left section */}
          <div className="flex flex-col flex-1">
            <span className="font-semibold text-primary ">
              {jobDetails?.title}
            </span>
            <div className="flex flex-col gap-1 my-3.5">
              <span className="text-primary text-sm">
                {jobDetails?.location}
              </span>
              <span className="text-primary text-sm">
                ${jobDetails?.salary?.min} - ${jobDetails?.salary?.max}
              </span>
              <span className="text-primary text-sm capitalize">
                {Object.entries(JobTypeEnum)
                  .find(
                    ([key, value]) => value === (jobDetails?.jobType ?? 1)
                  )[0]
                  ?.toLowerCase()}
              </span>
            </div>
            <span className="text-primary text-sm">
              {jobDetails?.industryType}
            </span>

            <div className="my-3">
              <span className="font-inter font-semibold text-primary text-sm">
                Description
              </span>
              <p
                dangerouslySetInnerHTML={{
                  __html: jobDetails?.description,
                }}
                className="text-light-black text-sm mt-2"
              />
            </div>

            {/* <div className="my-3">
              <span className="font-inter font-semibold text-primary text-sm">
                Responsibilities
              </span>
              <p className="text-light-black text-sm mt-2">
                Identify problems based on the product vision / requirements and
                come up with delightful design solutions & deliverables.
              </p>

              <p className="text-light-black text-sm mt-2">
                Identify problems based on the product vision / requirements and
                come up with delightful design solutions & deliverables.
              </p>

              <p className="text-light-black text-sm mt-2">
                Identify problems based on the product vision / requirements and
                come up with delightful design solutions & deliverables.
              </p>
            </div> */}
          </div>
          {/* Action section */}
          <div className="flex flex-col w-52 h-full border-l pl-6">
            {/*Button list container */}
            <div className="flex flex-col gap-3">
              <button className="flex justify-center font-semibold text-xs text-primary rounded-lg bg-[#FFCC00] h-8 w-32 items-center gap-2">
                <ApplyIcon className="w-4 h-4" />
                Apply Now
              </button>

              <button
                onClick={() => setShowShareJobModal(true)}
                className="flex justify-center font-semibold text-xs text-primary rounded-lg border-2 h-8 w-32 items-center gap-2"
              >
                <ShareArrowIcon className="w-4 h-4" />
                Share Job
              </button>

              <button className="flex justify-center font-semibold text-xs text-primary rounded-lg border-2 h-8 w-32 items-center gap-2">
                <ReferIcon className="w-7 h-7" />
                Refer Job
              </button>

              <button className="flex justify-center font-semibold text-xs text-primary rounded-lg border-2 h-8 w-32 items-center gap-2">
                <TrackIcon className="w-4 h-4" />
                Track Job
              </button>
            </div>

            <div className="flex flex-col mt-4 gap-1">
              <span className="font-medium text-xss text-light-black">
                You have <span className="font-semibold">applied</span> for this
                job
              </span>
              <span className="text-xs text-primary font-medium">
                15/10/2024
              </span>
            </div>

            <div className="flex flex-col mt-4 gap-1">
              <span className="font-medium text-xss text-light-black">
                You have <span className="font-semibold">tracked</span> for this
                job
              </span>
              <span className="text-xs text-primary font-medium">
                15/10/2024
              </span>
            </div>

            <div className="flex flex-col mt-4 gap-1">
              <span className="font-medium text-xss text-light-black">
                <span className="font-semibold">Job shared</span> to
              </span>
              <div className="flex items-center gap-4">
                <span className="text-xs text-primary font-medium">
                  John Doe
                </span>
                <span className="text-xs text-primary">15/10/2024</span>
              </div>

              <div className="flex items-center gap-4">
                <span className="text-xs text-primary font-medium">Andrew</span>
                <span className="text-xs text-primary">15/10/2024</span>
              </div>

              <div className="flex items-center gap-4">
                <span className="text-xs text-primary font-medium">
                  Lionel Messi
                </span>
                <span className="text-xs text-primary">15/10/2024</span>
              </div>
            </div>

            <div className="flex flex-col mt-4 gap-1">
              <span className="font-medium text-xss text-light-black">
                <span className="font-semibold">Job referred</span> to
              </span>
              <div className="flex items-center gap-4">
                <span className="text-xs text-primary font-medium">
                  John Doe
                </span>
                <span className="text-xs text-primary">15/10/2024</span>
              </div>

              <div className="flex items-center gap-4">
                <span className="text-xs text-primary font-medium">Andrew</span>
                <span className="text-xs text-primary">15/10/2024</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Share Job Modal */}
      <ShareJobModal
        showShareJobModal={showShareJobModal}
        setShowShareJobModal={setShowShareJobModal}
      />
    </div>
  );
};

export default PublicJobDetailPage;
