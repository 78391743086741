import { RESET_SEARCH_DATA, SET_SEARCH_DATA } from "../actions/action.types";

const initialState = {
  addedSkills: [],
  searchResults: [],
};

const searchReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SEARCH_DATA:
      const updatedData = { ...state, ...payload };
      if (payload) {
        localStorage.setItem("searchData", JSON.stringify(updatedData));
      }
      return updatedData;
    case RESET_SEARCH_DATA:
      return {...initialState};
    default:
      return state;
  }
};

export default searchReducer;
