import { Link } from "react-router-dom";
import PlusIcon from "../../../assets/svg/PlusIcon";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";
import CustomInput from "../../../components/CustomInput/CustomInput";
import CrossBig from "../../../assets/svg/CrossBig";
import { useForm } from "react-hook-form";
import { inviteUserViaEmail } from "../../../endpoints/recruiter.service";
import { colors } from "../../../utils/theme";
import { useSnackbar } from "notistack";
import FileIcon from "../../../assets/images/fileIcon.png";
import { useEffect, useState } from "react";
import { getReferralContacts } from "../../../endpoints/user.service";

const NetworkPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      inviteModal: false,
      isSubmitting: false,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
    },
  });
  const fields = watch();

  useEffect(() => {
    setLoading(true);
    getReferralContacts()
      .then((response) => {
        const { data, success } = response;
        if (success) {
          setMembers(data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const handleFormSubmit = async () => {
    setValue("isSubmitting", true);
    try {
      await inviteUserViaEmail({
        firstName: fields?.firstName,
        lastName: fields?.lastName,
        email: fields?.email,
      });
      enqueueSnackbar("Member invited successfully", { variant: "success" });
      reset({
        inviteModal: false,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
      });
    } catch (error) {
      enqueueSnackbar("Error inviting member", { variant: "error" });
    } finally {
      setValue("isSubmitting", false);
    }
  };

  return (
    <div className="flex flex-col items-center rounded-lg bg-white w-full flex-grow p-4 gap-3">
      {loading ? (
        <div className="gap-2.5 flex mt-16 flex-col w-full">
          {Array.from({ length: 10 }, (x, i) => i).map((key) => {
            return (
              <div className="flex justify-between">
                <div
                  style={{
                    borderWidth: 1,
                  }}
                  className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
                >
                  <div className="h-3 rounded bg-gray-200 w-full"></div>
                  <div className="h-3 rounded bg-gray-200 w-full"></div>
                  <div className="h-3 rounded bg-gray-200 w-full"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {members && members.length > 0 ? (
            <>
              <div className="flex items-center justify-between w-full py-5">
                <div />
                <button className="bg-primary hover:bg-black rounded-md text-white font-medium text-sm px-6 py-1.5 flex justify-self-center">
                  Search
                </button>

                <button
                  onClick={() => setValue("inviteModal", true)}
                  className="flex items-center justify-center border bg-green-600 rounded-lg px-2 py-1 gap-1 text-white font-semibold text-sm"
                >
                  <PlusIcon className={"w-4 h-4"} fill={"white"} />
                  Invite
                </button>
              </div>
              <div className="grid grid-cols-[1fr_1fr_1fr_0.5fr] rounded-md w-full px-5">
                <span className="font-bold text-primary text-xs">Name</span>
                <span className="font-bold text-primary text-xs">
                  Profession
                </span>
                <span className="font-bold text-primary text-xs">Location</span>
                <span className="font-bold text-primary text-xs">Status</span>
              </div>

              <div className="w-full rounded-md">
                {members?.map((member) => {
                  return (
                    <Link
                      to={"/network-detail"}
                      state={member}
                      className={`grid grid-cols-[1fr_1fr_1fr_0.5fr] py-3 w-full px-5 bg-[#FAFAFA] border border-[#ECECEC] rounded mb-3 cursor-pointer ${
                        member._id !== 0 && "border-t"
                      }`}
                    >
                      <span className="font-medium text-primary text-xs">
                        {member?.firstName} {member?.lastName}
                      </span>
                      <span className="font-medium text-primary text-xs">
                        {member?.jobTitle ?? "-"}
                      </span>
                      <span className="font-semibold text-primary text-xs">
                        {member?.location ?? "-"}
                      </span>
                      <span className="font-semibold text-primary text-xs">
                        {member?.status}
                      </span>
                    </Link>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="flex flex-col h-full justify-center items-center">
              <img src={FileIcon} alt="File Icon" />
              <p className="font-semibold text-sm text-primary">
                You currently don’t have any contact.
              </p>
              <div className="flex flex-col gap-2 mt-3">
                <p className="font-medium text-xs text-light-black">
                  Click here to invite.
                </p>
                <button
                  onClick={() => setValue("inviteModal", true)}
                  className="flex items-center justify-center border bg-green-600 rounded-lg px-2 py-1 gap-1 text-white font-semibold text-sm"
                >
                  <PlusIcon className={"w-4 h-4"} fill={"white"} />
                  Invite
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {fields?.inviteModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-1/3">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-xl font-semibold">Invite Contact</h2>
              <button
                onClick={() =>
                  reset({
                    inviteModal: false,
                    firstName: undefined,
                    lastName: undefined,
                    email: undefined,
                  })
                }
              >
                <CrossBig width={24} height={24} />
              </button>
            </div>
            <div className="bg-[#F9FAFB] px-8 py-3 rounded-2xl">
              <div className="mb-4">
                <CustomInput
                  label="First Name"
                  name="firstName"
                  control={control}
                  errors={errors}
                  rules={{
                    required: "This field is required.",
                  }}
                />
              </div>
              <div className="mb-4">
                <CustomInput
                  label="Last Name"
                  name="lastName"
                  control={control}
                  errors={errors}
                  rules={{
                    required: "This field is required.",
                  }}
                />
              </div>
              <div className="mb-4">
                <CustomInput
                  label="Email address"
                  name="email"
                  control={control}
                  errors={errors}
                  rules={{
                    required: "This field is required.",
                  }}
                />
              </div>
              <button
                onClick={handleSubmit(handleFormSubmit)}
                className="text-xl bg-primary text-white py-1.5 px-5 rounded-md flex items-center justify-self-end mt-14 mb-6"
              >
                {fields.isSubmitting && (
                  <LoadingAnimation color={colors.White} />
                )}{" "}
                Invite
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NetworkPage;
