import { useState } from "react";
import { useNavigate } from "react-router-dom";
import VerificationCodeInput from "../../components/OtpInputBox/VerificationCodeInput";
import CustomInput from "../../components/CustomInput/CustomInput";
import regexList from "../../utils/regexList";
import { useForm } from "react-hook-form";
import {
  forgotPassword,
  forgotPasswordVerify,
} from "../../endpoints/user.service";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const [showInputBox, setShowInputBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState("");
  const [code, setCode] = useState();

  const field = watch();

  const handleSubmitButton = (data) => {
    setLoading(true);
    if (emailSent) {
      forgotPasswordVerify(field?.email, code)
        .then((response) => {
          const { success } = response;
          if (success) {
            navigate("/update-forgot-password", {
              state: {
                email: field?.email,
                otpCode: code,
              },
            });
          }
        })
        .finally(() => setLoading(false));
    } else {
      forgotPassword(data?.email)
        .then((response) => {})
        .finally(() => {
          setEmailSent(true);
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex flex-col items-center my-20 border rounded-xl px-10 w-max py-10 mx-auto bg-white">
      <span className="font-didact font-semibold text-3xl">
        Forgot Password
      </span>
      <span className="font-montserrat text-light-text font-medium mt-3">
        {emailSent
          ? "Please enter your verification code."
          : "Please enter you verification email."}
      </span>
      {/* {showInputBox && !emailSent ? null : emailSent ? (
        <EmailSuccess className={"w-14 h-14 my-4"} />
      ) : (
        <EmailOpenIcon className={"w-14 h-14 my-4"} />
      )} */}

      {emailSent ? (
        <span className="font-montserrat text-light-black font-medium text-sm w-96 text-center">
          A verification code has been sent to your email address
          <span className="font-semibold">{verificationEmail}</span>, please
          check your inbox and insert the code to verify your email.
        </span>
      ) : (
        <span className="font-montserrat text-light-black font-medium text-sm w-96 text-center">
          Simply enter your email address in the field below, and we’ll send you
          detailed instructions to reset your password.
        </span>
      )}

      {emailSent ? (
        <div className="mt-3">
          <VerificationCodeInput
            loading={false}
            onChange={(codeNum) => {
              setCode(codeNum);
            }}
            length={6}
          />
        </div>
      ) : (
        <div className="w-[90%] mt-3">
          <CustomInput
            label={"Verification email"}
            name="email"
            placeholder={"Enter your verification email"}
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.email,
                message: "Please enter a valid email.",
              },
            }}
            control={control}
            errors={errors}
          />
        </div>
      )}
      <button
        disabled={code?.length !== 6 && showInputBox}
        onClick={
          !emailSent
            ? handleSubmit((e) => handleSubmitButton(e))
            : handleSubmitButton
        }
        className={`flex items-center justify-center gap-4 font-montserrat font-medium text-sm text-white bg-primary rounded-full py-3 w-[28rem] mt-3 ${
          (code?.length !== 6 && emailSent) || (!emailSent && !isDirty)
            ? "opacity-50"
            : "opacity-100"
        }`}
      >
        {emailSent ? "Verify" : "Verify your email address"}
        {loading && <LoadingAnimation />}
      </button>
      {emailSent ? (
        <span className="font-montserrat mt-2.5 text-light-text font-medium text-xs">
          Didn't receive {showInputBox ? "the code" : "verification email"}?{" "}
          <span className="text-secondary">
            Resend {showInputBox ? "code" : ""}
          </span>
        </span>
      ) : null}
    </div>
  );
};

export default ForgotPassword;
