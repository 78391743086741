import * as React from "react";
const LaptopIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      fill="#2E2E2E"
      d="M1.475 16.218v-.833h17.05v.833H1.475Zm2.371-1.666c-.384 0-.704-.129-.96-.386a1.309 1.309 0 0 1-.386-.961v-8.14c0-.384.128-.705.385-.962a1.3 1.3 0 0 1 .96-.385h12.309c.383 0 .703.129.96.385.256.257.385.577.386.961v8.142c0 .383-.129.703-.386.96a1.309 1.309 0 0 1-.961.386H3.846Zm0-.834h12.308a.49.49 0 0 0 .352-.16.488.488 0 0 0 .16-.353v-8.14a.492.492 0 0 0-.16-.353.491.491 0 0 0-.353-.161H3.846a.491.491 0 0 0-.353.16.49.49 0 0 0-.16.353v8.142c0 .128.053.245.16.351.107.107.224.16.352.161"
    />
    <path
      stroke="#2E2E2E"
      strokeWidth={0.2}
      d="M3.846 13.718h12.308a.49.49 0 0 0 .352-.16.488.488 0 0 0 .16-.353v-8.14a.492.492 0 0 0-.16-.353.491.491 0 0 0-.353-.161H3.846a.491.491 0 0 0-.353.16.49.49 0 0 0-.16.353v8.142c0 .128.053.245.16.351.107.107.224.16.352.161m-.512 0V4.552M1.475 16.218v-.833h17.05v.833H1.475Zm2.371-1.666c-.384 0-.704-.129-.96-.386a1.309 1.309 0 0 1-.386-.961v-8.14c0-.384.128-.705.385-.962a1.3 1.3 0 0 1 .96-.385h12.309c.383 0 .703.129.96.385.256.257.385.577.386.961v8.142c0 .383-.129.703-.386.96a1.309 1.309 0 0 1-.961.386H3.846Z"
    />
  </svg>
);
export default LaptopIcon;
