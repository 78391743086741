import * as React from "react";
const TransactionIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#4D4D4D"
        d="M7.992 7.949a.336.336 0 0 0-.27-.329l-1.628-.326a1.269 1.269 0 0 1 .248-2.51h.191v-.7h.934v.7h.116c.7 0 1.277.539 1.337 1.224h-.945a.409.409 0 0 0-.392-.291h-1.24a.335.335 0 0 0-.066.662l1.629.326a1.269 1.269 0 0 1-.25 2.51h-.19v.701h-.933v-.7h-.116c-.7 0-1.277-.54-1.337-1.225h.945c.05.169.207.292.392.292h1.24c.184 0 .335-.15.335-.335ZM7 1.167c1.6 0 3.077.64 4.16 1.75H9.917v1.166h2.333c.643 0 1.167-.523 1.167-1.166V.583H12.25v1.803A6.931 6.931 0 0 0 7 0C3.14 0 0 3.14 0 7h1.167A5.84 5.84 0 0 1 7 1.167ZM12.833 7A5.84 5.84 0 0 1 7 12.833a5.78 5.78 0 0 1-4.16-1.75h1.243V9.917H1.75c-.643 0-1.167.523-1.167 1.166v2.334H1.75v-1.803A6.931 6.931 0 0 0 7 14c3.86 0 7-3.14 7-7h-1.167Zm-1.166 0c0-.619-.124-1.21-.344-1.75h-1.297c.3.516.474 1.112.474 1.75 0 1.93-1.57 3.5-3.5 3.5a3.475 3.475 0 0 1-1.75-.474v1.297c.54.22 1.131.344 1.75.344A4.672 4.672 0 0 0 11.667 7ZM7 3.5c.638 0 1.234.174 1.75.474V2.677A4.635 4.635 0 0 0 7 2.333 4.672 4.672 0 0 0 2.333 7c0 .619.124 1.21.344 1.75h1.297A3.476 3.476 0 0 1 3.5 7c0-1.93 1.57-3.5 3.5-3.5Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default TransactionIcon;
