import { useEffect, useState } from "react";

const ApplyIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 14"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <g clip-path="url(#clip0_5550_18269)">
        <path
          d="M11.6952 1.0773H10.3644V0.546875C10.3644 0.244812 10.1196 0 9.81749 0H5.51523C5.21317 0 4.96835 0.244812 4.96835 0.546875V1.0773H3.6377C2.88382 1.0773 2.27051 1.69061 2.27051 2.44449V12.6328C2.27051 13.3867 2.88382 14 3.6377 14H11.6952C12.4491 14 13.0624 13.3867 13.0624 12.6328V2.44449C13.0624 1.69061 12.449 1.0773 11.6952 1.0773ZM9.27061 1.09375V2.15471H6.062C6.062 1.79123 6.062 1.49098 6.062 1.09375H9.27061ZM11.9687 12.6328C11.9687 12.7836 11.8459 12.9062 11.6952 12.9062H3.6377C3.48688 12.9062 3.36426 12.7836 3.36426 12.6328V2.44449C3.36426 2.29378 3.48688 2.17105 3.6377 2.17105H4.96825V2.70158C4.96825 3.00365 5.21317 3.24846 5.51512 3.24846H9.81749C10.1194 3.24846 10.3644 3.00365 10.3644 2.70158V2.17105H11.6952C11.8459 2.17105 11.9687 2.29378 11.9687 2.44449V12.6328ZM10.204 6.3853C10.4177 6.59892 10.4177 6.94521 10.204 7.15872L7.33017 10.0326C7.11665 10.2462 6.77037 10.2462 6.55675 10.0326L5.12857 8.60442C4.91505 8.3909 4.91505 8.04462 5.12857 7.83099C5.34219 7.61748 5.68837 7.61748 5.90199 7.83099L6.94351 8.87251L9.43062 6.38541C9.64424 6.17178 9.99042 6.17178 10.204 6.3853Z"
          fill="#2E2E2E"
        />
      </g>
      <defs>
        <clipPath id="clip0_5550_18269">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.657227)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ApplyIcon;
