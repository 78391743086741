import { skillsLastUsed, skillsUsed } from "../../../utils/constants";

const SkillItem = ({ skill, handleRemoveSkill }) => {
  return (
    <div
      key={skill?.id}
      className="grid space-x-3 py-2.5 items-center w-full justify-center relative grid-cols-[1.7fr_0.68fr_0.58fr_1fr_0.5fr]"
    >
      <div className="border border-gray-100 bg-[#FAFAFA] px-4 py-2.5 text-xs font-medium text-primary rounded flex flex-1">
        {skill.skill}
      </div>
      <div className="border border-gray-100 bg-[#FAFAFA] px-4 py-2.5 text-xs font-medium text-primary rounded flex flex-1">
        {skill.rating}/10
      </div>
      <div className="border border-gray-100 bg-[#FAFAFA] px-4 py-2.5 text-xs font-medium text-primary rounded flex flex-1">
        {skill.experience}
      </div>
      <div className="border border-gray-100 bg-[#FAFAFA] px-4 py-2.5 text-xs font-medium text-primary rounded flex flex-1">
        {skill.lastUsed}
      </div>
      <button
        className="flex items-center font-medium text-xs underline"
        onClick={handleRemoveSkill}
      >
        Remove
      </button>
    </div>
  );
};

export default SkillItem;
