import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import ChevronLeftIcon from "../../assets/svg/ChevronLeftIcon";
import { getCompanyList } from "../../endpoints/company.service";
import { addJob, updateJob } from "../../endpoints/job.service";
import { setCompanies } from "../../store/actions/company.action";
import { JobStatus, JobType } from "../../utils/constants";
import Detail from "./components/Detail";
import Description from "./components/Description";
import Skills from "./components/Skills";
import Settings from "./components/Settings";
import { colors } from "../../utils/theme";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";

const STEP_CONFIG = [
  { label: "Details", component: Detail },
  { label: "Description", component: Description },
  { label: "Skills", component: Skills },
  { label: "Settings", component: Settings },
];

const CreateJobPage2 = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state?.company);

  const [activeStep, setActiveStep] = useState(1);
  const [createNewCompany, setCreateNewCompany] = useState(false);

  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: useInitializeFormDefaults(state, companies),
    mode: "onChange",
  });

  const fields = watch();

  useEffect(() => {
    fetchCompanyList();
  }, [createNewCompany]);

  const fetchCompanyList = useCallback(() => {
    getCompanyList()
      .then(({ data, success }) => {
        if (success) dispatch(setCompanies(data));
      })
      .finally(() => setValue("companiesLoading", false));
  }, [dispatch, setValue]);

  const handleNext = useCallback(async () => {
    const isStepValid = await trigger();
    if (!isStepValid) return;

    if (createNewCompany) {
      setCreateNewCompany(false);
      return;
    }

    if (activeStep === 2) {
      await createJob(fields);
    } else if (activeStep === 4) {
      await finishJob();
    } else if (activeStep < STEP_CONFIG.length) {
      setActiveStep((prev) => prev + 1);
    }
  }, [activeStep, createNewCompany, fields]);

  const createJob = useCallback(
    async (data) => {
      try {
        setValue("isLoading", true);
        const jobTypeValue = JobType.find(
          (x) => x.label?.toLowerCase() === data?.jobType?.toLowerCase()
        )?.value;
        const jobPayload = {
          title: data?.jobTitle,
          description: data?.jobDescription,
          location: data?.jobLocation,
          salary: {
            min: data?.salaryRangeFrom,
            max: data?.salaryRangeTo,
          },
          industryType: data?.industryType,
          jobType: jobTypeValue,
          companyId: data?.selectedCompany?._id,
          status: JobStatus.DRAFT,
          isSalaryHidden: data?.isSalaryHidden,
        };

        const response = data?.createdDraftJob
          ? await updateJob({
              jobId: data?.createdDraftJob?._id,
              ...jobPayload,
            })
          : await addJob(jobPayload);

        if (response?.success) {
          setValue("createdDraftJob", response?.data);
          setActiveStep((prev) => prev + 1);
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      } finally {
        setValue("isLoading", false);
      }
    },
    [setValue, enqueueSnackbar]
  );

  const finishJob = useCallback(async () => {
    try {
      setValue("isLoading", true);
      const { success } = await updateJob({
        jobId: fields?.createdDraftJob?._id,
        status: JobStatus.LIVE,
        companyId: fields?.selectedCompany?._id,
      });

      if (success) navigate("/job", { replace: true });
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    } finally {
      setValue("isLoading", false);
    }
  }, [fields, navigate, enqueueSnackbar]);

  const renderStepContent = useMemo(() => {
    const CurrentComponent = STEP_CONFIG[activeStep - 1].component;
    return (
      <CurrentComponent
        control={control}
        errors={errors}
        createdDraftJob={fields?.createdDraftJob}
        setCreateNewCompany={setCreateNewCompany}
        createNewCompany={createNewCompany}
        loading={fields?.companiesLoading}
        setValue={setValue}
        fields={fields}
      />
    );
  }, [activeStep, control, errors, fields]);

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <BackButton navigate={navigate} />
      <form className="flex flex-col items-center w-full h-full">
        <StepIndicator steps={STEP_CONFIG} activeStep={activeStep} />

        <div className="flex flex-col py-5 flex-1 overflow-y-scroll no-scrollbar w-full">
          {renderStepContent}
        </div>

        <NavigationButtons
          activeStep={activeStep}
          onNext={handleNext}
          onPrevious={() => setActiveStep((prev) => prev - 1)}
          totalSteps={STEP_CONFIG.length}
          isLoading={fields?.isLoading}
        />
      </form>
    </div>
  );
};

const BackButton = ({ navigate }) => (
  <button onClick={() => navigate(-1)} className="flex items-center">
    <ChevronLeftIcon className="w-4 h-4" fill="#4d4d4d" />
    <label className="text-xs font-medium text-primary">Back to listing</label>
  </button>
);

const StepIndicator = ({ steps, activeStep }) => (
  <div className="flex justify-center items-center my-4 py-6 w-full bg-[#FAFAFA] rounded">
    <div className="flex gap-4 items-center">
      {steps.map((step, stepIndex) => {
        const isActive = stepIndex + 1 === activeStep;
        return (
          <React.Fragment key={step.label}>
            {stepIndex !== 0 && (
              <div className="w-14 h-px border border-b border-light-black" />
            )}
            <div
              className={`flex gap-6 ${
                isActive ? "bg-primary" : "bg-white"
              } rounded items-center`}
            >
              <div className="flex items-center gap-2">
                <label
                  className={`font-medium text-xs px-6 py-2 ${
                    isActive ? "text-white" : "text-primary"
                  }`}
                >
                  {step.label}
                </label>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  </div>
);

const NavigationButtons = ({
  activeStep,
  onNext,
  onPrevious,
  totalSteps,
  isLoading = false,
}) => (
  <div className="flex justify-between w-full px-20 py-5">
    <button
      type="button"
      onClick={onPrevious}
      disabled={activeStep === 1 || isLoading}
      className={`bg-[#F7F7F7] rounded-md text-xs px-4 py-2 self-end text-primary
        ${activeStep === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
    >
      Back
    </button>

    <div className="flex gap-5">
      <button
        type="button"
        disabled={isLoading}
        className="rounded-md text-xs px-4 py-2 self-end"
      >
        Save draft
      </button>
      <button
        type="button"
        onClick={onNext}
        disabled={isLoading}
        className="bg-primary flex items-center text-white rounded-md text-xs px-4 py-2 self-end"
      >
        {isLoading && (
          <LoadingAnimation color={colors.White} className="w-4 h-4" />
        )}
        {activeStep === totalSteps ? "Finish" : "Continue"}
      </button>
    </div>
  </div>
);

// Helper function to initialize form defaults
const useInitializeFormDefaults = (state, companies) => ({
  companiesLoading: companies?.length > 0 ? false : true,
  industryType: state?.industryType,
  jobDescription: state?.description,
  jobLocation: state?.location,
  jobTitle: state?.title,
  jobType: state
    ? JobType?.find((x) => state?.jobType === x.value)?.label
    : undefined,
  salaryRangeFrom: state?.salary?.min,
  salaryRangeTo: state?.salary?.max,
  isSalaryHidden: state?.isSalaryHidden ?? false,
  selectedCompany: state
    ? companies?.find((x) => x._id === state?.companyId)
    : undefined,
  createdDraftJob: state ?? undefined,
});

export default CreateJobPage2;
