import * as React from "react";
const SmartPhone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g fill="#6F6F6F" clipPath="url(#a)">
      <path d="M10.001 0h-4a3.337 3.337 0 0 0-3.333 3.333v9.334A3.337 3.337 0 0 0 6.001 16h4a3.338 3.338 0 0 0 3.334-3.333V3.333A3.337 3.337 0 0 0 10 0Zm-4 1.333h4a2 2 0 0 1 2 2v7.334h-8V3.333a2 2 0 0 1 2-2Zm4 13.334h-4a2 2 0 0 1-2-2V12h8v.667a2 2 0 0 1-2 2Z" />
      <path d="M7.999 14a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SmartPhone;
