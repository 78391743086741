import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "../../../../assets/svg/ChevronLeftIcon";
import ShareIconOutline from "../../../../assets/svg/ShareIconOutline";
import ReferIcon from "../../../../assets/svg/ReferIcon";
import TrackingIcon from "../../../../assets/svg/TrackingIcon";
import LikeIconLeft from "../../../../assets/svg/LikeIconLeft";
import profile1 from "../../../../assets/profilePic3.png";
import { forwardRef, useEffect, useState } from "react";
import LocationIconOutline from "../../../../assets/svg/LocationIconOutline";
import { isNullOrEmpty } from "../../../../utils/helperFunction";
import { getCandidates } from "../../../../endpoints/candidate.service";

const tabs = ["Profile"];

const RenderBody = forwardRef(({ activeTab, data }) => {
  switch (activeTab) {
    case 0:
      return (
        <div className="flex flex-1 flex-col">
          <div className="flex items-center gap-2">
            <LocationIconOutline className={"w-4 h-4"} fill="#6f6f6f" />
            <span className="font-medium text-xs text-light-black">
              {data?.location ?? "N/A"}
            </span>
          </div>
          <span className="text-primary mt-3 font-medium">
            A Highly Skilled Senior Web Developer with Over 15 Years of
            Experience
          </span>
          <span className="text-primary text-xs font-medium text-justify leading-5">
            A highly skilled Node.js and React developer with 15 years of
            experience in full stack development, specializing in building
            scalable, high-performance applications. Proficient in Javascript,
            Typescript and modern frameworks, with deep expertise in back-end
            development using Node.js and front-end technologies like React.
            Extensive experience with databases, particularly MongoDB, and
            integrating third-party APIs. Adept at optimizing code, improving
            system efficiency, and ensuring seamless user experiences. Strong
            problem-solving skills and a passion for delivering high-quality
            software solutions on time and within budget.
          </span>

          <div className="flex flex-col w-full mt-8">
            <div className="grid grid-cols-[2fr_0.4fr_0.31fr_1fr] w-full gap-14">
              <span className="font-semibold text-xs text-primary">Skill</span>
              <span className="font-semibold text-xs text-primary">Rating</span>
              <span className="font-semibold text-xs text-primary">Years</span>
              <span className="font-semibold text-xs text-primary">When</span>
            </div>

            <div className="flex flex-col w-full mt-4 gap-5">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 4]?.map((item) => {
                return (
                  <div className="grid grid-cols-[2fr_0.4fr_0.31fr_1fr] w-full gap-14">
                    <div className="w-full bg-gray-50 border text-xs font-medium py-2 px-2.5 text-primary rounded-md">
                      Python
                    </div>
                    <div className="w-full bg-gray-50 text-center border text-xs font-medium py-2 px-2.5 text-primary rounded-md">
                      8/10
                    </div>
                    <div className="w-full text-center bg-gray-50 border text-xs font-medium py-2 px-2.5 text-primary rounded-md">
                      1
                    </div>
                    <div className="w-full bg-gray-50 border text-xs font-medium py-2 px-2.5 text-primary rounded-md">
                      Last year
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
      return (
        <div className="flex flex-col gap-4 pt-10 pr-14 max-h-fit flex-1 overflow-y-scroll no-scrollbar"></div>
      );
    case 1:
      return <div></div>;
    default:
      return <div />;
  }
});

const NetworkDetailPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { state: networkDetail } = useLocation();

  useEffect(() => {
    getCandidates({
      candidateId: networkDetail?.candidateId,
    });
  }, []);

  return (
    <div className="flex flex-1 flex-col w-full flex-grow max-h-full">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center self-start mb-5"
      >
        <ChevronLeftIcon className={"w-4 h-4"} />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>
      <div className="flex flex-1 rounded-lg bg-white max-h-full overflow-hidden w-full flex-grow p-4 ">
        <div className="flex flex-col py-6 pr-6 w-[15%] items-center gap-5">
          <span className="text-xs font-semibold text-primary">CA-FQ1478</span>
          <button className="rounded-md py-1.5 text-xs font-semibold text-white bg-green-900 w-full">
            Open to offers
          </button>

          <span className="text-xs font-medium text-primary">
            View full profile
          </span>

          {/* Action button section */}
          <div className="flex flex-col gap-6 mt-24">
            <div className="group flex flex-col justify-center w-14 h-14 border rounded-md items-center cursor-pointer gap-1 bg-gray-50">
              <div className="flex border border-primary h-[22px] w-[22px] rounded-full justify-center items-center">
                <ShareIconOutline className={"w-6 h-6"} />
              </div>
              <span className="font-medium uppercase text-[8px] text-light-black">
                Share
              </span>
            </div>

            <div className="group flex flex-col justify-center w-14 h-14 border rounded-md items-center cursor-pointer gap-1 bg-gray-50">
              <div className="flex border border-primary h-[22px] w-[22px] rounded-full justify-center items-center">
                <TrackingIcon className={"w-6 h-6"} fill={"#2E2E2E"} />
              </div>
              <span className="font-medium uppercase text-[8px] text-light-black">
                Track
              </span>
            </div>

            <div className="group flex flex-col justify-center w-14 h-14 border rounded-md items-center cursor-pointer gap-1 bg-gray-50">
              <div className="flex border border-primary h-[22px] w-[22px] rounded-full justify-center items-center">
                <ReferIcon className="w-6 h-6" />
              </div>
              <span className="font-medium uppercase text-[8px] text-light-black">
                Refer
              </span>
            </div>

            <div className="group flex flex-col justify-center w-14 h-14 border rounded-md items-center cursor-pointer gap-1 bg-gray-50">
              <div className="flex border border-primary h-[22px] w-[22px] rounded-full justify-center items-center">
                <LikeIconLeft className={"w-5 h-5 mt-0.5"} fill={"#2E2E2E"} />
              </div>
              <span className="font-medium uppercase text-[8px] text-light-black">
                Likes
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col border-x flex-grow border-b">
          {/* Top container */}
          <div className="flex items-center bg-[#FAFAFA] py-2.5 px-2.5 border border-[#F7F7F7] mb-1 relative">
            {/* Image container */}
            <div className="absolute top-0 flex rounded-full h-40 w-40 bg-[#E1E0E0] items-center justify-center">
              {networkDetail?.profilePic ? (
                <img
                  src={networkDetail?.profilePic}
                  className="rounded-full h-40 w-40 object-fill z-10"
                />
              ) : !isNullOrEmpty(networkDetail?.firstName) ? (
                <span className="font-medium text-5xl text-gray-500">
                  {networkDetail?.firstName?.charAt(0)}{" "}
                  {networkDetail?.lastName?.charAt(0)}
                </span>
              ) : (
                <img
                  src={profile1}
                  className="rounded-full h-40 w-40 object-fill z-10"
                />
              )}
            </div>

            <div className="flex justify-center flex-1 gap-1 pl-20 items-center">
              <div className="flex flex-col gap-2.5 my-2">
                <span className="font-montserrat text-primary text-2xl border-b border-primary w-max pb-1 tracking-[0.3rem] px-9">
                  {!isNullOrEmpty(networkDetail?.firstName)
                    ? `${networkDetail?.firstName}${
                        !isNullOrEmpty(networkDetail?.lastName) &&
                        networkDetail?.lastName
                      }`
                    : "John Doe"}
                </span>
                <span className="font-montserrat text-light-black font-medium text-xs max-[900px]:w-min text-center">
                  Senior Application Developer
                </span>
              </div>
            </div>
          </div>

          {/* Main Body Wrapper */}
          <div className="flex flex-col overflow-hidden relative flex-1">
            <div className="flex flex-col pl-48">
              <div className="h-px w-[85%] bg-[#dddddd] absolute right-0 top-[2.7rem] z-0"></div>
              {/* Tab section */}
              <div className="flex items-center gap-5 xl:pr-10 2xl:pr-20 mt-[0.8rem]">
                {tabs?.map((item, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => setActiveTab(index)}
                      style={{
                        borderBottom:
                          activeTab === index
                            ? "2px solid #EFBF04"
                            : "2px solid transparent",
                      }}
                      className="pb-1 px-2.5"
                    >
                      <span className="font-montserrat text-primary font-medium text-xs">
                        {item}
                      </span>
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-1 flex-col overflow-y-scroll mt-8 no-scrollbar h-[100] pl-32 pr-24 py-10">
              <RenderBody activeTab={activeTab} data={networkDetail} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkDetailPage;
