import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { useSelector } from "react-redux";

const Contacts = forwardRef(({ data, setData, handleNext }, ref) => {
  const { user } = useSelector((state) => state.auth);
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      address: user?.address,
      email: user?.email,
      phoneNo:
        user?.countryCode && user?.phoneNo
          ? (user?.countryCode + user?.phoneNo).toString()
          : undefined,
    },
  });

  useEffect(() => {
    if (Object?.keys(dirtyFields)?.length > 0) {
      setData((prev) => {
        return {
          ...prev,
          contactDetails: { ...prev?.details, isFilled: true },
        };
      });
    } else {
      setData((prev) => {
        return {
          ...prev,
          contactDetails: {
            ...prev?.details,
            isEditing: true,
            isFilled: false,
            isCompleted: user?.address || user?.phoneNo ? true : false,
          },
        };
      });
    }
  }, [Object?.keys(dirtyFields)?.length]);

  useImperativeHandle(ref, () => handleSubmit(handleNext));

  return (
    <div className="flex flex-col">
      <div className={`flex flex-col items-start gap-3 mb-8`}>
        <span className="font-montserrat text-[#383838] font-bold text-sm">
          Contact Details
        </span>
      </div>

      <div className="flex flex-col w-3/4 xl:w-2/4">
        <form className="flex flex-col gap-4">
          <CustomInput
            label={"Address"}
            name="address"
            placeholder={"123 West Street, Utopia, UK, 230093"}
            control={control}
            errors={errors}
          />
          <CustomInput
            type="phone"
            label={"Contact Number"}
            name="phoneNo"
            placeholder={"+1  999-999-9999"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"Email Address"}
            name="email"
            placeholder={"abc@gmail.com"}
            control={control}
            errors={errors}
          />
        </form>
      </div>
    </div>
  );
});

export default Contacts;
