import * as React from "react";
const AppliedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <circle
      cx={12}
      cy={12}
      r={11.4}
      fill="#fff"
      stroke="#4D4D4D"
      strokeWidth={1.2}
    />
    <path
      fill="#4D4D4D"
      stroke="#4D4D4D"
      strokeWidth={0.6}
      d="M12.5 9.5h-7a.5.5 0 1 1 0-1h7a.5.5 0 0 1 0 1Zm.5 2a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5ZM9.5 14a.5.5 0 0 0-.5-.5H5.5a.5.5 0 0 0 0 1H9a.5.5 0 0 0 .5-.5Zm4.5 3.5a.5.5 0 0 0 .385-.185l4.5-5.5a.5.5 0 1 0-.77-.63L14 16.215l-2.11-2.535a.5.5 0 1 0-.77.64l2.5 3a.5.5 0 0 0 .38.18Z"
    />
  </svg>
);
export default AppliedIcon;
