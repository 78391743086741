import { useEffect, useState } from "react";

const LinkIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <path
        d="M6.27208 8.08581C6.48101 8.29527 6.48101 8.63438 6.27208 8.84331L3.72369 11.3896C3.04387 12.0694 3.04441 13.1687 3.72369 13.8485C4.40191 14.5256 5.50441 14.5256 6.18262 13.8485L8.72833 11.3001C8.9378 11.0906 9.27691 11.0906 9.48583 11.3001C9.6953 11.509 9.6953 11.8481 9.48637 12.0576L6.94066 14.606C5.84458 15.7015 4.06119 15.701 2.96673 14.606C1.86851 13.5072 1.86851 11.7297 2.96673 10.6321L5.51458 8.08581C5.72351 7.87634 6.06262 7.87634 6.27208 8.08581Z"
        fill="#2E2E2E"
      />
      <path
        d="M14.1764 3.39489C15.2746 4.49364 15.2746 6.27114 14.1764 7.36882L11.6285 9.91507C11.419 10.1245 11.0794 10.124 10.8705 9.91507C10.6615 9.70561 10.6615 9.3665 10.8705 9.15757L13.4183 6.61132C14.0982 5.9315 14.0976 4.83222 13.4183 4.15239C12.7401 3.47525 11.6376 3.47525 10.9594 4.15239L8.41369 6.70079C8.20422 6.91025 7.86512 6.91025 7.65619 6.70079C7.44672 6.49186 7.44672 6.15275 7.65565 5.94329L10.2014 3.39489C11.2985 2.29936 13.0808 2.29936 14.1764 3.39489Z"
        fill="#2E2E2E"
      />
      <path
        d="M6.58532 10.9858C6.37586 10.7764 6.37586 10.4378 6.58532 10.2283L9.79961 7.01403C10.0091 6.80457 10.3476 6.80457 10.5571 7.01403C10.7666 7.2235 10.7666 7.56207 10.5571 7.77153L7.34282 10.9858C7.13336 11.1953 6.79479 11.1953 6.58532 10.9858Z"
        fill="#2E2E2E"
      />
    </svg>
  );
};

export default LinkIcon;
