import ChevronDown from "../../../../assets/svg/ChevronDown";
import CloseIcon from "../../../../assets/svg/CloseIcon";
import CopyIcon from "../../../../assets/svg/CopyIcon";
import LinkIcon from "../../../../assets/svg/LinkIcon";
import SearchIcon from "../../../../assets/svg/SearchIcon";

const ShareAssessmentLink = ({
  setShowModal,
  handleSend,
  selectedCandidates,
  setSelectedCandidates,
}) => {
  return (
    <div className="flex flex-col">
      {/* Header */}
      <div className="flex items-center justify-between">
        <span className="font-semibold text-primary">
          Share assessment link with selected candidates
        </span>
        <button
          onClick={() => {
            setSelectedCandidates([]);
            setShowModal(false);
          }}
        >
          <CloseIcon className={"w-6 h-6"} />
        </button>
      </div>

      {/* Selection container */}
      <div className="border rounded-md px-2 py-1 mt-4">
        <span className="font-semibold text-light-black text-xs">
          {selectedCandidates?.length} candidates selected
        </span>
        <div className="flex gap-2 flex-wrap py-2">
          {selectedCandidates?.map((item, index) => {
            const handleRemoveAssessment = () => {
              setSelectedCandidates((prevSelected) =>
                prevSelected.filter((candidate) => candidate?._id !== item._id)
              );
            };
            return (
              <div
                key={index}
                className="flex items-center justfiy-center bg-light-white-700 rounded-md px-2 py-1 gap-3"
              >
                <span className="font-medium text-xs text-primary">
                  {item.firstName} {item.lastName}
                </span>
                <span onClick={handleRemoveAssessment}>
                  <CloseIcon className={"w-3.5 h-3.5 cursor-pointer"} />
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-6">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="select-all"
            checked
            className="h-4 w-4 border rounded-sm border-primary"
          />
          <span className="font-medium text-primary text-sm">
            Share assessment link to candidate’s message box
          </span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-4 h-4 rounded-sm border border-primary" />
          <span className="font-medium text-primary text-sm">
            Share assessment link to candidate’s email address
          </span>
        </div>
      </div>

      {/* Link input container */}
      <div className="flex itesm-center p-2 bg-light-white-400 mt-5 rounded-md w-max gap-2">
        <LinkIcon className={"w-4 h-4"} />
        <input
          placeholder="example.com/share.link"
          className="bg-transparent outline-none text-xs w-64"
        />
        <CopyIcon className={"w-4 h-4"} />
      </div>

      <button
        onClick={handleSend}
        className="bg-primary rounded-md self-end mt-4 text-white font-medium text-sm px-4 py-1.5 hover:bg-black"
      >
        Send
      </button>
    </div>
  );
};

export default ShareAssessmentLink;
