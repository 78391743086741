import * as React from "react";
const CrossBig = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      stroke="#2E2E2E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.779}
      d="M1.945 1.948 21.397 21.4m-19.452 0L21.397 1.948"
    />
  </svg>
);
export default CrossBig;
