import { useEffect, useState } from "react";

const ChevronDownFilled = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.99997 9C6.80222 9.00004 6.60892 9.05871 6.44451 9.1686C6.2801 9.27848 6.15196 9.43465 6.07629 9.61735C6.00062 9.80005 5.98082 10.0011 6.01939 10.195C6.05795 10.389 6.15316 10.5671 6.29297 10.707L11.293 15.707C11.4805 15.8945 11.7348 15.9998 12 15.9998C12.2651 15.9998 12.5194 15.8945 12.707 15.707L17.707 10.707C17.8468 10.5671 17.942 10.389 17.9806 10.195C18.0191 10.0011 17.9993 9.80005 17.9236 9.61735C17.848 9.43465 17.7198 9.27848 17.5554 9.1686C17.391 9.05871 17.1977 9.00004 17 9H6.99997Z"
        fill={fill}
      />
    </svg>
  );
};

export default ChevronDownFilled;
