import dayjs from "dayjs";
import { getDayWithOrdinal } from "../../../utils/ordinal";
import Divider from "../../../components/Divider/Divider";
import PhoneIcon from "../../../assets/svg/PhoneIcon";
import LaptopIcon from "../../../assets/svg/LaptopIcon";
import InterviewIcon from "../../../assets/svg/InterviewIcon";
import { ScheduledMeeting } from "./dummyData";

const ScheduleCard = ({ item, isLast }) => {
  const isToday = dayjs(item.meetingDate).isSame(dayjs(), "day");
  const day = dayjs(item.meetingDate).format("D");

  return (
    <div className={`flex ${isLast ? "" : "mb-3"}`}>
      <div style={{ flex: 1 }}>
        <label
          className={`font-semibold text-sm leading-6 justify-center flex items-center h-full ${
            isToday ? "text-secondary" : "text-primary"
          }`}
        >
          {getDayWithOrdinal(day)}
        </label>
      </div>
      <div
        style={{ flex: 9 }}
        className={`${
          isToday ? "border-[#FF9800] bg-[#FFFCF7]" : "bg-[#FAFAFA]"
        } flex border justify-between items-center py-1.5 rounded-md pl-6 pr-5 hover:bg-white cursor-pointer`}
      >
        <div className="flex flex-grow-0 flex-shrink-0 w-[30%]">
          {item.acceptedSchedule ? (
            <label className="font-semibold text-xs text-primary">
              Pending Acceptance{" "}
              <label className="font-semibold text-xs text-secondary">
                ({item.acceptedSchedule}/{item.totalSchedule})
              </label>
            </label>
          ) : (
            <label className="font-semibold text-xs text-primary">
              Meeting Scheduled
            </label>
          )}
        </div>
        <div className="flex flex-grow-0 flex-shrink-0 w-1/2">
          <label className="font-medium text-xs text-primary">
            {item.interviews}
          </label>
        </div>
        <div className="flex flex-grow-0 flex-shrink-0 w-[10%] italic">
          <label className="font-medium text-xs text-primary">
            {item.meetingTime}
          </label>
        </div>
        <div className="flex justify-end flex-grow-0 flex-shrink-0 w-[10%]">
          {item.meetingType === "call" && (
            <PhoneIcon height={"20px"} width={"20px"} />
          )}
          {item.meetingType === "online" && (
            <LaptopIcon height={"20px"} width={"20px"} />
          )}
          {item.meetingType === "facetoface" && (
            <InterviewIcon height={"20px"} width={"20px"} />
          )}
        </div>
      </div>
    </div>
  );
};

const ScheduleSection = () => {
  const groupedMeetings = ScheduledMeeting.reduce((acc, meeting) => {
    const year = dayjs(meeting.meetingDate).format("YYYY");
    const month = dayjs(meeting.meetingDate).format("MMMM");

    if (!acc[year]) {
      acc[year] = {};
    }
    if (!acc[year][month]) {
      acc[year][month] = [];
    }
    acc[year][month].push(meeting);

    return acc;
  }, {});

  return (
    <div className="items-center w-full pl-[42px] pr-[50px]">
      <div className="flex items-center justify-center w-full pb-1 gap-6">
        <label className="font-semibold text-sm text-primary">
          Meeting schedules
        </label>
      </div>

      <div className="flex-grow">
        {Object.keys(groupedMeetings).map((year, yearIndex) => (
          <div key={year}>
            {yearIndex > 0 && <Divider className="my-10 bg-[#DDDDDD]" />}
            <label className="font-semibold text-sm text-primary leading-6 pl-2.5">
              {year}
            </label>

            {Object.keys(groupedMeetings[year]).map((month, monthIndex) => (
              <div key={month}>
                <div
                  className={`flex gap-4 items-center pb-5 ${
                    monthIndex > 0 ? "pt-6" : ""
                  }`}
                >
                  <label className="w-[8%] font-semibold text-sm text-primary leading-6">
                    {month}
                  </label>
                  <Divider className="bg-[#DDDDDD]" />
                </div>
                {groupedMeetings[year][month].map((meeting, index) => (
                  <ScheduleCard
                    key={index}
                    item={meeting}
                    isLast={index === groupedMeetings[year][month].length - 1}
                  />
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleSection;
