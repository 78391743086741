import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import QuillEditor from "../../../components/CreateQuestion/Question/QuillEditor";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const About = forwardRef(({ isEditing, setData, handleNext }, ref) => {
  const { user } = useSelector((state) => state.auth);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, dirtyFields },
    watch,
  } = useForm({
    defaultValues: {
      about: user?.about,
    },
  });

  const states = watch();

  useImperativeHandle(ref, () => handleSubmit(handleNext));

  useEffect(() => {
    if (Object?.keys(dirtyFields)?.length > 0) {
      setData((prev) => ({
        ...prev,
        about: { ...prev?.about, isFilled: true },
      }));
    } else {
      setData((prev) => ({
        ...prev,
        about: {
          ...prev?.about,
          isEditing: true,
          isFilled: false,
          isCompleted: user?.about ? true : false,
        },
      }));
    }
  }, [Object?.keys(dirtyFields)?.length]);

  return (
    <div>
      {!isEditing ? (
        <div className="h-full flex flex-1 flex-col overflow-y-scroll no-scrollbar w-4/5">
          <div className="flex flex-col">
            <span className="font-montserrat text-[#383838] font-bold text-sm mb-8">
              About
            </span>
            <div
              className="font-montserrat text-primary font-medium text-xs leading-5 mb-3"
              dangerouslySetInnerHTML={{ __html: states?.about }}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <span className="font-montserrat text-[#383838] font-bold text-sm mb-8">
            About
          </span>
          <div className="mr-4">
            <Controller
              name={"about"}
              control={control}
              render={({ field }) => {
                return (
                  <div className="flex flex-col gap-1">
                    <QuillEditor {...field} />
                    {errors["about"]?.message && (
                      <span className="whitespace-pre text-xs mt-2 text-orange-700">
                        {errors["about"]?.message}
                      </span>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default About;
