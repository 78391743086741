export default function handleError(err) {
  if (
    err.response &&
    err.response.data &&
    (err.response.data.err ||
      err.response?.data?.message ||
      err.response?.data?.data?.message)
  ) {
    throw (
      err.response.data?.err ||
      err.response.data?.message ||
      err.response.data?.data?.message
    );
  } else {
    throw err.message || "Something went wrong!";
  }
}
