import { useEffect, useState } from "react"

const ChevronLeftIcon = ({ className }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || '';
    if (!classStr.includes('w-')) {
      classStr += ' w-5';
    }
    if (!classStr.includes('h-')) {
      classStr += ' h-5';
    }

    setClass(classStr);
  }, [className])

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={_class}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
    </svg>
  )
}

export default ChevronLeftIcon;
