import { useEffect, useState } from "react";

const ReferIcon = ({ className, fill = "white" }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 35 35"
      stroke-width="0.5"
      class={_class}
    >
      <g clip-path="url(#clip0_5824_15033)">
        <path
          d="M16.0095 17.9435C16.0095 16.5072 15.1901 15.2588 13.9942 14.641C14.6431 14.1342 15.0615 13.3453 15.0615 12.4601C15.0615 10.9345 13.8204 9.69336 12.2948 9.69336C10.7693 9.69336 9.5281 10.9345 9.5281 12.4601C9.5281 13.3453 9.94649 14.1342 10.5954 14.641C9.39949 15.2588 8.58008 16.5072 8.58008 17.9435C8.58008 18.2191 8.80348 18.4425 9.07907 18.4425H15.5105C15.7861 18.4425 16.0095 18.2191 16.0095 17.9435ZM9.624 17.4445C9.85896 16.1841 10.9672 15.2268 12.2948 15.2268C13.6224 15.2268 14.7306 16.1841 14.9656 17.4445H9.624ZM10.526 12.4601C10.526 11.4848 11.3195 10.6913 12.2948 10.6913C13.27 10.6913 14.0635 11.4848 14.0635 12.4601C14.0635 13.4353 13.27 14.2288 12.2948 14.2288C11.3195 14.2288 10.526 13.4354 10.526 12.4601Z"
          fill="#2E2E2E"
          stroke="#4D4D4D"
          stroke-width="0.170323"
        />
        <path
          d="M22.6787 20.3848C23.6629 19.7411 24.3146 18.6293 24.3146 17.368C24.3146 15.3817 22.6986 13.7656 20.7122 13.7656C18.7259 13.7656 17.1098 15.3817 17.1098 17.368C17.1098 18.6293 17.7615 19.7411 18.7458 20.3848C17.0204 21.1439 15.8125 22.8694 15.8125 24.8721C15.8125 25.1477 16.0359 25.3711 16.3115 25.3711H25.113C25.3885 25.3711 25.6119 25.1477 25.6119 24.8721C25.6119 22.8694 24.4041 21.1439 22.6787 20.3848ZM16.8422 24.3731C17.0879 22.4564 18.7298 20.9704 20.7122 20.9704C22.6946 20.9704 24.3365 22.4565 24.5822 24.3731H16.8422ZM23.3166 17.368C23.3166 18.8041 22.1483 19.9724 20.7122 19.9724C19.2761 19.9724 18.1078 18.8041 18.1078 17.368C18.1078 15.932 19.2761 14.7636 20.7122 14.7636C22.1483 14.7636 23.3166 15.932 23.3166 17.368Z"
          fill="#2E2E2E"
          stroke="#4D4D4D"
          stroke-width="0.170323"
        />
        <path
          d="M12.9678 21.3535C12.773 21.5483 12.773 21.8643 12.9678 22.0591L13.25 22.3413H11.0134C10.2207 22.3413 9.57806 21.6987 9.57806 20.906V19.9697C9.57806 19.6941 9.35466 19.4707 9.07907 19.4707C8.80348 19.4707 8.58008 19.6941 8.58008 19.9697V21.405C8.58008 22.4733 9.44609 23.3393 10.5144 23.3393H13.25L12.9678 23.6215C12.773 23.8164 12.773 24.1323 12.9678 24.3272C13.1627 24.522 13.4786 24.522 13.6735 24.3272L14.7924 23.2082C14.9957 23.005 14.9957 22.6756 14.7924 22.4724L13.6735 21.3534C13.4786 21.1586 13.1627 21.1586 12.9678 21.3535Z"
          fill="#2E2E2E"
          stroke="#4D4D4D"
          stroke-width="0.170323"
        />
      </g>
      <defs>
        <clipPath id="clip0_5824_15033">
          <rect
            width="18.7355"
            height="17.0323"
            fill="white"
            transform="translate(7.72852 9.0166)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReferIcon;
