import React from "react";

import "twin.macro";
import tw, { styled } from "twin.macro";
import { colors } from "../../utils/theme";

export const Container = styled.div(({}) => [
  tw`border rounded-full w-4 h-4 p-0.5`,
  `border-color: ${colors.PrimaryBlack}`,
]);

export const InnerContainer = styled.div(({}) => [
  tw`flex flex-1 w-full h-full rounded-full`,
  `background-color:${colors.PrimaryBlack}`,
]);

export const RowContainer = tw.div`flex gap-4`;

const RadioCircleUI = ({ checked = false, onClick }) => {
  return (
    <Container onClick={onClick}>{checked && <InnerContainer />}</Container>
  );
};

export default RadioCircleUI;
