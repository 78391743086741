import { useState } from "react";
import CloseIcon from "../../../assets/svg/CloseIcon";
import CopyIcon from "../../../assets/svg/CopyIcon";
import LinkIcon from "../../../assets/svg/LinkIcon";
import Modal from "../../../components/Modal/Modal";
import { useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput";
import regexList from "../../../utils/regexList";
import ChevronDown from "../../../assets/svg/ChevronDown";
import SearchIcon from "../../../assets/svg/SearchIcon";
import { useParams } from "react-router-dom";
import DotLoader from "../../../components/DotLoader/DotLoader";
import TickThinOutline from "../../../assets/svg/TickThinOutline";
import { shareJob } from "../../../endpoints/job.service";
import { useSnackbar } from "notistack";

const options = [
  {
    label: "Share job description with registered members",
    value: 1,
  },
  {
    label: "Share job description via email address",
    value: 2,
  },
];

const ShareToRegisteredMembers = ({ onCloseForm }) => {
  const assessmentList = [
    {
      _id: 0,
      refNo: "CA-1234GH",
      name: "Cameron Williamson",
      emailAddress: "tim.jennings@example.com",
      status: "Open to work",
    },
    {
      _id: 1,
      refNo: "CA-1234GH",
      name: "Cameron Williamson",
      emailAddress: "tim.jennings@example.com",
      status: "Open to work",
    },
    {
      _id: 2,
      refNo: "CA-1234GH",
      name: "Cameron Williamson",
      emailAddress: "tim.jennings@example.com",
      status: "Open to work",
    },
    {
      _id: 3,
      refNo: "CA-1234GH",
      name: "Cameron Williamson",
      emailAddress: "tim.jennings@example.com",
      status: "Open to work",
    },
    {
      _id: 4,
      refNo: "CA-1234GH",
      name: "Cameron Williamson",
      emailAddress: "tim.jennings@example.com",
      status: "Open to work",
    },
    {
      _id: 5,
      refNo: "CA-1234GH",
      name: "Cameron Williamson",
      emailAddress: "tim.jennings@example.com",
      status: "Open to work",
    },
    {
      _id: 6,
      refNo: "CA-1234GH",
      name: "Cameron Williamson",
      emailAddress: "tim.jennings@example.com",
      status: "Open to work",
    },
    {
      _id: 7,
      refNo: "CA-1234GH",
      name: "Cameron Williamson",
      emailAddress: "tim.jennings@example.com",
      status: "Open to work",
    },
    {
      _id: 8,
      refNo: "CA-1234GH",
      name: "Cameron Williamson",
      emailAddress: "tim.jennings@example.com",
      status: "Open to work",
    },
    {
      _id: 9,
      refNo: "CA-1234GH",
      name: "Cameron Williamson",
      emailAddress: "tim.jennings@example.com",
      status: "Open to work",
    },
  ];
  const [selectedAssessments, setSelectedAssessments] = useState([]);

  const allSelected =
    assessmentList.length > 0 &&
    selectedAssessments.length === assessmentList.length;

  const handleSelectAssessment = (item) => {
    setSelectedAssessments((prevSelected) => {
      const isSelected = prevSelected.some(
        (assessment) => assessment._id === item._id
      );

      if (isSelected) {
        return prevSelected.filter((assessment) => assessment._id !== item._id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedAssessments(assessmentList);
    } else {
      setSelectedAssessments([]);
    }
  };
  const handleCloseForm = () => {
    onCloseForm && onCloseForm();
  };

  return (
    <div className="flex flex-col">
      {/* Header */}
      <div className="flex items-center justify-between">
        <span className="font-semibold text-primary">
          Select members to share job description
        </span>
        <button onClick={handleCloseForm}>
          <CloseIcon className="w-6 h-6 ml-auto" />
        </button>
      </div>

      {/* Input container */}
      <div className="flex rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5 mt-4">
        <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-2">
          <label className="text-primary text-sm font-medium">Search by</label>
          <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
        </button>
        <input
          className="bg-white text-xs font-medium text-light-black outline-none px-3 py-2 rounded-lg w-[34rem]"
          placeholder="Search..."
        />
        <SearchIcon className={"w-6 h-6"} fill={"#2e2e2e"} />
      </div>

      {/* Table container */}
      <div className="mt-3.5 border rounded-md">
        <div className="grid grid-cols-[8%_0.5fr_1fr_1.2fr_1fr] border-b py-3 gap-5">
          <div className="flex items-center justify-center">
            <input
              type="checkbox"
              id="select-all"
              checked={allSelected}
              onChange={handleSelectAll}
              className="h-4 w-4 border rounded-sm border-primary"
            />
          </div>
          <span className="font-semibold text-primary text-xs">Reference</span>
          <span className="font-semibold text-primary text-xs">Name</span>
          <span className="font-semibold text-primary text-xs">
            Email address
          </span>
          <span className="font-semibold text-primary text-xs">Status</span>
        </div>

        <div className="overflow-y-scroll max-h-fit no-scrollbar">
          {assessmentList?.map((item) => {
            const isSelected = selectedAssessments.some(
              (assessment) => assessment._id === item._id
            );
            return (
              <div
                key={item.id}
                className="grid grid-cols-[8%_0.5fr_1fr_1.2fr_1fr] py-3 gap-5"
              >
                <div className="flex items-center justify-center">
                  <input
                    type="checkbox"
                    id={`assessment-${item._id}`}
                    checked={isSelected}
                    onChange={() => handleSelectAssessment(item)}
                    className={`h-4 w-4 border rounded-sm ${
                      isSelected ? "bg-primary" : "border-primary"
                    }`}
                  />
                </div>
                <span className="font-medium text-primary text-xs">
                  {item.refNo}
                </span>
                <span className="font-medium text-primary text-xs">
                  {item.name}
                </span>
                <span className="font-medium text-primary text-xs">
                  {item.emailAddress}
                </span>
                <span className="font-medium text-primary text-xs">
                  {item.status}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      {/* Footer */}
      <div className="flex justify-end mt-3">
        <button className="rounded-md bg-gray-500 text-white px-5 py-1.5 font-semibold text-xs">
          Next
        </button>
      </div>
    </div>
  );
};

const InputField = () => {
  const { jobId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { firstName: "", lastName: "", email: "" },
  });
  const [isSending, setIsSending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSave = async (data) => {
    const payload = {
      jobId: jobId,
      sharedType: 2,
      email: data?.email,
      receiverFirstName: data?.firstName,
      receiverLastName: data?.lastName,
    };
    setIsSuccess(false);
    setIsError(false);
    setIsSending(true);
    try {
      const { success, data } = await shareJob(payload);
      if (success) {
        setIsSuccess(true);
        enqueueSnackbar(data?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      setIsError(true);
      enqueueSnackbar(error, {
        variant: "error",
      });
    } finally {
      setIsSending(false);
    }
    console.log("====================================");
    console.log("data check", payload);
    console.log("====================================");
  };

  return (
    <form
      onSubmit={handleSubmit(handleSave)}
      className="grid grid-cols-[1fr_1fr_1.5fr_0.5fr] items-center mt-2 rounded-md w-full gap-3 pr-10"
    >
      <CustomInput
        name={`firstName`}
        placeholder="Recipient's first name"
        rules={{ required: "First name is required." }}
        control={control}
        errors={errors}
      />
      <CustomInput
        name={`lastName`}
        placeholder="Recipient's last name"
        rules={{ required: "Last name is required." }}
        control={control}
        errors={errors}
      />
      <CustomInput
        name={`email`}
        placeholder="Enter email address"
        rules={{
          required: "Email address is required.",
          pattern: {
            value: regexList.email,
            message: "Please enter a valid email address.",
          },
        }}
        control={control}
        errors={errors}
      />
      <button
        type="submit"
        className="flex items-center gap-2 text-primary text-xs font-medium"
      >
        {isSending ? "Sending" : isSuccess ? "Sent" : "Send"}
        {isSuccess ? (
          <TickThinOutline className={"w-5 h-5"} fill={"red"} />
        ) : null}
        {isSending ? <DotLoader /> : null}
      </button>
    </form>
  );
};

const ShareViaEmail = ({ onCloseForm }) => {
  const handleCloseForm = () => {
    onCloseForm && onCloseForm();
  };

  // // Individual field submission handler
  // const handleIndividualSubmit = (index, data) => {
  //   console.log(`Field ${index + 1} Data:`, data.recipients[index]);
  //   // Add API call or any other action here for this specific recipient
  // };

  return (
    <div className="flex flex-col w-[58rem]">
      {/* Header */}
      <div className="flex items-center justify-between">
        <span className="font-semibold text-primary">
          Share job description via email address for unregistered members
        </span>
        <button
          className="hover:bg-gray-100 w-max ml-auto rounded-md"
          onClick={handleCloseForm}
        >
          <CloseIcon className={"w-6 h-6 ml-auto"} />
        </button>
      </div>

      {/* Recipients Input Container */}
      <div className="my-4">
        {Array.from({ length: 5 }).map((_, index) => (
          <InputField key={_} />
        ))}
      </div>

      {/* Footer */}
      <div className="flex justify-end mt-3">
        <button
          className="rounded-md bg-gray-500 text-white px-5 py-1.5 font-semibold text-xs"
          onClick={handleCloseForm}
        >
          Close
        </button>
      </div>
    </div>
  );
};

// const ShareViaEmail = () => {
//   const {
//     handleSubmit,
//     control,
//     formState: { errors },
//   } = useForm({
//     defaultValues: {
//       recipients: [{ firstName: "", lastName: "", email: "" }], // Ensure recipients array exists
//     },
//   });

//   const { fields, append, remove } = useFieldArray({
//     control,
//     name: "recipients", // Must match the name in defaultValues
//   });

//   const onSubmit = (data) => {
//     console.log("Form Data:", data);
//   };

//   return (
//     <div className="flex flex-col">
//       {/* Header */}
//       <div className="flex items-center">
//         <span className="font-semibold text-primary">
//           Share job description via email address for unregistered members
//         </span>
//         <CloseIcon className="w-6 h-6 ml-auto" />
//       </div>

//       {/* Recipients Input Container */}
//       <div className="my-4">
//         {fields.map((field, index) => (
//           <div
//             key={field.id}
//             className="flex items-center mt-2 rounded-md w-full gap-2 pr-20"
//           >
//             <CustomInput
//               name={`recipients.${index}.firstName`}
//               placeholder="Recipient's first name"
//               rules={{ required: "First name is required." }}
//               control={control}
//               errors={errors?.recipients?.[index]?.firstName} // Optional chaining
//             />
//             <CustomInput
//               name={`recipients.${index}.lastName`}
//               placeholder="Recipient's last name"
//               rules={{ required: "Last name is required." }}
//               control={control}
//               errors={errors?.recipients?.[index]?.lastName} // Optional chaining
//             />
//             <CustomInput
//               name={`recipients.${index}.email`}
//               placeholder="Enter email address"
//               rules={{
//                 required: "Email address is required.",
//                 pattern: {
//                   value: regexList.email,
//                   message: "Please enter a valid email address.",
//                 },
//               }}
//               control={control}
//               errors={errors?.recipients?.[index]?.email} // Optional chaining
//             />
//             <button
//               type="button"
//               className="font-medium text-light-black text-xs"
//               onClick={() => remove(index)}
//             >
//               Remove
//             </button>
//           </div>
//         ))}
//         <button
//           type="button"
//           className="mt-3 text-primary text-xs font-medium"
//           onClick={() => append({ firstName: "", lastName: "", email: "" })}
//         >
//           Add Recipient
//         </button>
//       </div>

//       {/* Footer Buttons */}
//       <div className="flex justify-end mt-3">
//         <button
//           onClick={handleSubmit(onSubmit)}
//           className="rounded-md bg-primary text-white px-5 py-1.5 font-semibold text-xs"
//         >
//           Submit
//         </button>
//       </div>
//     </div>
//   );
// };
// const ShareViaEmail = () => {
//   const {
//     handleSubmit,
//     control,
//     formState: { errors },
//     setValue,
//     watch,
//   } = useForm({
//     defaultValues: {
//       isLoading: false,
//     },
//   });
//   return (
//     <div className="flex flex-col">
//       <div className="flex items-center gap-96">
//         <span className="font-semibold text-primary">
//           Share job description via email address for unregistered members
//         </span>

//         <CloseIcon className={"w-6 h-6 ml-auto"} />
//       </div>

//       {/* List input container */}
//       <div className="my-4">
//         {[1, 2, 3, 4]?.map((item) => {
//           return (
//             <div
//               key={item}
//               className="flex itesm-center mt-2 rounded-md w-full gap-2 pr-20"
//             >
//               <CustomInput
//                 name="firstName"
//                 placeholder={"Recipient's first name"}
//                 rules={{
//                   required: "This field is required.",
//                 }}
//                 control={control}
//                 errors={errors}
//               />
//               <CustomInput
//                 name="lastName"
//                 placeholder={"Recipient's last name"}
//                 rules={{
//                   required: "This field is required.",
//                 }}
//                 control={control}
//                 errors={errors}
//               />
//               <CustomInput
//                 name="email"
//                 placeholder={"Enter email address"}
//                 rules={{
//                   required: "This field is required.",
//                   pattern: {
//                     value: regexList.email,
//                     message: "Please enter a valid email.",
//                   },
//                 }}
//                 control={control}
//                 errors={errors}
//               />
//               <button
//                 onClick={() => handleSubmit()}
//                 className="font-medium text-light-black text-xs"
//               >
//                 Send
//               </button>
//             </div>
//           );
//         })}
//       </div>

//       {/* Options container */}

//       {/* Button container */}
//       <button className="rounded-md bg-primary text-white px-5 py-1.5 font-semibold text-xs ml-auto mt-3">
//         Close
//       </button>
//     </div>
//   );
// };

const ShareJobModal = ({ showShareJobModal, setShowShareJobModal }) => {
  const [selectedOption, setSelectedOption] = useState();
  const [savedOption, setSavedOption] = useState();

  const onCloseForm = () => {
    setShowShareJobModal(false);
    setSelectedOption();
    setSavedOption();
  };

  return (
    <Modal isOpen={showShareJobModal} containerStyle={"p-6 w-max"}>
      {savedOption === 1 ? (
        <ShareToRegisteredMembers onCloseForm={onCloseForm} />
      ) : savedOption === 2 ? (
        <ShareViaEmail onCloseForm={onCloseForm} />
      ) : (
        <div className="flex flex-col w-max">
          <button
            className="hover:bg-gray-100 w-max ml-auto rounded-md"
            onClick={() => setShowShareJobModal(false)}
          >
            <CloseIcon className={"w-6 h-6 ml-auto"} />
          </button>
          <span className="font-semibold text-primary">Share Job</span>

          {/* Link input container */}
          <div className="flex itesm-center p-2 bg-light-white-400 mt-2 rounded-md w-full gap-2">
            <LinkIcon className={"w-4 h-4"} />
            <input
              placeholder="example.com/share.link"
              className="bg-transparent outline-none text-xs flex-1"
              value={"jobdescriptionshareexample.com/share.link"}
            />
            <CopyIcon className={"w-4 h-4"} />
          </div>

          {/* Options container */}
          <div className="flex flex-col my-4 gap-4">
            {options?.map((option) => {
              const isSelected = selectedOption === option?.value;
              return (
                <button
                  key={option?.value}
                  onClick={() => setSelectedOption(option?.value)}
                  className="flex items-center gap-2"
                >
                  <div className="flex h-4 w-4 rounded-full border border-1 border-light-black">
                    {isSelected ? (
                      <div className="h-2.5 w-2.5 rounded-full bg-light-black m-auto" />
                    ) : null}
                  </div>
                  <span className="font-medium text-light-black text-sm">
                    {option?.label}
                  </span>
                </button>
              );
            })}
          </div>

          {/* Button container */}
          <button
            disabled={!selectedOption}
            onClick={() => setSavedOption(selectedOption)}
            className={`rounded-md bg-primary text-white px-3 py-1.5 font-semibold text-xs ml-auto mt-3 ${selectedOption ? 'opacity-100':'opacity-50'}`}
          >
            Continue
          </button>
        </div>
      )}
    </Modal>
  );
};

export default ShareJobModal;
