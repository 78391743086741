import * as React from "react";
const GreenCheckmark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15px"
    height="15px"
    viewBox="0 0 512 512"
    fill="#23BD33"
    {...props}
  >
    <path d="M237 .7c-37.1 3.5-65.3 11.4-96.1 26.9C100.3 47.9 62.7 82.4 38 122 20.1 150.6 7.1 187.5 1.8 224.5c-1.7 11.8-1.7 51.2 0 63 4.2 29.5 12 55.3 24.2 80 13.7 28 27.2 46.8 49.4 69.1 22.1 22 41.2 35.7 68.1 48.9 18.8 9.2 30.9 13.7 50 18.5 50 12.6 100.2 10.2 149-7.1 15-5.3 40.8-18.3 54-27.1 30.2-20.2 57.3-48 76.9-78.8 7.2-11.3 18.8-35.3 23.5-48.6 23.5-66.2 19.4-137.2-11.5-199.3-12.4-25-26.3-44.7-45.8-64.8-21.4-22.2-43-37.9-71.1-51.8C338.9 11.9 311.3 4 278.5 1 268.3.1 245.2-.1 237 .7zm54.5 34.2c62.8 10.6 115.8 44.8 151.1 97.7 17.9 26.7 29.6 57.3 35.1 90.9 2.4 15.3 2.4 49.7 0 65-10.8 66.8-47.4 122.2-104.2 158-24.3 15.3-53.7 26.1-85 31.2-15.4 2.4-49.7 2.4-65-.1C157.8 466.9 103.7 432 67.8 377c-16.8-25.7-28.1-55.7-33.5-88.5-2.4-15.3-2.4-49.7 0-65 8.1-49.7 29.1-91.2 63.6-125.5 38.2-38.1 86.2-60.5 140.1-65.4 10.8-1 41.8.4 53.5 2.3z" />
    <path d="M362 161c-1.4.6-36.6 35.1-78.2 76.8L208 313.5l-28.2-28.2c-30.5-30.4-30.7-30.5-39.5-28.8-4.9.9-10.9 6.9-11.8 11.8-1.7 9-2.3 8.3 36.3 46.9 38.6 38.6 37.8 38 46.9 36.3 3.4-.6 13.2-10 87.1-83.8 60.5-60.5 83.5-84.1 84.3-86.6 2.2-6.7-.2-13.9-6.3-18.4-3.1-2.3-11.1-3.2-14.8-1.7z" />
  </svg>
);
export default GreenCheckmark;
