import {
  REMOVE_TOKEN,
  LOGIN_USER,
  SET_AUTH_LOADING,
  AUTH_USER_UPDATE,
  SET_USER_DATA,
} from "./action.types";

import { refreshTokenService } from "../../endpoints/auth.service";

interface User {
  user: any;
  companyDetails?: any;
  token: string;
  refreshToken: string;
}

interface SetUserDataPayload {
  user?: any;
  experience?: any;
}

interface AuthLoadingPayload {
  status: boolean;
}

export const removeToken = () => {
  return {
    type: REMOVE_TOKEN,
  };
};

export const tokenValidateSuccess = (payload: User) => {
  return {
    type: LOGIN_USER,
    payload: payload,
  };
};

export const refreshToken = (token: string) => async (dispatch: any) => {
  try {
    const { data } = await refreshTokenService(token);
    dispatch({
      type: LOGIN_USER,
      payload: {
        user: data.user,
        companyDetails: data?.companyDetails,
        token: data.token,
        refreshToken: data.refreshToken,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    dispatch(removeToken());
    return null;
  }
};

export const setUserData = (payload: SetUserDataPayload) => {
  return {
    type: SET_USER_DATA,
    payload: payload,
  };
};

export const authUserUpdate = (user: any) => {
  return {
    type: AUTH_USER_UPDATE,
    payload: user,
  };
};

export const setAuthLoading = (status: boolean) => {
  return {
    type: SET_AUTH_LOADING,
    payload: status,
  };
};
