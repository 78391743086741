import React from "react";

const VerificationCardSkeleton = () => {
  return (
    <div className="flex flex-col my-20 border rounded-xl p-5 w-2/3 h-max m-auto bg-white gap-10">
      <div className="flex flex-col gap-2">
        <div className="rounded-full h-3 w-32 bg-gray-100 animate-sequential-blink" />
        <div className="rounded-full h-3 w-14 bg-gray-100 animate-sequential-blink" />
        <div className="rounded-full h-3 w-12 bg-gray-100 animate-sequential-blink" />
      </div>
      <div className="flex flex-col gap-2">
        <div className="rounded-full h-3 w-20 bg-gray-100 animate-sequential-blink" />
        <div className="rounded-full h-3 w-52 bg-gray-100 animate-sequential-blink" />
        <div className="rounded-full h-3 w-64 bg-gray-100 animate-sequential-blink" />
      </div>

      <div className="flex flex-col gap-2">
        <div className="rounded-full h-3 w-20 bg-gray-100 animate-sequential-blink" />
        <div className="rounded-full h-3 w-52 bg-gray-100 animate-sequential-blink" />
        <div className="rounded-full h-3 w-64 bg-gray-100 animate-sequential-blink" />
      </div>

      <div className="flex flex-col gap-2">
        <div className="rounded-full h-3 w-20 bg-gray-100 animate-sequential-blink" />
        <div className="rounded-full h-3 w-52 bg-gray-100 animate-sequential-blink" />
        <div className="rounded-full h-3 w-96 bg-gray-100 animate-sequential-blink" />
      </div>
    </div>
  );
};

export default VerificationCardSkeleton;
