import React from "react";
import { jobs } from "../dummyData";
import { colors } from "../../../../utils/theme";
import LikeIconLeft from "../../../../assets/svg/LikeIconLeft";
import ReferIcon from "../../../../assets/svg/ReferIcon";
import TrackingIcon from "../../../../assets/svg/TrackingIcon";
import ShareIconOutline from "../../../../assets/svg/ShareIconOutline";

const JobItem = (job) => {
  return (
    <div className={`py-4 px-3 rounded-sm bg-[${colors.BackgroundGray}]`}>
      <div className="flex items-center">
        <span className="flex flex-1 font-montserrat text-primary font-semibold text-base">
          {job.title}
        </span>
        <span className="font-montserrat text-light-black italic font-semibold text-xs">
          {job._id}
        </span>
      </div>
      <div className="flex mt-4 gap-5 pb-5 flex-col md:flex-row border-b border-b-[#DDDDDD]">
        <div className="flex flex-col gap-1.5 md:w-5/12">
          <span className="font-montserrat text-primary font-normal text-sm">
            {job.location}
          </span>
          <span className="font-montserrat text-primary font-normal text-xs">
            {job.price}
          </span>
          <span className="font-montserrat text-primary font-normal text-sm">
            {job.jobType}
          </span>
          <span className="font-montserrat text-primary font-normal text-sm mt-3">
            {job.about}
          </span>
        </div>
        <span className="flex md:w-8/12 font-montserrat text-primary leading-5 font-medium text-xs text-justify">
          {job.description}
        </span>
      </div>
      <div className="flex mt-4 gap-4 items-center">
        <button className="flex items-center bg-[#FFFFFF] justify-center gap-3 border text-xs text-primary rounded-md px-2 py-1.5 font-medium w-max">
          View job
        </button>
        <button className="flex items-center bg-[#FFFFFF] justify-center gap-3 border text-xs text-primary rounded-md px-2 py-1.5 font-medium w-max">
          Quick apply
        </button>
        <div className="flex flex-1 gap-8 justify-end">
          <div className="group flex flex-col justify-between items-center cursor-pointer h-9">
            <LikeIconLeft className="w-7 h-7 mt-0.5" fill={"#2E2E2E"} />
            <span className="font-medium uppercase text-[8px] text-light-black">
              Likes
            </span>
          </div>
          <div className="group flex flex-col justify-between items-center cursor-pointer h-9">
            <ReferIcon className="w-6 h-6" />
            <span className="font-medium uppercase text-[8px] text-light-black">
              Refer
            </span>
          </div>
          <div className="group flex flex-col justify-between items-center cursor-pointer h-9">
            <TrackingIcon className={"w-6 h-6"} fill={"#2E2E2E"} />
            <span className="font-medium uppercase text-[8px] text-light-black">
              Track
            </span>
          </div>
          <div className="group flex flex-col justify-between items-center cursor-pointer h-9">
            <ShareIconOutline className="w-6 h-6" />
            <span className="font-medium uppercase text-[8px] text-light-black">
              Share
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Jobs = () => {
  return (
    <div className="flex flex-col gap-6">
      {jobs.map((job) => (
        <JobItem key={job._id} {...job} />
      ))}
    </div>
  );
};

export default Jobs;
