import React, { useState, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { JobSkills, skillsUsed } from "../../../utils/constants";
import {
  addSkill,
  updateSkill,
  deleteSkill,
  searchSkill,
  getSkills,
} from "../../../endpoints/skill.service";
import { colors } from "../../../utils/theme";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";

const ReusableInput = ({
  type = "text",
  placeholder = "Skill",
  value,
  onChange,
  isValid = true,
  options = [],
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const handleTextChange = async (text) => {
    onChange(text);
    if (type === "skill") {
      try {
        const { success, data } = await searchSkill(text);
        setSuggestions(success ? data : []);
        setShowSuggestions(success && data?.length > 0);
      } catch {
        setShowSuggestions(false);
        setSuggestions([]);
      }
    }
  };

  const renderSuggestions = () => {
    const suggestionTypes = {
      skill: () =>
        suggestions?.map((item) => (
          <div
            key={item?._id}
            onClick={() => {
              onChange(item?.title);
              setShowSuggestions(false);
            }}
            className="hover:bg-gray-100 px-4 py-2 cursor-pointer"
          >
            <span className="font-medium text-xs text-primary">
              {item?.title}
            </span>
          </div>
        )),
      lastUsed: () =>
        Object.entries(options)?.map(([key]) => (
          <div
            key={key}
            onClick={() => {
              onChange(key);
              setShowSuggestions(false);
            }}
            className="hover:bg-gray-100 px-4 py-1 cursor-pointer"
          >
            <span className="font-medium text-xs text-primary">{key}</span>
          </div>
        )),
    };

    return suggestionTypes[type] ? (
      <div className="absolute top-full left-0 w-full bg-white rounded-md border border-gray-300 z-50 shadow-lg max-h-52 overflow-y-auto">
        {suggestionTypes[type]()}
      </div>
    ) : null;
  };

  return (
    <div
      className={`flex items-center w-full bg-white rounded-md border border-gray-100 px-3 relative ${
        isValid ? "border-gray-100" : "border-red-500"
      }`}
    >
      <input
        className="bg-transparent w-full text-xs font-medium h-9 outline-none pb-0.5"
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleTextChange(e.target.value)}
        onFocus={() => setShowSuggestions(true)}
        onBlur={() => setTimeout(() => setShowSuggestions(false), 300)}
      />

      {(type === "skill" || type === "lastUsed") &&
        showSuggestions &&
        value &&
        renderSuggestions()}

      {type === "rating" && (
        <span className="font-medium text-xs text-primary">/&nbsp;10</span>
      )}
    </div>
  );
};

const SkillInput = ({ addUpdateSkill, data = {} }) => {
  const {
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...data,
      lastUsed: "Current",
    },
  });

  const [validationErrors, setValidationErrors] = useState({
    skill: true,
    level: true,
    experience: true,
    lastUsed: true,
  });

  const onSave = async (field) => {
    const isValid = await trigger();

    if (isValid) {
      try {
        const result = await addUpdateSkill(field);
        if (result) {
          reset({
            skill: "",
            level: "",
            experience: "",
            lastUsed: "Current",
          });
          setValidationErrors({
            skill: true,
            level: true,
            experience: true,
            lastUsed: true,
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setValidationErrors({
        skill: !errors.skill,
        level: !errors.level,
        experience: !errors.experience,
        lastUsed: !errors.lastUsed,
      });
    }
  };

  return (
    <div className="bg-[#FAFAFA] rounded-[20px] py-5 px-20">
      <div className="grid flex-1 w-full grid-cols-[1.9fr_0.75fr_0.6fr_1fr_0.5fr]">
        {["Skill", "Rating", "Years", "When"].map((label) => (
          <span
            key={label}
            className="text-xs font-semibold text-gray-700 flex-1"
          >
            {label}
          </span>
        ))}
        <div className="w-16 items-center px-4" />
      </div>
      <div className="grid space-x-3 py-3 items-center w-full justify-center relative grid-cols-[1.7fr_0.68fr_0.58fr_1fr_0.5fr]">
        {[
          {
            name: "skill",
            type: "skill",
            rules: { required: "Skill is required" },
          },
          {
            name: "level",
            type: "rating",
            placeholder: "8",
            rules: { required: "Rating is required" },
          },
          {
            name: "experience",
            placeholder: "Yrs",
            rules: { required: "Experience is required" },
          },
          {
            name: "lastUsed",
            type: "lastUsed",
            placeholder: "Skill last used",
            rules: { required: "Last used is required" },
          },
        ].map(({ name, type = "text", placeholder, rules }) => (
          <div key={name} className="flex flex-col text-sm text-primary">
            <Controller
              control={control}
              name={name}
              rules={rules}
              render={({ field: { value, onChange } }) => (
                <ReusableInput
                  type={type}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  options={skillsUsed}
                  isValid={validationErrors[name]}
                />
              )}
            />
          </div>
        ))}
        <button
          onClick={handleSubmit(onSave)}
          className="flex items-center font-medium bg-primary text-white text-xs border rounded-md w-max py-1.5 px-5 hover:bg-black"
        >
          Add
        </button>
      </div>
    </div>
  );
};

// Main Component
const Skills = ({ createdDraftJob }) => {
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [skillsList, setSkillsList] = useState(
    state?.skills?.map((x) => ({
      skill: x?.title,
      rating: x?.rating,
      experience: x?.years,
      lastUsed: JobSkills.find((j) => j.value === x?.lastUsed)?.label,
      _id: x?._id,
      isSaved: true,
      isEdit: false,
    })) || []
  );

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        setIsLoading(true);
        const result = await getSkills({
          jobId: createdDraftJob?._id,
          type: 2,
        });

        if (result?.success && result?.data?.length) {
          const fetchedSkills = result.data.map((x) => ({
            skill: x?.title,
            rating: x?.rating,
            experience: x?.years,
            lastUsed: JobSkills.find((j) => j.value === x?.lastUsed)?.label,
            _id: x?._id,
            isSaved: true,
            isEdit: false,
          }));

          setSkillsList(fetchedSkills);
        }
      } catch (error) {
        enqueueSnackbar("Error fetching skills", { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    if (createdDraftJob?._id) {
      fetchSkills();
    }
  }, [createdDraftJob?._id]);

  const addUpdateSkill = useCallback(
    async (data) => {
      try {
        const lastUsed = JobSkills.find(
          (x) => x.label?.toLowerCase() === data?.lastUsed?.toLowerCase()
        );
        const skillData = {
          title: data?.skill,
          rating: data?.level,
          years: data?.experience,
          lastUsed: lastUsed?.value,
        };

        const response = data?._id
          ? await updateSkill({ id: data?._id, ...skillData })
          : await addSkill({
              ...skillData,
              jobId: createdDraftJob?._id,
              type: 2,
              userType: 2,
            });

        if (response?.success) {
          const newSkill = {
            skill: data.skill,
            rating: data.level,
            experience: data.experience,
            lastUsed: data.lastUsed,
            _id: response.data._id,
            isSaved: true,
            isEdit: false,
          };

          setSkillsList((prevSkills) => [newSkill, ...prevSkills]);
          enqueueSnackbar("Skill added successfully!", { variant: "success" });
          return true;
        }
        return false;
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      }
    },
    [createdDraftJob, enqueueSnackbar]
  );

  const deleteSkillPress = async (skill) => {
    try {
      const data = await deleteSkill(skill?._id);
      if (data?.success) {
        setSkillsList((prevSkills) =>
          prevSkills.filter((s) => s?._id !== skill?._id)
        );
        enqueueSnackbar("Skill removed successfully!", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  return (
    <div className="flex flex-col self-center w-[80%]">
      <p className="text-xs text-primary font-medium mb-2">Job skills</p>
      <SkillInput addUpdateSkill={addUpdateSkill} />

      {isLoading ? (
        <div className="flex justify-center mt-40">
          <LoadingAnimation color={colors.PrimaryBlack} />
        </div>
      ) : (
        skillsList.length > 0 && (
          <div>
            <div className="grid flex-1 mt-7 mb-2 justify-center grid-cols-[1.9fr_0.75fr_0.6fr_1fr_0.5fr] px-20">
              {["Skill", "Rating", "Years", "When", ""].map((label) => (
                <span
                  key={label}
                  className="text-xs font-semibold text-gray-700 flex-1"
                >
                  {label}
                </span>
              ))}
            </div>
            <div className="overflow-auto h-full px-20">
              {skillsList.map((skill) => (
                <div
                  key={skill?._id}
                  className="grid space-x-3 py-2.5 items-center w-full justify-center relative grid-cols-[1.7fr_0.68fr_0.58fr_1fr_0.5fr]"
                >
                  {[
                    skill.skill,
                    `${skill.rating}/10`,
                    skill.experience,
                    skill.lastUsed,
                  ].map((value, index) => (
                    <div
                      key={index}
                      className="border border-gray-100 bg-[#FAFAFA] px-4 py-2.5 text-xs font-medium text-primary rounded flex flex-1"
                    >
                      {value}
                    </div>
                  ))}
                  <div
                    className="flex items-center font-medium text-xs underline cursor-pointer"
                    onClick={() => deleteSkillPress(skill)}
                  >
                    Remove
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Skills;
