import api from "../api";
import axios from "../utils/axios.instance";
import handleError from "./handle-error";

export const addSkill = async (payload) => {
  try {
    const { data } = await axios.post(api.addSkill, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const searchSkill = async (searchText) => {
  try {
    const { data } = await axios.get(api.skillSearch + `/${searchText}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const updateSkill = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateSkill, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const deleteSkill = async (SkillId) => {
  try {
    const { data } = await axios.delete(api.deleteSkill + SkillId);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getSkills = async (payload) => {
  try {
    const type = payload?.type || 1;
    const slug =
      payload?.candidateId && payload?.jobId
        ? `candidateId=${payload?.candidateId}&jobId=${payload?.jobId}`
        : payload?.candidateId
        ? `candidateId=${payload?.candidateId}`
        : `jobId=${payload?.jobId}`;

    const { data } = await axios.get(api.listSkill + `?type=${type}&${slug}`);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getMatchedCandidates = async (payload) => {
  try {
    const { data } = await axios.post(api.getMatchedCandidate, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getReferralMatchedCandidates = async () => {
  try {
    const { data } = await axios.get(api.referralMatchedContacts);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getReferralMatchedContactDetails = async (id) => {
  try {
    const { data } = await axios.get(
      api.referralMatchedContactDetails + `/${id}`
    );
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getSkillIndividualPercentage = async (payload) => {
  try {
    const { data } = await axios.post(
      api.getSkillIndividualPercentage,
      payload
    );
    return data;
  } catch (err) {
    return handleError(err);
  }
};
