import { useEffect, useState } from "react";

const EmailSuccess = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 71 61"
      class={_class}
    >
      <path
        d="M5.12578 49.8812H32.9508C33.5304 49.8812 34.0008 49.4108 34.0008 48.8312C34.0008 48.2516 33.5304 47.7812 32.9508 47.7812H5.12578C4.87956 47.7812 4.64646 47.7308 4.42701 47.6526L25.2874 24.1662L28.01 26.6384C29.4186 27.9173 31.1847 28.5568 32.9508 28.5568C34.7169 28.5568 36.4835 27.9173 37.8916 26.6384L40.6147 24.1662L42.1582 25.904C42.3656 26.1376 42.6538 26.2568 42.9436 26.2568C43.192 26.2568 43.4408 26.1691 43.6408 25.9916C44.0745 25.6068 44.1139 24.9427 43.7285 24.5096L42.1698 22.7545L62.7571 4.06187C62.8291 4.27345 62.8768 4.49605 62.8768 4.73177V26.3224C62.8768 26.902 63.3472 27.3724 63.9268 27.3724C64.5064 27.3724 64.9768 26.902 64.9768 26.3224V4.73125C64.9768 2.41548 63.0926 0.53125 60.7768 0.53125H5.12578C2.81001 0.53125 0.925781 2.41548 0.925781 4.73125V45.6812C0.925781 47.9975 2.81001 49.8812 5.12578 49.8812ZM3.06096 46.0288C3.04206 45.9154 3.02578 45.8004 3.02578 45.6812V4.73125C3.02578 4.49552 3.07356 4.27292 3.14548 4.06135L23.7323 22.754L3.06096 46.0288ZM60.7758 2.63125C60.9086 2.63125 61.0383 2.64648 61.1643 2.67063L36.4798 25.0839C34.4675 26.9109 31.4335 26.9104 29.4212 25.0839L4.73728 2.67063C4.86328 2.647 4.99296 2.63125 5.12578 2.63125H60.7758Z"
        fill="#BABABA"
      />
      <path
        d="M52.8992 26.1692C43.3463 26.1692 35.5742 33.9413 35.5742 43.4942C35.5742 53.0471 43.3463 60.8192 52.8992 60.8192C62.4521 60.8192 70.2242 53.0471 70.2242 43.4942C70.2242 33.9413 62.4521 26.1692 52.8992 26.1692ZM52.8992 58.7192C44.5039 58.7192 37.6742 51.8895 37.6742 43.4942C37.6742 35.0994 44.5039 28.2692 52.8992 28.2692C61.2945 28.2692 68.1242 35.0994 68.1242 43.4942C68.1242 51.8889 61.2945 58.7192 52.8992 58.7192Z"
        fill="#BABABA"
      />
      <path
        d="M62.395 38.8137L51.8499 49.3589L44.9797 42.4887C44.5702 42.0787 43.9045 42.0787 43.495 42.4887C43.085 42.8987 43.085 43.5634 43.495 43.9739L51.1075 51.5864C51.3123 51.7917 51.5811 51.8936 51.8499 51.8936C52.1187 51.8936 52.3875 51.7912 52.5922 51.5864L63.8797 40.2989C64.2897 39.8889 64.2897 39.2243 63.8797 38.8137C63.4702 38.4042 62.8045 38.4042 62.395 38.8137Z"
        fill="#BABABA"
      />
    </svg>
  );
};

export default EmailSuccess;
