import * as React from "react";
const GroupIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <circle
      cx={18}
      cy={12}
      r={11.4}
      fill="#fff"
      stroke="#FF9800"
      strokeWidth={1.2}
    />
    <circle
      cx={15}
      cy={13}
      r={11.4}
      fill="#fff"
      stroke="#FF9800"
      strokeWidth={1.2}
    />
    <circle
      cx={12}
      cy={14}
      r={11.4}
      fill="#fff"
      stroke="#FF9800"
      strokeWidth={1.2}
    />
    <path
      fill="#FF9800"
      stroke="#FF9800"
      strokeWidth={0.6}
      d="M12.358 13.896a2.937 2.937 0 0 0 1.494-2.549A2.95 2.95 0 0 0 10.905 8.4a2.95 2.95 0 0 0-2.948 2.947c0 1.095.607 2.04 1.495 2.549-2.22.583-3.852 2.46-3.852 4.685v.724a.295.295 0 0 0 .59 0v-.724c0-2.363 2.115-4.286 4.715-4.286 2.6 0 4.716 1.923 4.716 4.286v.724a.295.295 0 0 0 .59 0v-.724c0-2.225-1.633-4.103-3.853-4.685Zm-3.811-2.549a2.36 2.36 0 0 1 2.358-2.357 2.36 2.36 0 0 1 2.358 2.357 2.36 2.36 0 0 1-2.358 2.358 2.36 2.36 0 0 1-2.358-2.358Z"
    />
    <path
      fill="#FF9800"
      stroke="#FF9800"
      strokeWidth={0.6}
      d="M16.327 13.903A2.356 2.356 0 0 0 15.03 9.58a.295.295 0 0 0 0 .59 1.77 1.77 0 0 1 1.768 1.768 1.77 1.77 0 0 1-1.768 1.768.295.295 0 0 0 0 .59c1.95 0 3.537 1.466 3.537 3.269v.562a.295.295 0 0 0 .59 0v-.563c0-1.703-1.189-3.15-2.831-3.66Z"
    />
  </svg>
);
export default GroupIcon;
