import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  addSkill,
  deleteSkill,
  updateSkill,
} from "../../../../../endpoints/skill.service";
import ProgressBar from "../../../../../components/ProgressBar/ProgressBar";

import { JobSkills, skillsUsed } from "../../../../../utils/constants";
import { SkillInput } from "../../skill/skillInput";
import { SkillData } from "../../skill/skillInput.types";
import EditIcon from "../../../../../assets/svg/EditIcon";
import TrashIcon from "../../../../../assets/svg/TrashIcon";
import { isNullOrEmpty } from "../../../../../utils/helperFunction";
import { AddEditExperienceProps, experienceFeilds } from "./experienceBlock.types";

interface SkillItemProps extends AddEditExperienceProps {
  skillData: SkillData;
  workExperienceId: string;
  index: number;
  showEditDeleteButtons?: boolean;
}

const SkillItem = ({
  fields,
  control,
  errors,
  skillData,
  workExperienceId,
  index,
  setExperiences,
  showEditDeleteButtons,
  handleDeleteSkill,
  setValue,
}: SkillItemProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { title, rating, isEdit, lastUsed, years } = skillData;
  const [editable, setEditable] = useState<boolean>(false);

  useEffect(() => {
    setEditable(isEdit ?? false);
  }, [isEdit]);

  const addUpdateSkill = async (
    data: {
      _id?: string;
      skill: string;
      lastUsed: string;
      level: string;
      experience: string;
      skillId?: string;
    },
    index: number
  ) => {
    try {
      if (!workExperienceId) {
        enqueueSnackbar("Please save your experience before saving skills.", {
          variant: "error",
        });
        return;
      }
      const lastUsed = JobSkills.find((x) => x.label === data?.lastUsed);
      const payload = {
        ...data,
        workExperienceId: workExperienceId,
        lastUsed: lastUsed?.value,
        type: 1,
        userType: 1,
        id: data?._id,
      };

      let response;

      if (data?._id) {
        delete payload?._id;
        // Update existing education
        response = await updateSkill(payload);
      } else {
        // Add new education
        delete payload._id;
        response = await addSkill(payload);
      }

      const { data: responseData, success } = response;
      if (success) {
        data?._id
          ? setExperiences((prev) => {
            return prev?.map((exp) => {
              if (exp?._id === workExperienceId) {
                return {
                  ...exp,
                  skills: exp?.skills.map((x) => {
                    if (x?._id === data?._id) {
                      return responseData;
                    }
                    return x;
                  }),
                };
              } else {
                return exp;
              }
            });
          })
          : setExperiences((prev) => {
            return prev?.map((exp) => {
              if (exp?._id === workExperienceId) {
                return {
                  ...exp,
                  skills: exp?.skills
                    ? [responseData, ...exp?.skills]
                    : [responseData],
                };
              } else {
                return exp;
              }
            });
          });
        setEditable(false);
        const successMessage = data?._id
          ? `Skills updated successfully.`
          : "Skills saved successfully.";
        enqueueSnackbar(successMessage, { variant: "success" });
      }
    } catch (error) {
      console.error("Error saving education:", error);
      enqueueSnackbar(`An error occurred while saving skills.`, {
        variant: "error",
      });
    }
  };

  const deleteSkillApi = async () => {
    handleDeleteSkill({ _id: skillData?._id, customId: skillData?.customId })
    try {
      if (isNullOrEmpty(skillData?._id)) {
        setExperiences((prev) => {
          console.log(
            prev?.map((exp) => {
              if (exp?._id === workExperienceId) {
                return {
                  ...exp,
                  skills: exp?.skills.filter(
                    //@ts-ignore
                    (x, i) => x.customId !== skillData?.customId
                  ),
                };
              } else {
                return exp;
              }
            })
          );

          return prev?.map((exp) => {
            if (exp?._id === workExperienceId) {
              return {
                ...exp,
                skills: exp?.skills.filter(
                  //@ts-ignore
                  (x, i) => x.customId !== skillData?.customId
                ),
              };
            } else {
              return exp;
            }
          });
        });

        return;
      }

      const { success, response } = await deleteSkill(skillData?._id);

      if (success) {
        setExperiences((prev) => {
          return prev?.map((exp) => {
            if (exp?._id === workExperienceId) {
              return {
                ...exp,
                skills: exp?.skills.filter((x) => x._id !== skillData?._id),
              };
            } else {
              return exp;
            }
          });
        });
      }
    } catch (error) {
      enqueueSnackbar(`An error occurred while delete skill.`, {
        variant: "error",
      });
    }
  };

  return (
    <>
      {!editable ? (
        <div className="flex justify-between items-center grid-cols-4">
          <span className="flex-1 font-medium text-primary text-xs">
            {title}
          </span>
          <ProgressBar value={rating} total={10} />
          <span className="flex-1 font-medium text-primary text-center text-xs">
            {years}
          </span>
          <span className="flex-1 font-medium text-primary text-xs text-center">
            {Object.keys(skillsUsed).find(
              //@ts-ignore
              (key) => skillsUsed[key] === lastUsed
            )}
          </span>
          {showEditDeleteButtons && (
            <div className="flex w-[5%] mr-5 items-end gap-3">
              <div
                className="cursor-pointer"
                onClick={() => setEditable(true)}
              >
                <EditIcon className={"w-4 h-4"} />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => deleteSkillApi()}
              >
                <TrashIcon fill="" className={"w-4 h-4"} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <SkillInput
          fields={fields}
          control={control}
          errors={errors}
          workExperienceId={workExperienceId}
          data={skillData}
          deleteSkill={deleteSkillApi}
          addUpdateSkill={addUpdateSkill}
          index={index}
          setValue={setValue}
        />
      )}
    </>
  );
};

export default SkillItem;
