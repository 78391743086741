import * as React from "react";
const RightArrowIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.98044 5.68661C8.93132 5.64083 8.89191 5.58563 8.86459 5.5243C8.83726 5.46297 8.82256 5.39676 8.82138 5.32962C8.82019 5.26249 8.83254 5.1958 8.85769 5.13354C8.88284 5.07128 8.92027 5.01473 8.96775 4.96725C9.01523 4.91977 9.07178 4.88234 9.13404 4.85719C9.1963 4.83204 9.26299 4.81969 9.33012 4.82088C9.39726 4.82206 9.46347 4.83676 9.5248 4.86409C9.58613 4.89141 9.64133 4.93081 9.68711 4.97994L12.3538 7.64661C12.4474 7.74036 12.5 7.86744 12.5 7.99994C12.5 8.13244 12.4474 8.25952 12.3538 8.35327L9.68711 11.0199C9.64133 11.0691 9.58613 11.1085 9.5248 11.1358C9.46347 11.1631 9.39726 11.1778 9.33012 11.179C9.26299 11.1802 9.1963 11.1678 9.13404 11.1427C9.07178 11.1175 9.01523 11.0801 8.96775 11.0326C8.92027 10.9852 8.88284 10.9286 8.85769 10.8663C8.83254 10.8041 8.82019 10.7374 8.82138 10.6703C8.82256 10.6031 8.83726 10.5369 8.86459 10.4756C8.89191 10.4142 8.93132 10.359 8.98044 10.3133L10.7938 8.49994H4.33377C4.20117 8.49994 4.07399 8.44726 3.98022 8.35349C3.88645 8.25972 3.83377 8.13255 3.83377 7.99994C3.83377 7.86733 3.88645 7.74015 3.98022 7.64639C4.07399 7.55262 4.20117 7.49994 4.33377 7.49994H10.7938L8.98044 5.68661Z"
      fill="#6F6F6F"
    />
  </svg>
);
export default RightArrowIcon;
