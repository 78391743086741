import { useEffect, useState } from "react";

const LocationIconFilled = ({ className, fill }) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 24" stroke-width="0" stroke={fill} class={_class}>
         <path d="M9.42858 0C6.92887 0.00294898 4.53239 0.997261 2.76483 2.76482C0.99727 4.53238 0.00295901 6.92886 1.0031e-05 9.42857C-0.00298388 11.4713 0.664279 13.4587 1.89944 15.0857C1.89944 15.0857 2.15658 15.4243 2.19858 15.4731L9.42858 24L16.662 15.4689C16.6997 15.4234 16.9577 15.0857 16.9577 15.0857L16.9586 15.0831C18.1931 13.4568 18.8601 11.4704 18.8572 9.42857C18.8542 6.92886 17.8599 4.53238 16.0923 2.76482C14.3248 0.997261 11.9283 0.00294898 9.42858 0ZM9.42858 12.8571C8.75047 12.8571 8.08759 12.6561 7.52377 12.2793C6.95994 11.9026 6.52049 11.3671 6.26099 10.7406C6.00149 10.1141 5.9336 9.42477 6.06589 8.75969C6.19818 8.09461 6.52472 7.4837 7.00422 7.00421C7.48371 6.52471 8.09462 6.19817 8.7597 6.06588C9.42478 5.93359 10.1141 6.00148 10.7406 6.26098C11.3671 6.52048 11.9026 6.95993 12.2793 7.52376C12.6561 8.08759 12.8572 8.75046 12.8572 9.42857C12.856 10.3375 12.4944 11.2089 11.8517 11.8517C11.209 12.4944 10.3375 12.856 9.42858 12.8571Z" fill={fill}/>
        </svg>
    )
};

export default LocationIconFilled;