import "twin.macro";
import tw, { styled, css } from "twin.macro";
import { colors } from "../../utils/theme";

const getColorStyles = (color, variant) => css`
  background-color: ${variant === "contain"
    ? color ?? colors.Green
    : colors.Transparent};
`;

export const ButtonContainer = styled.button(
  ({ color, variant, style, disabled }) => [
    tw`flex items-center justify-center rounded-full relative px-4 py-2 hover:bg-opacity-90 gap-2`,
    getColorStyles(color, variant),
    disabled && tw`opacity-50`,
    variant === "outline" && `border: 1px solid ${color ?? colors.Green}`,
    style,
  ]
);

export const IconWrapper = tw.div`
  ${`w-4 h-4`}
`;

export const Text = styled.span(({ color, variant, textStyle }) => [
  tw`font-montserrat font-semibold text-sm text-white`,
  variant === "outline" && `color: ${color ?? colors.White}`,
  css(textStyle),
]);
