import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SearchContext } from "./SearchContext";

const TemplateSearchesPage = () => {
  const navigate = useNavigate();
  const { searchResults } = useContext(SearchContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="bg-[#F9FAFB] w-full px-20 py-7 rounded-xl items-center flex flex-col h-full">
        <span className="self-start text-primary font-semibold pl-8">
          Search Templates
        </span>
        <div className="flex flex-col w-full mt-3 gap-2 overflow-auto">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]?.map(() => {
            return (
              <Link
                to={"/search/template-result?template_name=Python developer for contract at Google"}
                className="group flex items-center justify-between w-full gap-4 cursor-pointer"
              >
                <div className="flex flex-1 bg-white rounded-md border h-full items-center pl-5 py-1.5 group-hover:bg-gray-50">
                  <span className="font-medium text-primary text-xs">
                    Python developer for contract at Google
                  </span>
                </div>
                <div className="h-px w-10 bg-gray-400" />
                <button className="flex items-center justify-center rounded-md bg-white px-5 h-full text-xs font-medium text-green-600 border group-hover:bg-gray-50">
                  View 24 matches
                </button>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TemplateSearchesPage;
