import { useEffect, useState } from "react";

const TickOutline = ({ className, fill }) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 8" class={_class}>
            <path d="M2.21019 6.75015C2.30315 6.84387 2.41375 6.91827 2.53561 6.96904C2.65747 7.01981 2.78817 7.04594 2.92019 7.04594C3.0522 7.04594 3.1829 7.01981 3.30476 6.96904C3.42662 6.91827 3.53722 6.84387 3.63019 6.75015L7.71019 2.67015C7.89849 2.48184 8.00428 2.22645 8.00428 1.96015C8.00428 1.69384 7.89849 1.43845 7.71019 1.25015C7.52188 1.06184 7.26649 0.956055 7.00019 0.956055C6.73388 0.956055 6.47849 1.06184 6.29019 1.25015L2.92019 4.63015L1.71019 3.41015C1.52188 3.22184 1.26649 3.11605 1.00019 3.11605C0.733884 3.11605 0.478489 3.22184 0.290185 3.41015C0.101882 3.59845 -0.00390625 3.85384 -0.00390625 4.12015C-0.00390625 4.38645 0.101882 4.64184 0.290185 4.83015L2.21019 6.75015Z" fill={fill} />
        </svg>
    )
};

export default TickOutline;