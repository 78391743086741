import { useEffect, useState } from "react";

const EditBoxIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      stroke={fill}
      class={_class}
    >
      <path d="M12.7267 9.62764V12.5876C12.7265 13.0065 12.5601 13.4081 12.2639 13.7042C11.9678 14.0004 11.5662 14.1668 11.1473 14.167H3.41335C3.20518 14.1669 2.99909 14.1257 2.8069 14.0457C2.61472 13.9657 2.44023 13.8485 2.29347 13.7009C2.14671 13.5533 2.03057 13.3781 1.95172 13.1855C1.87287 12.9928 1.83287 12.7865 1.83401 12.5783V4.85298C1.83277 4.64514 1.8728 4.43913 1.95176 4.24687C2.03072 4.05462 2.14706 3.87995 2.29402 3.73299C2.44099 3.58602 2.61566 3.46969 2.80791 3.39072C3.00016 3.31176 3.20618 3.27174 3.41401 3.27298H6.37335" stroke="#4D4D4D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.7266 5.99681L10.0033 3.27281M4.55664 10.5355V9.09214C4.55797 8.85414 4.65264 8.62547 4.81997 8.45681L11.1746 2.10214C11.259 2.01688 11.3594 1.94919 11.47 1.90299C11.5807 1.85679 11.6994 1.83301 11.8193 1.83301C11.9392 1.83301 12.0579 1.85679 12.1686 1.90299C12.2793 1.94919 12.3797 2.01688 12.464 2.10214L13.898 3.53614C13.9832 3.62046 14.0509 3.72085 14.0971 3.83151C14.1433 3.94217 14.1671 4.06089 14.1671 4.18081C14.1671 4.30072 14.1433 4.41944 14.0971 4.5301C14.0509 4.64076 13.9832 4.74115 13.898 4.82547L7.54331 11.1801C7.37433 11.348 7.14612 11.4425 6.90798 11.4435H5.46464C5.34535 11.4437 5.2272 11.4203 5.11696 11.3747C5.00671 11.3291 4.90655 11.2623 4.8222 11.1779C4.73785 11.0936 4.67097 10.9934 4.6254 10.8832C4.57983 10.7729 4.55647 10.6548 4.55664 10.5355Z" stroke="#4D4D4D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default EditBoxIcon;
