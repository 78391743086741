export const colors = {
  Green: "#00BB29",
  PrimaryBlack: "#2E2E2E",
  TextPrimary: "#2e2e2e",
  SecondaryOrange: "#FF9800",
  Transparent: "transparent",
  White: "#FFFFFF",
  Border: "#E9EAF0",
  TextSecondary: "#6F6F6F",
  Pink: "#FF24BD",
  LightGrey: "#BABABA",
  BackgroundGray: "#FAFAFA",
  grey:{
    100:'#F9FAFB'
  }
};
