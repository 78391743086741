import * as React from "react";
const DownloadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#6F6F6F"
      d="m12.5 15.577-3.539-3.538.708-.72L12 13.65V5h1v8.65l2.33-2.33.709.719-3.539 3.538ZM7.116 19c-.46 0-.845-.154-1.153-.462-.308-.308-.462-.693-.463-1.154v-2.423h1v2.423c0 .154.064.295.192.424a.582.582 0 0 0 .423.192h10.77a.59.59 0 0 0 .423-.192.583.583 0 0 0 .192-.424v-2.423h1v2.423c0 .46-.154.845-.462 1.153-.308.308-.693.462-1.154.463H7.116Z"
    />
  </svg>
);
export default DownloadIcon;
