import { useEffect, useState } from "react";

const TickSquareIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <path
        d="M20.5577 0.25H3.94231C2.96337 0.251069 2.02484 0.640422 1.33263 1.33263C0.640422 2.02484 0.251069 2.96337 0.25 3.94231V20.5577C0.251069 21.5366 0.640422 22.4752 1.33263 23.1674C2.02484 23.8596 2.96337 24.2489 3.94231 24.25H20.5577C21.5366 24.2489 22.4752 23.8596 23.1674 23.1674C23.8596 22.4752 24.2489 21.5366 24.25 20.5577V3.94231C24.2489 2.96337 23.8596 2.02484 23.1674 1.33263C22.4752 0.640422 21.5366 0.251069 20.5577 0.25ZM18.4952 8.22827L10.7413 17.459C10.6563 17.5603 10.5505 17.6422 10.431 17.699C10.3116 17.7559 10.1813 17.7864 10.049 17.7885H10.0335C9.90408 17.7884 9.77615 17.7612 9.65798 17.7085C9.5398 17.6558 9.43402 17.5789 9.3475 17.4827L6.02442 13.7904C5.94003 13.7009 5.87438 13.5954 5.83133 13.4801C5.78828 13.3649 5.76871 13.2422 5.77375 13.1193C5.7788 12.9964 5.80836 12.8757 5.86071 12.7644C5.91306 12.653 5.98713 12.5533 6.07858 12.471C6.17003 12.3887 6.277 12.3255 6.39321 12.2852C6.50943 12.2448 6.63254 12.2281 6.75531 12.236C6.87808 12.2439 6.99803 12.2762 7.10811 12.3312C7.2182 12.3861 7.3162 12.4624 7.39635 12.5558L10.0092 15.4588L17.0817 7.04096C17.2404 6.85755 17.4648 6.74393 17.7066 6.72466C17.9483 6.7054 18.1879 6.78204 18.3736 6.93802C18.5593 7.094 18.6761 7.31678 18.6989 7.55822C18.7216 7.79966 18.6485 8.04035 18.4952 8.22827Z"
        fill={fill}
      />
    </svg>
  );
};

export default TickSquareIcon;
