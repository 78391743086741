import {
  Controller,
} from "react-hook-form";
import CustomInput from "../../../../../components/CustomInput/CustomInput";
import QuillEditor from "../../../../../components/CreateQuestion/Question/QuillEditor";
import moment from "moment";
import { AddEditExperienceProps } from "./experienceBlock.types";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import SkillItem from "./skillItem";
import { hoursToYears } from "../../../../../utils/helperFunction";

const AddEditExperience: React.FC<AddEditExperienceProps> = ({
  control,
  fields,
  errors,
  setValue,
  handleAddSkill,
  setExperiences,
  handleDeleteSkill
}) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (fields.startDate && fields?.isCurrent && (moment(fields.startDate).isSameOrAfter(moment(), 'date'))) {
      enqueueSnackbar(
        "Start date should be ahead of current date",
        {
          variant: "error",
        }
      );
      setValue('startDate', undefined)
    } else if (fields?.startDate && fields?.endDate && (moment(fields.startDate).isSameOrAfter(moment(fields?.endDate), 'date'))) {
      enqueueSnackbar(
        "Start Date should be ahead of End Date",
        {
          variant: "error",
        }
      );
      setValue('endDate', undefined)
    }
  }, [
    fields?.isCurrent,
    fields?.startDate,
    fields?.endDate
  ])

  return (
    <div className="w-full">
      <div className="flex flex-col gap-4 mb-3">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={fields?.isCurrent}
            className="accent-[#EFBF04] size-4 rounded text-emerald-500"
            onChange={(e) => {
              if (fields?.startDate && (moment(fields.startDate).isSameOrAfter(moment(), 'date'))) {
                enqueueSnackbar(
                  "Start date should be ahead of current date",
                  {
                    variant: "error",
                  }
                );
                return
              }
              setValue("isCurrent", e?.target?.checked)
            }}
          />
          <label className="text-primary text-xs font-medium">
            Currently working for this role
          </label>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <div className="flex flex-col gap-3">
            <CustomInput
              type="date"
              label="Start Date"
              name="startDate"
              rules={{ required: "This field is required." }}
              control={control}
              errors={errors}
            />
            <CustomInput
              type="text"
              label={"Job Title"}
              name={"jobTitle"}
              placeholder={"Enter your Job Title"}
              rules={{ required: "This field is required." }}
              control={control}
              errors={errors}
            />
            <CustomInput
              label="Location"
              name="location"
              rules={{
                required: "This field is required.",
              }}
              placeholder={"Ex: London"}
              control={control}
              errors={errors}
            />
          </div>
          <div className="flex flex-col gap-3">
            <div
              className={`${fields?.isCurrent ? "opacity-20" : "opacity-100"}`}
            >
              <CustomInput
                type="date"
                label={"End Date"}
                name={"endDate"}
                rules={{
                  required: fields?.isCurrent
                    ? false
                    : "This field is required.",
                }}
                control={control}
                errors={errors}
              />
            </div>

            <CustomInput
              type="text"
              label={"Company Name"}
              name={"companyName"}
              placeholder={"Enter your Company Name"}
              rules={{ required: "This field is required." }}
              control={control}
              errors={errors}
            />
          </div>
        </div>
        <div className="mr-4">
          <Controller
            name={"jobDescription"}
            control={control}
            rules={{
              required: "This field is required.",
            }}
            render={({ field }) => {
              return (
                <div className="flex flex-col gap-1">
                  <span className="font-montserrat text-primary font-medium text-xs mb-2">
                    Job Description{" "}
                    <span className="font-montserrat font-medium text-xs text-red-500">
                      *
                    </span>
                  </span>
                  <QuillEditor
                    onChange={field.onChange}
                    value={field.value}
                    label=""
                    required={true}
                  />
                  {errors["jobDescription"]?.message && (
                    <span className="whitespace-pre text-xs mt-2 text-orange-700">
                      {errors["jobDescription"]?.message}
                    </span>
                  )}
                </div>
              );
            }}
          />
        </div>
      </div>
      <div className="flex flex-col">
        {!!fields?.skills?.length && (
          <div className="flex mb-5 mt-6 grid-cols-4">
            <span className="font-montserrat text-primary font-semibold text-sm flex-1">
              Skill
              {/* {hoursToYears(moment(fields?.isCurrent ? moment() : moment(fields?.endDate)).diff(fields?.startDate, 'hours'))} */}
            </span>
            <span className="font-montserrat text-primary font-semibold flex-1 text-sm">
              Experience Level
            </span>
            <span className="font-montserrat text-primary font-semibold flex-1 text-sm text-center">
              Years
            </span>
            <span className="font-montserrat text-primary font-semibold flex-1 text-sm text-center">
              Last Used
            </span>
            <label
              style={{ width: "8%" }}
              className="flex font-bold text-sm text-primary"
            />
          </div>
        )}
        {fields?.skills?.length > 0 && (
          <div className="flex flex-col gap-4">
            {fields?.skills.map((skill: any, index: number) => (
              <SkillItem
                key={skill?._id ?? skill?.customId}
                fields={fields}
                control={control}
                errors={errors}
                skillData={skill}
                workExperienceId={fields?._id}
                index={index}
                showEditDeleteButtons
                setExperiences={setExperiences}
                handleDeleteSkill={handleDeleteSkill}
                setValue={setValue}
              />
            ))}
          </div>
        )}
        <button
          onClick={handleAddSkill}
          className="text-sm py-1 px-2 text-[#6f6f6f] rounded font-semibold w-max mt-2"
        >
          + Add Skill
        </button>
      </div>
    </div>
  );
};

export default AddEditExperience;
