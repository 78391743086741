import { SET_JOB_DATA, SET_JOB_DETAIL_DATA } from "../actions/action.types";

const initialState = {
  jobs: [],
  jobDetail: undefined,
};

const jobReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_JOB_DATA:
      return { ...state, jobs: payload };
    case SET_JOB_DETAIL_DATA:
      return { ...state, jobDetail: payload };
    default:
      return state;
  }
};

export default jobReducer;
