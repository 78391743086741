import { SET_COMPANY_DATA } from "../actions/action.types";

const initialState = {
  companies: [],
};

const companyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COMPANY_DATA:
      return { ...state, companies: payload };
    default:
      return state;
  }
};

export default companyReducer;
