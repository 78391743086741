import * as React from "react";
const CrossIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      stroke="#6F6F6F"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.5 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM7.5 8l6 6m0-6-6 6"
    />
  </svg>
);
export default CrossIcon;
