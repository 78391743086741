import { useState } from "react";
import ChevronDown from "../../../../../../assets/svg/ChevronDown";
import SearchIcon from "../../../../../../assets/svg/SearchIcon";
import CloseIcon from "../../../../../../assets/svg/CloseIcon";
import CopyIcon from "../../../../../../assets/svg/CopyIcon";
import LinkIcon from "../../../../../../assets/svg/LinkIcon";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { sendAssessment } from "../../../../../../endpoints/assessment.service";
import { useSnackbar } from "notistack";

const SelectAssessment = ({
  assessmentList,
  selectedAssessments,
  handleSelectAssessment,
  setSelectedAssessments,
}) => {
  const allSelected =
    assessmentList.length > 0 &&
    selectedAssessments.length === assessmentList.length;

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedAssessments(assessmentList);
    } else {
      setSelectedAssessments([]);
    }
  };

  return (
    <div className="flex flex-1 flex-col ">
      {/* Header */}
      <div className="flex items-center justify-between">
        <span className="font-semibold text-primary">
          Select assessment to share link with candidate
        </span>
      </div>

      {/* Search section */}
      <div className="flex rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5 mt-6">
        <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-2">
          <label className="text-primary text-sm font-medium">Search by</label>
          <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
        </button>
        <input
          className="bg-white text-xs font-medium text-light-black outline-none px-3 py-2 rounded-lg w-80 flex-1"
          placeholder="Search..."
        />
        <SearchIcon className={"w-5 h-5"} fill={"#2e2e2e"} />
      </div>

      <div className="w-1/2 overflow-y-scroll no-scrollbar">
        {/* Table container */}
        <div className="mt-3.5">
          <div className="grid grid-cols-[12%_1fr_3fr] border-b py-3">
            <div className="flex items-center justify-center">
              <input
                type="checkbox"
                id="select-all"
                checked={allSelected}
                onChange={handleSelectAll}
                className="h-4 w-4 border rounded-sm border-primary"
              />
            </div>
            <span className="font-semibold text-primary text-xs">
              Reference
            </span>
            <span className="font-semibold text-primary text-xs">
              Assessment Name
            </span>
          </div>

          <div className="overflow-y-scroll max-h-fit no-scrollbar">
            {assessmentList?.map((item) => {
              const isSelected = selectedAssessments.some(
                (assessment) => assessment._id === item._id
              );
              return (
                <div
                  key={item.id}
                  className="grid grid-cols-[12%_1fr_3fr] py-3"
                >
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      id={`assessment-${item._id}`}
                      checked={isSelected}
                      onChange={() => handleSelectAssessment(item)}
                      className={`h-4 w-4 border rounded-sm ${
                        isSelected ? "bg-primary" : "border-primary"
                      }`}
                    />
                  </div>
                  <span className="font-medium text-primary text-xs">
                    {item.refNo}
                  </span>
                  <span className="font-medium text-primary text-xs">
                    {item.title}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const ShareAssessmentLink = ({
  selectedAssessments,
  setSelectedAssessments,
}) => {
  return (
    <div className="flex flex-col">
      {/* Header */}
      <div className="flex items-center justify-between">
        <span className="font-semibold text-primary">
          Share selected assessment link
        </span>
      </div>

      {/* Selection container */}
      <div className="border rounded-md px-2 py-1 mt-4">
        <span className="font-semibold text-light-black text-xs">
          {selectedAssessments?.length} assessment selected
        </span>
        <div className="flex gap-2 flex-wrap py-2">
          {selectedAssessments?.map((item, index) => {
            const handleRemoveAssessment = () => {
              setSelectedAssessments((prevSelected) =>
                prevSelected.filter((assessment) => assessment._id !== item._id)
              );
            };
            return (
              <div
                key={index}
                className="flex items-center justfiy-center bg-light-white-700 rounded-md px-2 py-1 gap-3"
              >
                <span className="font-medium text-xs text-primary">
                  {item.title}
                </span>
                <span onClick={handleRemoveAssessment}>
                  <CloseIcon className={"w-3.5 h-3.5 cursor-pointer"} />
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-6">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="scales"
            name="scales"
            checked
            className="h-4 w-4 border rounded-sm border-primary"
          />
          <span className="font-medium text-primary text-sm">
            Share assessment link to candidate’s message box
          </span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-4 h-4 rounded-sm border border-primary" />
          <span className="font-medium text-primary text-sm">
            Share assessment link to candidate’s email address
          </span>
        </div>
      </div>
    </div>
  );
};

const SendAssessmentLinkSection = () => {
  const navigate = useNavigate();
  const { assessmentList } = useSelector((state) => state?.assessment);
  const { candidateId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState(0);
  const [selectedAssessments, setSelectedAssessments] = useState([]);

  const sendAssessmentData = async () => {
    const payload = selectedAssessments?.map((item) => {
      return {
        assessmentId: item.userId,
        candidateId: candidateId,
      };
    });

    try {
      const { data } = await sendAssessment(payload);
      enqueueSnackbar(data.message, {
        variant: "success",
      });

      navigate("/contacts");
    } catch (error) {
      console.error("Error sending assessment data:", error);
      enqueueSnackbar("Oops! something error occoured!", {
        variant: "error",
      });
    }
  };

  const handleSelectAssessment = (item) => {
    setSelectedAssessments((prevSelected) => {
      const isSelected = prevSelected.some(
        (assessment) => assessment._id === item._id
      );

      if (isSelected) {
        return prevSelected.filter((assessment) => assessment._id !== item._id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleNext = () => {
    if (step === 0) {
      setStep(1);
    } else {
      // Handle sending logic here if needed
    }
  };

  const handlePrev = () => {
    setStep(0);
  };

  return (
    <div className="py-6 px-5 w-full h-full relative">
      <div className="">
        {step === 0 ? (
          <SelectAssessment
            assessmentList={assessmentList}
            selectedAssessments={selectedAssessments}
            handleSelectAssessment={handleSelectAssessment}
            setSelectedAssessments={setSelectedAssessments}
          />
        ) : (
          <ShareAssessmentLink
            selectedAssessments={selectedAssessments}
            setSelectedAssessments={setSelectedAssessments}
          />
        )}
      </div>
      <div className="flex items-center justify-between">
        {step === 0 ? null : (
          <button
            onClick={handlePrev}
            className="border border-primary rounded-md self-end mt-4 text-primary font-medium text-sm px-4 py-1 hover:bg-grey-100"
          >
            Previous
          </button>
        )}
        <button
          onClick={step === 0 ? handleNext : sendAssessmentData}
          className="bg-primary rounded-md self-end mt-4 text-white font-medium text-sm px-4 py-1 hover:bg-black"
        >
          {step === 0 ? "Next" : "Send"}
        </button>
      </div>
    </div>
  );
};

export default SendAssessmentLinkSection;
