import * as React from "react";
const NetworkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#4D4D4D"
        d="M3.333 13.333H5.26a2.664 2.664 0 0 0-.831 1.334H3.333a2 2 0 0 1-2-2v-1.334h1.334v1.334c0 .368.298.666.666.666ZM4.667 10c0-.735-.598-1.333-1.334-1.333h-2C.598 8.667 0 9.265 0 10v.667h4.667V10ZM2.333 8a1.333 1.333 0 1 0 .001-2.666 1.333 1.333 0 0 0 0 2.666Zm10.334.667c-.736 0-1.334.598-1.334 1.333v.667H16V10c0-.735-.598-1.333-1.333-1.333h-2ZM8 2.667A1.333 1.333 0 1 0 8 0a1.333 1.333 0 0 0 0 2.666Zm5.667 2.666A1.333 1.333 0 1 0 13.666 8a1.333 1.333 0 0 0 0-2.666Zm-10.334-4a2 2 0 0 0-2 2v.865c.31-.125.646-.198 1-.198.114 0 .224.02.334.033v-.7c0-.368.298-.666.666-.666h1.943c.096-.084.187-.175.295-.244-.15-.334-.238-.701-.238-1.09h-2Zm10 2v.7c.11-.014.22-.033.334-.033.354 0 .69.073 1 .198v-.865a2 2 0 0 0-2-2h-2c0 .389-.088.756-.238 1.09.108.069.198.16.295.244h1.943c.368 0 .666.298.666.666Zm0 9.334a.667.667 0 0 1-.666.666H10.74c.398.348.694.806.831 1.334h1.095a2 2 0 0 0 2-2v-1.334h-1.334v1.334ZM9 3.333H7c-.735 0-1.333.598-1.333 1.334v.666h4.666v-.666c0-.736-.598-1.334-1.333-1.334ZM9 14H7c-.735 0-1.333.598-1.333 1.333V16h4.666v-.667C10.333 14.598 9.735 14 9 14Zm-1-.667a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default NetworkIcon;
