import { useSnackbar } from "notistack";
import { useState } from "react";
// import { useSelector } from "react-redux";
import {
  checkBoxQuestion,
  multipleChoice,
  singleAnswer,
} from "./CreateQuestion.const";
import {
  createAssessmentQuestion,
  createAssessmentSection,
  deleteAssessmentQuestion,
  deleteAssessmentSection,
  updateAssessmentQuestion,
  updateAssessmentSection,
} from "../../endpoints/assessment.service";
// import {
//   createSection,
//   createQuestion,
//   deleteSection,
//   deleteQuestion,
// } from "endpoints/test";

const useCreateQuestion = ({ assessmentId }) => {
  const [assessment, setAssessment] = useState([
    { id: 1, section: undefined, sectionSaved: false, sectionEditing: true },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSectionTitleSave = async ({ sectionTitle, sectionIndex }) => {
    try {
      const payload = {};
      payload.title = sectionTitle;
      payload.assessmentId = assessmentId;

      const existingSection = assessment?.[sectionIndex];
      if (existingSection?.sectionId) {
        payload.id = existingSection?.sectionId;
      }

      const { data } = payload?.id
        ? await updateAssessmentSection(payload?.id, payload.title)
        : await createAssessmentSection(payload);
      const tempAssessment = [...assessment];
      tempAssessment[sectionIndex] = {
        ...tempAssessment[sectionIndex],
        section: sectionTitle,
        sectionSaved: true,
        sectionEditing: true,
        sectionId: data?._id,
        questions: payload?.id ? tempAssessment[sectionIndex]?.questions : [],
      };

      const updatedAssessment = tempAssessment.map((section, index) =>
        index === sectionIndex
          ? { ...section, sectionEditing: true }
          : { ...section, sectionEditing: false }
      );
      setAssessment(updatedAssessment);
      enqueueSnackbar(
        payload?.id
          ? "Section updated successfully."
          : "Section added successfully.",
        {
          variant: "success",
        }
      );
    } catch (error) {
      console.log(error);

      enqueueSnackbar("Oops! something error occoured!", {
        variant: "error",
      });
    }
  };

  const onEditTitle = ({ sectionIndex }) => {
    const tempAssessment = [...assessment];
    tempAssessment[sectionIndex] = {
      ...tempAssessment[sectionIndex],
      sectionSaved: false,
      sectionEditing: true,
    };
    const updatedAssessment = tempAssessment.map((section, index) =>
      index === sectionIndex
        ? { ...section, sectionEditing: true }
        : { ...section, sectionEditing: false }
    );
    setAssessment(updatedAssessment);
  };

  const addNewSection = () => {
    let tempData = assessment.map((section) => ({
      ...section,
      sectionEditing: false,
    }));

    const maxId = tempData.reduce((max, { id }) => Math.max(max, id), 0);

    tempData.push({
      id: maxId + 1,
      section: undefined,
      sectionSaved: false,
      sectionEditing: true,
    });
    setAssessment(tempData);
  };

  const onDeleteSection = async ({ sectionIndex }) => {
    try {
      const sectionId = assessment[sectionIndex]?.sectionId;
      if (sectionId) {
        await deleteAssessmentSection(sectionId);
      }

      const tempAssessment = [...assessment];
      tempAssessment.splice(sectionIndex, 1);

      if (!tempAssessment.length) {
        setAssessment([
          {
            id: 1,
            section: undefined,
            sectionSaved: false,
            sectionEditing: true,
          },
        ]);
      } else {
        setAssessment(tempAssessment);
      }
    } catch (error) {
      enqueueSnackbar("Oops! something error occurred!", {
        variant: "error",
      });
    }
  };

  const onChangeQuestionType = ({
    sectionIndex,
    questionIndex,
    questionType,
  }) => {
    const tempData = [...assessment];
    let question;
    if (questionType === "multiple-choice") {
      question = multipleChoice;
    } else if (questionType === "check-box") {
      question = checkBoxQuestion;
    } else {
      question = singleAnswer;
    }
    if (tempData[sectionIndex] && tempData[sectionIndex].questions) {
      tempData[sectionIndex].questions[questionIndex] = question;
    }
    setAssessment(tempData);
  };

  const handleQuestionSave = async ({
    localQuestion,
    sectionId,
    sectionIndex,
    questionIndex,
  }) => {
    try {
      setIsLoading(true);
      let question = assessment[sectionIndex].questions[questionIndex];
      question.question = localQuestion.question;
      let payload = {};

      if (localQuestion.editorContent) {
        payload.editorContent = JSON.stringify(localQuestion.editorContent);
        question.editorContent = localQuestion.editorContent;
      } else {
        question.answer = localQuestion.answer;
        question.choices = question.choices?.map((x) => ({
          ...x,
          option: localQuestion[`option ${x.id}`],
        }));
        payload.answerOptions = question?.choices.flatMap((choice, index) => {
          return {
            title: choice?.option,
            order: index + 1,
          };
        });
        payload.correctAnswer = question.answer;
      }

      payload.questionType = question?.type === "single-answer" ? 1 : 2;
      payload.question = question?.question;
      payload.testType = "wholeCourse";
      payload.assessmentId = assessmentId;
      payload.sectionId = sectionId;
      if (question?.questionId) {
        payload.id = question?.questionId;
      }

      const { data } = payload?.id
        ? await updateAssessmentQuestion(payload)
        : await createAssessmentQuestion(payload);
      let tempData = assessment;

      if (tempData[sectionIndex] && tempData[sectionIndex].questions) {
        tempData[sectionIndex].questions[questionIndex] = {
          ...question,
          saved: question?.questionId ? false : true,
          editing: question?.questionId ? false : true,
          questionId: data?._id,
        };
      }
      if (question?.questionId) {
        const lastQuestionIndex = assessment[sectionIndex].questions.length - 1;
        assessment[sectionIndex].questions[lastQuestionIndex] = {
          ...assessment[sectionIndex].questions[lastQuestionIndex],
          editing: true,
          saved: false,
        };
      }
      setAssessment([...tempData]);
      enqueueSnackbar(
        payload?.id
          ? "Question updated successfully."
          : "Question added successfully.",
        {
          variant: "success",
        }
      );
    } catch (error) {
      console.log("err", error);
      enqueueSnackbar("Oops! something error occoured!", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addAndRemoveOptions = ({
    type,
    sectionIndex,
    questionIndex,
    index,
  }) => {
    let tempArray = assessment;
    if (
      tempArray &&
      tempArray[sectionIndex] &&
      tempArray[sectionIndex].questions &&
      tempArray[sectionIndex].questions[questionIndex] &&
      tempArray[sectionIndex].questions[questionIndex].choices
    ) {
      if (type === "add") {
        tempArray[sectionIndex].questions[questionIndex].choices.splice(
          index,
          0,
          {
            id:
              tempArray[sectionIndex].questions[questionIndex].choices.length +
              1,
            option: undefined,
          }
        );
      } else {
        tempArray[sectionIndex].questions[questionIndex].choices.splice(
          index,
          1
        );
      }
    }
    setAssessment([...tempArray]);
  };

  const addNewQuestion = ({ sectionIndex, questionType }) => {
    let tempData = [...assessment];
    if (tempData[sectionIndex]) {
      tempData[sectionIndex].questions = tempData[sectionIndex].questions.map(
        (q) => ({ ...q, editing: false })
      );

      const maxId = tempData[sectionIndex].questions.reduce(
        (max, { id }) => Math.max(max, id),
        0
      );

      let newQuestion;
      if (questionType === "multiple-choice") {
        newQuestion = {
          id: maxId + 1,
          ...multipleChoice,
          editing: true,
        };
      } else {
        newQuestion = {
          id: maxId + 1,
          ...singleAnswer,
          editing: true,
        };
      }
      tempData[sectionIndex].questions.push(newQuestion);
      setAssessment([...tempData]);
    }
  };

  const onEditQuestion = ({ sectionIndex, questionIndex }) => {
    let tempData = [...assessment];
    if (tempData[sectionIndex] && tempData[sectionIndex].questions) {
      tempData[sectionIndex].questions = tempData[sectionIndex].questions.map(
        (question, index) => {
          return {
            ...question,
            editing: index === questionIndex,
          };
        }
      );
    }
    setAssessment([...tempData]);
  };

  const onDeleteQuestion = async ({ sectionIndex, questionIndex }) => {
    try {
      let tempData = [...assessment];
      if (tempData[sectionIndex] && tempData[sectionIndex].questions) {
        const questionToBeRemoved =
          tempData[sectionIndex].questions[questionIndex];
        if (questionToBeRemoved.questionId) {
          await deleteAssessmentQuestion(questionToBeRemoved.questionId);
        }
        tempData[sectionIndex].questions.splice(questionIndex, 1);
        setAssessment(tempData);
      }
    } catch (error) {
      console.error("Error deleting question:", error);
      enqueueSnackbar(
        "Oops! Something went wrong while deleting the question!",
        {
          variant: "error",
        }
      );
    }
  };

  return {
    onSectionTitleSave,
    assessment,
    setAssessment,
    onChangeQuestionType,
    addAndRemoveOptions,
    handleQuestionSave,
    addNewQuestion,
    onEditTitle,
    onDeleteSection,
    onEditQuestion,
    onDeleteQuestion,
    addNewSection,
    isLoading,
  };
};

export default useCreateQuestion;
