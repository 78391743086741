import * as React from "react";
const OfferReceived = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <circle
      cx={12}
      cy={12}
      r={11.4}
      fill="#fff"
      stroke="#FF9800"
      strokeWidth={1.2}
    />
    <g fill="#FF9800" clipPath="url(#a)">
      <path d="M7.185 8.924a1.32 1.32 0 0 0-1.313 1.312v6.59c0 .72.593 1.313 1.313 1.313h9.625a1.32 1.32 0 0 0 1.312-1.313v-6.59c0-.228.032-.462-.056-.748a.803.803 0 0 0-.297-.418.905.905 0 0 0-.522-.146h-1.75a.437.437 0 1 0 0 .875h1.734c-1.56 1.363-3.16 2.72-4.74 4.072-.313.276-.675.276-.987 0L6.89 9.803c.535 0 1.08-.004 1.608-.004a.437.437 0 1 0 0-.875H7.185Zm-.438 1.922 4.178 3.682c.612.54 1.532.54 2.144 0l4.178-3.577v5.875c0 .25-.188.438-.437.438H7.185a.427.427 0 0 1-.438-.438v-5.98Z" />
      <path d="M11.997 5.861a.438.438 0 0 0-.437.438v3.832l-.994-1.056c-.206-.208-.43-.172-.619-.019a.437.437 0 0 0-.018.619l1.75 1.86a.437.437 0 0 0 .637 0l1.75-1.86a.437.437 0 1 0-.637-.6l-.994 1.056V6.3a.438.438 0 0 0-.438-.438Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M5 5h14v14H5z" />
      </clipPath>
    </defs>
  </svg>
);
export default OfferReceived;
