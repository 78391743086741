import { useForm } from "react-hook-form";
import CustomInput from "../../../../../../../components/CustomInput/CustomInput";
import { useEffect, useRef, useState } from "react";
import regexList from "../../../../../../../utils/regexList";
import FolderIcon from "../../../../../../../assets/svg/FolderIcon";
import {
  addCandidate,
  updateCandidate,
} from "../../../../../../../endpoints/candidate.service";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { extractDialCode } from "../../../../../../../utils/helperFunction";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { gender } from "../../../../../../../utils/constants";

const PersonalDetails = ({ candidateDetails, setCandidateDetails }) => {
  const navigate = useNavigate();
  const { candidateId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);
  const filePickerRef = useRef();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({});
  const [isEdit, setIsEdit] = useState(!candidateId);

  const savePersonalDetails = (data) => {
    if (!isEdit) {
      setIsEdit(true);
      return;
    }
    const payload = {
      ...data,
      recruiterId: user?._id,
      dob: new Date(data?.dob).toISOString(),
      countryCode: extractDialCode("+" + data?.phoneNo)?.dialCode,
      phoneNo: extractDialCode("+" + data?.phoneNo)?.localNumber,
      gender: data?.gender === "Male" ? 1 : data?.gender === "Female" ? 2 : 3,
      type: user?.type === 2 ? 1 : 2,
    };

    if (candidateId) {
      setIsEdit(false);
      payload.candidateId = candidateId;
      updateCandidate(payload)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            setCandidateDetails(data);
            setIsEdit(false);
            enqueueSnackbar("Details updated successfully", {
              variant: "success",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err, { variant: "error" });
        });
    } else {
      addCandidate(payload)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            setCandidateDetails(data);
            setIsEdit(false);
            enqueueSnackbar("Details added successfully", {
              variant: "success",
            });
            navigate(`/contacts/edit-candidate/${data?._id}`);
          }
        })
        .catch((err) => {
          enqueueSnackbar(err, { variant: "error" });
        });
    }
  };

  useEffect(() => {
    if (candidateDetails) {
      ["firstName", "lastName", "jobTitle", "email", "location"]?.forEach(
        (item) => setValue(item, candidateDetails[item])
      );
      setValue(
        "gender",
        gender.filter((item) => item?.value === candidateDetails?.gender)[0]
          ?.label
      );
      setValue("dob", moment(candidateDetails?.dob).format("YYYY-MM-DD"));
      setValue(
        "phoneNo",
        candidateDetails?.countryCode + candidateDetails?.phoneNo
      );
    }
  }, [candidateDetails]);

  return (
    <form
      onSubmit={handleSubmit(savePersonalDetails)}
      className="border rounded-lg px-4 py-4 mt-5"
    >
      <div className="flex items-center justify-between">
        <label className="font-semibold text-sm">Personal Details</label>
        <button
          type="submit"
          className="group flex items-center justify-center font-semibold text-sm realtive"
        >
          <span className="z-50">{isEdit ? "Save" : "Edit"}</span>
        </button>
      </div>
      {/* Form container */}
      <div className="flex itesm-center gap-5 mt-6">
        {/* Col 1 */}
        <div className="flex flex-col flex-1 gap-5">
          <CustomInput
            label={"First Name"}
            placeholder={"Candidate's First Name"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            disabled={!isEdit}
            control={control}
            name={"firstName"}
            rules={{
              required: "This field is required.",
            }}
            errors={errors}
          />
          <CustomInput
            type="phone"
            label={"Phone Number"}
            name={"phoneNo"}
            disabled={!isEdit}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"Job Title"}
            placeholder={"Ex: Sales Manager"}
            inActiveOutlineColor="border border-px"
            disabled={!isEdit}
            bgColor={"bg-white"}
            control={control}
            name={"jobTitle"}
            rules={{
              required: "This field is required.",
            }}
            errors={errors}
          />

          <CustomInput
            label={"Gender"}
            name={"gender"}
            disabled={!isEdit}
            control={control}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            type="dropdown"
            placeholder={"Select Gender"}
            dropdownList={["Male", "Female", "Others"]}
            rules={{
              required: "This field is required.",
            }}
            errors={errors}
          />
        </div>
        {/* Col 2 */}
        <div className="flex flex-col flex-1 gap-5">
          <CustomInput
            label={"Last Name"}
            disabled={!isEdit}
            placeholder={"Candidate's Last Name"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            control={control}
            name={"lastName"}
            rules={{
              required: "This field is required.",
            }}
            errors={errors}
          />
          <CustomInput
            label={"Email Address"}
            disabled={!isEdit}
            placeholder={"abc@gmail.com"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            control={control}
            name={"email"}
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.email,
                message: "Please enter a valid email.",
              },
            }}
            errors={errors}
          />
          <CustomInput
            name={"dob"}
            label={"Birth Date"}
            disabled={!isEdit}
            placeholder={"16-11-1997"}
            bgColor={"bg-white"}
            inActiveOutlineColor="border border-px"
            type="date"
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"Location"}
            disabled={!isEdit}
            placeholder={"ExLondon"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            name={"location"}
            control={control}
            rules={{
              required: "This field is required.",
            }}
            errors={errors}
          />
        </div>
        {/* Col 3 */}
        <div className="flex flex-col flex-1 gap-5">
          <div className="flex flex-col">
            <span className="font-montserrat text-primary font-medium text-xs mb-2">
              Candidate CV
            </span>
            <button
              type="button"
              onClick={() => filePickerRef?.current?.click()}
              className="flex items-center border rounded-lg border overflow-hidden pr-2"
            >
              <span className="text-sm text-[#6f6f6f] font-medium py-2.5 px-3 bg-[#E9EAF0]">
                PDF
              </span>
              <span className="text-xs font-medium my-auto px-3 text-[#6F6F6F] flex-1">
                Drop files headers
                <span className="text-light-grey"> or</span>
                <span className="text-secondary"> browse</span>
              </span>
              <FolderIcon className={"w-8 h-8"} />
            </button>
            <input ref={filePickerRef} type="file" className="hidden" />
          </div>
        </div>
      </div>
    </form>
  );
};

export default PersonalDetails;
