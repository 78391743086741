export default function Tooltip({ message, children }) {
  return (
    <div className="group relative flex max-w-max flex-col items-center justify-center z-50">
      {children}
      <div className="absolute left-1/2 top-8 ml-auto mr-auto min-w-max -translate-x-1/2 scale-0 transform rounded-lg px-3 py-2 text-xs font-medium transition-all duration-500 group-hover:scale-100">
        <div className="flex max-w-xs flex-col items-center shadow-lg">
          <div className="clip-bottom h-1 w-2 bg-gray-800"></div>
          <span className="font-montserrat font-normal rounded bg-gray-800 px-1.5 py-[2px] text-center text-[8px] text-white whitespace-break-spaces">
            {message}
          </span>
        </div>
      </div>
    </div>
  );
}
