import handleError from "./handle-error";
import api from "../api";
import axios from "../utils/axios.instance";

export const getCompanyList = async (query = "") => {
  try {
    const { data } = await axios.get(api.companyList + query);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const addCompany = async (payload) => {
  try {
    const { data } = await axios.post(api.addCompany, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateCompany = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateCompany, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteCompany = async (companyId) => {
  try {
    const { data } = await axios.delete(api.deleteCompany + "/" + companyId);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

//Company contact

export const getCompanyContacts = async (companyId) => {
  try {
    const { data } = await axios.get(api.getCompanyContact + "/" + companyId);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const addCompanyContact = async (payload) => {
  try {
    const { data } = await axios.post(api.addCompanyContact, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateCompanyContact = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateCompanyContact, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteCompanyContact = async (id) => {
  try {
    const { data } = await axios.delete(api.deleteCompanyContact + "/" + id);
    return data;
  } catch (err) {
    return handleError(err);
  }
};
