import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useRef, useState } from "react";
import { Cropper } from "react-cropper";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import { colors } from "../../utils/theme";
import regexList from "../../utils/regexList";
import { updateCompany } from "../../endpoints/company.service";
import { setUserData } from "../../store/actions/auth.action";
import { useSnackbar } from "notistack";
import Modal from "../../components/Modal/Modal";
import { profileImage, uploadUrl } from "../../endpoints/images.service";

const MyCompanyDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cropperRef = useRef(null);
  const filePickerRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { companyDetails, user } = useSelector((state) => state.auth);
  const {
    control,
    formState: { errors, isDirty },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: companyDetails?.name,
      websiteUrl: companyDetails?.websiteUrl,
      address1: companyDetails?.address1,
      email: companyDetails?.email,
      city: companyDetails?.city,
      state: companyDetails?.state,
      zipCode: companyDetails?.zipCode,
      country: companyDetails?.country,
      primaryContactNumber: companyDetails?.primaryContactNumber,
      zipCode: companyDetails?.zipCode,
      image: undefined,
      logo: undefined,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const fields = watch();

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      setValue("file", file, { shouldDirty: true });
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("image", reader.result, { shouldDirty: true });
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("Error during file upload or API calls:", error);
    }
  };

  const handleItemClick = (item) => {
    navigate("/account-members-detail", { state: { item } });
  };

  const getCroppedImage = (e) => {
    e.preventDefault();

    if (cropperRef.current) {
      const cropperInstance = cropperRef.current.cropper;
      if (cropperInstance) {
        const croppedDataUrl = cropperInstance.getCroppedCanvas().toDataURL();
        setValue("logo", croppedDataUrl);
        setValue("image", undefined);
      } else {
        console.error("Cropper instance is not found");
      }
    } else {
      console.error("cropperRef.current is not initialized");
    }
  };

  const updateCompanyDetails = async (payload) => {
    console.log("check payload", payload);
    payload.companyId = companyDetails?._id;
    try {
      setIsLoading(true);
      if (payload?.logo) {
        const { data } = await profileImage();
        if (!data?.data || !data?.data.uploadURL) {
          throw new Error("Failed to get upload URL");
        }
        const formData = new FormData();
        const base64Data = payload?.logo?.split(";base64,").pop();
        const byteArray = Uint8Array.from(atob(base64Data), (c) =>
          c.charCodeAt(0)
        );
        const blob = new Blob([byteArray], { type: payload?.file?.type });
        formData.append("file", blob, payload?.file?.name);
        const fileResponse = await uploadUrl(data?.data.uploadURL, formData);
        delete payload["file"];
        delete payload["logo"];
        delete payload["image"];
        const picURl = fileResponse?.variants?.find(
          (x) => x.split("/").slice(-1)[0] === "public"
        );
        payload["logo"] = picURl;
      }
      const { success, data: companyDetails } = await updateCompany(payload);
      if (success) {
        dispatch(setUserData({ companyDetails }));
        setValue("logo", undefined);
        setValue("image", undefined);
        setValue("openProfileModel", false);
        enqueueSnackbar("Details updated successfully.", {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log("error", error);
    } finally {
      setIsLoading(false);
      setIsEditing(false);
    }
  };

  return (
    <div className="flex flex-col overflow-y-scroll no-scrollbar h-full rounded-lg bg-white w-full flex-grow p-10">
      <div className="flex flex-col gap-5 rounded-md bg-gray-50 p-5">
        <div className="grid grid-cols-3">
          <div className="flex flex-col">
            <label className="font-semibold text-primary text-sm">
              Complete your company details
            </label>
            <span className="font-medium text-xs text-light-grey">
              Keep your company details up to date.
            </span>
          </div>
          <div className="flex justify-center items-center">
            <label className="font-semibold text-primary text-sm underline">
              Account Admin
            </label>
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => {
                if (isEditing) {
                  handleSubmit(updateCompanyDetails)();
                } else {
                  setIsEditing((prev) => !prev);
                }
              }}
              className="flex bg-primary rounded-md py-1.5 px-4 w-max"
            >
              <span className="flex items-center gap-4 font-semibold text-sm text-white m-auto">
                {isEditing ? "Save" : "Edit"}
                {isLoading ? <LoadingAnimation /> : null}
              </span>
            </button>
          </div>
        </div>
        <div
          className={`grid grid-cols-2 gap-5 ${
            isEditing ? "opacity-100" : "opacity-60"
          }`}
        >
          {/* Form container */}
          <div className="flex flex-col gap-4">
            <CustomInput
              disabled={!isEditing}
              label="Company Name"
              name="name"
              placeholder={"ABC Company"}
              control={control}
              errors={errors}
            />
            <CustomInput
              disabled={!isEditing}
              label="Website URL"
              name="websiteUrl"
              placeholder={"Ex: doe"}
              control={control}
              errors={errors}
            />
            <CustomInput
              disabled={!isEditing}
              label="Primary Contact Number"
              name="primaryContactNumber"
              placeholder={"Ex: doe"}
              control={control}
              errors={errors}
            />

            <CustomInput
              disabled={!isEditing}
              label="Company email address"
              name="email"
              placeholder={"Ex: doe"}
              rules={{
                required: "This field is required.",
                pattern: {
                  value: regexList.email,
                  message: "Please enter a valid email.",
                },
              }}
              control={control}
              errors={errors}
            />

            <div className="flex flex-col gap-4 mt-2">
              <label className="font-semibold text-primary text-sm">
                Company address
              </label>
              <CustomInput
                disabled={!isEditing}
                label="Address"
                name="address1"
                placeholder={"Ex: Project Manager"}
                control={control}
                errors={errors}
              />
              <CustomInput
                disabled={!isEditing}
                label={"City"}
                name="city"
                placeholder={"Ex: United Kingdom"}
                control={control}
                errors={errors}
              />
              <CustomInput
                disabled={!isEditing}
                label={"Country"}
                name="country"
                placeholder={"Ex: United Kingdom"}
                control={control}
                errors={errors}
              />
              <CustomInput
                disabled={!isEditing}
                label={"Postal Code"}
                name="zipCode"
                placeholder={"Ex: United Kingdom"}
                control={control}
                errors={errors}
              />
            </div>
          </div>
          {/* Image container */}
          <div className="flex flex-col flex-1 gap-6 px-14 pt-10">
            <div className="flex self-center rounded-full h-40 w-40 bg-white items-center justify-center border">
              {fields?.logo || companyDetails?.logo ? (
                <img
                  src={fields?.logo || companyDetails?.logo}
                  className="rounded-full h-40 w-40 object-fill"
                />
              ) : (
                <span className="font-montserrat font-extrabold text-5xl text-gray-200">
                  LOGO
                </span>
              )}
            </div>
            <button
              disabled={!isEditing}
              type="button"
              onClick={() => filePickerRef?.current?.click()}
              className="flex  items-center border rounded-lg overflow-hidden pr-2"
            >
              <span className="text-sm text-[#6f6f6f] font-medium py-2.5 px-3 bg-[#E9EAF0]">
                PNG/JPG
              </span>
              <span className="text-xs flex font-medium px-3 text-ngray flex-1 text-left whitespace-nowrap">
                {fields?.file
                  ? fields?.file?.name
                  : companyDetails?.logo
                  ? companyDetails?.logo
                  : "Upload logo here"}
              </span>
            </button>
            <input
              ref={filePickerRef}
              type="file"
              className="hidden"
              accept=".png, .jpg"
              onChange={handleFileChange}
            />
          </div>
        </div>

        {/* Image Cropper Modal */}
        <Modal isOpen={fields?.image}>
          <div className="flex flex-col mt-7">
            {fields?.image && (
              <Cropper
                src={fields?.image}
                ref={cropperRef}
                style={{ height: 400, width: "100%" }}
                aspectRatio={1}
                viewMode={2}
                guides={false}
                minCropBoxWidth={50} // Minimum width for the crop area
                minCropBoxHeight={50} // Minimum height for the crop area
              />
            )}
            <div className="mt-10 flex justify-end">
              <button
                onClick={(e) => getCroppedImage(e)}
                className="bg-primary text-white px-6 py-2.5 rounded-md"
              >
                Crop Image
              </button>
            </div>
          </div>
          <style>
            {`
                        /* Ensure the cropper's crop box is circular */
                        .cropper-face,
                        .cropper-view-box,
                        .cropper-crop-box {
                          border-radius: 50% !important; /* Make crop area circular */
                          border: 2px solid white !important; /* Optional: Set the color of the circular crop lines */
                        }

                        .cropper-face {
                          background-color: rgba(0, 0, 0, 0.3) !important; /* Optional: Set the background color of the circular crop area */
                        }

                        .cropper-view-box {
                          border-radius: 50% !important; /* Make sure the selection area is circular */
                        }

                        .cropper-crop-box {
                          border-radius: 50% !important; /* Ensure the cropping boundary is circular */
                        }
                      `}
          </style>
        </Modal>
        {/* <button
          disabled={isLoading || !isDirty}
          onClick={handleSubmit(updateCompanyDetails)}
          className={`bg-primary items-center flex text-white px-8 py-2 text-sm font-semibold rounded-md w-max ml-auto mt-3 ${
            isDirty ? "opacity-100 hover:bg-black" : "opacity-50"
          }`}
        >
          {isLoading && <LoadingAnimation color={colors.White} />}
          Update
        </button> */}
      </div>
    </div>
  );
};

export default MyCompanyDetailsPage;
