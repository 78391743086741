import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css"; // Import Quill's styles

const QuillEditor = ({ onChange, value, label, required }) => {
  const quillRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      const quill = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: "#custom-toolbar", // Use custom toolbar
          },
        },
      });

      // Set initial content
      quill.clipboard.dangerouslyPasteHTML(value?.trim());

      // Handle content change
      quill.on("text-change", () => {
        const content = quill.root.innerHTML;
        onChange(content);
      });

      editorRef.current = quill;

      // Clean up on unmount
      return () => {
        quill.off("text-change");
      };
    }
  }, [quillRef.current]); // Ensure this runs only once

  useEffect(() => {
    if (editorRef.current && value !== editorRef.current.root.innerHTML) {
      editorRef.current.clipboard.dangerouslyPasteHTML(value?.trim());
    }
  }, [value]);

  return (
    <div className="flex flex-col">
      {label && (
        <span className="font-montserrat text-primary font-medium text-xs mb-2">
          {label}{" "}
          {required && (
            <span className="font-montserrat text-primary font-medium text-xs text-red-500">
              *
            </span>
          )}
        </span>
      )}
      <style>
        {`
          .ql-container.ql-snow {
            border: 1px solid #dddddd;
            border-radius: 4px 4px 0px 0px;
          }
            
          #custom-toolbar  {
            border-top: none !important;
            border-color: #dddddd;
            border-radius: 0px 0px 4px 4px;
          }

          #custom-toolbar button {
            border: none !important;
            background: none !important;
            cursor: pointer;
            outline: none;
            padding: 0;
            margin: 0;
          }
        `}
      </style>
      <div ref={quillRef} style={{ minHeight: 200 }} />
      <div id="custom-toolbar" className="flex gap-2">
        <button type="button" className="ql-bold" />
        <button type="button" className="ql-italic" />
        <button type="button" className="ql-underline" />
        <button type="button" className="ql-strike" />
        <button type="button" className="ql-link" />
        <button type="button" className="ql-list" value="bullet" />
        <button type="button" className="ql-list" value="ordered" />
        <button class="ql-image"></button>
        <button class="ql-video"></button>
        <button class="ql-code-block"></button>
      </div>
    </div>
  );
};

export default QuillEditor;
