import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../../../../../components/CustomInput/CustomInput";
import CustomSlider from "../../../../../../../components/CustomSlider/CustomSlider";
import { useEffect, useState } from "react";
import PlusIcon from "../../../../../../../assets/svg/PlusIcon";
import { useParams } from "react-router-dom";
import {
  addSkill,
  deleteSkill,
  getSkills,
  listSkill,
  updateSkill,
} from "../../../../../../../endpoints/candidate.service";
import { useSnackbar } from "notistack";
import TrashIcon from "../../../../../../../assets/svg/TrashIcon";
import EditIcon from "../../../../../../../assets/svg/EditIcon";

const sliderValues = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 9.7,
    label: "10",
  },
];

const skillsLastUsed = [
  {
    label: "Current",
    value: 1,
  },
  {
    label: "This Year",
    value: 2,
  },
  {
    label: "Last Year",
    value: 3,
  },
  {
    label: "2+ years ago",
    value: 4,
  },
];

const selectStyle = {
  MozAppearance: "none",
  WebkitAppearance: "none",
  appearance: "none",
  backgroundImage: `url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20fill%3D%22none%22%20viewBox%3D%220%200%2032%2032%22%20stroke-width%3D%220%22%20stroke%3D%22%232e2e2e%22%20class%3D%22custom-arrow%22%3E%3Cpath%20d%3D%22M9.3335%2013.333L16.0002%2019.9997L22.6668%2013.333%22%20stroke%3D%22%232e2e2e%22%20stroke-opacity%3D%221%22%20stroke-width%3D%224%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20/%3E%3C/svg%3E')`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right .7em center",
  backgroundSize: "16px auto",
  paddingRight: "2.5em",
};

const ProgressBar = ({ value }) => {
  return (
    <div className="flex items-center gap-1.5">
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((item) => {
        return (
          <div
            key={item}
            className={`w-3.5 h-3.5 ${
              value >= item + 1 ? "bg-[#EFBF04]" : "bg-[#ececec]"
            }`}
          />
        );
      })}
    </div>
  );
};

const RenderFormSkill = ({ skillSection, setSkills }) => {
  const { candidateId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({});
  const [isEdit, setIsEdit] = useState(!skillSection?._id);
  const [sliderValue, setSliderValue] = useState(5);

  const handleSave = (data) => {
    if (!isEdit) {
      setIsEdit(true);
      return;
    }
    let payload = {
      ...data,
      candidateId,
      rating: sliderValue,
      type: 1,
      userType: 2,
    };

    if (skillSection?._id) {
      payload = {};
      ["title", "years", "lastUsed"]?.forEach((item) => {
        if (data[item] !== skillSection[item]) {
          payload[item] = data[item];
        }
      });
      if (sliderValue !== skillSection?.rating) {
        payload.rating = sliderValue;
      }
      payload.id = skillSection?._id;
      updateSkill(payload)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            setIsEdit(false);
            enqueueSnackbar("Skill updated successfully", {
              variant: "success",
            });
            setSkills((prev) => {
              return prev?.map((item) => {
                if (item?._id === skillSection?._id) {
                  return data;
                } else {
                  return item;
                }
              });
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err, { variant: "error" });
        });
    } else {
      addSkill(payload)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            setIsEdit(false);
            enqueueSnackbar("Skill added successfully", {
              variant: "success",
            });
            setSkills((prev) => {
              return [data, ...prev]?.filter((item) => item?._id);
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err, { variant: "error" });
        });
    }
  };

  const handleDelete = () => {
    if (skillSection?._id) {
      deleteSkill(skillSection?._id)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            enqueueSnackbar("Skill deleted successfully", {
              variant: "success",
            });
            setSkills((prev) => {
              return prev?.filter((item) => item?._id !== skillSection?._id);
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err, { variant: "error" });
        });
    }
  };

  useEffect(() => {
    if (skillSection?._id) {
      ["title", "years", "lastUsed"]?.forEach((item) =>
        setValue(item, skillSection[item])
      );
      setSliderValue(skillSection?.rating);
    }
  }, [skillSection?._id]);

  return (
    <form
      key={skillSection.id}
      onSubmit={handleSubmit(handleSave)}
      className={`flex gap-4 bg-[#FAFAFA] py-3 pl-3.5 ${
        isEdit && "pb-6"
      } pe-6 rounded-md border justify-between items-center`}
    >
      <div className="w-1/2 flex gap-[27px]">
        <div className="flex w-2/5">
          {!isEdit ? (
            <span className="font-medium text-primary text-xs">
              {skillSection?.title}
            </span>
          ) : (
            <CustomInput
              label="Skill"
              placeholder="Ex: Python"
              bgColor="bg-white"
              inActiveOutlineColor="border-[#E9EAF0]"
              control={control}
              errors={errors}
              name="title"
              rules={{
                required: "This field is required.",
              }}
              labelStyle={`text-xs`}
              className={`border !rounded-[8px]`}
            />
          )}
        </div>
        <div className="flex w-2/5">
          {!isEdit ? (
            <ProgressBar value={skillSection?.rating} />
          ) : (
            <CustomSlider
              label="Skill Rating"
              step={1}
              min={0}
              max={10}
              fillerClass={`h-[5px]`}
              sliderValues={sliderValues}
              value={sliderValue}
              onChange={(value) => setSliderValue(value)}
              labelStyle={`text-xs`}
            />
          )}
        </div>
      </div>
      <div className="w-4/12 flex justify-between">
        <div className="flex flex-col w-1/3">
          {!isEdit ? (
            <span className="font-medium text-primary text-xs">
              {skillSection?.years}
            </span>
          ) : (
            <CustomInput
              label="No. of years"
              placeholder="Ex: 6"
              bgColor="bg-white"
              inActiveOutlineColor="border-[#E9EAF0]"
              type="number"
              control={control}
              errors={errors}
              name="years"
              rules={{
                required: "This field is required.",
              }}
              labelStyle={`text-xs`}
              className={`border !rounded-[8px]`}
            />
          )}
        </div>
        <div className="w-2/4 flex flex-col">
          {!isEdit ? (
            <span className="font-medium text-primary text-xs">
              {
                skillsLastUsed?.filter(
                  (item) => item?.value === skillSection?.lastUsed
                )[0]?.label
              }
            </span>
          ) : (
            <>
              <span className="font-montserrat text-primary font-medium text-xs mb-2">
                Last Used
              </span>
              <Controller
                name={`lastUsed`}
                control={control}
                defaultValue={1}
                rules={{
                  required: "This field is required.",
                }}
                render={({ field }) => (
                  <>
                    <select
                      {...field}
                      style={selectStyle}
                      className={`w-full h-auto leading-normal rounded-lg py-2.5 px-3 font-montserrat font-medium text-xs flex items-center justify-between border !rounded-[8px] border-[#E9EAF0] cursor-pointer`}
                    >
                      <option value="" disabled>
                        Please select
                      </option>
                      {skillsLastUsed?.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                    {errors?.lastUsed?.message && (
                      <span className="whitespace-pre text-xs mt-2 text-orange-700">
                        {errors?.lastUsed?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </>
          )}
        </div>
      </div>

      <div className={`flex items-center gap-4 ${isEdit && "pt-5"}`}>
        <button className="font-semibold text-primary text-xs">
          {!isEdit ? <EditIcon className={"w-4 h-4"} /> : "Save"}
        </button>
        <button onClick={handleDelete} type="button">
          <TrashIcon className={"w-4 h-4"} />
        </button>
      </div>
    </form>
  );
};

const SkillSection = () => {
  const { candidateId } = useParams();
  const [skills, setSkills] = useState([
    { id: 1, skill: "", experience: "", lastUsed: "" },
  ]);

  const handleAddSkill = () => {
    setSkills([
      { id: skills.length + 1, skill: "", experience: "", lastUsed: "" },
      ...skills,
    ]);
  };

  useEffect(() => {
    if (candidateId) {
      getSkills(candidateId).then((response) => {
        const { success, data } = response;
        if (success) {
          if (data?.length) {
            setSkills(data);
          } else {
            setSkills((prev) => {
              return [{ id: 1, skill: "", experience: "", lastUsed: "" }];
            });
          }
        }
      });
    }
  }, [candidateId]);

  return (
    <div className="flex flex-col gap-6 border rounded-lg px-4 py-7 mt-5">
      <label className="text-primary font-medium text-sm text-center">
        Please add relevant skills of the candidate
      </label>
      {skills?.filter((item) => item?._id)?.length ? (
        <div className="flex items-center px-4">
          <label
            style={{ width: "25%" }}
            className="flex font-semibold text-sm text-primary"
          >
            Skill
          </label>
          <label
            style={{ width: "20%" }}
            className="flex font-semibold text-sm text-primary"
          >
            Level out of 10
          </label>
          <label
            style={{ width: "25%" }}
            className="flex font-semibold text-sm text-primary justify-center"
          >
            Years of experience
          </label>
          <label
            style={{ width: "20%" }}
            className="flex font-semibold text-sm text-primary"
          >
            Last used
          </label>
          <div className="w-[10%]" />
        </div>
      ) : null}

      {skills.map((skillSection, index) => (
        <RenderFormSkill
          key={skillSection.id}
          skillSection={skillSection}
          index={index}
          setSkills={setSkills}
        />
      ))}

      <button
        onClick={handleAddSkill}
        className="flex items-center justify-center gap-3 border text-sm text-primary rounded-md px-2 py-1.5 font-semibold w-max"
      >
        <PlusIcon className={"w-4 h-4"} fill={"#2e2e2e"} />
        Add Skill
      </button>
    </div>
  );
};

export default SkillSection;
