import React, { useState, useEffect } from "react";

const DashboardIcon = ({
    size = "24",
    fill = 'none',
    className
}) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" class={_class}>
            <g clip-path="url(#clip0_2321_338)">
                <path d="M7.33333 7.33333H0V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0L7.33333 0V7.33333ZM1.33333 6H6V1.33333H2C1.82319 1.33333 1.65362 1.40357 1.5286 1.5286C1.40357 1.65362 1.33333 1.82319 1.33333 2V6Z" fill={fill} />
                <path d="M16.0013 7.33333H8.66797V0H14.0013C14.5317 0 15.0404 0.210714 15.4155 0.585786C15.7906 0.960859 16.0013 1.46957 16.0013 2V7.33333ZM10.0013 6H14.668V2C14.668 1.82319 14.5977 1.65362 14.4727 1.5286C14.3477 1.40357 14.1781 1.33333 14.0013 1.33333H10.0013V6Z" fill={fill} />
                <path d="M7.33333 16.0013H2C1.46957 16.0013 0.960859 15.7906 0.585786 15.4155C0.210714 15.0404 0 14.5317 0 14.0013L0 8.66797H7.33333V16.0013ZM1.33333 10.0013V14.0013C1.33333 14.1781 1.40357 14.3477 1.5286 14.4727C1.65362 14.5977 1.82319 14.668 2 14.668H6V10.0013H1.33333Z" fill={fill} />
                <path d="M14.0013 16.0013H8.66797V8.66797H16.0013V14.0013C16.0013 14.5317 15.7906 15.0404 15.4155 15.4155C15.0404 15.7906 14.5317 16.0013 14.0013 16.0013ZM10.0013 14.668H14.0013C14.1781 14.668 14.3477 14.5977 14.4727 14.4727C14.5977 14.3477 14.668 14.1781 14.668 14.0013V10.0013H10.0013V14.668Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_2321_338">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )

}
export default DashboardIcon