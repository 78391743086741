import * as React from "react";
const PhoneIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      fill="#2E2E2E"
      d="M16 16.667c-1.501 0-3.006-.346-4.515-1.037-1.51-.691-2.9-1.673-4.171-2.944-1.261-1.272-2.24-2.667-2.936-4.184C3.681 6.983 3.333 5.482 3.333 4v-.667h3.7l.706 3.403-2.247 2.091c.38.66.774 1.261 1.181 1.806a13.22 13.22 0 0 0 1.273 1.474c.445.467.947.903 1.506 1.309.56.405 1.197.805 1.914 1.2l2.175-2.257 3.125.63v3.678H16Z"
    />
  </svg>
);
export default PhoneIcon;
