import React, { useMemo, useState } from "react";
import SearchIcon from "../../../../assets/svg/SearchIcon";
import { partners } from "../dummyData";

const Partner = () => {
  const [search, setSearch] = useState();
  const filterData = useMemo(() => {
    if (!search || !search?.trim()) {
      return partners;
    }

    return partners?.filter((p) =>
      p?.name?.toLowerCase()?.includes(search?.toLowerCase())
    );
  }, [search]);
  return (
    <div className="flex flex-col gap-8">
      <div className="flex rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5 self-start">
        <input
          className="bg-white text-xs font-medium text-light-black outline-none px-3 py-2 rounded-lg w-80"
          placeholder="Search for company name here..."
          value={search}
          onChange={(t) => setSearch(t.target.value)}
        />
        <SearchIcon className={"w-6 h-6"} fill="#2e2e2e" />
      </div>
      <div className="flex flex-col gap-6">
        {filterData?.map((partner) => (
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="w-1/4">
              <img
                src={partner.image}
                className="w-20 h-8 md:mt-2 object-contain"
              />
            </div>
            <div className="md:ml-6 flex flex-1 flex-col gap-1">
              <span className="  font-montserrat text-primary font-semibold text-base">
                {partner.name}
              </span>
              <span className="font-montserrat  text-primary font-medium text-xs leading-5">
                {partner.description}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partner;
