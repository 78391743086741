import { useContext, useState } from "react";
import { skillsUsed } from "../../utils/constants";
import {
  getMatchedCandidates,
  searchSkill,
} from "../../endpoints/skill.service";
import SkillItem from "../../components/Cards/SkillItem/SkillItem";
import { Link, useNavigate } from "react-router-dom";
import { SearchContext } from "./SearchContext";
import { isNullOrEmpty } from "../../utils/helperFunction";
import Modal from "../../components/Modal/Modal";
import CloseIcon from "../../assets/svg/CloseIcon";
import TickThinOutline from "../../assets/svg/TickThinOutline";
import { useDispatch, useSelector } from "react-redux";
import { setSearchData } from "../../store/actions/search.action";

const Input = ({
  type = "text",
  placeholder = "Skill",
  value,
  onChange,
  isValid,
}) => {
  const [showSkillsUsed, setShowSkillsUsed] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const handleTextChange = async (text) => {
    onChange(text);
    if (type === "skill") {
      try {
        const { success, data } = await searchSkill(text);
        if (success && data?.length) {
          setShowSuggestions(true);
          setSuggestions(data);
        } else {
          setShowSuggestions(false);
          setSuggestions([]);
        }
      } catch (error) {
        setShowSuggestions(false);
        setSuggestions([]);
      }
    }
  };

  return (
    <div
      className={`flex items-center w-full bg-white rounded-md border border-gray-100 px-3 relative ${
        !isValid && "border-red-500"
      }`}
    >
      <input
        onClick={() => type === "skillUsed" && setShowSkillsUsed(true)}
        className={`bg-transparent w-full text-xs font-medium h-9 outline-none pb-0.5`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleTextChange(e.target.value)}
      />

      {type === "skill" && showSuggestions ? (
        <div className="absolute top-full left-0 w-full bg-white rounded-md border border-gray-300 z-50 shadow-lg max-h-52 overflow-y-auto">
          {suggestions?.map((item) => (
            <div
              key={item?._id}
              onClick={() => {
                onChange(item?.title);
                setShowSuggestions(false);
              }}
              className="hover:bg-gray-100 px-4 py-2 cursor-pointer"
            >
              <span className="font-medium text-xs text-primary">
                {item?.title}
              </span>
            </div>
          ))}
        </div>
      ) : null}

      {type === "rating" && (
        <span className="font-medium text-xs text-primary">/&nbsp;10</span>
      )}

      {type === "skillUsed" && showSkillsUsed ? (
        <div
          className="flex flex-col absolute top-full left-0 bg-white rounded-md border z-50 w-full shadow-lg border-gray-300"
          onMouseLeave={() => setShowSkillsUsed(false)}
        >
          {Object.entries(skillsUsed)?.map(([key, value]) => (
            <div
              key={key}
              onClick={() => {
                onChange(value);
                setShowSkillsUsed(false);
              }}
              className="hover:bg-gray-100 px-4 py-1"
            >
              <span className="font-medium text-xs text-primary">{key}</span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const SearchPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addedSkills, searchResults } = useSelector((state) => state.search);
  const { templateList, setTemplateList } = useContext(SearchContext);
  const [currentSkill, setCurrentSkill] = useState({
    skill: "",
    rating: "",
    experience: "",
    lastUsed: "",
  });
  const [showSaveTemplate, setShowSaveTemplate] = useState(false);
  const [showSearchTemplates, setShowSearchTemplates] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templateTitle, setTemplateTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isSearchEnabled = addedSkills.length > 0;

  const isSkillValid = !isNullOrEmpty(currentSkill.skill?.trim());
  const isRatingValid = !isNullOrEmpty(currentSkill.rating);
  const isExperienceValid = !isNullOrEmpty(currentSkill.experience);
  const isLastSkillUsedValid = !isNullOrEmpty(currentSkill.lastUsed);

  const handleAddSkill = () => {
    setIsSubmitted(true);
    if (
      currentSkill.skill &&
      currentSkill.rating &&
      currentSkill.experience &&
      currentSkill.lastUsed
    ) {
      const formattedCurrentSkill = {
        ...currentSkill,
        rating:parseInt(currentSkill?.rating),
        experience:parseInt(currentSkill?.experience),
        lastUsed:parseInt(currentSkill?.lastUsed)
      }
      dispatch(
        setSearchData({
          addedSkills: [...addedSkills, { ...formattedCurrentSkill, id: Date.now() }],
        })
      );
      setCurrentSkill({
        skill: "",
        rating: "",
        experience: "",
        lastUsed: "",
      });
      setIsSubmitted(false);
    }
  };

  const handleRemoveSkill = (skillId) => {
    dispatch(
      setSearchData({
        addedSkills: addedSkills.filter((skill) => skill.id !== skillId),
      })
    );
  };

  const onSearchPress = async () => {
    setIsLoading(true);
    try {
      const payload = addedSkills?.map((item) => {
        const newItem = {
          ...item,
          lastUsed: parseInt(item?.lastUsed),
          rating: parseInt(item?.rating),
          experience: parseInt(item?.experience),
        };
        delete newItem.id;
        return newItem;
      });
      const { success, data } = await getMatchedCandidates({ skills: payload });
      if (success) {
        dispatch(setSearchData({ searchResults: data }));
        if (selectedTemplate) {
          navigate(
            `/search/template-result?template_name=${selectedTemplate?.title}`
          );
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveTemplate = () => {
    setTemplateList((prev) => {
      return [
        ...prev,
        {
          title: templateTitle,
          skills: addedSkills,
        },
      ];
    });
    setTemplateTitle("");
    setShowSaveTemplate(false);
  };

  console.log(addedSkills)

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col gap-1 pt-10 xl:w-[70%] 2xl:w-[80%]">
        <div className="bg-[#FAFAFA] rounded-[20px] pt-5 pb-8 px-20">
          <div className="grid flex-1 w-full grid-cols-[1.9fr_0.75fr_0.6fr_1fr_0.5fr]">
            <span className="text-xs font-semibold text-gray-700 flex-1">
              Skill
            </span>
            <span className="text-xs font-semibold text-gray-700 flex-1">
              Rating
            </span>
            <span className="text-xs font-semibold text-gray-700 flex-1">
              Years
            </span>
            <span className="text-xs font-semibold text-gray-700 flex-1">
              When
            </span>
            <div className="w-16 items-center px-4" />
          </div>

          <div className="grid space-x-3 py-3 items-center w-full justify-center relative grid-cols-[1.7fr_0.68fr_0.58fr_1fr_0.5fr]">
            <div className="flex flex-1 text-xs">
              <Input
                type="skill"
                value={currentSkill.skill}
                onChange={(value) =>
                  setCurrentSkill((prev) => ({ ...prev, skill: value }))
                }
                isValid={isSkillValid || !isSubmitted}
              />
            </div>
            <div className="flex flex-1 text-xs">
              <Input
                type="rating"
                placeholder="8"
                value={currentSkill.rating}
                onChange={(value) =>
                  setCurrentSkill((prev) => ({ ...prev, rating: value }))
                }
                isValid={isRatingValid || !isSubmitted}
              />
            </div>
            <div className="flex flex-1 text-xs">
              <Input
                placeholder="Yrs"
                value={currentSkill.experience}
                onChange={(value) =>
                  setCurrentSkill((prev) => ({ ...prev, experience: value }))
                }
                isValid={isExperienceValid || !isSubmitted}
              />
            </div>
            <div className="flex flex-1 text-xs">
              <Input
                type="skillUsed"
                placeholder="Skill last used"
                value={currentSkill.lastUsed}
                onChange={(value) =>
                  setCurrentSkill((prev) => ({ ...prev, lastUsed: value }))
                }
                isValid={isLastSkillUsedValid || !isSubmitted}
              />
            </div>
            <button
              onClick={handleAddSkill}
              className="flex items-center font-medium bg-primary text-white text-xs border rounded-md w-max py-1.5 px-5 hover:bg-black"
            >
              Add
            </button>
          </div>
        </div>

        <div className="flex items-center justify-between my-7 gap-5 relative w-full self-center">
          <div className="items-center w-full grid grid-cols-[1fr_1fr_1fr_0.4fr]">
            <div className="flex-1">
              {templateList?.length ? (
                <button
                  onClick={() => setShowSearchTemplates(true)}
                  className="flex items-center gap-1 border rounded-md text-xs px-4 py-2 text-primary font-medium hover:bg-gray-50 bg-white"
                >
                  {selectedTemplate ? (
                    selectedTemplate?.title
                  ) : (
                    <>
                      Search Templates
                      <span className="text-white bg-primary rounded-full h-4 w-4 flex items-center justify-center">
                        {templateList?.length}
                      </span>
                    </>
                  )}
                </button>
              ) : null}
            </div>
            <div className="flex-1 flex justify-center">
              <button
                className={`flex items-center relative self-center font-medium text-white text-xs border rounded-md w-max py-1.5 px-5 gap-4 hover:bg-black ${
                  isSearchEnabled
                    ? "bg-primary cursor-pointer"
                    : "bg-gray-300 cursor-not-allowed"
                }`}
                onClick={onSearchPress}
                disabled={!isSearchEnabled}
              >
                Search
              </button>
            </div>
            {isLoading ? (
              <span className="text-sm italic flex text-gray-400 font-medium text-center self-center whitespace-pre absolute right-40">
                Analyzing matches
                <span className="animate-bounce"> .</span>
                <span className="animate-bounce animation-delay-75"> .</span>
                <span className="animate-bounce animation-delay-100"> .</span>
              </span>
            ) : (
              <div className="flex-1 flex ml-8">
                {searchResults?.length ? (
                  <Link
                    to={"possible-match"}
                    className="border border-gray-100 rounded-md bg-white text-xs px-8 py-1.5 text-green-600 font-semibold hover:bg-gray-50"
                  >
                    View {searchResults?.length} matches
                  </Link>
                ) : null}
              </div>
            )}
            <div />
          </div>
        </div>
        {addedSkills?.length ? (
          <button
            onClick={() => setShowSaveTemplate(true)}
            className="self-start text-xs text-primary font-semibold hover:bg-white my-3"
          >
            Save Template
          </button>
        ) : null}
      </div>
      {addedSkills.length > 0 && (
        <>
          <div className="grid flex-1 mt-5 mb-2 justify-center xl:w-[70%] 2xl:w-[80%] grid-cols-[1.9fr_0.75fr_0.6fr_1fr_0.5fr] px-20">
            <span className="text-xs font-semibold text-gray-700 flex-1">
              Skill
            </span>
            <span className="text-xs font-semibold text-gray-700 flex-1">
              Rating
            </span>
            <span className="text-xs font-semibold text-gray-700 flex-1">
              Years
            </span>
            <span className="text-xs font-semibold text-gray-700 flex-1">
              When
            </span>
            <div className="w-16 items-center px-4" />
          </div>
          <div className="overflow-auto h-full xl:w-[70%] 2xl:w-[80%] px-20">
            {addedSkills.map((skill) => (
              <SkillItem
                key={skill.id}
                skill={skill}
                handleRemoveSkill={() => handleRemoveSkill(skill.id)}
              />
            ))}
          </div>
        </>
      )}

      {/* Save Template Modal */}
      <Modal isOpen={showSaveTemplate} containerStyle="w-max">
        <div>
          <div className="flex items-center justify-between gap-10">
            <label className="text-primary font-semibold">Save Template</label>
            <button
              onClick={() => {
                setShowSaveTemplate(false);
                setTemplateTitle("");
              }}
            >
              <CloseIcon className={"w-5 h-5"} fill="reds" />
            </button>
          </div>
          <div className="flex flex-col gap-2 mt-3">
            <label className="font-medium text-primary text-xs">Title</label>
            <input
              className="border rounded-md w-96 py-2 px-2 text-xs font-medium"
              placeholder="Title for your template"
              value={templateTitle}
              onChange={(e) => setTemplateTitle(e?.target?.value)}
            />
            <button
              onClick={handleSaveTemplate}
              className="bg-primary rounded-md self-end text-white py-1.5 px-4 text-sm font-semibold mt-2 hover:bg-black"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      {/* Show Search Templates Modal */}
      <Modal isOpen={showSearchTemplates} containerStyle="w-max">
        <div>
          <div className="flex items-center justify-between gap-10">
            <label className="text-primary font-semibold">
              Select your Template
            </label>
            <button
              onClick={() => {
                setShowSearchTemplates(false);
              }}
            >
              <CloseIcon className={"w-5 h-5"} fill="reds" />
            </button>
          </div>
          <div className="flex flex-col mt-3">
            {templateList?.map((template, templateIndex) => {
              const isSelected = template?.title === selectedTemplate?.title;
              return (
                <button
                  key={template?.title}
                  onClick={() => {
                    setSelectedTemplate(template);
                    // setAddedSkills(template?.skills);
                    dispatch(setSearchData({ addedSkills }));
                    setShowSearchTemplates(false);
                  }}
                  className={`flex py-3 itesm-center justify-between hover:bg-gray-50 ${
                    templateIndex !== 0 ? "border-t" : ""
                  }`}
                >
                  <span className="font-medium text-xs">{template?.title}</span>
                  <div className="flex items-center justify-center border border-primary h-3.5 w-3.5 rounded-full">
                    {isSelected ? (
                      <TickThinOutline
                        className={"w-2.5 h-2.5"}
                        fill={"black"}
                      />
                    ) : null}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SearchPage;
