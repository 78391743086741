import * as React from "react";
const SingleQuestion = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4055_17663)">
      <path
        d="M18.7502 6.56555H13.5654C13.1512 6.56555 12.8154 6.90134 12.8154 7.31555C12.8154 7.72977 13.1512 8.06555 13.5654 8.06555H18.7502C19.1644 8.06555 19.5002 7.72977 19.5002 7.31555C19.5002 6.90134 19.1644 6.56555 18.7502 6.56555Z"
        fill="#2E2E2E"
      />
      <path
        d="M10.4348 18.1417H5.25C4.83579 18.1417 4.5 18.4775 4.5 18.8917C4.5 19.3059 4.83579 19.6417 5.25 19.6417H10.4348C10.849 19.6417 11.1848 19.3059 11.1848 18.8917C11.1848 18.4775 10.849 18.1417 10.4348 18.1417Z"
        fill="#2E2E2E"
      />
      <path
        d="M7.18555 6.75C6.3838 6.75 5.6308 6.43725 5.06455 5.871C4.49755 5.30475 4.18555 4.551 4.18555 3.75C4.18555 2.949 4.49755 2.19525 5.06455 1.629C5.6308 1.06275 6.3838 0.75 7.18555 0.75C7.9873 0.75 8.73955 1.062 9.30655 1.629C9.87355 2.19525 10.1855 2.949 10.1855 3.75C10.1855 4.551 9.87355 5.30475 9.30655 5.871C8.73955 6.438 7.98655 6.75 7.18555 6.75ZM7.18555 2.25C6.78505 2.25 6.40855 2.406 6.12505 2.6895C5.84155 2.973 5.68555 3.3495 5.68555 3.75C5.68555 4.1505 5.84155 4.527 6.12505 4.8105C6.69205 5.3775 7.67905 5.3775 8.24605 4.8105C8.52955 4.527 8.68555 4.1505 8.68555 3.75C8.68555 3.3495 8.52955 2.973 8.24605 2.6895C7.96255 2.406 7.58605 2.25 7.18555 2.25Z"
        fill="#2E2E2E"
      />
      <path
        d="M8.24626 3.75055C7.95337 3.45766 7.4785 3.45766 7.1856 3.75055C6.89271 4.04345 6.89271 4.51832 7.1856 4.81121L9.08047 6.70608C9.37337 6.99898 9.84824 6.99898 10.1411 6.70608C10.434 6.41319 10.434 5.93832 10.1411 5.64542L8.24626 3.75055Z"
        fill="#2E2E2E"
      />
      <path
        d="M19.5912 18.6075C19.1982 18.7387 18.7737 18.5265 18.6425 18.1335L17.417 14.4615C17.351 14.2635 17.1912 14.2342 17.102 14.2342C17.0127 14.2342 16.853 14.2635 16.787 14.4607L15.5615 18.1327C15.4302 18.5257 15.0057 18.738 14.6127 18.6067C14.2197 18.4755 14.0082 18.051 14.1395 17.658L15.338 14.0685C15.581 13.341 16.223 12.7815 16.988 12.7372C17.8392 12.6877 18.5742 13.1895 18.8397 13.986L20.066 17.658C20.1972 18.051 19.985 18.4755 19.5927 18.6067L19.5912 18.6075Z"
        fill="#2E2E2E"
      />
      <path d="M15.457 16.113H18.7473V17.613H15.457V16.113Z" fill="#2E2E2E" />
      <path
        d="M20.25 23.25H3.75C2.09325 23.25 0.75 21.9068 0.75 20.25V17.25C0.75 15.5932 2.09325 14.25 3.75 14.25H12.894C13.308 14.25 13.644 14.586 13.644 15C13.644 15.414 13.308 15.75 12.894 15.75H3.75C2.92125 15.75 2.25 16.4212 2.25 17.25V20.25C2.25 21.0788 2.92125 21.75 3.75 21.75H20.25C21.0788 21.75 21.75 21.0788 21.75 20.25V17.25C21.75 16.7835 21.5317 16.3515 21.1777 16.0718C20.9392 15.8828 20.8717 15.5467 20.9767 15.261C21.1507 14.7847 21.7493 14.6017 22.1423 14.9227C22.8285 15.483 23.2507 16.3342 23.2507 17.25V20.25C23.2507 21.9068 21.9067 23.25 20.25 23.25Z"
        fill="#2E2E2E"
      />
      <path
        d="M3.74977 11.8156H20.2498C21.9065 11.8156 23.2498 10.4723 23.2498 8.81555V5.81555C23.2498 4.1588 21.9065 2.81555 20.2498 2.81555H11.8565C11.4425 2.81555 11.1065 3.15155 11.1065 3.56555C11.1065 3.97955 11.4425 4.31555 11.8565 4.31555H20.2505C21.0793 4.31555 21.7505 4.9868 21.7505 5.81555V8.81555C21.7505 9.6443 21.0793 10.3156 20.2505 10.3156H3.74977C2.92102 10.3156 2.24977 9.6443 2.24977 8.81555V5.81555C2.24977 5.34905 2.46802 4.91705 2.82202 4.6373C3.06052 4.4483 3.12802 4.1123 3.02302 3.82655C2.84902 3.3503 2.25052 3.1673 1.85752 3.4883C1.17127 4.04855 0.749023 4.8998 0.749023 5.81555V8.81555C0.749023 10.4723 2.09302 11.8156 3.74977 11.8156Z"
        fill="#2E2E2E"
      />
    </g>
    <defs>
      <clipPath id="clip0_4055_17663">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SingleQuestion;
