import { useEffect, useState } from "react";

const ShareArrowIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 14"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <g clip-path="url(#clip0_5550_18272)">
        <path
          d="M10.5739 11.0833C10.5739 12.6933 9.26723 14 7.65723 14H3.57389C1.96389 14 0.657227 12.6933 0.657227 11.0833V6.99998C0.657227 5.38999 1.96389 4.08332 3.57389 4.08332H4.15723C4.47806 4.08332 4.74056 4.34582 4.74056 4.66665C4.74056 4.98748 4.47806 5.24999 4.15723 5.24999H3.57389C2.61139 5.24999 1.82389 6.03749 1.82389 6.99998V11.0833C1.82389 12.0458 2.61139 12.8333 3.57389 12.8333H7.65723C8.61973 12.8333 9.40723 12.0458 9.40723 11.0833C9.40723 10.7625 9.66973 10.5 9.99056 10.5C10.3114 10.5 10.5739 10.7625 10.5739 11.0833ZM14.1497 3.43582L10.9939 0.174985C10.7722 -0.0583484 10.3989 -0.0641817 10.1714 0.163318C9.94389 0.390818 9.93223 0.758318 10.1597 0.985818L13.1581 4.07748H8.24056C6.63056 4.07748 5.32389 5.38415 5.32389 6.99415V9.91082C5.32389 10.2317 5.58639 10.4942 5.90723 10.4942C6.22806 10.4942 6.49056 10.2317 6.49056 9.91082V6.99415C6.49056 6.03165 7.27806 5.24415 8.24056 5.24415H13.1522L10.1539 8.33582C9.93223 8.56915 9.93223 8.93665 10.1656 9.15832C10.2764 9.26915 10.4281 9.32165 10.5739 9.32165C10.7197 9.32165 10.8772 9.26332 10.9939 9.14665L14.1439 5.89748C14.8264 5.21498 14.8264 4.10665 14.1497 3.42998V3.43582Z"
          fill="#2E2E2E"
        />
      </g>
      <defs>
        <clipPath id="clip0_5550_18272">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.657227)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShareArrowIcon;
