import { useEffect, useState } from "react";

const CopyIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <path
        d="M4.78955 4.52042V2.332C4.78968 1.97869 4.93012 1.63989 5.17999 1.39011C5.42986 1.14032 5.76871 1 6.12202 1H17.6676C18.021 1 18.3599 1.14039 18.6098 1.39027C18.8597 1.64016 19.0001 1.97908 19.0001 2.33247V13.8785C19 14.2318 18.8596 14.5705 18.6098 14.8203C18.36 15.07 18.0213 15.2104 17.6681 15.2105H15.455"
        stroke="#6F6F6F"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8785 4.78955H2.33153C1.9783 4.7898 1.63962 4.9303 1.38994 5.18016C1.14025 5.43002 1 5.76879 1 6.12202V17.6676C1 18.021 1.14039 18.3599 1.39027 18.6098C1.64016 18.8597 1.97908 19.0001 2.33247 19.0001H13.8785C14.2318 19 14.5705 18.8596 14.8203 18.6098C15.07 18.36 15.2104 18.0213 15.2105 17.6681V6.12108C15.2103 5.76793 15.0698 5.42932 14.8201 5.17966C14.5703 4.92999 14.2317 4.78968 13.8785 4.78955Z"
        stroke="#6F6F6F"
        stroke-width="1.6"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
