import { useEffect, useState } from "react";

const ShareIconOutline = ({ className, fill = "white" }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 35 35"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <g clip-path="url(#clip0_5824_15057)">
        <path
          d="M21.8805 21.1076C21.0181 21.1076 20.2411 21.48 19.7018 22.0725L14.8512 19.0683C14.9807 18.7367 15.0525 18.3767 15.0525 18C15.0525 17.6232 14.9807 17.2631 14.8512 16.9317L19.7018 13.9274C20.2411 14.5198 21.0181 14.8924 21.8805 14.8924C23.5051 14.8924 24.8268 13.5707 24.8268 11.9461C24.8268 10.3215 23.5051 9 21.8805 9C20.2559 9 18.9343 10.3217 18.9343 11.9463C18.9343 12.3229 19.0062 12.683 19.1356 13.0145L14.2851 16.0187C13.7458 15.4263 12.9688 15.0537 12.1064 15.0537C10.4818 15.0537 9.16016 16.3755 9.16016 18C9.16016 19.6246 10.4818 20.9462 12.1064 20.9462C12.9688 20.9462 13.7458 20.5738 14.2851 19.9812L19.1356 22.9854C19.0062 23.3169 18.9343 23.677 18.9343 24.0538C18.9343 25.6783 20.2559 27 21.8805 27C23.5051 27 24.8268 25.6783 24.8268 24.0538C24.8268 22.4292 23.5051 21.1076 21.8805 21.1076ZM20.0086 11.9463C20.0086 10.9141 20.8483 10.0743 21.8805 10.0743C22.9127 10.0743 23.7524 10.9141 23.7524 11.9463C23.7524 12.9784 22.9127 13.8182 21.8805 13.8182C20.8483 13.8182 20.0086 12.9784 20.0086 11.9463ZM12.1064 19.8719C11.0741 19.8719 10.2343 19.0321 10.2343 18C10.2343 16.9678 11.0741 16.1281 12.1064 16.1281C13.1386 16.1281 13.9782 16.9678 13.9782 18C13.9782 19.0321 13.1386 19.8719 12.1064 19.8719ZM20.0086 24.0537C20.0086 23.0215 20.8483 22.1818 21.8805 22.1818C22.9127 22.1818 23.7524 23.0215 23.7524 24.0537C23.7524 25.0859 22.9127 25.9256 21.8805 25.9256C20.8483 25.9256 20.0086 25.0859 20.0086 24.0537Z"
          fill="#2E2E2E"
          stroke="#2E2E2E"
          stroke-width="0.170323"
        />
      </g>
      <defs>
        <clipPath id="clip0_5824_15057">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(8 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShareIconOutline;
