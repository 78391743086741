import React from "react";

const ProgressBar = ({ value, total = 10 }) => {
  const progressPercentage = (value / total) * 100;

  return (
    <div className="flex flex-1 items-center">
      <div className="relative w-full h-[6px] bg-[#ECECEC] rounded-full">
        <div
          className="absolute top-0 left-0 h-[6px] bg-[#EFBF04] rounded-full"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <span className="font-montserrat text-light-grey font-semibold ml-2 text-[10px] ">
        {value}
      </span>
    </div>
  );
};

export default ProgressBar;
