import { useLayoutEffect } from "react";
import ChevronLeftIcon from "../../assets/svg/ChevronLeftIcon";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const tabs = [
  { name: "Candidates", path: "candidates" },
  { name: "Companies", path: "companies" },
];

const ContactsPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (pathname === "/contacts") {
      navigate("candidates");
    }
  }, [pathname]);

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center self-start"
      >
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>

      {/* Tabs container */}
      <div className="flex items-center justify-around border rounded-xl mt-4 overflow-hidden h-10">
        {tabs?.map((item, index) => {
          return (
            <button
              onClick={() => navigate(item?.path)}
              key={item?.name}
              style={{
                borderBottom: pathname?.includes(item?.path)
                  ? "3px solid #FF9800"
                  : "3px solid transparent",
              }}
              className={`w-1/2 flex justify-center items-center h-full cursor-pointer ${
                pathname?.includes(item?.path)
                  ? "text-primary"
                  : "text-light-pink"
              } font-semibold text-sm`}
            >
              {item?.name}
            </button>
          );
        })}
      </div>
      <div className="flex flex-1 flex-col overflow-y-hidden no-scrollbar">
        <Outlet />
      </div>
    </div>
  );
};

export default ContactsPage;
