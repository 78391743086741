import profileImage from "../../assets/images/profilepic.png";

const matchedItems = [
  {
    jobTitle: "Web Designer",
    approved: false,
  },
  {
    jobTitle: "Marketing Coordinator",
    approved: true,
  },
  {
    jobTitle: "President of States",
    approved: true,
  },
  {
    jobTitle: "Mean Stack Developer",
    approved: true,
  },
  {
    jobTitle: "Senior Java Developer",
    approved: false,
  },
  {
    jobTitle: "Web Designer",
    approved: false,
  },
  {
    jobTitle: "Marketing Coordinator",
    approved: true,
  },
  {
    jobTitle: "President of States",
    approved: true,
  },
  {
    jobTitle: "Mean Stack Developer",
    approved: true,
  },
  {
    jobTitle: "Senior Java Developer",
    approved: false,
  },
];

const RequestedDetailsPage = () => {
  return (
    <div className="flex flex-col items-center w-full max-h-fit">
      <span className="font-semibold text-sm mt-4 mb-9 text-light-grey">
        Possible matched results.
      </span>
      <div className="flex flex-col w-full gap-10">
        {matchedItems?.map((item, index) => {
          return (
            <div key={index} className="flex items-center w-full gap-7">
              <div className="grid grid-cols-[0.75fr_1.5fr_1fr_1fr] py-2.5 bg-white rounded-sm border flex-1 items-center px-5">
                <span className="font-medium text-xs italic text-gray-500">
                  100% matched
                </span>
                <div className="flex items-center gap-5">
                  <div className="w-14 relative">
                    <img
                      src={profileImage}
                      className="w-14 h-14 absolute -top-7"
                    />
                  </div>
                  <span className="font-medium text-xs text-primary">Jane</span>
                </div>
                <span className="font-medium text-xs text-primary">
                  {item?.jobTitle}
                </span>
                <span className="font-medium text-xs text-green-900 text-end">
                  Available
                </span>
              </div>
              <div className="flex items-center gap-3">
                <div className="flex flex-col items-center w-24">
                  <span className="text-xs italic font-medium">
                    {item?.approved ? "Approved" : "Requested"}
                  </span>
                  <span className="text-xs italic font-medium text-gray-600">
                    18/12/24
                  </span>
                </div>
                {item?.approved ? (
                  <button className="rounded-md text-primary border text-xs font-medium py-2 w-24">
                    View profile
                  </button>
                ) : (
                  <div className="w-24" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RequestedDetailsPage;
