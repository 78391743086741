import api from "../api";
import axios from "../utils/axios.instance";
import handleError from "./handle-error";

export const accountMembers = async ({
  page = 1,
  limit = 10,
  searchText = "",
  status = null,
}) => {
  try {
    const params = new URLSearchParams();
    if (page) params.append("page", page);
    if (limit) params.append("limit", limit);
    if (searchText) params.append("searchText", searchText);
    if (status) params.append("status", status);

    const endpoint = `${api.accountMembers}?${params.toString()}`;
    const { data } = await axios.get(endpoint);

    return data;
  } catch (error) {
    throw handleError(err);
  }
};

export const recruiterDetailById = async ({ recruiterId }) => {
  try {
    const { data } = await axios.get(api.recruiterDetail + `/${recruiterId}`);
    return data;
  } catch (err) {}
};

export const updateRecruiterProfile = async (payload) => {
  try {
    const { data } = await axios.patch(api.recruiterProfileUpdate, payload);
    return data;
  } catch (err) {}
};

export const updateReferralProfile = async (payload) => {
  try {
    const { data } = await axios.patch(api.referralProfileUpdate, payload);
    return data;
  } catch (err) {}
};

export const approvalRequest = async ({ recruiterId, status }) => {
  try {
    const params = new URLSearchParams();
    if (recruiterId) params.append("id", recruiterId);
    if (status) params.append("status", status);

    const endpoint = `${api.approvalRequest}?${params.toString()}`;

    const { data } = await axios.get(endpoint);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const suspendAccount = async ({ recruiterId }) => {
  try {
    const endpoint = `${api.suspendAccount}/${recruiterId}`;
    const { data } = await axios.get(endpoint);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const makeAdminById = async ({ recruiterId }) => {
  try {
    const { data } = await axios.get(api.userAdmin + `/${recruiterId}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const deleteRecruiterAccount = async (id) => {
  try {
    const { data } = await axios.delete(api.deleteRecruiterAccount + `/${id}`);
    if (data.success) return data;
    return false;
  } catch (err) {
    return handleError(err);
  }
};

export const inviteUserViaEmail = async (payload) => {
  try {
    const { data } = await axios.post(api.inviteUserEmail, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
