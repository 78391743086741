import { Link, useNavigate } from "react-router-dom";
import ChevronDown from "../../../assets/svg/ChevronDown";
import PlusIcon from "../../../assets/svg/PlusIcon";
import SearchIcon from "../../../assets/svg/SearchIcon";
import { AssessmentSetupData } from "./dummyData";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
  deleteAssessment,
  listAssessment,
} from "../../../endpoints/assessment.service";
import Spinner from "../../../components/Spinner/Spinner";
import { setAssessmentList } from "../../../store/actions/assessment.action";
import { useDispatch, useSelector } from "react-redux";

const LiveAssessmentCard = ({ item, onClickAssessment }) => {
  return (
    <Link
      key={item?._id}
      to={"assessment-details"}
      state={item}
      className="flex items-center py-3.5 border rounded-md bg-[#FAFAFA] pl-3  hover:bg-white cursor-pointer mb-3"
      // onClick={() => deleteAssessment(item?._id)}
      // onClick={() => onClickAssessment && onClickAssessment(item)}
    >
      <label className="w-1/4 font-medium text-xs text-primary">
        {item.refNo}
      </label>
      <label
        style={{ width: "30%" }}
        className="font-medium text-xs text-primary"
      >
        {item.title}
      </label>
      <label
        style={{ width: "30%" }}
        className="font-semibold text-xs text-primary"
      >
        Assessments taken ({item.assessmentsTaken})
        <label
          className={`${
            item.newAssessments > 0 ? "text-secondary" : "text-primary"
          } font-medium text-xs pl-2`}
        >
          New ({item.newAssessments})
        </label>
      </label>
      <label
        style={{ width: "20%" }}
        className="italic font-medium text-xs text-primary"
      >
        {moment(item.createdAt).format("hh:mm")}
        <label className="font-medium text-xs text-primary pl-2">
          {moment(item.createdAt).format("DD/MM/YYYY")}
        </label>
      </label>
    </Link>
  );
};

const AssessmentSetupCard = ({ item, onClickAssessment }) => {
  return (
    <div
      className="flex items-center py-3.5 border rounded-md bg-[#FAFAFA] pl-3  hover:bg-white cursor-pointer mb-3"
      onClick={() => onClickAssessment && onClickAssessment(item)}
    >
      <label className="w-1/4 font-medium text-xs text-primary">
        {item.refId}
      </label>
      <label
        style={{ width: "30%" }}
        className="font-medium text-xs text-primary"
      >
        {item.title}
      </label>
      <label
        style={{ width: "30%" }}
        className={`${
          item.status === "Assessment Live" ? "text-secondary" : "text-primary"
        } font-semibold text-xs`}
      >
        {item.status}
      </label>
      <label
        style={{ width: "20%" }}
        className="italic font-medium text-xs text-primary"
      >
        {item.time}
        <label className="font-medium text-xs text-primary pl-2">
          {item.date}
        </label>
      </label>
    </div>
  );
};

const AssessmentsSection = ({ activeTab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { assessmentList } = useSelector((state) => state.assessment);
  const [loading, setLoading] = useState(assessmentList?.length === 0);

  useEffect(() => {
    listAssessment()
      .then((res) => {
        dispatch(setAssessmentList(res.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const onClickAssessment = useCallback(
    (assessment) => {
      navigate("assessment-details", {
        state: assessment,
      });
    },
    [navigate]
  );

  return (
    <>
      {/* Search and filter section */}
      <div className="flex items-center justify-between w-full mt-6">
        <div className="flex items-center gap-8">
          {/* Input container */}
          <div className="flex rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5">
            <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-2">
              <label className="text-primary text-sm font-medium">
                Search by
              </label>
              <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
            </button>
            <input
              className="bg-white text-xs font-medium text-light-black outline-none px-3 py-2 rounded-lg w-80"
              placeholder="Search ..."
            />
            <SearchIcon className={"w-6 h-6"} fill={"#2e2e2e"} />
          </div>
          {activeTab !== "Live Assessments" && (
            <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-4">
              <label className="text-primary text-sm font-medium">Status</label>
              <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
            </button>
          )}
        </div>

        <button
          onClick={() => navigate("/my-assessments/create-assessment")}
          className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-semibold text-sm"
        >
          <PlusIcon className={"w-4 h-4"} fill={"white"} />
          Assessment
        </button>
      </div>

      {loading ? (
        <div className="gap-2.5 flex mt-2 flex-col">
          {Array.from({ length: 10 }, (x, i) => i).map((key) => {
            return (
              <div className="flex justify-between">
                <div
                  style={{
                    borderWidth: 1,
                  }}
                  className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
                >
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {/* ScrollArea  */}
          <div className="flex flex-1 flex-col pt-5 max-h-fit overflow-y-hidden">
            <div className="flex items-center pb-3 pl-3">
              <label className="w-1/4 flex font-semibold text-xs text-primary">
                Reference
              </label>
              <label
                style={{ width: "30%" }}
                className="flex font-semibold text-xs text-primary"
              >
                Title
              </label>
              <label
                style={{ width: "30%" }}
                className="flex font-semibold text-xs text-primary"
              >
                Status
              </label>
              <div style={{ width: "20%" }} />
            </div>
            <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar">
              {activeTab === "Live Assessments" ? (
                <>
                  {assessmentList.map((item) => {
                    return (
                      <LiveAssessmentCard
                        key={item?._id}
                        item={item}
                        onClickAssessment={onClickAssessment}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  {AssessmentSetupData.map((item) => {
                    return (
                      <AssessmentSetupCard
                        key={item?.refId}
                        item={item}
                        onClickAssessment={onClickAssessment}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AssessmentsSection;
