import * as React from "react";
const ProfileIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M5.66634 9C5.66634 10.2867 6.71301 11.3333 7.99967 11.3333C9.28634 11.3333 10.333 10.2867 10.333 9C10.333 7.71333 9.28634 6.66667 7.99967 6.66667C6.71301 6.66667 5.66634 7.71333 5.66634 9ZM8.99967 9C8.99967 9.55133 8.55101 10 7.99967 10C7.44834 10 6.99967 9.55133 6.99967 9C6.99967 8.44867 7.44834 8 7.99967 8C8.55101 8 8.99967 8.44867 8.99967 9ZM9.60901 0H3.33301C2.23034 0 1.33301 0.897333 1.33301 2V16H14.6663V5.05733L9.60901 0ZM9.99967 2.276L12.3903 4.66667H9.99967V2.276ZM9.99967 14.6667H5.99967V14C5.99967 13.6327 6.29901 13.3333 6.66634 13.3333H9.33301C9.70101 13.3333 9.99967 13.6327 9.99967 14V14.6667ZM13.333 14.6667H11.333V14C11.333 12.8973 10.4357 12 9.33301 12H6.66634C5.56367 12 4.66634 12.8973 4.66634 14V14.6667H2.66634V2C2.66634 1.63267 2.96567 1.33333 3.33301 1.33333H8.66634V6H13.333V14.6667Z"
      fill="#4D4D4D"
    />
  </svg>
);
export default ProfileIcon;
