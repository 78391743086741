import { useEffect, useState } from "react";

const PlusIcon = ({ className, fill }) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="0" stroke={fill} class={_class}>
          <path d="M5 12H12M12 12H19M12 12V5M12 12V19" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>

        </svg>
    )
};

export default PlusIcon;