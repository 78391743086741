import { useEffect, useState } from "react";

const CompanyIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 30 30"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <mask id="mask0_5039_17013" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
        <path d="M0 0H30V30H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5039_17013)">
        <path d="M18.75 28.75H6.25C3.48875 28.75 1.25 26.5113 1.25 23.75V6.25C1.25 3.48875 3.48875 1.25 6.25 1.25H13.75C16.5113 1.25 18.75 3.48875 18.75 6.25V28.75Z" stroke="white" stroke-width="2.66667" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.25 17.5H7.5" stroke="white" stroke-width="2.66667" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.5 17.5H13.75" stroke="white" stroke-width="2.66667" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.25 22.5H7.5" stroke="white" stroke-width="2.66667" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.5 22.5H13.75" stroke="white" stroke-width="2.66667" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.25 7.5H7.5" stroke="white" stroke-width="2.66667" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.5 7.5H13.75" stroke="white" stroke-width="2.66667" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.25 12.5H7.5" stroke="white" stroke-width="2.66667" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.5 12.5H13.75" stroke="white" stroke-width="2.66667" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18.75 7.5H23.75C26.5112 7.5 28.75 9.73875 28.75 12.5V23.75C28.75 26.5113 26.5112 28.75 23.75 28.75H18.75" stroke="white" stroke-width="2.66667" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M23.75 16.25C23.06 16.25 22.5 16.8088 22.5 17.5C22.5 18.1913 23.06 18.75 23.75 18.75C24.44 18.75 25 18.1913 25 17.5C25 16.8088 24.44 16.25 23.75 16.25Z" fill="white" />
        <path d="M23.75 21.25C23.06 21.25 22.5 21.8088 22.5 22.5C22.5 23.1913 23.06 23.75 23.75 23.75C24.44 23.75 25 23.1913 25 22.5C25 21.8088 24.44 21.25 23.75 21.25Z" fill="white" />
        <path d="M23.75 13.75C24.44 13.75 25 13.1913 25 12.5C25 11.8088 24.44 11.25 23.75 11.25C23.06 11.25 22.5 11.8088 22.5 12.5C22.5 13.1913 23.06 13.75 23.75 13.75Z" fill="white" />
      </g>
    </svg>
  );
};

export default CompanyIcon;
