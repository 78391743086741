import React, { useState, useEffect } from "react";
import TickSquareIcon from "../../assets/svg/TickSquareIcon";

const Checkbox = ({
  value = false,
  disabled = false,
  handleClick,
  className = "w-5 h-5",
  fill = "#21AC6A",
  // onChange
}) => {
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  return (
    <button
      disabled={disabled}
      onClick={() => handleClick && handleClick(isChecked, setIsChecked)}
      style={{
        border: !isChecked ? "2px solid lightgray" : "none",
      }}
      className={`${className} rounded-sm flex items-center justify-center z-50`}
    >
      {isChecked && <TickSquareIcon fill={fill} className={`${className}`} />}
    </button>
  );
};

export default Checkbox;
