import * as React from "react";
const MyContactsIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_4691_13467)">
      <path
        d="M13.2187 2.27601L11.724 0.781343C11.4769 0.532881 11.183 0.335893 10.8593 0.201777C10.5355 0.0676619 10.1884 -0.000917112 9.838 9.26126e-06H4C3.46957 9.26126e-06 2.96086 0.210723 2.58579 0.585796C2.21071 0.960868 2 1.46958 2 2.00001V16H14V4.16134C13.9982 3.45448 13.7174 2.7769 13.2187 2.27601ZM12.276 3.21868C12.3095 3.25505 12.3409 3.29334 12.37 3.33334H10.6667V1.63001C10.7067 1.6591 10.745 1.69049 10.7813 1.72401L12.276 3.21868ZM3.33333 14.6667V2.00001C3.33333 1.8232 3.40357 1.65363 3.5286 1.5286C3.65362 1.40358 3.82319 1.33334 4 1.33334H9.33333V4.66668H12.6667V14.6667H3.33333ZM4.66667 10.6667H11.3333V6.66668H4.66667V10.6667ZM6 8.00001H10V9.33334H6V8.00001ZM4.66667 12H11.3333V13.3333H4.66667V12Z"
        fill="#4D4D4D"
      />
    </g>
    <defs>
      <clipPath id="clip0_4691_13467">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MyContactsIcon;
