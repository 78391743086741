import * as React from "react";
const AddIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      fill="#2E2E2E"
      d="M8 13.75a.781.781 0 0 1-.781-.782V3.032a.781.781 0 1 1 1.562 0v9.936c0 .432-.35.781-.781.781Z"
    />
    <path
      fill="#2E2E2E"
      d="M12.967 8.781H3.031a.781.781 0 1 1 0-1.562h9.936a.781.781 0 1 1 0 1.562Z"
    />
  </svg>
);
export default AddIcon;
