import { useEffect, useState } from "react";

const BellIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 28"
      class={_class}
    >
      <path
        d="M25.1186 16.8814L22.8964 14.6592C22.8027 14.5654 22.75 14.4383 22.75 14.3056V11.375C22.7473 9.20661 21.9406 7.11624 20.4861 5.50811C19.1327 4.01191 17.3079 3.03204 15.323 2.72605C15.0695 2.68696 14.875 2.4736 14.875 2.21706V1.375C14.875 1.09886 14.6511 0.875 14.375 0.875H13.625C13.3489 0.875 13.125 1.09886 13.125 1.375V2.21706C13.125 2.4736 12.9305 2.68696 12.677 2.72605C10.6921 3.03204 8.86729 4.01191 7.51395 5.50811C6.05936 7.11624 5.25271 9.20661 5.25 11.375V14.3056C5.25 14.4383 5.19732 14.5654 5.10355 14.6592L2.88137 16.8814C2.71727 17.0454 2.62505 17.268 2.625 17.5V20.125C2.625 20.3571 2.71719 20.5796 2.88128 20.7437C3.04538 20.9078 3.26794 21 3.5 21H9.125C9.40114 21 9.625 21.2239 9.625 21.5V21.6799C9.60597 22.7899 9.99723 23.868 10.7238 24.7074C11.4504 25.5469 12.4612 26.0887 13.5625 26.229C14.1708 26.2893 14.785 26.2217 15.3655 26.0304C15.9461 25.8391 16.4802 25.5284 16.9335 25.1183C17.3867 24.7082 17.7492 24.2078 17.9974 23.6492C18.2457 23.0906 18.3743 22.4863 18.375 21.875V21.5C18.375 21.2239 18.5989 21 18.875 21H24.5C24.7321 21 24.9546 20.9078 25.1187 20.7437C25.2828 20.5796 25.375 20.3571 25.375 20.125V17.5C25.375 17.268 25.2827 17.0454 25.1186 16.8814ZM16.625 21.875C16.625 22.5712 16.3484 23.2389 15.8562 23.7312C15.3639 24.2234 14.6962 24.5 14 24.5C13.3038 24.5 12.6361 24.2234 12.1438 23.7312C11.6516 23.2389 11.375 22.5712 11.375 21.875V21.5C11.375 21.2239 11.5989 21 11.875 21H16.125C16.4011 21 16.625 21.2239 16.625 21.5V21.875ZM23.625 18.75C23.625 19.0261 23.4011 19.25 23.125 19.25H4.875C4.59886 19.25 4.375 19.0261 4.375 18.75V18.0694C4.375 17.9368 4.42768 17.8096 4.52145 17.7158L6.74362 15.4936C6.90773 15.3296 6.99995 15.107 7 14.875V11.375C7 9.51849 7.7375 7.73801 9.05025 6.42525C10.363 5.1125 12.1435 4.375 14 4.375C15.8565 4.375 17.637 5.1125 18.9497 6.42525C20.2625 7.73801 21 9.51849 21 11.375V14.875C21 15.107 21.0923 15.3296 21.2564 15.4936L23.4786 17.7158C23.5723 17.8096 23.625 17.9367 23.625 18.0694V18.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default BellIcon;
