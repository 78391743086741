import "twin.macro";
import tw, { styled, css } from "twin.macro";

const scrollHide = css`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ContentContainer = styled.div(() => [
  tw`bg-white flex flex-col flex-1 w-full h-full p-4 max-h-[88vh] rounded-lg overflow-y-scroll`,
  scrollHide,
]);
