import { Cropper } from "react-cropper";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput/CustomInput";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import { colors } from "../../utils/theme";
import regexList from "../../utils/regexList";
import EmailSuccess from "../../assets/svg/EmailSuccess";
import { addCompanyInfo } from "../../endpoints/user.service";
import { useSnackbar } from "notistack";
import { profileImage, uploadUrl } from "../../endpoints/images.service";
import { RecruiterApprovalStatus } from "../../utils/constants";

const RecruiterCreateProfilePage = () => {
  const navigate = useNavigate();
  const cropperRef = useRef(null);
  const filePickerRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState(1);
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    trigger,
  } = useForm({
    defaultValues: {
      companyName: user?.companyName ?? "",
      websiteURL: "",
      companyNo: "",
      contactNo: "",
      emailAddress: "",
      image: undefined,
      profileImage: undefined,
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      isLoading: false,
      isModalOpen: false,
    },
  });
  const fields = watch();

  // Handle file selection
  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      setValue("file", file, { shouldDirty: true });
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("image", reader.result, { shouldDirty: true });
          setValue("isModalOpen", true);
          trigger("image");
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("Error during file upload or API calls:", error);
    }
  };

  // Get the cropped image
  const getCroppedImage = (e) => {
    e.preventDefault();

    if (cropperRef.current) {
      const cropperInstance = cropperRef.current.cropper;
      if (cropperInstance) {
        const croppedDataUrl = cropperInstance.getCroppedCanvas().toDataURL();
        setValue("profileImage", croppedDataUrl);
        setValue("isModalOpen", false);
      } else {
        console.error("Cropper instance is not found");
      }
    } else {
      console.error("cropperRef.current is not initialized");
    }
  };

  const handleNext = async (formData) => {
    if (step === 2) {
      try {
        setValue("isLoading", true);
        console.log("formData", formData);

        const payload = {
          name: formData?.companyName,
          websiteUrl: formData?.websiteURL,
          address1: formData?.streetAddress,
          city: formData?.city,
          state: formData?.state,
          zipCode: formData?.postalCode,
          country: formData?.country,
          email: formData?.emailAddress,
          countryCode: "",
          phoneNumber: formData?.companyNo,
          primaryContactNumber: formData?.contactNo,
        };

        if (formData?.profileImage) {
          const { data } = await profileImage();
          if (!data?.data || !data?.data.uploadURL) {
            throw new Error("Failed to get upload URL");
          }
          const apiformData = new FormData();
          const base64Data = formData?.profileImage?.split(";base64,").pop();
          console.log(base64Data, "base64Data");

          const byteArray = Uint8Array.from(atob(base64Data), (c) =>
            c.charCodeAt(0)
          );
          const blob = new Blob([byteArray], { type: formData?.file?.type });
          apiformData.append("file", blob, formData?.file?.name);
          const fileResponse = await uploadUrl(
            data?.data.uploadURL,
            apiformData
          );
          delete formData["file"];
          delete formData["profileImage"];
          delete formData["image"];
          const picURl = fileResponse?.variants?.find(
            (x) => x.split("/").slice(-1)[0] === "public"
          );
          payload["logo"] = picURl;
        }

        const data = await addCompanyInfo(payload);
        console.log("asdasdasd", data);
        if (data?.success) {
          setStep((prev) => prev + 1);
        }
      } catch (error) {
        enqueueSnackbar(error, "error");
      } finally {
        setValue("isLoading", false);
      }
      return;
    }
    setStep((prev) => prev + 1);
  };

  const closeModal = () => {
    setValue("isModalOpen", false);
    setValue("image", undefined);
    setValue("file", undefined);
    setValue("profileImage", undefined);
  };

  useEffect(() => {
    if (user?.recruiterApprovalStatus === RecruiterApprovalStatus.REQUESTED) {
      setStep(3);
    } else if (!user?.recruiterApprovalStatus) {
      setStep(1);
    } else {
      navigate("/");
    }
  }, [user]);

  return (
    <div className="flex flex-col rounded-lg bg-white p-10 flex-grow w-full overflow-y-scroll no-scrollbar">
      {step !== 3 ? (
        <>
          <span className="font-semibold text-primary text-center">
            Please complete your company details
          </span>

          <div className="flex flex-col gap-5 rounded-md bg-gray-50 px-10 pt-14 pb-5 mt-8">
            {/* Form container */}
            <>
              {/* Step 1 Form */}
              {step === 1 ? (
                <>
                  <div className="flex gap-14 items-center">
                    <div className="flex flex-1 flex-col gap-5">
                      <CustomInput
                        label="Company Name"
                        name="companyName"
                        placeholder={"Ex: ABC Company"}
                        rules={{
                          required: "This field is required.",
                        }}
                        control={control}
                        errors={errors}
                      />
                      <CustomInput
                        label="Website URL"
                        name="websiteURL"
                        placeholder={"Enter your company's website URL"}
                        rules={{
                          required: "This field is required.",
                          pattern: {
                            value: regexList.website,
                            message: "Please enter a valid website URL.",
                          },
                        }}
                        control={control}
                        errors={errors}
                      />
                      <CustomInput
                        label="Registered Company Number"
                        name="companyNo"
                        placeholder={"Enter your company's registered number"}
                        rules={{
                          required: "This field is required.",
                        }}
                        control={control}
                        errors={errors}
                      />
                      <CustomInput
                        label={"Primary contact number"}
                        name="contactNo"
                        placeholder={"Enter contact number"}
                        rules={{
                          required: "This field is required.",
                          pattern: {
                            value: regexList.contactRegex,
                            message: "Please enter a valid contact number.",
                          },
                        }}
                        control={control}
                        errors={errors}
                      />
                      <CustomInput
                        label={"Company email address"}
                        name="emailAddress"
                        placeholder={"Enter email address"}
                        rules={{
                          required: "This field is required.",
                          pattern: {
                            value: regexList.email,
                            message: "Please enter a valid email.",
                          },
                        }}
                        control={control}
                        errors={errors}
                      />
                    </div>
                    <div className="flex flex-col flex-1 max-w-[40%] gap-6">
                      <div className="flex self-center rounded-full h-40 w-40 bg-white items-center justify-center border">
                        {fields?.profileImage || user?.profilePic ? (
                          <img
                            src={fields?.profileImage || user?.profilePic}
                            className="rounded-full h-40 w-40 object-fill"
                          />
                        ) : (
                          <span className="font-montserrat font-extrabold text-5xl text-gray-200">
                            LOGO
                          </span>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => filePickerRef?.current?.click()}
                        className="flex  items-center border rounded-lg overflow-hidden pr-2"
                      >
                        <span className="text-sm text-[#6f6f6f] font-medium py-2.5 px-3 bg-[#E9EAF0]">
                          PNG/JPG
                        </span>
                        <span className="text-xs flex font-medium px-3 text-ngray flex-1 text-left whitespace-nowrap">
                          {fields?.file
                            ? fields?.file?.name
                            : user?.profilePic
                            ? user?.profilePic
                            : "Upload logo here"}
                        </span>
                      </button>
                      <input
                        ref={filePickerRef}
                        type="file"
                        className="hidden"
                        accept=".png, .jpg"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  {fields?.isModalOpen && (
                    <div
                      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
                      onClick={closeModal} // Close modal when clicking outside the image
                    >
                      <div
                        className="bg-white p-4 rounded-lg relative w-11/12 max-w-3xl"
                        onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
                      >
                        <button
                          onClick={closeModal}
                          className="absolute top-0 right-0 text-black pt-3 pr-5 text-2xl font-semibold"
                        >
                          X
                        </button>
                        <div className="flex flex-col mt-7">
                          {fields?.image && (
                            <Cropper
                              src={fields?.image}
                              ref={cropperRef}
                              style={{ height: 400, width: "100%" }}
                              aspectRatio={1}
                              viewMode={2}
                              guides={false}
                              minCropBoxWidth={50} // Minimum width for the crop area
                              minCropBoxHeight={50} // Minimum height for the crop area
                            />
                          )}
                          <div className="mt-10 flex justify-end">
                            <button
                              onClick={(e) => getCroppedImage(e)}
                              className="bg-primary text-white px-7 py-2.5 text-xl rounded-md"
                            >
                              Crop Image
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <style>
                    {`
                        /* Ensure the cropper's crop box is circular */
                        .cropper-face,
                        .cropper-view-box,
                        .cropper-crop-box {
                          border-radius: 50% !important; /* Make crop area circular */
                          border: 2px solid white !important; /* Optional: Set the color of the circular crop lines */
                        }

                        .cropper-face {
                          background-color: rgba(0, 0, 0, 0.3) !important; /* Optional: Set the background color of the circular crop area */
                        }

                        .cropper-view-box {
                          border-radius: 50% !important; /* Make sure the selection area is circular */
                        }

                        .cropper-crop-box {
                          border-radius: 50% !important; /* Ensure the cropping boundary is circular */
                        }
                      `}
                  </style>
                </>
              ) : (
                <>
                  <span className="font-semibold">Company address</span>
                  <div className="flex flex-1 flex-col gap-5 mx-auto w-1/2">
                    <CustomInput
                      label="Street Address"
                      name="streetAddress"
                      placeholder={"Enter street addresss"}
                      rules={{
                        required: "This field is required.",
                      }}
                      control={control}
                      errors={errors}
                    />
                    <CustomInput
                      label="Town / City"
                      name="city"
                      placeholder={"Enter city name"}
                      rules={{
                        required: "This field is required.",
                      }}
                      control={control}
                      errors={errors}
                    />
                    <CustomInput
                      label="State / Province"
                      name="state"
                      placeholder={"Enter state/province"}
                      rules={{
                        required: "This field is required.",
                      }}
                      control={control}
                      errors={errors}
                    />
                    <CustomInput
                      label={"Postal Code"}
                      name="postalCode"
                      placeholder={"Enter postal code"}
                      rules={{
                        required: "This field is required.",
                      }}
                      control={control}
                      errors={errors}
                    />
                    <CustomInput
                      label={"Country"}
                      name="country"
                      placeholder={"Enter country"}
                      rules={{
                        required: "This field is required.",
                      }}
                      control={control}
                      errors={errors}
                    />
                  </div>
                </>
              )}

              <div className="flex items-center justify-between mt-10">
                {step === 2 ? (
                  <button
                    disabled={fields?.isLoading}
                    onClick={(e) => {
                      setStep(1);
                      //   if (fields?.image) {
                      //     getCroppedImage(e);
                      //   } else {
                      //     updateProfile(fields);
                      //   }
                    }}
                    className="border border-primary items-center flex text-black px-5 py-2 text-sm font-semibold rounded-md w-max mt-1 hover:bg-white"
                  >
                    Back
                  </button>
                ) : (
                  <div />
                )}
                <button
                  disabled={fields?.isLoading}
                  onClick={handleSubmit(handleNext)}
                  className="bg-primary items-center flex text-white px-5 py-2 text-sm font-semibold rounded-md w-max mt-1 hover:bg-black"
                >
                  {fields?.isLoading && (
                    <LoadingAnimation color={colors.White} />
                  )}
                  {step === 2 ? "Save" : "Next"}
                </button>
              </div>
            </>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center flex-1">
          <span className="font-semibold text-primary text-3xl">
            Account authorization pending!
          </span>
          <EmailSuccess className={"w-14 h-14 my-10"} />

          <div className="flex flex-col gap-4 font-medium text-primary w-1/2">
            <span>
              Hello{" "}
              <span className="font-bold">
                {user?.firstName} {user?.lastName}!
              </span>
            </span>
            <span>
              Your account registration is currently under review. You will
              receive a notification on your registered email address as soon as
              it has been authorized.
            </span>
            <span>Thank you for your patience.</span>
          </div>

          {/* <button
            // onClick={() => navigate("/login")}
            type="submit"
            className="bg-secondary flex items-center justify-center rounded-full mt-20 py-3 px-28"
          >
            <span className="text-white font-medium">Back to login page</span>
          </button> */}
        </div>
      )}
    </div>
  );
};

export default RecruiterCreateProfilePage;
