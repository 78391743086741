import { Link } from "react-router-dom";
import { timeSincePost } from "../../../utils/helperFunction";

const JobCard = ({ data }) => {
  const { _id, title, industryType, location, description, updatedAt } =
    data || {};
  return (
    <Link
      to={`detail/${_id}`}
      className="rounded-xl border p-4 hover:bg-light-white-700 h-34 flex flex-col"
    >
      <div className="flex items-center justify-between">
        <span className="font-semibold text-primary text-sm">{title}</span>
        <span className="font-semibold text-xs text-light-black">
          {timeSincePost(updatedAt)}
        </span>
      </div>
      <span className="font-medium text-primary text-sm">{industryType}</span>

      <div className="flex flex-col my-2.5">
        <span className="font-medium text-primary text-sm">{location}</span>
        <span className="font-medium text-primary text-sm">
          Help Desk & IT Support
          <span className="text-xs">
            (Information & Communication Technology)
          </span>
        </span>
      </div>
      <p
        dangerouslySetInnerHTML={{
          __html: description,
        }}
        className="font-medium text-xs text-light-black line-clamp-2"
      />
    </Link>
  );
};

export default JobCard;
