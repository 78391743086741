export const multipleChoice = {
  type: "multiple-choice",
  question: undefined,
  choices: [
    {
      id: 1,
      option: undefined,
    },
    {
      id: 2,
      option: undefined,
    },
    {
      id: 3,
      option: undefined,
    },
    {
      id: 4,
      option: undefined,
    },
  ],
  saved: false,
  editing: true,
  answer: undefined,
};

export const checkBoxQuestion = {
  type: "check-box",
  question: undefined,
  choices: [
    {
      id: 1,
      option: undefined,
    },
    {
      id: 2,
      option: undefined,
    },
    {
      id: 3,
      option: undefined,
    },
    {
      id: 4,
      option: undefined,
    },
  ],
  saved: false,
  editing: true,
  answer: undefined,
};

export const singleAnswer = {
  type: "single-answer",
  question: undefined,
  choices: [],
  saved: false,
  editing: true,
  answer: undefined,
};
