import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import regexList from "../../utils/regexList";
import CustomInput from "../../components/CustomInput/CustomInput";
import { changePassword } from "../../endpoints/user.service";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdatePassword = async (data) => {
    setIsLoading(true);
    const payload = data;
    payload.id = user?._id;
    delete payload.confirmPassword;
    try {
      const { success, data } = await changePassword(payload);
      if (success) {
        enqueueSnackbar("Password changed successfully.", {
          variant: "success",
        });
        navigate("/dashboard");
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
      console.log("Error changing password", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center rounded-lg bg-white w-full flex-grow p-4">
      <div className="flex flex-col bg-light-white-900 p-4 w-2/3 rounded-md">
        <span className="font-bold text-lg text-primary">Change Password</span>
        <span className="font-medium text-xs text-gray-500 mt-1">
          Keep Your Account Safe: Update Your Password Regularly.
        </span>
        <form
          onSubmit={handleSubmit(handleUpdatePassword)}
          className="flex flex-col gap-5 mt-5"
        >
          <CustomInput
            label={"Old Password"}
            name="oldPassword"
            placeholder={"Enter your Old Password"}
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.password,
                message: "Please enter a valid password.",
              },
            }}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"New Password"}
            name="newPassword"
            placeholder={"Enter your New Password"}
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.password,
                message: "Please enter a valid password.",
              },
              validate: (value) =>
                value !== watch("oldPassword") ||
                "New password should not match with old password.",
            }}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"Confirm Password"}
            name="confirmPassword"
            placeholder={"Enter your Confirm Password"}
            rules={{
              required: "This field is required.",
              validate: (value) =>
                value === watch("newPassword") ||
                "Confirm Password do not match.",
            }}
            control={control}
            errors={errors}
          />

          <button
            disabled={!isDirty}
            className={`flex gap-3 bg-primary text-white rounded-md ml-auto px-3 py-2 text-sm font-medium ${
              isDirty ? "opacity-100" : "opacity-50"
            }`}
          >
            Change Password
            {isLoading && <LoadingAnimation />}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
