import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { ExperienceData } from "../experience.types";
import {
  addExperience,
  deleteExperience,
  updateExperience,
} from "../../../../../endpoints/experience.service";
import AddEditExperience from "./addEditExperience";
import { useForm } from "react-hook-form";
import { experienceFeilds } from "./experienceBlock.types";
import { isNullOrEmpty } from "../../../../../utils/helperFunction";
import moment from "moment";
import CloseIcon from "../../../../../assets/svg/CloseIcon";
import EditIcon from "../../../../../assets/svg/EditIcon";
import SkillItem from "./skillItem";
import LoadingAnimation from "../../../../../assets/svg/LoadingAnimation";
import { colors } from "../../../../../utils/theme";

const ExperienceBlock: React.FC<{
  showOnly?: boolean;
  experiences?: ExperienceData[];
  popUpShow?: boolean;
  closeModal?: () => void;
  setExperiences?: React.Dispatch<React.SetStateAction<ExperienceData[]>>;
}> = ({
  showOnly,
  experiences,
  popUpShow = false,
  closeModal,
  setExperiences,
}) => {
    const {
      handleSubmit,
      control,
      setValue,
      reset,
      formState: { errors, touchedFields },
      watch,
    } = useForm<experienceFeilds>({
      defaultValues: {
        startDate: undefined,
        endDate: undefined,
        jobTitle: undefined,
        companyName: undefined,
        location: undefined,
        jobDescription: undefined,
        isCurrent: false,
        isNewAdded: false,
        isLoadingDelete: false,
        skills: []
      },
    });
    const fields = watch();

    useEffect(() => {
      if (!isNullOrEmpty(experiences) && fields?._id) {
        const findExp = experiences?.find((x) => x._id === fields?._id);
        setValue("skills", findExp?.skills);
      }
    }, [experiences, fields?._id, Object?.keys(touchedFields)?.length]);

    const { enqueueSnackbar } = useSnackbar();

    // console.log('====================================');
    // console.log("fields", fields);
    // console.log('====================================');

    const onSave = async (data: experienceFeilds) => {
      try {
        const payload = {
          ...data,
          skills: data?.skills?.map((skill) => {
            return { ...skill, lastUsed: typeof (skill?.lastUsed) === 'string' ? parseInt(skill?.lastUsed) : skill?.lastUsed }
          })
        }
        // return
        if (isNullOrEmpty(payload?.endDate) || payload?.isCurrent === true) {
          delete payload?.endDate;
        }
        if (payload?._id) {
          await updateExperience({ ...payload, id: payload?._id }).then((response) => {
            const { success, data } = response;
            if (success) {
              setExperiences &&
                setExperiences((prev) => {
                  return prev?.map((exp) => {
                    if (exp?._id === data?._id) {
                      return { ...exp, ...data };
                    } else {
                      return exp;
                    }
                  });
                });
              reset({
                startDate: undefined,
                endDate: undefined,
                jobTitle: undefined,
                companyName: undefined,
                location: undefined,
                jobDescription: undefined,
                isCurrent: false,
                isNewAdded: false,
                isLoadingDelete: false,
              });
              enqueueSnackbar("Experience updated successfully.", {
                variant: "success",
              });
            }
          });
        } else {
          await addExperience(payload).then((response) => {
            const { success, data } = response;
            if (success) {
              setExperiences &&
                setExperiences(experiences ? [data, ...experiences] : [data]);
              closeModal && closeModal();
              enqueueSnackbar("Experience added successfully.", {
                variant: "success",
              });
            }
          });
        }
      } catch (error) {

      }
    };

    const handleAddSkill = () => {
      if (fields?.skills?.length) {
        setValue("skills", [
          {
            customId: fields?.skills?.length + 1,
            isEdit: true,
            title: "",
            years: "",
            lastUsed: "",
          },
          ...fields?.skills,
        ]);
      } else {
        if (fields?.startDate && (fields?.endDate || fields?.isCurrent)) {
          setValue("skills", [
            {
              customId: 1,
              isEdit: true,
              title: "",
              years: "",
              lastUsed: "",
            },
          ]);
        } else {
          enqueueSnackbar("Please add Start Date and End Date before adding skill.", {
            variant: "error",
          });
        }
      }
    };

    const handleDeleteSkill = ({ customId, _id }: { customId?: string, _id?: string }) => {
      if (fields?.skills) {
        setValue("skills", fields?.skills?.filter((skill) => _id ? skill?._id !== _id : skill?.customId !== customId));
      }
    }

    const deleteExperienceApiCall = async () => {
      try {
        setValue("isLoadingDelete", true);
        const { success, data } = (await deleteExperience(fields?._id)) as any;
        if (success) {
          const removedData = experiences?.filter((x) => x._id !== fields?._id);
          setExperiences && setExperiences(removedData ? [...removedData] : []);
          reset({
            startDate: undefined,
            endDate: undefined,
            jobTitle: undefined,
            companyName: undefined,
            location: undefined,
            jobDescription: undefined,
            isCurrent: false,
            isNewAdded: false,
            isLoadingDelete: false,
          });
          enqueueSnackbar("Experience deleted successfully.", {
            variant: "success",
          });
        }
      } catch (error: any) {
        enqueueSnackbar(error, {
          variant: "error",
        });
      } finally {
        setValue("isLoadingDelete", false);
      }
    };

    const RenderExperience = ({
      experience,
      index,
    }: {
      experience: ExperienceData;
      index: number;
    }) => (
      <div
        className={`flex justify-between pb-4 ${popUpShow &&
          experiences &&
          index < experiences?.length - 1 &&
          "border-b"
          }`}
      >
        <div className="flex flex-col gap-1 flex-1">
          <span className="font-montserrat text-primary font-bold text-sm">
            {moment(experience?.startDate).format("MMM YYYY")} -{" "}
            {experience?.isCurrent
              ? "Present"
              : moment(experience?.endDate).format("MMM YYYY")}
          </span>
          <span className="font-montserrat text-primary font-semibold text-sm">
            {experience?.jobTitle}, {experience?.companyName}
          </span>
          <span className="font-montserrat text-primary text-xs">
            {experience?.location}
          </span>

          <span
            className="font-montserrat mt-1 text-primary font-medium text-xs text-justify  whitespace-pre-wrap leading-5 pr-10"
            dangerouslySetInnerHTML={{
              __html: experience?.jobDescription?.trim() ?? "",
            }}
          />
          {experience?.skills?.length > 0 && (
            <div className="flex flex-1 flex-col mt-6 gap-2">
              <div className="flex mb-5 grid-cols-4">
                <span className="font-montserrat text-primary font-semibold text-sm flex-1">
                  Skill
                </span>
                <span className="font-montserrat text-primary font-semibold flex-1 text-sm">
                  Experience Level
                </span>
                <span className="font-montserrat text-primary font-semibold flex-1 text-sm text-center">
                  Years
                </span>
                <span className="font-montserrat text-primary font-semibold flex-1 text-sm text-center">
                  Skills Used
                </span>
              </div>
              {experience?.skills?.map((skill: any, index: number) => (
                <SkillItem
                  key={skill?._id}
                  fields={fields}
                  control={control}
                  errors={errors}
                  skillData={skill}
                  workExperienceId={fields?._id}
                  index={index}
                  setExperiences={setExperiences}
                />
              ))}
            </div>
          )}
        </div>

        {popUpShow && (
          //@ts-ignore
          <div onClick={() => reset({ ...experience })}>
            <EditIcon className={"w-6 h-6 cursor-pointer"} fill="#4d4d4d" />
          </div>
        )}
      </div>
    );

    return (
      <div className="flex flex-1 pb-6 flex-col overflow-hidden">
        <div
          className={`flex flex-1 items-center justify-between pb-6 ${popUpShow && "py-6 px-6"
            }`}
        >
          <div className="flex gap-3 items-center">
            <span className="font-montserrat text-primary font-bold text-[14px]">
              Work Experience
            </span>
            {popUpShow && !fields?._id && (
              <button
                className="text-sm border py-1 px-2 text-[#6f6f6f] rounded font-semibold"
                onClick={() => {
                  setValue("isNewAdded", true);
                }}
              >
                + Add Experience
              </button>
            )}
          </div>
          {!showOnly && (
            <button
              onClick={closeModal}
              className="self-end text-black text-2xl font-semibold"
            >
              <CloseIcon className="w-6 h-6" />
            </button>
          )}
        </div>

        <div
          className={`flex flex-1 flex-col overflow-y-scroll no-scrollbar pb-6  ${popUpShow && "px-6"
            }`}
        >
          <div
            className={`flex flex-1 flex-col ${popUpShow && "border px-6 py-6"
              } rounded-lg`}
          >
            {(popUpShow || showOnly) && !fields?._id && !fields?.isNewAdded ? (
              <div className="flex  flex-1 flex-col gap-4">
                {experiences?.map((exp, index) => {
                  return (
                    <RenderExperience
                      experience={exp}
                      key={index}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : (
              <>
                <AddEditExperience
                  control={control}
                  fields={fields}
                  errors={errors}
                  setValue={setValue}
                  handleAddSkill={handleAddSkill}
                  handleDeleteSkill={handleDeleteSkill}
                  setExperiences={setExperiences}
                />
              </>
            )}
          </div>
        </div>
        {(!!fields?._id || fields?.isNewAdded) && (
          <div
            className={`flex border-t ${!!fields?._id ? "justify-between" : "justify-end"
              }  pt-4 px-6`}
          >
            {!!fields?._id && (
              <button
                className="flex items-center justify-center border rounded px-5 py-2 text-primary font-medium text-[15px]"
                onClick={deleteExperienceApiCall}
              >
                {fields?.isLoadingDelete && (
                  <LoadingAnimation color={colors.PrimaryBlack} />
                )}
                Delete Experience
              </button>
            )}

            <button
              className="flex items-center justify-center border bg-primary rounded px-5 py-2 text-white font-medium text-sm"
              onClick={handleSubmit(onSave)}
            >
              Save
            </button>
          </div>
        )}
      </div>
    );
  };

export default ExperienceBlock;
