import React from "react";
import { ButtonContainer, IconWrapper, Text } from "./button.styles";

const Button = ({
  onClick,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  color,
  children,
  variant = "contain",
  style,
  textStyle = ``,
  disabled,
  ...rest
}) => (
  <ButtonContainer
    onClick={onClick}
    color={color}
    variant={variant}
    style={style}
    disabled={disabled}
    {...rest}
  >
    {LeftIcon && <IconWrapper as={LeftIcon} />}
    <Text textStyle={textStyle} color={color} variant={variant}>
      {children}
    </Text>
    {RightIcon && <IconWrapper as={RightIcon} />}
  </ButtonContainer>
);

export default Button;
