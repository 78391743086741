import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GroupIcon from "../../../assets/svg/GroupIcon";
import AppliedIcon from "../../../assets/svg/AppliedIcon";
import CallIcon from "../../../assets/svg/CallIcon";
import ChevronLeftIcon from "../../../assets/svg/ChevronLeftIcon";
import OfferAcceptIcon from "../../../assets/svg/OfferAcceptIcon";
import OfferReceived from "../../../assets/svg/OfferReceived";

const JobTrackDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state || {};

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow overflow-hidden">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center self-start p-5"
      >
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>

      <div className="flex flex-1 flex-col max-h-full px-20 py-10">
        <div className="flex justify-between text-xs">
          <p>
            Company name:{" "}
            <span className="text-xs font-semibold">{item.company}</span>
          </p>
          <p>
            Recruitment agent name :{" "}
            <span className="text-xs font-semibold">{item.recruiterAgent}</span>
          </p>
          <p>
            Job reference no. :{" "}
            <span className="text-xs font-semibold">{item.id}</span>
          </p>
        </div>
        <hr className="border-t-1 border-gray-300 my-4" />
        <div className="py-10 max-h-fit overflow-y-scroll no-scrollbar">
          {item.progress.map((list, index) => (
            <div
              key={index}
              className={`flex flex-1 ${
                index === item.progress.length - 1 && "items-end"
              } gap-7 h-20`}
            >
              <div className="flex flex-col h-full  items-center w-12">
                <div
                  className={`border-l mr-1 ${
                    index === 0 ? "border-transparent" : "border-primary"
                  } flex flex-1`}
                />
                {list.key === "call" ? (
                  <CallIcon className={"w-7 h-7"} />
                ) : list.key === "applied" ? (
                  <AppliedIcon className={"w-7 h-7"} />
                ) : list.key === "accept" ? (
                  <OfferAcceptIcon className={"w-7 h-7"} />
                ) : list.key === "receive" ? (
                  <OfferReceived className={"w-7 h-7"} />
                ) : (
                  <GroupIcon className={"w-7 h-7"} />
                )}
                {index !== item.progress.length - 1 && (
                  <div className="border-l  mr-1 border-primary flex flex-1"></div>
                )}
              </div>
              <div className="flex flex-col flex-1">
                <div className="flex font-semibold text-xs italic text-[#6F6F6F] gap-4 mb-[6px]">
                  <p>Received 1:00pm</p>
                  <span>16th November 2024</span>
                </div>
                <div className="bg-[#FAFAFA] flex justify-between text-[#FF9800] text-xs font-semibold px-5 py-2">
                  <p
                    className={`w-1/4 ${
                      list.key === "call" && "text-[#4D4D4D]"
                    }`}
                  >
                    {list.title}
                  </p>
                  <div
                    className={`flex w-[40%] gap-2 ${
                      list.key === "call" && "text-[#6F6F6F]"
                    }`}
                  >
                    {list.key === "accept" ? (
                      <span>{list.accepted}</span>
                    ) : list.key === "receive" ? (
                      <span>By {item.company}</span>
                    ) : (
                      <>
                        <span>{list.time}</span>
                        <span>{list.date}</span>
                      </>
                    )}
                  </div>
                  <p
                    className={`w-1/4 ${
                      list.key === "call" && "text-[#6F6F6F]"
                    }`}
                  >
                    {list.key === "call" ? list.number : item.position}
                  </p>
                  <p
                    className={`w-1/4 text-center ${
                      list.key === "call" && "text-[#6F6F6F]"
                    }`}
                  >
                    Details
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobTrackDetail;
