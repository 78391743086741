export const ScheduledMeeting = [
  {
    meetingDate: "2024-01-10",
    meetingTime: "09:00-10:00",
    interviews: "Jane Doe's Interview",
    meetingType: "online",
    acceptedSchedule: 1,
    totalSchedule: 2,
  },
  {
    meetingDate: "2024-01-15",
    meetingTime: "11:00-12:00",
    interviews: "Mark Lee's Interview",
    meetingType: "facetoface",
  },
  {
    meetingDate: "2024-02-15",
    meetingTime: "11:00-12:00",
    interviews: "Mark Lee's Interview",
    meetingType: "facetoface",
  },
  {
    meetingDate: "2024-02-16",
    meetingTime: "11:00-12:00",
    interviews: "Mark Lee's Interview",
    meetingType: "facetoface",
  },
  {
    meetingDate: "2024-03-12",
    meetingTime: "13:00-14:00",
    interviews: "Samantha King's Interview",
    meetingType: "call",
    acceptedSchedule: 2,
    totalSchedule: 3,
  },
  {
    meetingDate: "2024-04-20",
    meetingTime: "10:00-11:00",
    interviews: "Michael Brown's Interview",
    meetingType: "facetoface",
  },
  {
    meetingDate: "2024-05-07",
    meetingTime: "12:00-13:00",
    interviews: "Emily White's Interview",
    meetingType: "online",
  },
  {
    meetingDate: "2024-06-21",
    meetingTime: "14:00-15:00",
    interviews: "Chris Green's Interview",
    meetingType: "call",
  },
  {
    meetingDate: "2024-07-18",
    meetingTime: "11:00-12:00",
    interviews: "Nina Black's Interview",
    meetingType: "facetoface",
  },
  {
    meetingDate: "2024-08-25",
    meetingTime: "10:00-11:00",
    interviews: "Liam Wilson's Interview",
    meetingType: "online",
  },
  {
    meetingDate: "2024-09-01",
    meetingTime: "10:00-11:00",
    interviews: "Pooja's Interview",
    meetingType: "facetoface",
    acceptedSchedule: 1,
    totalSchedule: 3,
  },
  {
    meetingDate: "2024-09-05",
    meetingTime: "10:00-11:00",
    interviews: "Pooja's Interview",
    meetingType: "facetoface",
  },
  {
    meetingDate: "2024-09-10",
    meetingTime: "10:00-11:00",
    interviews: "Pooja's Interview",
    meetingType: "facetoface",
  },
  {
    meetingDate: "2024-09-10",
    meetingTime: "12:00-13:00",
    interviews: "John Doe's Interview",
    meetingType: "online",
    acceptedSchedule: 2,
    totalSchedule: 3,
  },
  {
    meetingDate: "2024-09-15",
    meetingTime: "10:00-11:00",
    interviews: "Pooja's Interview",
    meetingType: "call",
  },
  {
    meetingDate: "2024-10-05",
    meetingTime: "10:00-11:00",
    interviews: "Pooja's Interview",
    meetingType: "online",
  },
  {
    meetingDate: "2024-10-20",
    meetingTime: "11:00-12:00",
    interviews: "Alice Johnson's Interview",
    meetingType: "facetoface",
  },
  {
    meetingDate: "2024-10-20",
    meetingTime: "13:00-14:00",
    interviews: "Bob Smith's Interview",
    meetingType: "facetoface",
  },
  {
    meetingDate: "2024-11-03",
    meetingTime: "10:00-11:00",
    interviews: "Pooja's Interview",
    meetingType: "call",
    acceptedSchedule: 2,
    totalSchedule: 5,
  },
  {
    meetingDate: "2024-11-18",
    meetingTime: "10:00-11:00",
    interviews: "Pooja's Interview",
    meetingType: "online",
  },
  {
    meetingDate: "2024-12-12",
    meetingTime: "09:00-10:00",
    interviews: "Ethan Harris's Interview",
    meetingType: "facetoface",
    acceptedSchedule: 1,
    totalSchedule: 4,
  },

  {
    meetingDate: "2025-01-16",
    meetingTime: "14:00-15:00",
    interviews: "David Lee's Interview",
    meetingType: "online",
  },
  {
    meetingDate: "2025-02-22",
    meetingTime: "10:00-11:00",
    interviews: "Sophie Taylor's Interview",
    meetingType: "facetoface",
  },
  {
    meetingDate: "2025-03-18",
    meetingTime: "11:00-12:00",
    interviews: "Olivia Brown's Interview",
    meetingType: "call",
    acceptedSchedule: 2,
    totalSchedule: 5,
  },
  {
    meetingDate: "2025-05-25",
    meetingTime: "10:00-11:00",
    interviews: "Ryan Johnson's Interview",
    meetingType: "facetoface",
  },
];
