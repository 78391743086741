import * as React from "react";
const LoadingAnimation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
    className={`animate-spin -ml-1 mr-3 text-white ${
      props?.className ? props?.className : " h-5 w-5"
    }`}
  >
    <circle
      cx={12}
      cy={12}
      r={10}
      stroke={props?.color ? props?.color : "currentColor"}
      strokeWidth={4}
      className="opacity-25"
    />
    <path
      fill={props?.color ? props?.color : "currentColor"}
      d="M4 12a8 8 0 0 1 8-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0 1 4 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      className="opacity-75"
    />
  </svg>
);
export default LoadingAnimation;
