export const LiveAssessmentsData = [
  {
    refId: "AS-7181MN",
    name: "C++ Assessment",
    assessmentsTaken: 4,
    newAssessments: 2,
    time: "08:55",
    date: "16/07/2024",
  },
  {
    refId: "AS-9202OP",
    name: "Swift Assessment",
    assessmentsTaken: 12,
    newAssessments: 6,
    time: "12:15",
    date: "15/07/2024",
  },
  {
    refId: "AS-1222QR",
    name: "Kotlin Assessment",
    assessmentsTaken: 7,
    newAssessments: 1,
    time: "10:20",
    date: "14/07/2024",
  },
  {
    refId: "AS-5262UV",
    name: "PHP Assessment",
    assessmentsTaken: 6,
    newAssessments: 3,
    time: "09:40",
    date: "12/07/2024",
  },
  {
    refId: "AS-7282WX",
    name: "Ruby Assessment",
    assessmentsTaken: 11,
    newAssessments: 4,
    time: "11:55",
    date: "11/07/2024",
  },
  {
    refId: "AS-9303YZ",
    name: "SQL Assessment",
    assessmentsTaken: 4,
    newAssessments: 1,
    time: "17:20",
    date: "10/07/2024",
  },
  {
    refId: "AS-1424AA",
    name: "HTML/CSS Assessment",
    assessmentsTaken: 9,
    newAssessments: 2,
    time: "12:35",
    date: "09/07/2024",
  },
  {
    refId: "AS-3444BB",
    name: "AWS Assessment",
    assessmentsTaken: 13,
    newAssessments: 7,
    time: "14:45",
    date: "08/07/2024",
  },
  {
    refId: "AS-1234AB",
    name: "Python Assessment",
    assessmentsTaken: 5,
    newAssessments: 0,
    time: "10:12",
    date: "22/07/2024",
  },
  {
    refId: "AS-5678CD",
    name: "JavaScript Assessment",
    assessmentsTaken: 8,
    newAssessments: 0,
    time: "14:25",
    date: "21/07/2024",
  },
  {
    refId: "AS-9101EF",
    name: "React Native Assessment",
    assessmentsTaken: 6,
    newAssessments: 0,
    time: "11:35",
    date: "20/07/2024",
  },
  {
    refId: "AS-1121GH",
    name: "Java Assessment",
    assessmentsTaken: 10,
    newAssessments: 0,
    time: "09:45",
    date: "19/07/2024",
  },
  {
    refId: "AS-3141IJ",
    name: "Node.js Assessment",
    assessmentsTaken: 3,
    newAssessments: 0,
    time: "16:00",
    date: "18/07/2024",
  },
  {
    refId: "AS-5161KL",
    name: "TypeScript Assessment",
    assessmentsTaken: 9,
    newAssessments: 0,
    time: "13:10",
    date: "17/07/2024",
  },
];

export const AssessmentSetupData = [
  {
    refId: "AS-1234EF",
    name: "Jane Cooper",
    experience: "5 years",
    status: "Applied",
    time: "10:12",
    date: "22/07/2024",
  },
  {
    refId: "AS-5678GH",
    name: "Jane Cooper",
    experience: "5 years",
    status: "Applied",
    time: "14:30",
    date: "21/07/2024",
  },
  {
    refId: "AS-9101IJ",
    name: "Jane Cooper",
    experience: "5 years",
    status: "Applied",
    time: "11:45",
    date: "20/07/2024",
  },
  {
    refId: "AS-1121KL",
    name: "Jane Cooper",
    experience: "5 years",
    status: "Applied",
    time: "09:50",
    date: "19/07/2024",
  },
  {
    refId: "AS-3141MN",
    name: "Jane Cooper",
    experience: "5 years",
    status: "Applied",
    time: "16:20",
    date: "18/07/2024",
  },
  {
    refId: "AS-5161OP",
    name: "Jane Cooper",
    experience: "5 years",
    status: "Applied",
    time: "13:15",
    date: "17/07/2024",
  },
  {
    refId: "AS-7181QR",
    name: "Esther Howard",
    experience: "5 years",
    status: "Applied",
    time: "08:40",
    date: "16/07/2024",
  },
  {
    refId: "AS-9202ST",
    name: "Esther Howard",
    experience: "5 years",
    status: "Applied",
    time: "12:10",
    date: "15/07/2024",
  },
  {
    refId: "AS-1222UV",
    name: "Esther Howard",
    experience: "5 years",
    status: "Applied",
    time: "10:25",
    date: "14/07/2024",
  },
  {
    refId: "AS-3242WX",
    name: "Esther Howard",
    experience: "5 years",
    status: "Applied",
    time: "15:35",
    date: "13/07/2024",
  },
  {
    refId: "AS-5262YZ",
    name: "Esther Howard",
    experience: "5 years",
    status: "Applied",
    time: "09:55",
    date: "12/07/2024",
  },
  {
    refId: "AS-7282AB",
    name: "Esther Howard",
    experience: "5 years",
    status: "Applied",
    time: "11:50",
    date: "11/07/2024",
  },
  {
    refId: "AS-9303CD",
    name: "Esther Howard",
    experience: "5 years",
    status: "Applied",
    time: "17:15",
    date: "10/07/2024",
  },
  {
    refId: "AS-1424EF",
    name: "Esther Howard",
    experience: "5 years",
    status: "Applied",
    time: "12:40",
    date: "09/07/2024",
  },
  {
    refId: "AS-3444GH",
    name: "Esther Howard",
    experience: "5 years",
    status: "Applied",
    time: "14:50",
    date: "08/07/2024",
  },
];
