import * as React from "react";
const PaymentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#4D4D4D"
      d="M9.333 6.667c0 .252.181.466.43.507l2.027.338a1.84 1.84 0 0 1 1.543 1.822c0 1.103-.897 2-2 2v.667H10v-.667c-1.103 0-2-.897-2-2h1.333c0 .367.299.667.667.667h1.333c.368 0 .667-.3.667-.667a.512.512 0 0 0-.43-.507L9.544 8.49A1.84 1.84 0 0 1 8 6.667c0-1.102.897-2 2-2v-.666h1.333v.666c1.103 0 2 .898 2 2H12a.667.667 0 0 0-.667-.666H10c-.368 0-.667.299-.667.666ZM16 3.334v11.333H0V3.334c0-1.103.897-2 2-2h12c1.103 0 2 .897 2 2Zm-1.333 0A.667.667 0 0 0 14 2.667H2c-.368 0-.667.3-.667.667v10h13.334v-10Zm-12 2.667h4V4.667h-4v1.334Zm0 2.666h4V7.334h-4v1.333Zm0 2.667h4v-1.333h-4v1.333Z"
    />
  </svg>
);
export default PaymentIcon;
