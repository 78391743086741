import React from "react";
import { removeToken } from "../store/actions/auth.action";
import axios from "./axios.instance";
import store from "../store/index";
const { dispatch } = store;
import { refreshTokenService } from "../endpoints/auth.service";

let isRefreshing = false;
let failedQueue = [];

const useAxiosInstance = () => {
  React.useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers["accessToken"] = token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const processQueue = (error, token = null) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });
      failedQueue = [];
    };

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        if (
          error.response?.status === 401 &&
          !originalRequest._retry &&
          !originalRequest.url.includes("/auth/")
        ) {
          if (!isRefreshing) {
            isRefreshing = true;

            const refreshToken = localStorage.getItem("refreshToken");

            return new Promise((resolve, reject) => {
              if (refreshToken) {
                refreshTokenService(refreshToken)
                  .then((res) => {
                    const { token, refreshToken } = res.data;
                    localStorage.setItem("token", token);
                    localStorage.setItem("refreshToken", refreshToken);
                    originalRequest.headers["accessToken"] = token;

                    return axios(originalRequest).then(resolve).catch(reject);
                  })
                  .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                  })
                  .finally(() => {
                    isRefreshing = false;
                  });
              }
            });
          }

          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          }).then((token) => {
            originalRequest.headers["accessToken"] = token;
            return axios(originalRequest);
          });
        }

        if (error.response?.status === 401) {
          dispatch(removeToken());
        }

        throw error;
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch]);

  return axios;
};

export { useAxiosInstance };
