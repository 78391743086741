import * as React from "react";
const CalenderIcon = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4373_2080)">
      <path
        d="M6.41667 6.41667H0V1.75C0 1.28587 0.184374 0.840752 0.512563 0.512563C0.840752 0.184374 1.28587 0 1.75 0L6.41667 0V6.41667ZM1.16667 5.25H5.25V1.16667H1.75C1.59529 1.16667 1.44692 1.22812 1.33752 1.33752C1.22812 1.44692 1.16667 1.59529 1.16667 1.75V5.25Z"
        fill="#4D4D4D"
      />
      <path
        d="M14.0007 6.41667H7.58398V0H12.2507C12.7148 0 13.1599 0.184374 13.4881 0.512563C13.8163 0.840752 14.0007 1.28587 14.0007 1.75V6.41667ZM8.75065 5.25H12.834V1.75C12.834 1.59529 12.7725 1.44692 12.6631 1.33752C12.5537 1.22812 12.4054 1.16667 12.2507 1.16667H8.75065V5.25Z"
        fill="#4D4D4D"
      />
      <path
        d="M6.41667 14.0003H1.75C1.28587 14.0003 0.840752 13.8159 0.512563 13.4877C0.184374 13.1595 0 12.7144 0 12.2503L0 7.58362H6.41667V14.0003ZM1.16667 8.75028V12.2503C1.16667 12.405 1.22812 12.5534 1.33752 12.6628C1.44692 12.7722 1.59529 12.8336 1.75 12.8336H5.25V8.75028H1.16667Z"
        fill="#4D4D4D"
      />
      <path
        d="M12.2507 14.0003H7.58398V7.58362H14.0007V12.2503C14.0007 12.7144 13.8163 13.1595 13.4881 13.4877C13.1599 13.8159 12.7148 14.0003 12.2507 14.0003ZM8.75065 12.8336H12.2507C12.4054 12.8336 12.5537 12.7722 12.6631 12.6628C12.7725 12.5534 12.834 12.405 12.834 12.2503V8.75028H8.75065V12.8336Z"
        fill="#4D4D4D"
      />
    </g>
    <defs>
      <clipPath id="clip0_4373_2080">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CalenderIcon;
