import * as React from "react";
const Phone = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8515 2.19335L12.7381 0.0800171L8.56479 4.26002L10.8381 6.53335C9.92479 8.57335 8.51146 9.99335 6.53813 10.8467L4.26479 8.57335L0.078125 12.74L2.19146 14.8534C2.93146 15.5934 3.92479 16 4.99813 16C9.83146 16 15.9981 9.83335 15.9981 5.00002C15.9981 3.92668 15.5915 2.93335 14.8515 2.19335ZM4.99813 14.6667C4.28479 14.6667 3.61813 14.4 3.13813 13.9134L1.96479 12.74L4.25813 10.4467L6.21146 12.4L6.61813 12.2467C9.25813 11.24 11.1581 9.34669 12.2515 6.62002L12.4181 6.21335L10.4648 4.25335L12.7581 1.96002L13.9315 3.13335C14.4181 3.62002 14.6848 4.28002 14.6848 4.99335C14.6848 9.14668 9.17146 14.66 5.01813 14.66L4.99813 14.6667Z"
      fill="#2E2E2E"
    />
  </svg>
);
export default Phone;
