import React, { useCallback, useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css"; // Import Quill's styles
import ChevronLeftIcon from "../../../assets/svg/ChevronLeftIcon";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getAssessmentDetails } from "../../../endpoints/assessment.service";
import SectionContainer from "./components/SectionContainer";
import EditBoxIcon from "../../../assets/svg/EditBoxIcon";
import LeftArrowIcon from "../../../assets/svg/LeftArrowIcon";
import RightArrowIcon from "../../../assets/svg/RightArrowIcon";

const AssessmentView = () => {
  const editorRef = useRef(null);
  const { assessmentId } = useParams();
  const navigate = useNavigate();
  const [assessmentDetails, setAssessmentDetails] = useState();
  const [activeQuestion, setActiveQuestion] = useState({
    section: 0,
    question: 0,
  });
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const currentSection = assessmentDetails
    ? assessmentDetails?.sections[activeQuestion?.section]
    : {};
  const currentQuestion = assessmentDetails
    ? currentSection?.questions[activeQuestion?.question]
    : {};

  const handlePrev = () => {
    const isFirstQuestion = !activeQuestion?.question;
    if (isFirstQuestion) {
      setActiveQuestion((prev) => {
        return {
          section: prev?.section - 1,
          question:
            assessmentDetails?.sections[prev?.section - 1]?.questions?.length -
            1,
        };
      });
    } else {
      setActiveQuestion((prev) => {
        return { ...prev, question: prev?.question - 1 };
      });
    }
  };

  const handleNext = () => {
    const isLastQuestion =
      currentSection?.questions?.length - 1 === activeQuestion?.question;
    if (isLastQuestion) {
      setActiveQuestion((prev) => {
        return { section: prev?.section + 1, question: 0 };
      });
    } else {
      setActiveQuestion((prev) => {
        return { ...prev, question: prev?.question + 1 };
      });
    }
  };

  const onBackPress = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    getAssessmentDetails(assessmentId).then((res) => {
      if (res?.success) {
        setAssessmentDetails(res?.data[0]);
      }
    });
    return () => {};
  }, []);

  // Back Next controller
  useEffect(() => {
    if (assessmentDetails) {
      const sectionLastIndex = assessmentDetails?.sections?.length - 1;
      const questionLastIndex =
        assessmentDetails?.sections[sectionLastIndex]?.questions?.length - 1;
      if (activeQuestion?.question === 0 && activeQuestion?.section === 0) {
        setShowPrev(false);
      } else {
        setShowPrev(true);
      }

      if (
        activeQuestion?.question === questionLastIndex &&
        activeQuestion?.section === sectionLastIndex
      ) {
        setShowNext(false);
      } else {
        setShowNext(true);
      }
    }
  }, [activeQuestion, assessmentDetails]);

  useEffect(() => {
    if (currentQuestion?.questionType === 1) {
      const quill = new Quill(editorRef.current, {
        theme: "snow", // Use the snow theme
        readOnly: true, // Set editor to read-only
        modules: {
          toolbar: false, // Disable the toolbar
        },
      });

      if (currentQuestion?.questionType === 1) {
        // Set initial content
        if (typeof currentQuestion?.question === "string") {
          quill.clipboard.dangerouslyPasteHTML(
            currentQuestion?.question?.trim()
          );
        }
      }

      // Handle content change
      quill.on("text-change", () => {
        const content = quill.root.innerHTML;
        onChange(content);
      });
    }

    // editorRef.current = quill;

    // Clean up on unmount
    return () => {
      if (currentQuestion?.questionType === 1) {
        // quill.off("text-change");
      }
    };
  }, [currentQuestion]);

  return (
    <>
      <style>
        {`
      .quill-container {
    border: none !important;
    box-shadow: none !important;
    height: 300px;
}

.ql-editor {
    padding: 0;
    margin: 0;
}

.ql-container {
    border: none !important;
    box-shadow: none !important;
}
      `}
      </style>
      <div className="flex w-full flex-grow p-4 gap-4">
        {/* Section container */}
        <div className="flex flex-col bg-white rounded-lg w-[25%] gap-2">
          {assessmentDetails?.sections?.map((section, sectionIndex) => {
            return (
              <SectionContainer
                section={section}
                sectionIndex={sectionIndex}
                activeQuestion={activeQuestion}
                setActiveQuestion={setActiveQuestion}
              />
            );
          })}
        </div>

        {/* Body container */}
        <div className="flex flex-col bg-white rounded-lg w-[75%] py-4 px-6">
          <div className="flex items-center justify-between">
            <button onClick={onBackPress} className="flex items-center">
              <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
              <label className="text-xs font-medium text-primary">
                Back to listing
              </label>
            </button>
            <Link
              to={`/my-assessments/edit-assessment/${assessmentId}`}
              className="flex items-center text-primary text-xs font-medium gap-2 border rounded-md px-2.5 py-1.5"
            >
              <EditBoxIcon className={"w-4 h-4"} fill={"black"} />
              Edit Questions
            </Link>
          </div>

          <div className="py-10 pl-4">
            <div className="flex items-center justify-between border-b border-light-grey pb-4">
              <label className="font-semibold text-primary text-sm">
                {assessmentDetails?.title}
              </label>
              <label className="font-semibold text-primary text-xs">
                Ref : {assessmentDetails?.refNo}
              </label>
            </div>

            <div className="flex items-center justify-center bg-light-white-700 rounded-md mt-9 py-2">
              <label className="font-semibold text-sm text-primary">
                Section {activeQuestion?.section + 1} :{" "}
                <span className="font-medium">{currentSection?.title}</span>
              </label>
            </div>

            {/* Back Next controller */}
            <div className="flex items-center justify-between my-5">
              <label className="font-medium text-light-black text-xs">
                Question {activeQuestion?.question + 1} of{" "}
                {currentSection?.questions?.length}
              </label>
              <div className="flex items-center gap-4">
                {showPrev ? (
                  <button
                    onClick={handlePrev}
                    className="flex items-center gap-1"
                  >
                    <LeftArrowIcon className="w-4 h-4" />
                    <span className="font-medium text-light-black text-xs">
                      Back
                    </span>
                  </button>
                ) : null}
                {showNext ? (
                  <button
                    onClick={handleNext}
                    className="flex items-center gap-1"
                  >
                    <span className="font-medium text-light-black text-xs">
                      Next
                    </span>
                    <RightArrowIcon className="w-4 h-4" />
                  </button>
                ) : (
                  <div className="w-12" />
                )}
              </div>
            </div>
            {currentQuestion?.questionType === 2 ? (
              <span className="font-medium text-sm text-primary">
                {currentQuestion?.question}
              </span>
            ) : (
              <div ref={editorRef} className="font-medium text-xs" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentView;
