import * as React from "react";
const ClipboardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      fill="#6F6F6F"
      d="M11.25 2.813a2.8 2.8 0 0 1 .563 1.687v.563H6.186V4.5a2.8 2.8 0 0 1 .563-1.688H3.937a.563.563 0 0 0-.562.563v11.813a.563.563 0 0 0 .563.562h10.124a.562.562 0 0 0 .563-.563V3.376a.562.562 0 0 0-.563-.563H11.25Z"
      opacity={0.2}
    />
    <path
      stroke="#6F6F6F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.75 10.688h4.5M6.75 8.438h4.5M11.25 2.813h2.813a.562.562 0 0 1 .562.562v11.813a.562.562 0 0 1-.563.562H3.938a.563.563 0 0 1-.563-.563V3.376a.563.563 0 0 1 .563-.563H6.75"
    />
    <path
      stroke="#6F6F6F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.188 5.063V4.5a2.813 2.813 0 0 1 5.625 0v.563H6.186Z"
    />
  </svg>
);
export default ClipboardIcon;
