import { useNavigate } from "react-router-dom";
import ChevronDown from "../../assets/svg/ChevronDown";
import JobCard from "./components/JobCard";
import { useEffect } from "react";
import { getJobs } from "../../endpoints/job.service";
import { useState } from "react";
import JobCardPlaceholder from "./components/JobCardPlaceholder";

const JobsListPage = () => {
  const [jobList, setJobList] = useState([]);
  const [isJobLoading, setIsJobLoading] = useState(false);

  useEffect(() => {
    setIsJobLoading(true);
    getJobs()
      .then((response) => {
        const { success, data } = response;
        if (success) {
          setJobList(data);
        }
      })
      .finally(() => setIsJobLoading(false));
  }, []);

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4 cursor-pointer">
      {/* Heading container */}
      <div
        className={`flex flex-col gap-4 justify-around border rounded-md mt-4 overflow-hidden bg-[url(/src/assets/images/assessmentBg.png)] bg-no-repeat bg-center p-3`}
      >
        <div className="flex items-center w-full gap-3">
          <div className="rounded-md bg-white flex-1">
            <input
              className="bg-transparent px-3 h-8 text-xs font-medium w-full"
              placeholder="Search by job titles or keyword..."
            />
          </div>
          <div className="rounded-md bg-white flex-1">
            <input
              className="bg-transparent px-3 h-8 text-xs font-medium w-full"
              placeholder="Search by job titles or keyword..."
            />
          </div>
          <div className="rounded-md bg-white flex-1">
            <input
              className="bg-transparent px-3 h-8 text-xs font-medium w-full"
              placeholder="Search by job titles or keyword..."
            />
          </div>
          <button className="font-medium text-sm h-8 rounded-md bg-white px-5 text-[#4d4d4d]">
            Search
          </button>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex items-center border border-white rounded-md text-white text-xs font-semibold py-1 px-2 gap-6">
            <span>Full Time</span>
            <ChevronDown className={"w-5 h-5"} fill={"white"} />
          </div>

          <div className="flex items-center border border-white rounded-md text-white text-xs font-semibold py-1 px-2 gap-6">
            <span>Paying $0</span>
            <ChevronDown className={"w-5 h-5"} fill={"white"} />
          </div>

          <div className="flex items-center border border-white rounded-md text-white text-xs font-semibold py-1 px-2 gap-6">
            <span>to $350</span>
            <ChevronDown className={"w-5 h-5"} fill={"white"} />
          </div>

          <div className="flex items-center border border-white rounded-md text-white text-xs font-semibold py-1 px-2 gap-6">
            <span>Listed Anytime</span>
            <ChevronDown className={"w-5 h-5"} fill={"white"} />
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar px-24 py-5 gap-3.5">
        {isJobLoading
          ? [1, 2, 3, 4, 5, 6, 7]?.map((item) => {
              return <JobCardPlaceholder key={item} />;
            })
          : jobList?.map((item) => {
              return <JobCard key={item?._id} data={item} />;
            })}
      </div>
    </div>
  );
};

export default JobsListPage;
