import React from "react";
import GroupIcon from "../../assets/svg/GroupIcon";
import { JobTrack } from "./dummydata";
import CallIcon from "../../assets/svg/CallIcon";
import AppliedIcon from "../../assets/svg/AppliedIcon";
import { useNavigate } from "react-router-dom";

const JobTrackPage = () => {
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    navigate("/job-track-detail", { state: { item } });
  };
  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-20">
      {JobTrack.map((data) => (
        <div className="flex items-end gap-7 space-y-8">
          <div>
            {data.key === "call" ? (
              <CallIcon className={"w-8 h-8"} />
            ) : data.key === "applied" ? (
              <AppliedIcon className={"w-8 h-8"} />
            ) : (
              <GroupIcon className={"w-8 h-8"} />
            )}
          </div>
          <div className="flex flex-col flex-1">
            <div className="flex font-semibold text-xs italic text-[#6F6F6F] gap-4 mb-[6px]">
              <p>Received 1:00pm</p>
              <span>16th November 2024</span>
            </div>
            <div
              className="bg-[#FAFAFA] flex justify-between text-[#FF9800] text-xs font-semibold px-5 py-2 cursor-pointer"
              onClick={() => handleItemClick(data)}
            >
              <p
                className={`w-1/4 ${
                  (data.key === "applied") | (data.key === "call") &&
                  "text-[#4D4D4D]"
                }`}
              >
                {data.title}
              </p>
              <div
                className={`flex w-[40%] gap-2 ${
                  (data.key === "applied") | (data.key === "call") &&
                  "text-[#6F6F6F]"
                }`}
              >
                {data.key === "applied" ? (
                  <span>{data.mode}</span>
                ) : (
                  <>
                    <span>{data.time}</span>
                    <span>{data.date}</span>
                  </>
                )}
              </div>
              <p
                className={`w-1/4 ${
                  (data.key === "applied") | (data.key === "call") &&
                  "text-[#6F6F6F]"
                }`}
              >
                {data.key === "call" ? data.number : data.position}
              </p>
              <p
                className={`w-1/4 text-center ${
                  (data.key === "applied") | (data.key === "call") &&
                  "text-[#6F6F6F]"
                }`}
              >
                {data.id}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default JobTrackPage;
