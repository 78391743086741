import * as React from "react";
const ContactsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#4D4D4D"
        d="M2.333 7.583h1.75V8.75h-1.75V7.583ZM5.25 8.75H7V7.583H5.25V8.75Zm-2.917 2.333h1.75V9.917h-1.75v1.166Zm2.917 0H7V9.917H5.25v1.166Zm-2.917-7h1.75V2.917h-1.75v1.166Zm2.917 0H7V2.917H5.25v1.166ZM2.333 6.417h1.75V5.25h-1.75v1.167Zm2.917 0H7V5.25H5.25v1.167ZM14 4.667V14H0V1.75A1.75 1.75 0 0 1 1.75 0h5.833a1.75 1.75 0 0 1 1.75 1.75v1.167h2.917A1.75 1.75 0 0 1 14 4.667ZM8.167 1.75a.583.583 0 0 0-.584-.583H1.75a.583.583 0 0 0-.583.583v11.083h7V1.75Zm4.666 2.917a.583.583 0 0 0-.583-.584H9.333v8.75h3.5V4.667ZM10.5 8.75h1.167V7.583H10.5V8.75Zm0 2.333h1.167V9.917H10.5v1.166Zm0-4.666h1.167V5.25H10.5v1.167Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ContactsIcon;
