import env from "./env";
const server = env.apiLink;

const api = {
  // auth
  login: `user/login`,
  token: `user/login/token`,
  signup: `user/signup`,
  verifyEmail: "user/verify-email",
  resendOtpverifyEmail: "user/resend-verify-email",
  updateDisableStatus: "user/update-disable-status",
  changePassword: "user/change-password",
  recruiterAddCompany: "recruiter/add-company",

  //candidate
  getCandidates: "contact/get-candidates",
  getRegisterCandidates: "contact/registered-candidates",
  getCandidateAssessment: "contact/candidate-assessments",

  getCandidateDetails: "contact/candidate-details",
  addCandidate: "contact/add-candidate",
  updateCandidate: "contact/update-candidate",
  deleteCandidate: "contact/delete-candidate",
  addExperience: "contact/add-experience",
  updateExperience: "contact/update-experience",
  getExperienceList: "contact/experiences",
  getExperience: "contact/get-experience",
  deleteExperience: "contact/delete-experience",
  getContactSkills: "contact/candidate-skills",

  //company
  getCompany: "",
  companyList: "company/lists",
  addCompany: "company/add",
  updateCompany: "company/update",
  deleteCompany: "company/delete",
  listSkill: "skill/lists",

  //company contact
  getCompanyContact: "company/contacts",
  addCompanyContact: "company/add-contact",
  deleteCompanyContact: "company/delete-contact",
  updateCompanyContact: "company/update-contact",

  createRecruiterProfile: `recruiter-profile`,
  resetPassword: `auth/reset-password`,
  registerEmailVerify: `auth/email-verify`,
  forgotPassword: `user/forgot-password`,
  forgotPasswordVerify: `user/forgot-password/verify`,
  resetForgotPassword: `user/reset-forgot-password`,

  //assessment
  listAssessment: `assessment/lists`,
  createAssessment: `assessment/create-assessment`,
  deleteAssessment: `assessment/delete-assessment`,
  assessmentDetails: `assessment/details`,
  updateAssessment: `assessment/update-assessment`,
  deleteAssessment: `assessment/delete-assessment`,
  createCompany: `assessment/create-company`,
  sendAssessment: `assessment/send-assessment`,

  //assessment section
  createSection: `assessment/create-section`,
  updateSection: `assessment/update-section`,
  getSection: `assessment/section`,
  updateSection: `assessment/update-section`,
  deleteSection: `assessment/delete-section`,

  //assessment question
  updateQuestion: `assessment/update-question`,
  createQuestion: `assessment/create-question`,
  getQuestionBySectionId: `assessment/question`,
  updateQuestion: `assessment/update-question`,
  deleteQuestion: `assessment/delete-question`,

  //Job
  getJobList: "job/lists",
  getJobDetails: "job/details",
  addJob: "job/add",
  shareJob: "job/share-job",
  verifyJob: "user/verify-share-job",
  verifyJobStatus: "user/share-job-verify-status",
  updateJob: "job/update",
  deleteJob: "job/delete/",

  // Education or Certificate
  addCertificateOrEducation: "contact/add-certificate",
  updateCertificateOrEducation: "contact/update-certificate",
  getCertificateOrEducation: "contact/certificates",

  //skill
  addSkill: "skill/add",
  updateSkill: "skill/update",
  deleteSkill: "skill/delete/",
  skillSearch: "skill/search",
  getSkillIndividualPercentage: "skill/get-individual-percentages",

  getMatchedCandidate: "skill/get-matched-candidates",

  //images
  profileImage: "medias/images-direct-url",

  //recruiter
  accountMembers: "recruiter/members",
  recruiterDetail: "recruiter/details",
  recruiterProfileUpdate: "recruiter/update-profile",
  approvalRequest: "recruiter/update-request",
  suspendAccount: "recruiter/suspend-account",
  userAdmin: "recruiter/make-admin",
  deleteRecruiterAccount: `user/deactivate`,

  //referral
  referralProfileUpdate: "referral/update-profile",
  referralContacts: "referral/contacts",
  referralMatchedContacts: "referral/matched-contacts",
  referralMatchedContactDetails: "referral/matched-contact-details",
  inviteUserEmail: "referral/invite",
};

export default api;
