import { useEffect, useState } from "react";

const PlusCircleIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <path
        d="M12 2.4375C10.1087 2.4375 8.25991 2.99833 6.68736 4.04907C5.11482 5.09981 3.88917 6.59327 3.16541 8.34059C2.44164 10.0879 2.25227 12.0106 2.62125 13.8656C2.99022 15.7205 3.90096 17.4244 5.2383 18.7617C6.57564 20.099 8.27951 21.0098 10.1345 21.3788C11.9894 21.7477 13.9121 21.5584 15.6594 20.8346C17.4067 20.1108 18.9002 18.8852 19.9509 17.3126C21.0017 15.7401 21.5625 13.8913 21.5625 12C21.5595 9.46478 20.5511 7.03425 18.7584 5.24158C16.9658 3.44891 14.5352 2.44048 12 2.4375ZM12 20.4375C10.3312 20.4375 8.69992 19.9426 7.31238 19.0155C5.92484 18.0884 4.84339 16.7706 4.20477 15.2289C3.56616 13.6871 3.39907 11.9906 3.72463 10.3539C4.05019 8.71721 4.85379 7.21379 6.03379 6.03379C7.2138 4.85378 8.71722 4.05019 10.3539 3.72462C11.9906 3.39906 13.6871 3.56615 15.2289 4.20477C16.7706 4.84338 18.0884 5.92484 19.0155 7.31238C19.9427 8.69992 20.4375 10.3312 20.4375 12C20.435 14.237 19.5453 16.3817 17.9635 17.9635C16.3817 19.5453 14.237 20.435 12 20.4375ZM16.3125 12C16.3125 12.1492 16.2532 12.2923 16.1478 12.3977C16.0423 12.5032 15.8992 12.5625 15.75 12.5625H12.5625V15.75C12.5625 15.8992 12.5032 16.0423 12.3978 16.1477C12.2923 16.2532 12.1492 16.3125 12 16.3125C11.8508 16.3125 11.7077 16.2532 11.6023 16.1477C11.4968 16.0423 11.4375 15.8992 11.4375 15.75V12.5625H8.25C8.10082 12.5625 7.95775 12.5032 7.85226 12.3977C7.74677 12.2923 7.6875 12.1492 7.6875 12C7.6875 11.8508 7.74677 11.7077 7.85226 11.6023C7.95775 11.4968 8.10082 11.4375 8.25 11.4375H11.4375V8.25C11.4375 8.10082 11.4968 7.95774 11.6023 7.85225C11.7077 7.74676 11.8508 7.6875 12 7.6875C12.1492 7.6875 12.2923 7.74676 12.3978 7.85225C12.5032 7.95774 12.5625 8.10082 12.5625 8.25V11.4375H15.75C15.8992 11.4375 16.0423 11.4968 16.1478 11.6023C16.2532 11.7077 16.3125 11.8508 16.3125 12Z"
        fill={fill}
      />
    </svg>
  );
};

export default PlusCircleIcon;
