import { useEffect, useState } from "react";

const FolderIcon = ({ className, fill }) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 24" stroke-width="0" stroke={fill} class={_class}>
            <path d="M21.9996 2.4H12.5868L11.1868 0.720001C10.9993 0.494418 10.7644 0.312944 10.4988 0.188497C10.2331 0.0640498 9.94334 -0.000309343 9.65001 1.11789e-06H3.59961C3.06937 0.000636261 2.56103 0.211554 2.1861 0.586489C1.81116 0.961424 1.60024 1.46976 1.59961 2V8.8C1.59961 8.90609 1.64175 9.00783 1.71677 9.08284C1.79178 9.15786 1.89352 9.2 1.99961 9.2H11.976C12.2943 9.19972 12.608 9.1236 12.8911 8.97793C13.1741 8.83227 13.4184 8.62127 13.6036 8.3624L14.6464 6.9024C14.7577 6.74719 14.9043 6.62067 15.0742 6.53332C15.244 6.44596 15.4322 6.40026 15.6232 6.4H23.5996C23.7057 6.4 23.8074 6.35786 23.8825 6.28284C23.9575 6.20783 23.9996 6.10609 23.9996 6V4.4C23.999 3.86976 23.7881 3.36142 23.4131 2.98649C23.0382 2.61155 22.5298 2.40064 21.9996 2.4Z" fill="#D1D1D1" />
            <path d="M23.6 5.59961H15.6236C15.3053 5.59999 14.9917 5.67616 14.7086 5.82181C14.4256 5.96746 14.1813 6.17841 13.996 6.43721L12.9528 7.89721C12.8417 8.05249 12.6951 8.17905 12.5253 8.26642C12.3555 8.35379 12.1674 8.39945 11.9764 8.39961H2C1.46976 8.40024 0.961423 8.61116 0.586488 8.9861C0.211553 9.36103 0.000635143 9.86937 0 10.3996L0 21.9996C0.000635143 22.5298 0.211553 23.0382 0.586488 23.4131C0.961423 23.7881 1.46976 23.999 2 23.9996H23.6C24.1302 23.999 24.6386 23.7881 25.0135 23.4131C25.3884 23.0382 25.5994 22.5298 25.6 21.9996V7.59961C25.5994 7.06937 25.3884 6.56103 25.0135 6.1861C24.6386 5.81116 24.1302 5.60024 23.6 5.59961Z" fill="#ECECEC" />
        </svg>
    )
};

export default FolderIcon;