import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationCodeInput from "../../components/OtpInputBox/VerificationCodeInput";
import CustomInput from "../../components/CustomInput/CustomInput";
import regexList from "../../utils/regexList";
import { useForm } from "react-hook-form";
import {
  forgotPassword,
  forgotPasswordVerify,
  resetForgotPassword,
} from "../../endpoints/user.service";
import { useSnackbar } from "notistack";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";

const UpdateForgotPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const { email, otpCode } = state;
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const [loading, setLoading] = useState(false);

  const handleSubmitButton = async (data) => {
    try {
      setLoading(true);
      const payload = {
        email,
        otpCode,
        password: data?.password,
      };
      const response = await resetForgotPassword(payload);
      const { success } = response;
      if (success) {
        navigate("/login");
        enqueueSnackbar("Password updated successfully.", {
          variant: "success",
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center my-20 border rounded-xl px-10 w-max py-10 mx-auto bg-white">
      <span className="font-didact font-semibold text-3xl">
        Update your password
      </span>
      <span className="font-montserrat text-light-text font-medium mt-3">
        Secure Your Account with a New Password.{" "}
      </span>

      {/* {emailSent ? (
        <span className="font-montserrat text-light-black font-medium text-sm w-96 text-center">
          A verification code has been sent to your email address
          <span className="font-semibold">{verificationEmail}</span>, please
          check your inbox and insert the code to verify your email.
        </span>
      ) : (
        <span className="font-montserrat text-light-black font-medium text-sm w-96 text-center">
          Simply enter your email address in the field below, and we’ll send you
          detailed instructions to reset your password.
        </span>
      )} */}

      <div className="flex flex-col w-[90%] my-5 gap-4">
        <CustomInput
          label={"New Password"}
          name="password"
          placeholder={"Enter your new password"}
          rules={{
            required: "This field is required.",
            pattern: {
              value: regexList.password,
              message: "Please enter a valid password.",
            },
          }}
          control={control}
          errors={errors}
        />
        <CustomInput
          label={"Confirm new password"}
          name="confirmPassword"
          placeholder={"Confirm password"}
          rules={{
            required: "This field is required.",
            validate: (value) =>
              value === watch("password") || "Confirm Password do not match.",
          }}
          control={control}
          errors={errors}
        />
      </div>

      <button
        disabled={!isDirty}
        onClick={handleSubmit(handleSubmitButton)}
        className={`flex items-center justify-center gap-4 font-montserrat font-medium text-sm text-white bg-primary rounded-full py-3 w-[28rem] mt-3 ${
          !isDirty ? "opacity-50" : "opacity-100"
        }`}
      >
        Update Password
        {loading && <LoadingAnimation />}
      </button>
    </div>
  );
};

export default UpdateForgotPassword;
